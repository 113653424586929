import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Button, Stack, Typography } from "@mui/material";
import { Row, Col } from "reactstrap";
import HFBox from "../../../Components/HFBox";
import HFHeader from "../../../Components/HFHeader";
import SimpleTable from "../../../Components/SimpleTable";
import HFSnackbar from "../../../Components/HFSnackbar";
import HFDialog from "../../../Components/HFDialog";
import HFIconButton from "../../../Components/HFIconButton";
import {
  HFAutoComplete,
  HFSelect,
  HFTextField,
} from "../../../Components/FormikFields";
import get from "../../../utils/get";
import post from "../../../utils/post";
import * as Yup from "yup";
import { Formik, Form, Field, connect } from "formik";
import AddConsumption from "./MaterialActions";
import Link from "@mui/material/Link";
import { Box } from "@mui/system";

const FormConfig = connect((props) => {
  const values = props.formik.values;
  useEffect(() => {
    if (values.material) {
      props.formik.setFieldValue("unit", values.material.unit);
    }
  }, [values.material]);

  return (
    <Form>
      <div className="row">
        <div className="col-md-3">
          <HFAutoComplete
            label="Material"
            name="material"
            options={props.materialList}
            getOptionLabel={(option) =>
              `${Object.keys(option).length > 0 ? option.material : ""}`
            }
          />
        </div>
        <div className="col-md-3">
          <HFAutoComplete
            label="Unit"
            name="unit"
            options={Object.values(props.unitList)}
            getOptionLabel={(option) => option}
          />
        </div>
        <div className="col-md-3">
          <HFAutoComplete
            label="Milestone"
            name="milestone"
            options={props.milestoneList}
            getOptionLabel={(option) => option.milestone}
          />
        </div>
        <div className="col-md-3">
          <HFTextField label="Quantity" name="quantity" />
        </div>
        <div className="col-md-3">
          <HFTextField label="Notes" name="notes" />
        </div>
        <div className="col-12">
          <Stack spacing={1} direction="row" className="mt-2">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button variant="outlined" type="reset">
              Reset
            </Button>
          </Stack>
        </div>
      </div>
    </Form>
  );
});

const AddRequirement = (props) => {
  console.log("add-req-props", props);
  const initialValues = {
    project_id: props.project.id,
    material: {},
    unit: "",
    quantity: "",
    notes: "",
  };
  const validation = Yup.object().shape({
    material: Yup.object().required("Required"),
  });

  const handleReqSubmit = (values, e) => {
    console.log("submit-values", e);
    post(`/app/usr/material-requirement`, JSON.stringify(values))
      .then((res) => {
        if (res.status) {
          props.setSnackbar({ ["status"]: true, ["msg"]: res.msg });
          e.resetForm();
          props.getData();
        } else {
          props.setSnackbar({
            ["status"]: true,
            ["type"]: "error",
            ["msg"]: res.msg,
          });
        }
        e.setSubmitting(false);
      })
      .catch((err) => {
        console.log("error", err);
        e.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={handleReqSubmit}
    >
      <FormConfig
        materialList={props.materialList}
        unitList={props.unitList}
        milestoneList={props.milestoneList}
      />
    </Formik>
  );
};
export default AddRequirement;
