import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
// import { ChakraProvider, Input, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Create, Delete, Download, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  deleteRentalLedgers,
  fetchRentalLedgers,
} from "../../../redux/actions/rentalsActions";

import { styled } from "@mui/system";
import moment from "moment";
import { deleteLedgerEntry } from "../../../redux/actions/ledgerAction";
import UpdateLedger from "./UpdateLedger";
import csvDownload from "json-to-csv-export";
import { invoice_type_arr, payment_type_arr, type_arr } from "../Constant";

const dataToConvert = {
  filename: "ledger.csv",
  delimiter: ",",
};

const Ledger = () => {
  const [loading, setLoading] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [enableInput, setEnableInput] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [initialValues, setInitialValues] = useState({
    rent: "",
    deposit: "",
    tenent_name: "",
    tenent_contact: "",
    notes: "",
  });
  const [UpdateValues, setUpdateValues] = useState({
    rent: "",
    deposit: "",
    tenent_name: "",
    tenent_contact: "",
    notes: "",
  });

  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataBuilding, setDataBuilding] = useState({});
  const [deleteId, setdeleteId] = useState({});

  //__________________ Model______________
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  //__________________ Model__________________

  const { id, uid, bookid } = useParams();
  function UpdatePropValue(data) {
    setUpdateValues({ ...data });
  }
  const StatusArr = ["", "Unpaid", "Paid", "Cancelled", "partially paid"];
  const [newFilterData, setNewFilterData] = useState({
    rl_id: "",
    type: "",
    invoice_type: "",
    amount: "",
    unit_name: "",
    date: "",
    payment_type: "",
  });

  const createUser = () => {
    setInitialValues({
      rent: "",
      deposit: "",
      tenent_name: "",
      tenent_contact: "",
      notes: "",
    });

    // setShowCreateUserForm(true);
    handleOpen();
  };
  function delUnits() {
    // console.log(deleteId, "delete id");
    deleteRentalLedgers(deleteId).then((res) => {
      console.log(res, "deleted");
      setAlertMsg("Ledger Deleted successfully");
    });
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  const download = () => {
    dataToConvert.data = data?.map((el) => {
      el.type = type_arr[el?.type];
      el.invoice_type = invoice_type_arr[el?.invoice_type];
      el.payment_type = payment_type_arr[el?.payment_type];
      return el;
    });

    csvDownload(dataToConvert);
  };

  useEffect(() => {
    fetchRentalLedgers().then((res) => {
      setData([...res?.res?.rows]);
    });
  }, [alertMsg]);

  useEffect(() => {
    const filteredData1 = data?.filter((item) => {
      return (
        (item?.rl_id.toString().includes(newFilterData.rl_id.toString()) ||
          newFilterData.rl_id.toString() == "") &&
        (invoice_type_arr[item?.invoice_type]
          .toLowerCase()
          .includes(newFilterData.invoice_type.toLowerCase()) ||
          newFilterData.invoice_type == "") &&
        (type_arr[item?.type]
          .toLowerCase()
          .includes(newFilterData.type.toLowerCase()) ||
          newFilterData.type == "") &&
        (item?.amount.toString().includes(newFilterData.amount.toString()) ||
          newFilterData.amount.toString() == "") &&
        ((item?.u_name + " " + item?.b_name)
          .toLowerCase()
          .includes(newFilterData.unit_name.toLowerCase()) ||
          newFilterData.unit_name.toLowerCase() == "") &&
        ((item?.date)
          .toLowerCase()
          .includes(newFilterData.date.toLowerCase()) ||
          newFilterData.date.toLowerCase() == "") &&
        (payment_type_arr[item?.payment_type]
          .toLowerCase()
          .includes(newFilterData.payment_type.toLowerCase()) ||
          newFilterData.payment_type == "")
        // item.date.toLowerCase().includes(filterText.toLowerCase()) ||
        // item.payment_type.toLowerCase().includes(filterText.toLowerCase()) ||
      );
    });
    setFilteredData((prev) => [...filteredData1]);
  }, [newFilterData, data]);

  return (
    <Container className="dashboard">
      <h3
        className="page-title"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "8px",
          wordSpacing: "15px",
        }}
      >
        Ledger
      </h3>
      <Card>
        <CardContent sx={{ overflow: "scroll", height: "83vh" }}>
          {
            <Button
              color="primary"
              type="button"
              className="previous"
              onClick={() => download()}
              variant="contained"
              sx={{ color: "black" }}
            >
              <Download />
              Download CSV
            </Button>
          }
          {/* <EditableReactTable reactTableData={reactTableData} /> */}

          <Table>
            <TableHead>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    padding: "7px 2px",
                  },
                  ".MuiTableRow-root": {
                    minWidth: "786px",
                  },
                  ".MuiInputBase-input": {
                    padding: "16.5px 5px;",
                  },
                }}
              >
                <TableCell>
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                      maxWidth: "40px",
                    }}
                    width="150px"
                    type="text"
                    name="rl_id"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    defaultValue={newFilterData.rl_id}
                    label="Id"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                      maxWidth: "120px",
                    }}
                    width="150px"
                    type="text"
                    name="invoice_type"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    defaultValue={newFilterData.invoice_type}
                    label="Invoice Type"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                      maxWidth: "70px",
                    }}
                    width="150px"
                    type="text"
                    name="type"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    defaultValue={newFilterData.type}
                    label="Type"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                      maxWidth: "100px",
                    }}
                    width="150px"
                    type="text"
                    name="amount"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    defaultValue={newFilterData.amount}
                    label="Amount"
                  />
                </TableCell>
                {/* <TableCell>Attachment</TableCell> */}

                <TableCell>
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                    }}
                    width="150px"
                    type="text"
                    name="unit_name"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    defaultValue={newFilterData.unit_name}
                    label="Unit Name"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                      maxWidth: "100px",
                    }}
                    width="150px"
                    type="text"
                    name="date"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    defaultValue={newFilterData.date}
                    label="Date"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                      maxWidth: "120px",
                    }}
                    width="150px"
                    type="text"
                    name="payment_type"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    defaultValue={newFilterData.payment_type}
                    label="Payment Type"
                  />
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Attachment</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log(data, "data")}
              {filteredData.map((obj) => (
                <TableRow
                  sx={{
                    ".MuiTableCell-root": {
                      padding: "10px 2px",
                    },
                  }}
                >
                  <TableCell> {obj.rl_id}</TableCell>
                  <TableCell> {invoice_type_arr[obj.invoice_type]}</TableCell>
                  <TableCell> {type_arr[obj.type]}</TableCell>
                  <TableCell>{obj.amount}</TableCell>
                  {/* <TableCell>
                    {<Link to={obj.attachment?.[0]}>attachments1</Link>}
                  </TableCell> */}
                  <TableCell>{obj.b_name + "||" + obj.u_name}</TableCell>

                  <TableCell>{moment(obj.date).format("DD-MM-YYYY")}</TableCell>
                  <TableCell>{payment_type_arr[obj.payment_type]}</TableCell>
                  <TableCell>{obj.description}</TableCell>
                  <TableCell>
                    {Array.isArray(obj.attachment) && obj.attachment.length ? (
                      <Link
                        to="/app/admin/Building/ledger/vimgArr/"
                        state={{ myState: obj.attachment }}
                      >
                        attachment1
                      </Link>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: 40 }}>
                    <Button
                      sx={{ minWidth: 10, p: 0, mr: 1 }}
                      variant="contained"
                      onClick={() => {
                        UpdatePropValue(obj);
                        handleOpenUpdate();
                      }}
                    >
                      <Edit />
                    </Button>
                    <Button
                      sx={{ minWidth: 10, p: 0 }}
                      variant="contained"
                      onClick={() => {
                        setdeleteId(obj);
                        handleOpenDialog();
                      }}
                    >
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box sx={{ height: 40, textAlign: "center " }}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </CardContent>
      </Card>

      {/* //_____________________________ Delete Unit _________________________ */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are U Sure You want to delete The building"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            if you click on Agree the building will be permanently delete from
            DataBase
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button
            onClick={() => {
              delUnits();
              handleCloseDialog();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* //_____________________________ Update Unit _____________________________ */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <UpdateLedger
            closePopup={handleCloseUpdate}
            initialValues={UpdateValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      <Snackbar
        open={!!alertMsg}
        autoHideDuration={3000}
        onClose={() => setAlertMsg("")}
        sx={{
          "&.MuiSnackbar-root": { left: "50%", transform: "translateX(-50%)" },
        }}
      >
        <Alert
          onClose={() => setAlertMsg("")}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Ledger;
