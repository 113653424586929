const myGetReq = async (url, header = {}) => {
  console.log("Get Request");
  try {
    console.log("fetching");
    const res = await fetch(url, {
      credentials: "same-origin",
      withCredentials: true,
      method: "GET",
      mode: "cors",
      // cache: "reload",
      ...header,
    });

    if (!res.ok) {
      window.location.href = "/app/login";
    }
    return await res.json();
  } catch (err) {
    return await err;
  }
};

export const downloadReq = async (url, isPreview) => {
  try {
    let fileName = url.split("/");
    fileName = fileName[fileName.length - 1];
    const res = await fetch(url, {
      credentials: "same-origin",
      withCredentials: true,
      method: "GET",
      mode: "cors",
    });
    if (!res.ok) {
      window.location.href = "/app/login";
    }

    if (isPreview) {
      return res.blob();
    } else {
      return await res.blob().then((blob) => {
        if (blob.type !== "application/json") {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      });
    }
  } catch (err) {
    return await err;
  }
};

export default myGetReq;
