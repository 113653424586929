import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    useDisclosure,
    Text,
    Box,
    Input,
    Select,
    Checkbox,
    Tooltip,
    useToast,

} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useState } from 'react'
import { addChildLedgerData, getChildLedgerData } from '../../redux/actions/childLedgerActions'
import { updateWoLedgerEntries } from '../../redux/actions/workOrderAction'
import { getLedgerData } from '../../redux/actions/ledgerAction'

const AddChildLedger = ({ cpp, rfun, cld, data, ledger }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [maxam, setmaxam] = useState(0)
    const [childledgerdata, setchildledgerdata] = useState([])
    const [legdata, setlegdata] = useState([])
    const [date, setdate] = useState(`${new Date(Date.now()).toISOString().split("T")[0]}`)
    console.log(ledger, cld, data, 303030)


    const [childledger, setchildledger] = useState({
        amount: data?.amount,
        ledgerid: "",
        info: "",
        date: `${new Date(Date.now()).toISOString().split("T")[0]}`,
    })

    const toast = useToast()




    function amset() {

        let MLAmoun = 0
        legdata.map((ele) => {
            if (ele.txn_id == childledger.ledgerid) {
                console.log(ele, "ergnbheg")
                MLAmoun = ele.amount
            }
        })


        let sum = 0
        if (childledgerdata?.length !== 0) {
            childledgerdata?.map((ele) => {
                //console.log(childledgerdata)
                if (ele?.ledgerid == childledger?.ledgerid) {
                    console.log(ele)
                    sum += Number(ele.amount)
                }
            })
        }

        console.log(sum, "sssssssssssssssssssssssssssss")
        setmaxam(Number(MLAmoun) - Number(sum))


    }

    // console.log(m, "tjtduj")


    function handlesubmit() {
        event.preventDefault()
        //  console.log("first", data.id,
        //     data.milestoneId)

        addChildLedgerData(childledger).then((res) => {
            console.log(res)
            updateWoLedgerEntries(
                data.id,
                childledger.ledgerid,
                data.milestoneId
            ).then((res) => {
                console.log(res, "808080080")
                rfun()
                toast({
                    title: 'Successfull.',
                    description: "Added Child Successfully",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                onClose()
            })
        })
    }


    function getchildledgerdata() {
        getChildLedgerData().then((res) => {
            setchildledgerdata(res.res.rows)
           
        })
    }


    function getlegdata() {
        console.log(data?.project_title.value, data?.category.value, 1010101)

        getLedgerData({
            value: data?.project_title.value,
            label: "Select All",
        }, {
            txn_id: 0,
            comments: "",
            date: "",
            person: "",
            paymentType: "",
            paymentMode: "",
            isGstBills: "",
            category: data?.category?.label,
            amount: "",
            txnReference: "",
        }, false).then((res) => {
            console.log(res)
            if (res.status) {
                let arr = []
                res?.data?.map((ele) => {
                    console.log(ele, "138138")
                    if (ele.mother == 1) {

                        arr.push(ele)
                    }
                })
                setlegdata(arr)

            }
        }).catch((res) => {
            //console.log(res, "103.103.103")
        })
    }

    useEffect(() => {
        if (isOpen) {

            getchildledgerdata()
            getlegdata()
            amset()
        }
    }, [isOpen, data])

    useEffect(() => {
        amset()
    }, [childledger])



    console.log(legdata, "127127127")
    return (
        <>


            < Button disabled={ledger} borderRadius={"0.5rem"} colorScheme='blue' m={1} onClick={() => {

                onOpen()
            }}>
                Child Entry
            </Button>

            <Modal

                size={"4xl"}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(50deg)' />
                <ModalContent borderRadius={"1rem"} overflowX={"hidden"} overflowY={"scroll"} h={"80vh"} mx={3} >
                    <form onSubmit={handlesubmit} >

                        <ModalHeader display={"flex"} flexWrap={"wrap-reverse"} justifyContent={"space-between"} fontSize={"large"} paddingLeft={"4"} paddingRight={"10"} pb={0} paddingTop={"6"} >
                            <Text px={2} fontSize={"1.5rem"} color={"rgb(2,80,86)"} fontWeight={"550"}  >
                                Child Ledger Entry
                            </Text>

                        </ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6} py={2}  >

                            <Box >
                                <Box mx={"0.2rem"} fontSize={"1.2rem"} >
                                    Mother Ledger
                                </Box>
                                <Select value={childledger.ledgerid} required my={"0.5rem"} size={"lg"} fontSize={"1.4rem"} onChange={(e) => {
                                    setchildledger({
                                        ...childledger,
                                        ["ledgerid"]: e.target.value
                                    })
                                    //console.log(e.target.value)
                                }} >
                                    <option value="" >Please Select Mother Ledger</option>
                                    {
                                        legdata && legdata.length && legdata.map((ele) => {
                                            return <option key={ele.txn_id} value={ele.txn_id} >

                                                <Box> {`Ledger id ${ele.txn_id}  Amount ₹${new Intl.NumberFormat('en-IN').format(ele.amount)}   ${new Date(Date.now()).toDateString()} `}</Box>
                                            </option>
                                        })
                                    }
                                </Select>

                            </Box>
                            {(maxam == 0 || maxam < data?.amount) && < Box color={"red"} fontSize={"1.5rem"} >
                                Max Child Amount Reached
                            </Box>}


                            <Box mx={"0.2rem"} fontSize={"1.2rem"} >
                                Amount
                            </Box>

                            <Input disabled value={childledger.amount} required min={1} max={Number(maxam)} type={"number"} onChange={(e) => {
                                setchildledger({
                                    ...childledger,
                                    "amount": e.target.value
                                })
                            }} placeholder='Amount' />

                            <Box mx={"0.2rem"} fontSize={"1.2rem"} >
                                Info
                            </Box>

                            <Input required
                                mt={"0.5rem"} onChange={(e) => {
                                    setchildledger({
                                        ...childledger,
                                        "info": e.target.value
                                    })
                                }} placeholder='Information' />



                        </ModalBody>

                        <ModalFooter>
                            <Button disabled={maxam < data?.amount} type={"submit"} mx={"0.5rem"} colorScheme='blue'>Submit</Button>

                            <Button colorScheme='red' variant='outline' onClick={() => {
                                onClose()
                            }}>Cancel</Button>
                        </ModalFooter>

                    </form>
                </ModalContent>
            </Modal>
        </ >
    )
}

export default AddChildLedger