import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Alert, Input } from "reactstrap";
import { Routes, Route, useParams } from "react-router-dom";
import Moment from "moment";
// import Form from './components/Form';
//-- import CreateProjectForm from "../CreateProject/createProjectForm";
import CreateLabourAttendenceForm from "./CreateLabourAttendenceForm.jsx";
// import DCreateLabourAttendenceForm2 from "./DCreateLabourAttendenceForm2.jsx";

//-- import { useSelector } from "react-redux";
//-- import { AppContext } from "../../Context/appContext";
//-- import { textAlign } from "@mui/system";

const LabourAttendence = () => {
  const [pleaseWait, setPleasewait] = useState(false);

  const onChangePleaseWait = (status) => {
    setPleasewait(status);
  };

  return (
    <Container className="dashboard create-project">
      <Row>
        <Col md={12}>
          <h3
            style={{
              color: "#000",
              textAlign: "center",
              fontSize: "1.7rem",
              fontWeight: "600",
              letterSpacing: "5px",
            }}
          >
            {/* Labour Attendence and WorkDone */}
            WORK FORCE ATTENDANCE
          </h3>
        </Col>
      </Row>
      <Row>
        <CreateLabourAttendenceForm onChangePleaseWait={onChangePleaseWait} />
      </Row>

      {/*-------------------- if you want loading on full screen make "pleaseWait" true ------------*/}
      {pleaseWait && (
        <div class="pleasewait-load">
          <div class="load__icon-wrap">
            <svg class="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      )}
    </Container>
  );
};

export default LabourAttendence;
