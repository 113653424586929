import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    ChakraProvider,
    Tooltip,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
    ProjectScope,
    paidStatusList,
    workStatusList,
} from "../../shared/helpers/constants";
import { getLedgerData } from "../../redux/actions/ledgerAction";
import { useState } from "react";
import ScheduleRequestPopup from "./ScheduleRequestPopup";

const DropdownMilestone = ({ rdata, idx, mdata, createWoTicket, pdata }) => {
    // mdata = mdata.filter((ele) => ele.task == rdata.task);

    // useEffect(() => {
    mdata = mdata.filter((ele) => ele.wo_milestones_id == rdata.id);
    // }, [rdata]);
    //console.log(rdata)

    const [ldata, setldata] = useState([]);
    // console.log(mdata, ldata, "yjhtgjygyjytg")

    const getWorkStatus = (val) => {
        let text = workStatusList.filter((o) => o.value === val);
        if (text.length) {
            return text[0].label;
        }
    };
    const getPaidStatus = (val) => {
        let text = paidStatusList.filter((o) => o.value === val);
        if (text.length) {
            return text[0].label;
        }
    };
    const getScope = (val) => {
        let text = ProjectScope.filter((o) => o.value === val);
        if (text.length) {
            return text[0].label;
        }
    };

    useEffect(() => {
        // console.log("32.32.32")
        getLedgerData(
            {
                label: pdata.project_title,
                value: pdata.project_id,
            },
            {
                txn_id: 0,
                comments: "",
                date: "",
                person: "",
                paymentType: "",
                paymentMode: "",
                isGstBills: "",
                category: pdata.category_name,
                amount: "",
                txnReference: "",
            },
            false
        )
            .then((res) => {
                // console.log(res, "34.34.34")
                if (res.status) {
                    //  console.log(res, "36.36.36")
                    // console.log(res.data)
                    setldata(res.data);
                }
            })
            .catch((res) => {
                // console.log(res, "103.103.103")
            });
    }, []);

    //console.log(ldata, "62.62.62")

    return (
        <AccordionItem
            color={"white"}
            backgroundColor={
                rdata.balance_amount < 10
                    ? "green.400"
                    : idx % 2 == 0
                        ? "blue.300"
                        : "blue.300"
            }
            borderRadius={"0.5rem"}
            border={"1px solid white"}
            my={"0.5rem"}
            py={"0.3rem"}
        >
            <Box display={"flex"}>
                <AccordionButton w={"fit-content"}>
                    <Box color={"white"} as="span" flex="1" textAlign="left">
                       {rdata.id}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <Box
                    px={"1rem"}
                    justifyContent={"space-between"}
                    display={"flex"}
                    flexWrap={"wrap"}
                    alignItems={"center"}
                >
                    <Tooltip
                        w={"11rem"}
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label={`Total Amount : ₹${rdata.amount} Balance Amount : ₹${rdata.balance_amount
                            } Paid Amount : ₹${rdata.total_paid ? rdata.total_paid : 0}
                    
                    `}
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"red.400"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >
                            <Box>{`T:₹${rdata.amount}`}</Box>
                            <Box>{`B:₹${rdata.balance_amount}`}</Box>
                            <Box>{`P:₹${rdata.total_paid ? rdata.total_paid : 0}`}</Box>
                        </Box>
                    </Tooltip>

                    <Tooltip
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label="S is start date, E is end date"
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"blackAlpha.300"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >
                            <Box>
                                {`S:${new Date(`${rdata.start_date}`)?.toDateString()}`}
                            </Box>
                            <Box>{`E:${new Date(`${rdata.end_date}`)?.toDateString()}`}</Box>
                        </Box>
                    </Tooltip>
                    <Tooltip
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label="payment percentage"
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"blackAlpha.300"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >{`${rdata.percentage}%`}</Box>
                    </Tooltip>

                    <Tooltip
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label="Pay Date"
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"blackAlpha.300"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >
                            {`${new Date(`${rdata.pay_date}`)?.toDateString()}`}
                        </Box>
                    </Tooltip>

                    <Tooltip
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label="Work Status"
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"blackAlpha.300"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >
                            {getWorkStatus(rdata.work_status)}
                        </Box>
                    </Tooltip>

                    <Tooltip
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label="Paid Status"
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"blackAlpha.300"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >
                            {getPaidStatus(rdata.paid_status)}
                        </Box>
                    </Tooltip>
                    <Tooltip
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label="Scope"
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"blackAlpha.300"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >
                            {getScope(rdata.scope)}
                        </Box>
                    </Tooltip>

                    <Tooltip
                        p={"0.5rem"}
                        borderRadius={"0.5rem"}
                        hasArrow
                        label="Task description"
                        bg="green.400"
                    >
                        <Box
                            backgroundColor={"blackAlpha.300"}
                            borderRadius={"0.3rem"}
                            px={"0.5rem"}
                            m={"0.2rem"}
                        >
                            {rdata.task}
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
            <Box>
                {rdata.paid_status === 1 ? (
                    <>"NA"</>
                ) : (
                    <ChakraProvider>
                        <ScheduleRequestPopup pdata={pdata} data={rdata} />
                    </ChakraProvider>
                )}
            </Box>

            <AccordionPanel pb={4}>
                {mdata &&
                    mdata.length != 0 &&
                    mdata?.map((ele, id) => {
                        return (
                            <Box>
                                <Box
                                    display={"flex"}
                                    justifyContent={"space-evenly"}
                                    borderRadius={"0.5rem 0.5rem 0 0.5rem"}
                                    border={"1px solid white"}
                                    color={"white"}
                                    backgroundColor={"blue.500"}
                                    mt={"0.7rem"}
                                    py={"0.7rem"}
                                    alignItems={"center"}
                                    flexWrap={"wrap"}
                                >
                                    <Tooltip
                                        p={"0.5rem"}
                                        borderRadius={"0.5rem"}
                                        hasArrow
                                        label="Request Id"
                                        bg="green.400"
                                    >
                                        <Box mx={"0.5rem"}>{ele.id}</Box>
                                    </Tooltip>

                                    <Tooltip
                                        p={"0.5rem"}
                                        borderRadius={"0.5rem"}
                                        hasArrow
                                        label="Payment Status"
                                        bg="green.400"
                                    >
                                        <Box mx={"0.5rem"}>
                                            {ele.status == 1
                                                ? "New"
                                                : ele.status == 2
                                                    ? "Payment Processed"
                                                    : ele.status == 3
                                                        ? "Rejected"
                                                        : "Completed"}
                                        </Box>
                                    </Tooltip>
                                    <Tooltip
                                        p={"0.5rem"}
                                        borderRadius={"0.5rem"}
                                        hasArrow
                                        label="Actual Amount"
                                        bg="green.400"
                                    >
                                        <Box mx={"0.5rem"}>{`₹${ele.amount}`}</Box>
                                    </Tooltip>
                                    <Tooltip
                                        p={"0.5rem"}
                                        borderRadius={"0.5rem"}
                                        hasArrow
                                        label="1% TDS"
                                        bg="green.400"
                                    >
                                        <Box>{`₹${ele.amount / 100}`}</Box>
                                    </Tooltip>
                                    <Tooltip
                                        p={"0.5rem"}
                                        borderRadius={"0.5rem"}
                                        hasArrow
                                        label="Amount After TDS cutting"
                                        bg="green.400"
                                    >
                                        <Box mx={"0.5rem"}>{`₹${ele.amount - ele.amount / 100
                                            }`}</Box>
                                    </Tooltip>
                                    <Tooltip
                                        p={"0.5rem"}
                                        borderRadius={"0.5rem"}
                                        hasArrow
                                        label="Payment Date in Requests"
                                        bg="green.400"
                                    >
                                        <Box mx={"0.5rem"}>
                                            {new Date(ele.payment_date).toDateString()}
                                        </Box>
                                    </Tooltip>
                                    <Tooltip
                                        p={"0.5rem"}
                                        borderRadius={"0.5rem"}
                                        hasArrow
                                        label="comments"
                                        bg="green.400"
                                    >
                                        <Box mx={"0.5rem"}>
                                            {ele.wt_comments}
                                        </Box>
                                    </Tooltip>
                                </Box>

                                {ldata.map((el) => {
                                    console.log(ele.ledger_ids);
                                    return (
                                        <>
                                            <Box display={"flex"} justifyContent={"right"}>
                                                {ele?.ledger_ids &&
                                                    ele?.ledger_ids[0] == el?.txn_id && (
                                                        <Box
                                                            border={"1px solid white"}
                                                            display={"flex"}
                                                            justifyContent={"space-evenly"}
                                                            borderRadius={"0 0 0.5rem 0.5rem"}
                                                            color={"white"}
                                                            alignItems={"center"}
                                                            backgroundColor={"red.400"}
                                                            w={"90%"}
                                                            flexWrap={"wrap"}
                                                            p={"0.5rem"}
                                                        >
                                                            <Tooltip
                                                                p={"0.5rem"}
                                                                borderRadius={"0.5rem"}
                                                                hasArrow
                                                                label="ledger TXN ID"
                                                                bg="green.400"
                                                            >
                                                                <Box>{el.txn_id}</Box>
                                                            </Tooltip>

                                                            <Tooltip
                                                                p={"0.5rem"}
                                                                borderRadius={"0.5rem"}
                                                                hasArrow
                                                                label="ledger person"
                                                                bg="green.400"
                                                            >
                                                                <Box>
                                                                    {`${el.person_first_name} 
                                                    ${el.person_last_name}`}
                                                                </Box>
                                                            </Tooltip>

                                                            <Tooltip
                                                                p={"0.5rem"}
                                                                borderRadius={"0.5rem"}
                                                                hasArrow
                                                                label="Mode of Payment"
                                                                bg="green.400"
                                                            >
                                                                <Box>
                                                                    {el.payment_mode_id == 1
                                                                        ? "Bank"
                                                                        : el.payment_mode_id == 2
                                                                            ? "Cash"
                                                                            : "Others"}
                                                                </Box>
                                                            </Tooltip>
                                                            <Tooltip
                                                                p={"0.5rem"}
                                                                borderRadius={"0.5rem"}
                                                                hasArrow
                                                                label="Payment Type"
                                                                bg="green.400"
                                                            >
                                                                <Box>
                                                                    {el.payment_type_id == 1
                                                                        ? "Income"
                                                                        : "Expense"}
                                                                </Box>
                                                            </Tooltip>
                                                            <Tooltip
                                                                p={"0.5rem"}
                                                                borderRadius={"0.5rem"}
                                                                hasArrow
                                                                label="GST Bill"
                                                                bg="green.400"
                                                            >
                                                                <Box>{el.is_gst_bill ? "Yes" : "No"}</Box>
                                                            </Tooltip>

                                                            <Tooltip
                                                                p={"0.5rem"}
                                                                borderRadius={"0.5rem"}
                                                                hasArrow
                                                                label="Amount In Ledger"
                                                                bg="green.400"
                                                            >
                                                                <Box>{`₹${el.amount}`}</Box>
                                                            </Tooltip>

                                                            <Tooltip
                                                                p={"0.5rem"}
                                                                borderRadius={"0.5rem"}
                                                                hasArrow
                                                                label="Payment Date in Ledger"
                                                                bg="green.400"
                                                            >
                                                                <Box>{new Date(el.date).toDateString()}</Box>
                                                            </Tooltip>
                                                        </Box>
                                                    )}
                                            </Box>
                                        </>
                                    );
                                })}
                            </Box>
                        );
                    })}
            </AccordionPanel>
        </AccordionItem>
    );
};

export default DropdownMilestone;

// < td > { row.task }</td >
//                           <td>
//                             <Container>
//                               <Row>
//                                 S:
//                                 {Moment(row.start_date).format(
//                                   "DD-MM-yyyy"
//                                 )}
//                               </Row>
//                               <Row>
//                                 E:
//                                 {Moment(row.end_date).format("DD-MM-yyyy")}
//                               </Row>
//                             </Container>
//                           </td>
//                           <td>{row.percentage}</td>
//                           <td>
//                             <div>{`T:${row.amount}`}</div>
//                             <div>{`B:${row.balance_amount}`}</div>
//                             <div>
//                               {`P:${row.total_paid ? row.total_paid : 0}`}
//                             </div>
//                           </td>
//                           <td>
//                             {Moment(row.pay_date).format("DD-MM-yyyy")}
//                           </td>
//                           <td>{getWorkStatus(row.work_status)}</td>
//                           <td>{getPaidStatus(row.paid_status)}</td>
//                           <td>{getScope(row.scope)}</td>
//                           <td>
//                             {row.paid_status === 1 ? (
//                               "NA"
//                             ) : (
//                               <span
//                                 style={{
//                                   cursor: "pointer",
//                                   textDecorationLine: "underline",
//                                 }}
//                                 onClick={() => createWoTicket(row)}
//                               >
//                                 Schedule Payment
//                               </span>
//                             )}

// date
// :
// "2022-11-18T18:30:00.000Z"
// is_gst_bill
// :
// false
// payment_mode_id
// :
// 1
// payment_type_id
// :
// 2
// person_first_name
// :
// "General"
// person_id
// :
// 212
// person_last_name
// :
// "Vendor"
// project_id
// :
// 147
// project_title
// :
// "Cx333 Nandi hills- Abhishek Dhull"
// txn_id
// :
// 3573
// txn_reference
// :
// "

// export const PaymentType = [
//     { label: "Please Select", value: 0 },
//     { label: "Income", value: 1 },
//     { label: "Expense", value: 2 },
// ];

// export const PaymentMode = [
//     { label: "Please Select", value: 0 },
//     { label: "Bank", value: 1 },
//     { label: "Cash", value: 2 },
//     { label: "Others", value: 3 },
// ];

// 	TO TRANSFER Bharath R INB NEFT UTR
// NO: SBIN122284790868 - NEFT INB: CNACDTWPF9
// TRANSFER TO 3197945044305