import { Box, Skeleton, SkeletonText } from '@chakra-ui/react'
import React from 'react'

const SkelitonCard = () => {
    return (
        <Box bg={"whiteAlpha.500"} h={"20rem"} borderRadius={"md"} padding='6' boxShadow='lg'  >
            <Skeleton height={"10rem"} />
            <SkeletonText mt='4' noOfLines={5} spacing='4' skeletonHeight='2' />
        </Box>
    )
}

export default SkelitonCard