import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// import { Col, Container, Row, Card, CardBody } from "reactstrap";
import {
  ProjectScope,
  workStatusList,
  paymentStatusList,
} from "../../shared/helpers/constants";
import Moment from "moment";
import Collapse from "../../shared/components/Collapse";
import DownloadBoxIcon from "mdi-react/DownloadBoxIcon";
import csvDownload from "json-to-csv-export";

// import Form from './components/Form';
import {
  getDetailedInfo,
  getProjectFile,
  sendMailProjectMilstone,
  getMailMilestoneData,
  sendMailPdfDocs,
} from "../../redux/actions/projectsAction";
import { yearMonthDateFormat } from "../../shared/helpers";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Cancel,
  Mail,
  PictureAsPdf,
  PictureAsPdfRounded,
  PictureAsPdfTwoTone,
} from "@mui/icons-material";
import Pdftemplate from "./PDFDownload/Pdftemplate";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import {
  Alert,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  ListItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { getLedgerDataIncome } from "../../redux/actions/ledgerAction";
import { saveAs } from "file-saver";
import moment from "moment";
const dataToConvert = {
  filename: "wps.csv",
  delimiter: ",",
};

const ProjectView = () => {
  const { pid } = useParams();
  const [data, setData] = useState({});
  const [drawingFiles, setDrawingFiles] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [ids, setIds] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [ledgerData, setLedgerData] = useState([]);
  const [mailCount, setMailCount] = useState(0);
  const [mailToolTip, setMailToolTip] = useState(``);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [ShareMailDisable, setshareMailDisable] = useState(false);
  const [alertMail, setAlertMail] = useState(false);
  const [mailData, setMailData] = useState([]);

  useEffect(() => {
    getDetailedProjectInfo(pid);
    getLedgerDataIncome(pid, 1).then((res) => {
      // console.log(res, "legdes income");
      setLedgerData([...res.data]);
    });
    getMailMilestoneData(pid).then((res) => {
      // console.log(res, "hello");
      setMailCount(res?.requirements?.rows.length);
      if (res?.requirements?.rows) {
        let str = ``;
        const data = res?.requirements?.rows;
        setMailData((prev) => data);
        const lastFive = data
          .sort((a, b) => {
            if (
              Number(new Date(a.updated_on)) < Number(new Date(b.updated_on))
            ) {
              return 1;
            } else if (
              Number(new Date(a.updated_on)) > Number(new Date(b.updated_on))
            ) {
              return -1;
            } else {
              return 0;
            }
          })
          .filter((el, ind) => ind < 5);

        lastFive.forEach((el) => {
          str =
            str +
            "\n" +
            `${moment(el.updated_on).format("DD-MM-YYYY || hh:mm")}`;
        });
        // console.log(str, data, "  time");
        setMailToolTip(str);
      }
    });
  }, []);
  const getDetailedProjectInfo = async (pid) => {
    getDetailedInfo(pid).then((res) => {
      if (res.status) {
        setData({ ...res.data });
      }
    });
  };

  const getFiles = async (filesList, type) => {
    if (!type) return;
    let fileArray = [];

    for (let idx in filesList) {
      filesList[idx] = JSON.parse(filesList[idx]);
      let fileName = filesList[idx].location.split("/");
      fileName = fileName[fileName.length - 1];
      console.log(filesList[idx].location, "location");
      const files = await getProjectFile(filesList[idx].location, true);
      let blob = window.URL.createObjectURL(files);
      let file = new File([blob], `${fileName}`, {
        preview: blob,
        type: files.type,
      });
      file.preview = blob;
      file.path = blob;
      file.fromsrv = filesList[idx];
      fileArray.push(file);
    }
    if (type === "drawings") {
      setDrawingFiles(fileArray);
    } else if (type === "floor_plans") {
      setFloorPlans(fileArray);
    } else if (type === "agreements") {
      setAgreements(fileArray);
    } else if (type === "ids") {
      setIds(fileArray);
    }
  };

  const getTotalAdditionalAmount = () => {
    let sum = 0;
    for (let idx in data.ws) {
      if (data.ws[idx].project_scope == 1 || data.ws[idx].project_scope == 3) {
        let num = Number(data.ws[idx].to_pay);
        if (!isNaN(num)) {
          sum += num;
        }
      }
    }
    return <div>Total: {sum}</div>;
  };
  const getPaidAdditionalAmount = () => {
    let sum = 0;
    for (let idx in data.ws) {
      if (data.ws[idx].project_scope == 1 || data.ws[idx].project_scope == 3) {
        let num = Number(data.ws[idx].paid);
        if (!isNaN(num)) {
          sum += num;
        }
      }
    }
    return <div>Paid: {sum}</div>;
  };
  const getTotalWpsAmount = () => {
    let sum = 0;
    for (let idx in data.ws) {
      let num = Number(data.ws[idx].to_pay);
      if (!isNaN(num)) {
        sum += num;
      }
    }
    return <div>Total: {sum}</div>;
  };
  const getPaidWpsAmount = () => {
    let sum = 0;
    for (let idx in data.ws) {
      let num = Number(data.ws[idx].paid);
      if (!isNaN(num)) {
        sum += num;
      }
    }
    return <div>Paid: {sum}</div>;
  };
  const getTotalWpsRegularAmount = () => {
    let sum = 0;
    for (let idx in data.ws) {
      if (data.ws[idx].project_scope == 0 || data.ws[idx].project_scope == 2) {
        let num = Number(data.ws[idx].to_pay);
        if (!isNaN(num)) {
          sum += num;
        }
      }
    }
    return <div>Total: {sum}</div>;
  };
  const getPaidWpsRegularAmount = () => {
    let sum = 0;
    for (let idx in data.ws) {
      if (data.ws[idx].project_scope == 0 || data.ws[idx].project_scope == 2) {
        let num = Number(data.ws[idx].paid);
        if (!isNaN(num)) {
          sum += num;
        }
      }
    }
    return <div>Paid: {sum}</div>;
  };
  const [pdfData, setPdfData] = useState({ data: [] });
  const wpsDownload = () => {
    let downloadData = [...data.ws];

    let projectTitle = data?.bi?.project_title;
    for (let idx in downloadData) {
      downloadData[idx].project_title = projectTitle;
      downloadData[idx].work_status = workStatusList.filter(
        (o) => o.value === downloadData[idx].work_status
      )[0].label;

      downloadData[idx].payment_date = Moment(
        downloadData[idx].payment_date
      ).format(yearMonthDateFormat);

      downloadData[idx].start_date = Moment(
        downloadData[idx].start_date
      ).format(yearMonthDateFormat);
      downloadData[idx].end_date = Moment(downloadData[idx].end_date).format(
        yearMonthDateFormat
      );

      downloadData[idx].payment_status = paymentStatusList.filter(
        (o) => o.value === downloadData[idx].payment_status
      )[0].label;

      downloadData[idx].project_scope = ProjectScope.filter(
        (o) => o.value === downloadData[idx].project_scope
      )[0].label;
    }

    dataToConvert.data = downloadData;
    csvDownload(dataToConvert);
  };
  const saveFile = () => {
    // This does the trick!
    pdf(
      <Pdftemplate
        data={pdfData.data}
        projectTitle={pdfData.projectTitle}
        name={pdfData.name}
        email={pdfData.email}
        location={pdfData.location}
        mobile_no={pdfData.mobile_no}
      />
    )
      .toBlob()
      .then((blob) =>
        saveAs(blob, `${pdfData.projectTitle}-PaymentLedger.pdf`)
      );
  };
  const sendPDF = () => {
    // This does the trick!
    pdf(
      <Pdftemplate
        data={pdfData.data}
        projectTitle={pdfData.projectTitle}
        name={pdfData.name}
        email={pdfData.email}
        location={pdfData.location}
        mobile_no={pdfData.mobile_no}
      />
    )
      .toBlob()
      .then((blob) => {
        const formData = new FormData();
        formData.append(
          "file",
          blob,
          `${pdfData.projectTitle}-PaymentLedger.pdf`
        );
        formData.append(
          "data",
          JSON.stringify({
            name: `${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`,
            SendFileName: `${pdfData.projectTitle}-PaymentLedger.pdf`,
            email: data?.bi?.customer_email,
          })
        );
        sendMailPdfDocs(formData);
      });
  };
  function wpsDownloadPdf() {
    let downloadData = [];
    let downloadAdditional = [];
    if (ledgerData) {
      // console.log(ledgerData, "fsdfd data");
      downloadData = ledgerData.map((obj) => ({
        amount: obj.amount,
        date: Moment(obj.date).format(yearMonthDateFormat),
        payment_mode_id: obj.payment_mode_id,
      }));
    }

    setPdfData({
      data: [...downloadData],
      // additional: [...downloadAdditional],
      projectTitle: data?.bi?.project_title,
      name: `${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`,
      email: data?.bi?.customer_email,
      mobile_no: data?.bi?.customer_contact_num,
      location: data?.bi?.location,
    });
  }

  useEffect(() => {
    if (Object.keys(data).length && Object.keys(data.bi).length) {
      console.log(data, "data");
      getFiles(data?.bi?.agreements, "agreements");
      getFiles(data?.bi?.drawings, "drawings");
      getFiles(data?.bi?.floor_plans, "floor_plans");
      getFiles(data?.bi?.ids, "ids");
    }
  }, [data]);

  useEffect(() => {
    wpsDownloadPdf();
  }, [ledgerData, data]);

  const loginState = useSelector((state) => state.login);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  const SendMailAll = () => {
    const mailData = {};
    mailData.email = data?.bi?.customer_email;
    mailData.name = `${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`;
    mailData.data = data?.ws.filter(
      (obj) =>
        obj.work_status === 1 ||
        (obj.work_status === 3 && obj.to_pay - obj.paid != 0)
    );
    mailData.p_id = pid;
    mailData.project_cx = data?.bi?.project_title.split(" ")[1];
    console.log(mailData, data?.bi?.project_title, "dfsdf");
    sendMailProjectMilstone(mailData).then((resp) => {
      console.log(resp, "resp");
      setshareMailDisable(false);
    });
  };
  const SendMailAllDownload = () => {
    const mailData = {};
    mailData.email = data?.bi?.customer_email;
    mailData.name = `${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`;
    mailData.data = data?.ws?.filter(
      (obj) =>
        obj.work_status === 1 ||
        (obj.work_status === 3 && obj.to_pay - obj.paid != 0)
    );
    mailData.p_id = pid;
    mailData.project_cx = data?.bi?.project_title.split(" ")[1];

    return mailData;
  };
  const SendMail = (id) => {
    const mailData = {};
    mailData.email = data?.bi?.customer_email;
    mailData.name = `${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`;
    mailData.data = data?.ws.filter((obj) => obj.id === id);
    mailData.isFirst = mailCount == 0 ? true : false;
    mailData.p_id = pid;
    mailData.project_cx = data?.bi?.project_title;
    // console.log(mailData.data, "dfsdf");
    sendMailProjectMilstone(mailData).then((resp) => {
      console.log(resp, "resp");
      setshareMailDisable(false);
    });
  };
  const SendMailDownload = (id) => {
    const mailData = {};
    mailData.email = data?.bi?.customer_email;
    mailData.name = `${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`;
    mailData.data = data?.ws.filter((obj) => obj.id === id);
    mailData.isFirst = mailCount == 0 ? true : false;
    mailData.p_id = pid;
    mailData.project_cx = data?.bi?.project_title;
    // console.log(mailData.data, "dfsdf");
    return mailData;
  };

  const handleClickOpenDialogSuccess = () => {
    setOpenDialogSuccess(true);
  };

  const handleCloseDialogSuccess = () => {
    setOpenDialogSuccess(false);
    // window.location.reload(false);
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 130,
    },
  });
  return (
    <Container>
      <Grid>
        <Card sx={{ overflow: "scroll" }}>
          <CardActions>
            <h2 className="page-title">Project Info</h2>
          </CardActions>
          <CardContent>
            <fieldset>
              <legend style={{ color: "#70bbfd" }}>Basic Info</legend>
              <Table>
                <TableBody>
                  <TableRow sx={{ padding: "8px" }}>
                    <TableCell>
                      <strong>Project Title:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.project_title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Status:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.project_status?.label}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Customer Name:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {`${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Customer Email:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.customer_email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Customer Contact #:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.customer_contact_num}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Project Manger:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {`${data?.bi?.project_manager_firstname} ${data?.bi?.project_manager_lastname}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Site Engineer:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {`${data?.bi?.site_engineer_firstname} ${data?.bi?.site_engineer_lastname}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Site Engineer Email:</strong>
                    </TableCell>
                    <TableCell> {data?.bi?.site_engineer_email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Site Engineer Contact #:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.site_engineer_contact_num}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Execution Lead:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.service_provider_firstname}{" "}
                      {data?.bi?.service_provider_lastname}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Execution Lead Contact #:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.service_provider_contact_num}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Location:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.location}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Geolocation:</strong>
                    </TableCell>
                    <TableCell
                      sx={{ padding: "8px", overflowWrap: "anywhere" }}
                    >
                      {" "}
                      {data?.bi?.lat_lng}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ padding: "8px" }}>
                            <strong>Drawings</strong>
                          </TableCell>
                          <TableCell sx={{ padding: "8px" }}>
                            {Array.isArray(drawingFiles) &&
                            drawingFiles.length ? (
                              drawingFiles.map((file, fileIdx) => {
                                return (
                                  <div>
                                    <a href={`${file.preview}`} target="_blank">
                                      <span>Drawings {fileIdx + 1}</span>
                                    </a>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableRow>
                  <TableRow>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ padding: "8px" }}>
                            <strong>Floor Plans</strong>
                          </TableCell>
                          <TableCell sx={{ padding: "8px" }}>
                            {Array.isArray(floorPlans) && floorPlans.length ? (
                              floorPlans.map((file, fileIdx) => {
                                return (
                                  <div>
                                    <a href={`${file.preview}`} target="_blank">
                                      <span>Floor Plan {fileIdx + 1}</span>
                                    </a>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableRow>
                  <TableRow>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ padding: "8px" }}>
                            <strong>Agreements</strong>
                          </TableCell>
                          <TableCell sx={{ padding: "8px" }}>
                            {Array.isArray(agreements) && agreements.length ? (
                              agreements.map((file, fileIdx) => {
                                return (
                                  <div>
                                    <a href={`${file.preview}`} target="_blank">
                                      <span>Agreement {fileIdx + 1}</span>
                                    </a>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableRow>
                  <TableRow>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ padding: "8px" }}>
                            <strong>Ids</strong>
                          </TableCell>
                          <TableCell sx={{ padding: "8px" }}>
                            {Array.isArray(ids) && ids.length ? (
                              ids.map((file, fileIdx) => {
                                return (
                                  <div>
                                    <a href={`${file.preview}`} target="_blank">
                                      <span>file {fileIdx + 1}</span>
                                    </a>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableRow>
                </TableBody>
              </Table>
            </fieldset>
            <div style={{ border: "1px solid #CCC" }}></div>

            <fieldset>
              <Collapse title={"Construction Requirement"}>
                <Table>
                  <TableHead sx={{ background: "#3aaeb754" }}>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Subject</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Computation</TableCell>
                      <TableCell>Project Scope</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.cr?.length &&
                      data.cr.map((row, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.subject}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.computation}</TableCell>
                            <TableCell>
                              {
                                ProjectScope.filter(
                                  (o) => o.value === row.project_scope
                                )[0].label
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Collapse>
            </fieldset>

            <div style={{ border: "1px solid #CCC" }}></div>
            <fieldset>
              <Collapse title={`Work & Payment Schedule`}>
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  {binaryconverter(
                    loginState.userDetails.access_control
                  ).charAt(24) == 1 && (
                    <Button
                      type="button"
                      color="primary"
                      variant="outlined"
                      className="clearFilter downloadIcon"
                      onClick={wpsDownload}
                    >
                      <DownloadBoxIcon size="2em" />
                      CSV
                    </Button>
                  )}
                  <ButtonGroup color="error">
                    <Button>
                      <Link to="/app/admin/view/pdf" state={pdfData}>
                        <PictureAsPdfRounded />
                        Viewer
                      </Link>
                    </Button>
                    <Button>
                      <PDFDownloadLink
                        document={
                          <Pdftemplate
                            data={pdfData.data}
                            projectTitle={pdfData.projectTitle}
                            name={pdfData.name}
                            email={pdfData.email}
                            location={pdfData.location}
                            mobile_no={pdfData.mobile_no}
                          />
                        }
                      >
                        <PictureAsPdfRounded />
                        Download
                      </PDFDownloadLink>
                    </Button>
                    <Button onClick={sendPDF}>
                      <PictureAsPdfTwoTone />
                      Send
                    </Button>
                  </ButtonGroup>
                  <CustomWidthTooltip title={mailToolTip}>
                    <Badge color="secondary" badgeContent={mailCount}>
                      <ButtonGroup>
                        <Button
                          variant="outlined"
                          onClick={handleClickOpenDialogSuccess}
                          disabled={ShareMailDisable}
                        >
                          <Mail />
                          send Mail
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleClickOpenDialogSuccess}
                          disabled={ShareMailDisable}
                        >
                          <Link
                            to="/app/admin/view/pdfMail"
                            state={SendMailAllDownload()}
                          >
                            <PictureAsPdfRounded />
                          </Link>
                        </Button>
                      </ButtonGroup>
                    </Badge>
                  </CustomWidthTooltip>
                </Box>

                {/* <button
                  type="button"
                  color="primary"
                  className="clearFilter downloadIcon"
                  onClick={wpsDownloadPdf}
                >
                  <PictureAsPdf size="2em" />
                </button> */}
                {/* <div className="table react-table">
                  <div className="table react-table table--not-pagination-view-tables"> */}
                <Table>
                  <TableHead sx={{ background: "#3aaeb754" }}>
                    <TableRow>
                      <TableCell sx={{ padding: "9px" }}>ID</TableCell>
                      <TableCell sx={{ padding: "9px" }}>Stage</TableCell>
                      <TableCell sx={{ padding: "9px" }}>Task</TableCell>
                      <TableCell sx={{ padding: "9px" }}>Start Date</TableCell>
                      <TableCell sx={{ padding: "9px" }} width={100}>
                        End Date
                      </TableCell>
                      <TableCell sx={{ padding: "9px" }}>
                        Payment Date
                      </TableCell>
                      <TableCell sx={{ padding: "9px" }}>
                        % of Final Quote
                      </TableCell>
                      <TableCell sx={{ padding: "9px" }}>To Pay(Rs)</TableCell>
                      <TableCell sx={{ padding: "9px" }}>Paid (Rs)</TableCell>
                      <TableCell sx={{ padding: "9px" }}>Work Status</TableCell>
                      <TableCell sx={{ padding: "9px" }}>
                        Payment Status
                      </TableCell>
                      <TableCell sx={{ padding: "9px" }}>
                        Project Scope
                      </TableCell>
                      <TableCell sx={{ padding: "9px" }}>Mail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.ws?.length &&
                      data.ws.map((row, idx) => {
                        if (row.project_scope == 0 || row.project_scope == 2) {
                          return (
                            <>
                              <TableRow
                                key={idx}
                                className={classNames({
                                  wipClr: row.work_status === 1,
                                  completedClr: row.work_status == 3,
                                })}
                              >
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.id}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.stage}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.task}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {Moment(row.start_date).format(
                                    yearMonthDateFormat
                                  )}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {Moment(row.end_date).format(
                                    yearMonthDateFormat
                                  )}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {Moment(row.payment_date).format(
                                    yearMonthDateFormat
                                  )}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.percent_of_final_quote}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "9px" }}
                                  style={{ textAlign: "center" }}
                                >
                                  {row.to_pay}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "9px" }}
                                  style={{ textAlign: "center" }}
                                >
                                  {row.paid}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {
                                    workStatusList.filter(
                                      (o) => o.value === row.work_status
                                    )[0].label
                                  }
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {
                                    paymentStatusList.filter(
                                      (o) => o.value === row.payment_status
                                    )[0].label
                                  }
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {
                                    ProjectScope.filter(
                                      (o) => o.value === row.project_scope
                                    )[0].label
                                  }
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  <CustomWidthTooltip
                                    title={mailData.reduce((acc, curr, ind) => {
                                      if (curr.stage_id == row.id && ind < 6) {
                                        acc =
                                          acc +
                                          "\n" +
                                          `${moment(curr.updated_on).format(
                                            "DD-MM-YYYY || hh:mm"
                                          )}`;
                                      }
                                      return acc;
                                    }, ``)}
                                  >
                                    <Button disabled={alertMail}>
                                      <Badge
                                        color="secondary"
                                        badgeContent={
                                          mailData.filter(
                                            (el) => el.stage_id == row.id
                                          ).length
                                        }
                                      >
                                        <Mail
                                          onClick={() => {
                                            setAlertMail(true);
                                            SendMail(row.id);
                                            setTimeout(() => {
                                              setAlertMail(false);
                                            }, 2000);
                                          }}
                                        />

                                        <Link
                                          to="/app/admin/view/pdfMail"
                                          state={SendMailDownload(row.id)}
                                        >
                                          <PictureAsPdfRounded />
                                        </Link>
                                      </Badge>
                                    </Button>
                                  </CustomWidthTooltip>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                      })}
                    {
                      <TableRow>
                        <TableCell
                          sx={{ padding: "9px", textAlign: "end" }}
                          colSpan={8}
                          className="text-right"
                        >
                          {getTotalWpsRegularAmount()}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "9px", textAlign: "Start" }}
                          colSpan={4}
                          className="text-right"
                        >
                          {getPaidWpsRegularAmount()}
                        </TableCell>
                      </TableRow>
                    }

                    <TableRow className="regularWorkNpayments">
                      <TableCell
                        colSpan={13}
                        sx={{
                          padding: "9px",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        <span>Additonal & Reimbursements </span>
                      </TableCell>
                    </TableRow>
                    {data?.ws?.length &&
                      data.ws.map((row, idx) => {
                        if (row.project_scope == 1 || row.project_scope == 3) {
                          return (
                            <>
                              <TableRow
                                key={idx}
                                className={classNames({
                                  wipClr: row.work_status === 1,
                                  completedClr: row.work_status == 3,
                                })}
                              >
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.id}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.stage}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.task}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {Moment(row.start_date).format(
                                    yearMonthDateFormat
                                  )}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {Moment(row.end_date).format(
                                    yearMonthDateFormat
                                  )}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {Moment(row.payment_date).format(
                                    yearMonthDateFormat
                                  )}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {row.percent_of_final_quote}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "9px" }}
                                  style={{ textAlign: "center" }}
                                >
                                  {row.to_pay}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "9px" }}
                                  style={{ textAlign: "center" }}
                                >
                                  {row.paid}
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {
                                    workStatusList.filter(
                                      (o) => o.value === row.work_status
                                    )[0].label
                                  }
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {
                                    paymentStatusList.filter(
                                      (o) => o.value === row.payment_status
                                    )[0].label
                                  }
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  {
                                    ProjectScope.filter(
                                      (o) => o.value === row.project_scope
                                    )[0].label
                                  }
                                </TableCell>
                                <TableCell sx={{ padding: "9px" }}>
                                  <CustomWidthTooltip
                                    title={mailData.reduce((acc, curr) => {
                                      if (curr.stage_id == row.id) {
                                        acc =
                                          acc +
                                          "\n" +
                                          `${moment(curr.updated_on).format(
                                            "DD-MM-YYYY || hh:mm"
                                          )}`;
                                      }
                                      return acc;
                                    }, ``)}
                                  >
                                    <Button disabled={alertMail}>
                                      <Badge
                                        color="secondary"
                                        badgeContent={
                                          mailData.filter(
                                            (el) => el.stage_id == row.id
                                          ).length
                                        }
                                      >
                                        <Mail
                                          onClick={() => {
                                            setAlertMail(true);
                                            SendMail(row.id);
                                            setTimeout(() => {
                                              setAlertMail(false);
                                            }, 2000);
                                          }}
                                        />
                                      </Badge>
                                    </Button>
                                  </CustomWidthTooltip>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                      })}
                    {
                      <TableRow>
                        <TableCell
                          sx={{ padding: "9px", textAlign: "end" }}
                          colSpan={8}
                          className="text-right"
                        >
                          {getTotalAdditionalAmount()}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "9px", textAlign: "Start" }}
                          colSpan={4}
                          className="text-right"
                        >
                          {getPaidAdditionalAmount()}
                        </TableCell>
                      </TableRow>
                    }
                    <TableRow>
                      <TableCell
                        sx={{ padding: "9px", textAlign: "end" }}
                        colSpan={8}
                        className="text-right"
                      >
                        {getTotalWpsAmount()}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "9px", textAlign: "Start" }}
                        colSpan={5}
                        className="text-right"
                      >
                        {getPaidWpsAmount()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* </div>
                </div> */}
              </Collapse>
            </fieldset>
            <Dialog
              open={openDialogSuccess}
              onClose={handleCloseDialogSuccess}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{ color: "#FFF", background: "#2e7d32" }}
              >
                {"Click Ok To Share"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ textTransform: "capitalize" }}
                >
                  Are you sure you want to Send the mail click ok to share
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setshareMailDisable(true);
                    SendMailAll();
                    handleCloseDialogSuccess();
                  }}
                  autoFocus
                >
                  Send
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseDialogSuccess}
                  autoFocus
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
          <Fade
            in={alertMail}
            style={{
              transitionDelay: alertMail ? "500ms" : "0ms",
              position: "fixed",
              bottom: "30px",
              left: "50%",
              transform: "translate(-50%,-50% )",
            }}
          >
            <Alert variant="filled" severity="success">
              Mail is sended successfully
            </Alert>
          </Fade>
        </Card>
      </Grid>
    </Container>
  );
};

export default ProjectView;
