import { Box, Td, Tr } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { downloadAttachments } from "../../redux/actions/ledgerAction";

const TableBody = ({
  cdiff,
  confirmDeleteEntry,
  md,
  data,
  idx,
  binaryconverter,
  loginState,
  viewOrUpdate,
  PaymentType,
  PaymentMode,
  childlegdata,
}) => {
  console.log(data.mother, "8888mo");
  const onClickAttachments = (link) => {
    downloadAttachments(link);
  };

  return (
    <>
      {md ? (
        <>
          <Tr>
            <Td>
              <Box display={"flex"}>
                {binaryconverter(loginState.userDetails.access_control).charAt(
                  5
                ) == 1 && (
                  <span
                    onClick={() => viewOrUpdate(data)}
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {data.txn_id}
                  </span>
                )}
                {cdiff !== 0 && (
                  <Box
                    borderRadius={"0.3rem"}
                    border={"1px solid white"}
                    color={"white"}
                    backgroundColor={"red"}
                    p={"0.2rem"}
                    mx={"1rem"}
                  >
                    {`₹${new Intl.NumberFormat("en-IN").format(
                      parseInt(cdiff)
                    )}`}
                  </Box>
                )}
              </Box>
            </Td>
            <Td>{data.project_title}</Td>
            <Td>{data.category_name}</Td>
            <Td>{`${data.person_first_name} ${data.person_last_name}`}</Td>
            <Td>{data.date}</Td>
            <Td>
              {`₹${new Intl.NumberFormat("en-IN").format(
                parseInt(data.amount)
              )}`}
            </Td>
            <Td>
              {" "}
              {
                PaymentType.filter((o) => o.value === data.payment_type_id)[0]
                  ?.label
              }
            </Td>
            <Td>
              {
                PaymentMode.filter((o) => o.value === data.payment_mode_id)[0]
                  ?.label
              }
            </Td>
            <Td>{data.is_gst_bill ? "Yes" : "No"}</Td>
            <Td>{data.txn_reference}</Td>
            <Td>{data.comments}</Td>
            <Td>
              {data.attachments.length ? (
                data.attachments.map((attach, xxidx) => {
                  return (
                    <div
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => onClickAttachments(attach)}
                    >
                      <a>Attachment-{`${xxidx + 1}`}</a>
                    </div>
                  );
                })
              ) : (
                <div>NA</div>
              )}
            </Td>
            <Td>
              {binaryconverter(loginState?.userDetails?.access_control).charAt(
                6
              ) == 1 && (
                <span onClick={() => confirmDeleteEntry(idx)}>
                  <a href="#">Delete</a>
                </span>
              )}
            </Td>
          </Tr>
          <Tr>
            <Td colSpan={"6"}>
              {childlegdata &&
                childlegdata.length != 0 &&
                childlegdata.map((ele) => {
                  return (
                    <Box>
                      {ele.ledgerid == data.txn_id && (
                        <Box display={"flex"} justifyContent={"right"}>
                          <Box
                            w={"80%"}
                            display={"flex"}
                            justifyContent={"space-evenly"}
                            borderRadius={"0 0 0.5rem 0.5rem"}
                            border={"1px solid white"}
                            color={"white"}
                            backgroundColor={"blue.500"}
                            py={"0.2rem"}
                            alignItems={"center"}
                            flexWrap={"wrap"}
                          >
                            <Box>
                              {`₹${new Intl.NumberFormat("en-IN").format(
                                ele.amount
                              )}`}
                            </Box>
                            <Box>{ele.info}</Box>

                            <Box px={"0.5rem"} m={"0.2rem"}>
                              {new Date(ele.date).toDateString()}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
            </Td>
          </Tr>
        </>
      ) : (
        <>
          {
            <>
              <Tr>
                <Td>
                  {binaryconverter(
                    loginState.userDetails.access_control
                  ).charAt(5) == 1 && (
                    <span
                      onClick={() => viewOrUpdate(data)}
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {data.txn_id}
                    </span>
                  )}
                </Td>
                <Td>{data.project_title}</Td>
                <Td>{data.category_name}</Td>
                <Td>{`${data.person_first_name} ${data.person_last_name}`}</Td>
                <Td>{data.date}</Td>
                <Td>
                  {" "}
                  {`₹${new Intl.NumberFormat("en-IN").format(
                    parseInt(data.amount)
                  )}`}
                </Td>
                <Td>
                  {" "}
                  {
                    PaymentType.filter(
                      (o) => o.value === data.payment_type_id
                    )[0]?.label
                  }
                </Td>
                <Td>
                  {
                    PaymentMode.filter(
                      (o) => o.value === data.payment_mode_id
                    )[0]?.label
                  }
                </Td>
                <Td>{data.is_gst_bill ? "Yes" : "No"}</Td>
                <Td>{data.txn_reference}</Td>
                <Td>{data.comments}</Td>
                <Td>
                  {data.attachments.length ? (
                    data.attachments.map((attach, xxidx) => {
                      return (
                        <div
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline",
                          }}
                          onClick={() => onClickAttachments(attach)}
                        >
                          <a>Attachment-{`${xxidx + 1}`}</a>
                        </div>
                      );
                    })
                  ) : (
                    <div>NA</div>
                  )}
                </Td>
                <Td>
                  {binaryconverter(
                    loginState?.userDetails?.access_control
                  ).charAt(6) == 1 && (
                    <span onClick={() => confirmDeleteEntry(idx)}>
                      <a href="#">Delete</a>
                    </span>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={"6"}>
                  {childlegdata &&
                    childlegdata.length != 0 &&
                    childlegdata.map((ele) => {
                      return (
                        <Box>
                          {ele.ledgerid == data.txn_id && (
                            <Box display={"flex"} justifyContent={"right"}>
                              <Box
                                w={"80%"}
                                display={"flex"}
                                justifyContent={"space-evenly"}
                                borderRadius={"0 0 0.5rem 0.5rem"}
                                border={"1px solid white"}
                                color={"white"}
                                backgroundColor={"blue.500"}
                                py={"0.2rem"}
                                alignItems={"center"}
                                flexWrap={"wrap"}
                              >
                                <Box>
                                  {`₹${new Intl.NumberFormat("en-IN").format(
                                    ele.amount
                                  )}`}
                                </Box>
                                <Box>{ele.info}</Box>

                                <Box px={"0.5rem"} m={"0.2rem"}>
                                  {new Date(ele.date).toDateString()}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                </Td>
              </Tr>
            </>
          }
        </>
      )}
    </>
  );
};

export default TableBody;
