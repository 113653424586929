import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stack,
  Text,
  Heading,
  Box,
  StackDivider,
  Button,
  Image,
  Select,
  Input,
  ChakraProvider,
  CircularProgress,
  Spinner,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { fetchProjects } from "../../redux/actions/workOrderAction";
import { getAttData } from "../../redux/actions/attendanceAction";
import "./attendanceClass.css";
import {
  getAdminProjects,
  getAdminProjectsAtt,
} from "../../redux/actions/projectsAction";
import { Fade } from "@mui/material";
import * as Material from "@mui/material";
// import Modal from "@mui/material/Modal";
import Pagination from "../Pagination/Pagination";
// --------------react -pdf---------------------

import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Pdftemplate from "../ProjectView/PDFDownload/Pdftemplate";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { Preview } from "@mui/icons-material";
// --------------react -pdf---------------------

const LabourView = ({ pathname }) => {
  const [loading, setLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [change, SetChange] = useState({
    project_id: 0,
    labour: "",
  });
  const att_title = [
    { name: "attendance_taken_by", label: "attendance taken by" },
    { name: "date", label: "Date" },
    { name: "name_attendance", label: "labour type" },
    { name: "no_of_worker", label: "no of worker" },
    { name: "project_id", label: "project" },
    { name: "comment", label: "comment" },
    { name: "attachment", label: "attachment" },
  ];

  const labour_type = [
    "Mason",
    "Helper",
    "Tile",
    "Painters",
    "Carpenters",
    "Electrician",
    "Fabricator",
    "Bar bending",
    "Shuttering",
    "other",
  ];
  // _________________________FUNCTIONS______________________________
  // _________________________Generate PDf FUNCTIONS______________________________
  // const [showPreview, setShowPreview] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // _________________________Generate PDf FUNCTIONS______________________________

  const getData = () => {
    getAdminProjectsAtt(JSON.stringify({ project_status: "all" })).then(
      (res) => {
        if (res.status) {
          let data1 = [];
          let arr = res.data;
          for (let idx in arr) {
            data1.push({
              value: arr[idx].project_id,
              label: arr[idx].project_title,
            });
          }
          setProjectsList(data1);
        }
      }
    );
  };
  async function getAttentance() {
    const resp = await getAttData(change.project_id, change.labour);
    console.log(resp);
    const arr = resp.result;
    arr?.sort((a, b) => new Date(b?.date) - new Date(a?.date));
    setLoading(false);
    setAttendanceData(arr);
  }
  const onChangeProject = (e) => {
    const project_id = Number(e.target.value);
    SetChange((prev) => ({ ...prev, project_id: project_id }));
  };
  const onChangeLabour = (e) => {
    const labour = e.target.value;
    SetChange((prev) => ({ ...prev, labour }));
  };
  // const onChangeDate = (e) => {
  //   console.log(new Date(e.target.value) + " " + new Date());
  // };
  // _________________________USE_EFFECT_ _____________________________
  useEffect(() => {
    setLoading(true);
    getAttentance();
  }, [change.labour, change.project_id]);
  useEffect(() => {
    setLoading(true);
    getAttentance();
    getData();
  }, []);

  let nevigate = useNavigate();
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return attendanceData.slice(firstPageIndex, lastPageIndex);
  }, [attendanceData, currentPage]);
  // pagination

  return (
    <>
      <ChakraProvider>
        <Heading
          size="md"
          style={{
            color: "black",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          View Attendance
        </Heading>
        <div className="viewSelect">
          {/* --------------------------PROJECT_SELECTION-------------------------*/}
          <Select
            variant="filled"
            placeholder="Select Project"
            onChange={onChangeProject}
          >
            {projectsList?.map((proj, key) => (
              <option value={proj.value} key={key}>
                {proj.label}
              </option>
            ))}
          </Select>
          {/* --------------------------LABOUR_SELECTION-------------------------*/}
          <Select
            variant="filled"
            placeholder="Select Labour"
            onChange={onChangeLabour}
          >
            {labour_type?.map((labour, ind) => (
              <option value={labour} key={ind}>
                {labour}
              </option>
            ))}
          </Select>
          {/* --------------------------CARD_VIEW-------------------------*/}
        </div>

        <Accordion defaultIndex={[0]} allowMultiple>
          {Array.isArray(currentTableData) &&
            currentTableData?.map((obj1, key) => (
              <AccordionItem key={key}>
                <Card
                  style={{
                    // backgroundColor: "#eae4e469",
                    // width: "400px",
                    boxShadow: "4px 10px 18px #b4b3b3",
                    margin: "7px",
                  }}
                >
                  <AccordionButton
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textTransform: "capitalize",
                    }}
                  >
                    <CardHeader>
                      <Heading size="md">
                        <div
                          style={{
                            color: "black",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{ textAlign: "left" }}>
                            <span style={{ textTransform: "uppercase" }}>
                              {obj1.name_attendance}
                            </span>
                            <span
                              style={{ fontWeight: 500, fontSize: "1rem" }}
                            >{`| (No Of Worker - ${obj1.no_of_worker})`}</span>
                          </span>
                        </div>
                      </Heading>
                    </CardHeader>
                    <div>
                      <small>
                        <strong>
                          {new Date(obj1.date).toLocaleDateString("en-IN")}
                        </strong>
                      </small>
                      <AccordionIcon />
                    </div>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <CardBody>
                      <Stack
                        divider={<StackDivider />}
                        spacing="4"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          alignContent: "space-between",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box style={{ width: "200px" }}>
                          <Heading size="xs" textTransform="uppercase">
                            <div style={{ color: "black", padding: "12px" }}>
                              attendance taken by
                            </div>
                          </Heading>
                          <Text
                            pt="2"
                            fontSize="sm"
                            style={{ padding: "12px" }}
                          >
                            {obj1.attendance_taken_by}
                          </Text>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Heading size="xs" textTransform="uppercase">
                            <div style={{ color: "black", padding: "12px" }}>
                              date
                            </div>
                          </Heading>
                          <Text
                            pt="2"
                            fontSize="sm"
                            style={{ padding: "12px" }}
                          >
                            {new Date(obj1.date).toLocaleDateString("en-IN")}
                          </Text>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Heading size="xs" textTransform="uppercase">
                            <div style={{ color: "black", padding: "12px" }}>
                              labour type
                            </div>
                          </Heading>
                          <Text
                            pt="2"
                            fontSize="sm"
                            style={{ padding: "12px" }}
                          >
                            {obj1.name_attendance}
                          </Text>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Heading size="xs" textTransform="uppercase">
                            <div style={{ color: "black", padding: "12px" }}>
                              no of worker
                            </div>
                          </Heading>
                          <Text
                            pt="2"
                            fontSize="sm"
                            style={{ padding: "12px" }}
                          >
                            {obj1.no_of_worker}
                          </Text>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Heading size="xs" textTransform="uppercase">
                            <div style={{ color: "black", padding: "12px" }}>
                              project
                            </div>
                          </Heading>
                          <Text
                            pt="2"
                            fontSize="sm"
                            style={{ padding: "12px" }}
                          >
                            {
                              projectsList?.find(
                                (nobj) => nobj.value == obj1.project_id
                              )?.label
                            }
                          </Text>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Heading size="xs" textTransform="uppercase">
                            <div style={{ color: "black", padding: "12px" }}>
                              comment
                            </div>
                          </Heading>
                          <Text
                            pt="2"
                            fontSize="sm"
                            style={{ padding: "12px" }}
                          >
                            {obj1.comment}
                          </Text>
                        </Box>
                        <Box style={{ width: "200px" }}>
                          <Heading size="xs" textTransform="uppercase">
                            <div style={{ color: "black", padding: "12px" }}>
                              attachment
                            </div>
                          </Heading>
                          <Text
                            pt="2"
                            fontSize="sm"
                            style={{ padding: "12px" }}
                          >
                            <Image
                              objectFit="cover"
                              maxW={{ base: "100%", sm: "200px" }}
                              src={`${obj1.attachment}`}
                              alt="Caffe Latte"
                            />
                          </Text>
                        </Box>
                      </Stack>
                    </CardBody>
                  </AccordionPanel>
                </Card>
              </AccordionItem>
            ))}
        </Accordion>
      </ChakraProvider>
      <ChakraProvider>
        <Material.Box
          sx={{
            height: 40,
            textAlign: "center ",
            zIndex: 4,
            display: `${loading ? "block" : "none"}`,
          }}
        >
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <CircularProgress isIndeterminate thickness="4px" />
            {/* <Spinner color="priamary" size="l" /> */}
          </Fade>
        </Material.Box>
      </ChakraProvider>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={attendanceData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default LabourView;
