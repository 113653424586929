import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import HFTable from "../../Components/HFTable";
import myGetReq from "../../utils/get";
import { useNavigate, Link, useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Tooltip,
  LinearProgress,
  Button,
  TextField,
  Autocomplete,
  List,
  ListItem,
  Chip,
  Paper,
  InputBase,
  Divider,
  Input,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Alert,
  Backdrop,
  Snackbar,
  TextareaAutosize,
  Fade,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import _, { size } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import HtmlArr from "./Template/AllTemplate";
import { Close, Mail, Preview } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SendMailLead } from "../../redux/actions/LeadAction";
import Droper from "./Droper";

const schema = yup.object().shape({
  title: yup.string().required("This field is required"),
  subject: yup.string().required("This field is required"),
  // body: yup.string().required("This field is required"),
});

const DialogBox = ({
  open,
  handleClickOpen,
  handleClose,
  isItemSelected,
  setIsItemSelected,
  setAlert,
  alert,
}) => {
  const handleDelete = (chipToDelete) => () => {
    setIsItemSelected((prev) =>
      prev.filter((chip) => chip.id != chipToDelete.id)
    );
  };
  const [addEmail, setAddEmail] = useState("");
  const [viewToggle, setViewToggle] = useState(false);
  // const [selectedTemplate, setSelectedTemplate] = useState(0);
  // const [mailBody, setMailBody] = useState("");
  // const [attachments, setAttachments] = useState([]);
  const [mailData, setMailData] = useState({
    selectedTemplate: 0,
    mailBody: "",
    attachments: [],
  });

  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: {},
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const Submit = (data) => {
    // console.log(data, "fdsfds data");
    const sendData = { ...data };
    if (mailData?.selectedTemplate != 0) {
      sendData.template = HtmlArr?.[mailData?.selectedTemplate].template;
      sendData.template_name = HtmlArr?.[mailData?.selectedTemplate].label;
      sendData.bodym = "";
    } else {
      sendData.template = "";
      sendData.template_name = "No Template";
      sendData.bodym = mailData.mailBody;
    }
    sendData.attachments = mailData.attachments;
    // sendData.mails = [...isItemSelected.map((el) => el.email)];
    sendData.mails = [...isItemSelected];

    console.log(sendData, "sdfds");
    SendMailLead(sendData, mailData.attachments);
    reset({ subject: "", title: "" });
    setIsItemSelected([]);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
      handleClose();
    }, 1500);
  };

  const handleAddEmail = () => {
    if (addEmail != "") {
      setIsItemSelected((prev) => [
        { id: Date.now(), email: addEmail },
        ...prev,
      ]);
      setAddEmail("");
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        sx={{
          "& .MuiTypography-root": {
            // background: "linear-gradient(0deg, #84 8585, transparent,#848585)",
            background: "red",
            color: "#FFF",
            borderBottom: "5px solid black",
            marginBottom: "10px",
          },
        }}
      >
        <form onSubmit={handleSubmit(Submit)} enctype="multipart/form-data">
          <DialogTitle>Welcome To Send Mail</DialogTitle>
          <DialogContent>
            {/* ______________________Email To_______________________ */}
            <Paper
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                // width: 400,
                my: "10px",
                border: "1px solid rgb(196 196 196)",
                boxShadow:
                  " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                "&:hover": {
                  border: "1px solid black",
                },
              }}
              margin="dense"
              mb="20px"
            >
              <InputBase
                id="email"
                label="Email To"
                type="email"
                sx={{ ml: 1, flex: 1 }}
                onChange={(e) => {
                  setAddEmail(e.target.value);
                }}
                value={addEmail}
                placeholder="Email To"
                inputProps={{ "aria-label": "search google maps" }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={handleAddEmail}
              >
                <AddIcon />
              </IconButton>
            </Paper>
            <List
              dense
              sx={{
                minHeight: "5vh",
                maxHeight: "30vh",
                overflowX: "scroll",
                ml: "20px",
                my: "5px",
                display: `${isItemSelected.length !== 0 ? "block" : "none"}`,
                // "&.MuiList-root": { background: "#FFF" },
                border: "1px solid #c4c4c4",
                borderRadius: "4px",
                boxShadow:
                  "rgb(38, 57, 77) 1px 3px 5px -5px ,rgb(38, 57, 77) -1px -3px 5px -5px",
              }}
            >
              {isItemSelected.map((obj) => (
                <ListItem key={obj.id}>
                  <Chip
                    icon={<Mail />}
                    label={obj.email}
                    onDelete={handleDelete(obj)}
                  />
                </ListItem>
              ))}
            </List>
            {/*_____________________________Title_________________________ */}
            <Controller
              name="title"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <TextField
                  // {...ref("title")}
                  sx={{
                    boxShadow:
                      " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                  }}
                  autoFocus
                  error={errors.title}
                  margin="dense"
                  mb="20px"
                  id="title_text"
                  label="Title"
                  type="text"
                  fullWidth
                  variant="outlined"
                  helperText={errors?.title?.message}
                  {...rest}
                />
              )}
            />
            {/*______________________________Subject________________________ */}
            <Controller
              name="subject"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <TextField
                  // {...ref("subject")}

                  error={errors.subject}
                  margin="dense"
                  sx={{
                    mb: "18px",
                    boxShadow:
                      " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                  }}
                  id="subject"
                  label="Subject"
                  type="text"
                  fullWidth
                  variant="outlined"
                  helperText={errors?.subject?.message}
                  {...rest}
                />
              )}
            />
            {/*_________________________Template______________________________*/}
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                borderRadius: "3px",
                border: "1px solid rgb(196 196 196)",
                boxShadow:
                  " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                "&:hover": {
                  border: "1px solid black",
                },
                "&:placeholder": {
                  color: "gray",
                },
                // width: 300,
              }}
            >
              <FormControl variant="standard" fullWidth>
                <Select
                  sx={{ ml: 1, flex: 1 }}
                  // labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={mailData.selectedTemplate}
                  onChange={(e) => {
                    // setSelectedTemplate(e.target.value);
                    setMailData((prev) => ({
                      ...prev,
                      selectedTemplate: e.target.value,
                    }));
                    // setValue(body, "Body");
                  }}
                >
                  {HtmlArr.map((el, ind) => (
                    <MenuItem value={ind}>{el.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={() => setViewToggle(true)}
              >
                <Preview />
              </IconButton>
            </Paper>
            {/*__________________________________Body____________________________________ */}
            <Fade in={mailData.selectedTemplate == 0}>
              <Paper
                sx={{
                  mt: "20px",
                  // p: "2px 4px",
                  boxShadow:
                    " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid rgb(196 196 196)",
                  borderRadius: "5px",
                  width: "initial",
                  "&:hover": {
                    border: "1px solid black",
                  },
                  // border: `${errors?.body ? "1px solid red" : "none"}`,
                }}
              >
                <TextareaAutosize
                  // ref={register("body")}
                  minRows={2}
                  maxRows={8}
                  placeholder={"body"}
                  style={{
                    borderRadius: "10px",
                    width: "-webkit-fill-available",
                    padding: "10px",
                  }}
                  onChange={(e) => {
                    // setMailBody(e.target.value);
                    setMailData((prev) => ({
                      ...prev,
                      mailBody: e.target.value,
                    }));
                  }}
                ></TextareaAutosize>
              </Paper>
            </Fade>
            {/* __________________________________File Picker_________________________*/}
            <Box mt={4}>
              <Droper
                value={mailData.attachments}
                onChange={(e) =>
                  setMailData((prev) => ({
                    ...prev,
                    attachments: e,
                  }))
                }
                uploadLabel={"Mails Attachments"}
              />
            </Box>
            {/* __________________________________-view file of Html Dialog_________________________*/}
            <Dialog
              open={viewToggle}
              onClose={() => setViewToggle(false)}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogActions>
                <Button
                  onClick={() => setViewToggle(false)}
                  variant="contained"
                  color="error"
                >
                  <Close />
                </Button>
              </DialogActions>
              <Box
                className="content"
                dangerouslySetInnerHTML={{
                  __html: HtmlArr?.[mailData.selectedTemplate].template,
                }}
              ></Box>

              <DialogActions>
                <Button
                  onClick={() => setViewToggle(false)}
                  variant="contained"
                  color="error"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            {/* ---------------view file of Html Dialog------------------ */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Send</Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: 1000000 }}
        open={alert}
        onClose={() => setAlert(false)}
      >
        <Alert
          variant="filled"
          severity="success"
          // sx={{ position: "absolute", top: "20px" }}
        >
          Mail is successfully send , Thanks you for sending mail
        </Alert>
      </Backdrop> */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert}
        autoHideDuration={1000}
        onClose={() => setAlert(false)}
      >
        <Alert
          onClose={() => setAlert(false)}
          elevation={6}
          variant="filled"
          severity={"success"}
          sx={{ width: "100%" }}
        >
          Mail is successfully send , Thanks you for sending mail
        </Alert>
      </Snackbar>
    </div>
  );
};

const Header = (props) => {
  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  const loginState = useSelector((state) => state.login);

  return (
    <Row>
      <Col md={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <h3 className="page-title">{props.title}</h3>
          {binaryconverter(loginState.userDetails.access_control).charAt(22) ==
            1 && (
            <Button
              variant="contained"
              color={"error"}
              onClick={props.handleClickOpen}
              startIcon={<Mail sx={{ color: "black" }} />}
            >
              Send Mail
            </Button>
          )}
          {binaryconverter(loginState.userDetails.access_control).charAt(22) ==
            1 && (
            <Button
              variant="contained"
              // onClick={() => {
              //   window.open(`${props.pathname}/add`);
              // }}
              component={Link}
              to={`${props.pathname}/add`}
              startIcon={<AddIcon />}
            >
              Add Lead
            </Button>
          )}
        </Box>
      </Col>
    </Row>
  );
};

const LeadList = (props) => {
  console.log("leads-props", props);
  const [leads, setLeads] = useState([]);
  const [tabValue, setTabValue] = useState(1);
  const [activeLeads, setActiveLeads] = useState([]);
  const [filteredActiveLeads, setFilteredActiveLeads] = useState([]);
  const [waitingLeads, setWaitingLeads] = useState([]);
  const [filteredWaitingLeads, setFilteredWaitingLeads] = useState([]);
  const [rnrLeads, setRnrLeads] = useState([]);
  const [rnrFilteredLeads, setRnrFilteredLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isItemSelected, setIsItemSelected] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = useState(false);

  let location = useLocation();

  const IDComponent = ({ row }) => {
    const loginState = useSelector((state) => state.login);
    function binaryconverter(hexadecimal) {
      let decimal = parseInt(hexadecimal, 16);
      let binary = decimal.toString(2);
      return binary;
    }

    return (
      <>
        {binaryconverter(loginState.userDetails.access_control).charAt(32) ==
        1 ? (
          <Tooltip title="Edit lead">
            <Link to={`${props.pathname}/update/${row.id}`}>{row.id}</Link>
          </Tooltip>
        ) : (
          row.id
        )}
      </>
    );
  };

  const NumberComponent = ({ row }) => {
    // console.log("number-component", row);
    return (
      <Tooltip
        title="WhatsApp"
        onClick={() => {
          window.open(`whatsapp://send?phone=${row.number}`);
        }}
      >
        <Box sx={{ cursor: "pointer" }}>{row.number}</Box>
      </Tooltip>
    );
  };

  const activeTableHeaders = [
    {
      title: "ID",
      key: "id",
      component: IDComponent,
      Prostyle: { padding: "0px", minWidth: "70px" },
    },
    {
      title: "Contact",
      key: "number",
      component: NumberComponent,
      Prostyle: { padding: "0px 5px", minWidth: "120px" },
    },
    {
      title: "Name",
      key: "name",
      Prostyle: { padding: "0px", minWidth: "100px" },
    },
    // { title: "Location", key: "location" },
    // { title: "Origin", key: "origin" },
    // { title: "Budget", key: "budget" },
    {
      title: "Requirement",
      key: "requirement",
      Prostyle: { padding: "0px", minWidth: "150px" },
    },
    {
      title: "Comments",
      key: "comments",
      Prostyle: { padding: "0px 12px 0px 12px", minWidth: "150px" },
    },
    {
      title: "Follow Up",
      key: "last_followup",
      Prostyle: { padding: "0px 10px", minWidth: "100px" },
    },
    {
      title: "Start Date",
      key: "start_date",
      Prostyle: { padding: "0px", minWidth: "100px" },
    },
    {
      title: "Count",
      key: "mail_count",
      Prostyle: { padding: "0px", minWidth: "10px" },
    },
    // { title: "Days", key: "days" },
    // { title: "Assign to", key: "assign_to_name" },
  ];

  let waitingTableHeaders = _.cloneDeep(activeTableHeaders);
  waitingTableHeaders.push({ title: "Remind on", key: "remind_on" });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    myGetReq(`/app/usr/leads?type=${props.type}`)
      .then((res) => {
        console.log("leads-data", res);
        setLeads(res.data);
        let active = [];
        let waiting = [];
        let rnr = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].status === "active") {
            active.push(res.data[i]);
          } else if (res.data[i].status === "waiting") {
            waiting.push(res.data[i]);
          }
          if (res.data[i].last_followup === "rnr") {
            rnr.push(res.data[i]);
          }
        }
        setActiveLeads(active);
        setFilteredActiveLeads(active);
        setWaitingLeads(waiting);
        setFilteredWaitingLeads(waiting);
        setRnrLeads(rnr);
        setRnrFilteredLeads(rnr);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }, [location]);

  const handleStar = (row) => {
    console.log("leads", activeLeads);
    if (row.status === "active") {
      let temp = _.cloneDeep(activeLeads);
      const index = _.findIndex(temp, { id: row.id });
      const star = row.star === 1 ? 0 : 1;
      temp[index].star = star;
      console.log("star", temp);
      setActiveLeads(temp);
    } else if (row.status === "waiting") {
    }
  };

  const action = ({ row }) => {
    return (
      <Box sx={{ minWidth: 80 }}>
        <Tooltip
          title="WhatsApp"
          onClick={() => {
            window.open(`whatsapp://send?phone=${row.number}`);
          }}
        >
          <IconButton>
            <WhatsAppIcon />
          </IconButton>
        </Tooltip>
        <Link to={`${props.pathname}/update/${row.id}`}>
          <Tooltip title="Edit lead">
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Link>
      </Box>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container className="dashboard">
        <Header
          title={`${_.upperFirst(props.type)} Leads`}
          pathname={props.pathname}
          handleClickOpen={handleClickOpen}
        />
        {!isLoading ? (
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 1,
              borderRadius: 2,
              p: 2,
              minWidth: 300,
            }}
          >
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={`Active (${filteredActiveLeads.length})`}
                    value={1}
                  />
                  <Tab
                    label={`Waiting (${filteredWaitingLeads.length})`}
                    value={2}
                  />
                  <Tab label={`RNR (${rnrFilteredLeads.length})`} value={3} />
                </TabList>
              </Box>
              <TabPanel value={1} className="p-0">
                <HFTable
                  headers={activeTableHeaders}
                  data={activeLeads}
                  filteredData={filteredActiveLeads}
                  setFilteredData={setFilteredActiveLeads}
                  action={false}
                  handleStar={handleStar}
                  setIsItemSelected={setIsItemSelected}
                  isItemSelected={isItemSelected}
                />
              </TabPanel>
              <TabPanel value={2} className="p-0">
                <HFTable
                  headers={waitingTableHeaders}
                  data={waitingLeads}
                  filteredData={filteredWaitingLeads}
                  setFilteredData={setFilteredWaitingLeads}
                  action={false}
                  handleStar={handleStar}
                  setIsItemSelected={setIsItemSelected}
                  isItemSelected={isItemSelected}
                />
              </TabPanel>
              <TabPanel value={3} className="p-0">
                <HFTable
                  headers={activeTableHeaders}
                  data={rnrLeads}
                  filteredData={rnrFilteredLeads}
                  setFilteredData={setRnrFilteredLeads}
                  action={false}
                  handleStar={handleStar}
                  setIsItemSelected={setIsItemSelected}
                  isItemSelected={isItemSelected}
                />
              </TabPanel>
            </TabContext>
            <DialogBox
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              open={open}
              setOpen={setOpen}
              isItemSelected={isItemSelected}
              setIsItemSelected={setIsItemSelected}
              setAlert={setAlert}
              alert={alert}
            />
          </Box>
        ) : (
          <LinearProgress />
        )}
      </Container>
    </>
  );
};
export default LeadList;
