import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  MenuItem,
  FormControl,
  Box,
  Container,
  Card,
  CardContent,
  Button,
  Stack,
  Alert,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { HFPicker } from "../../Components/FormikFields";
import myPostReq from "../../utils/post";
import myGetReq from "../../utils/get";
import moment from "moment";
import { Formik, Form, Field, connect } from "formik";
import { TextField, Select } from "formik-mui";
import * as Yup from "yup";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

const FormConfig = connect((props) => {
  console.log("form-config-props", props);
  const followupList = [
    { value: "new", status: "active", title: "New" },
    {
      value: "basic requirement",
      status: "active",
      title: "Basic requirement",
    },
    {
      value: "technical requirement",
      status: "active",
      title: "Technical requirement",
    },
    {
      value: "customer quotation",
      status: "active",
      title: "Customer quotation",
    },
    { value: "will book", status: "active", title: "Will book" },
    { value: "booked", status: "booked", title: "Booked" },
    { value: "ignored", status: "ignored", title: "Ignored" },
    { value: "other town", status: "ignored", title: "Other town" },
    { value: "duplicate", status: "ignored", title: "Duplicate" },
    { value: "rnr", status: "ignored", title: "RNR" },
  ];

  const originList = [
    { value: "website", title: "Website" },
    { value: "google", title: "Google" },
    { value: "facebook", title: "Facebook" },
    { value: "others", title: "Others" },
  ];

  const propTypeList = [
    { value: "g", title: "G" },
    { value: "g+1", title: "G+1" },
    { value: "g+2", title: "G+2" },
    { value: "g+3", title: "G+3" },
    { value: "g+4", title: "G+4" },
    { value: "commercial", title: "Commercial" },
  ];

  const { ref: materialRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      console.log("place", place);
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      console.log("lat-lng", lat);
      props.formik.setFieldValue("location", place.formatted_address);
      props.formik.setFieldValue("lat", lat);
      props.formik.setFieldValue("lng", lng);
    },
    options: {
      types: ["(regions)"],
      componentRestrictions: { country: "in" },
    },
  });
  console.log("place-ref", materialRef);

  const handleFollowup = (e, value) => {
    props.formik.setFieldValue("status", value.props.status);
  };

  useEffect(() => {
    if (props.formik.values.remind_on) {
      props.formik.setFieldValue("status", "waiting");
    }
  }, [props.formik.values.remind_on]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Form>
          <div className="row">
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="text"
                  size="small"
                  name="number"
                  label="Phone No"
                  variant="outlined"
                  component={TextField}
                />
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="text"
                  name="name"
                  size="small"
                  label="Name"
                  variant="outlined"
                  component={TextField}
                />
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="email"
                  name="email"
                  size="small"
                  label="Email"
                  variant="outlined"
                  component={TextField}
                />
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  name="location"
                  size="small"
                  label="Location"
                  variant="outlined"
                  inputRef={materialRef}
                  component={TextField}
                />
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="select"
                  name="origin"
                  label="Origin"
                  size="small"
                  variant="outlined"
                  component={Select}
                >
                  {originList.map((item, index) => (
                    <MenuItem key={`origin-${index}`} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  name="assign_to"
                  label="Assign to"
                  size="small"
                  variant="outlined"
                  component={Select}
                >
                  <MenuItem value={0}>Unassigned</MenuItem>
                  {props.assignToList &&
                    props.assignToList.map((item, index) => (
                      <MenuItem key={`key-${index}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </div>
            <div className={props.cols}>
              <HFPicker
                name="start_date"
                size="small"
                label="Start Date"
                variant="outlined"
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div className={props.cols}>
              <HFPicker
                name="remind_on"
                size="small"
                label="Remind on"
                variant="outlined"
                inputFormat="dd-MM-yyyy"
                disabled={
                  props.formik.values.status !== "active" &&
                  props.formik.values.status !== "waiting"
                }
              />
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="text"
                  name="budget"
                  size="small"
                  label="Budget (in lakh)"
                  variant="outlined"
                  component={TextField}
                />
              </FormControl>
            </div>

            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  name="follow_up"
                  size="small"
                  label="Follow Up"
                  variant="outlined"
                  component={Select}
                  onChange={handleFollowup}
                >
                  {followupList.map((item, index) => (
                    <MenuItem
                      key={`follow-${index}`}
                      status={item.status}
                      value={item.value}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  name="prop_type"
                  size="small"
                  label="Prop Type"
                  variant="outlined"
                  component={Select}
                >
                  {propTypeList.map((item, index) => (
                    <MenuItem key={`follow-${index}`} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  name="feedback"
                  size="small"
                  label="Feedback"
                  variant="outlined"
                  component={Select}
                >
                  <MenuItem value="cold">Cold</MenuItem>
                  <MenuItem value="warm">Warm</MenuItem>
                  <MenuItem value="hot">Hot</MenuItem>
                </Field>
              </FormControl>
            </div>

            {/* <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  name="rating"
                  size="small"
                  label="Rating"
                  variant="outlined"
                  component={Select}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                </Field>
              </FormControl>
            </div> */}
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="text"
                  name="requirement"
                  size="small"
                  label="Requirement"
                  variant="outlined"
                  multiline
                  rows={2}
                  component={TextField}
                />
              </FormControl>
            </div>
            <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="text"
                  name="comment"
                  size="small"
                  label="Comment"
                  variant="outlined"
                  multiline
                  rows={2}
                  component={TextField}
                />
              </FormControl>
            </div>
            {/* <div className={props.cols}>
              <FormControl className="w-100 mb-2">
                <Field
                  type="text"
                  name="status"
                  size="small"
                  label="Status"
                  variant="outlined"
                  disabled={true}
                  component={TextField}
                />
              </FormControl>
            </div> */}
          </div>

          <Stack spacing={1} direction="row" className="mt-2">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button variant="outlined" type="reset">
              Reset
            </Button>
          </Stack>
        </Form>
      </CardContent>
    </Card>
  );
});

const LeadForm = (props) => {
  console.log("lead-form-props", props);
  const [leadAdmins, setLeadAdmins] = useState();
  const [snackbar, setSnackbar] = useState({
    status: false,
    type: "success",
    msg: "success",
  });
  const navigate = useNavigate();

  const validation = Yup.object().shape({
    email: Yup.string().email("Invalid email"),
    number: Yup.string()
      .min(12, "Too Short!")
      .max(13, "Too Long!")
      .required("Required"),
    // assign_to: Yup.string().required("Required"),
  });

  const handleSubmit = (values, e) => {
    console.log("submit-form", values);
    try {
      Date.prototype.toJSON = function () {
        return moment(this).format("YYYY-MM-DD");
      };
      myPostReq("/app/usr/lead", JSON.stringify(values)).then((res) => {
        console.log("response", res);
        if (res.status) {
          e.setSubmitting(false);
          e.setFieldValue("comment", "");
          setSnackbar({ ...snackbar, ["status"]: true, ["type"]: "success" });
          if (values.id) {
            props.setLeadHistory(res.followup);
          }
          navigate(`${props.pathname}/update/${res.id}`);
        } else {
          e.setSubmitting(false);
          setSnackbar({
            ...snackbar,
            ["status"]: true,
            ["type"]: "error",
            ["msg"]: res.msg,
          });
        }
      });
    } catch (err) {
      console.log("error", err);
      setSnackbar({
        ...snackbar,
        ["status"]: true,
        ["type"]: "error",
        ["msg"]: err.response?.msg ? err.response.msg : "Something went wrong",
      });
    }
  };

  useEffect(() => {
    myGetReq(`/app/usr/leadAdmins`)
      .then((res) => {
        setLeadAdmins(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const handleClose = () => {
    setSnackbar({ ...snackbar, ["status"]: false });
  };

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        <FormConfig
          assignToList={leadAdmins}
          cols={props.cols ? props.cols : "col-3"}
        />
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.status}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity={snackbar.type}
          sx={{ width: "100%" }}
        >
          {snackbar.msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LeadForm;
