import React, { useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse, Button } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { useSelector, useDispatch } from "react-redux";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={handleToggleCollapse}
      >
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">{`${loginState.userDetails.first_name} ${loginState.userDetails.last_name}`}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
          <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />
          <div className="topbar__menu-divider" /> */}
          <TopbarMenuLink title="Logout" icon="exit" path="/user/logout" />
          {/* <div className="topbar__menu-divider" /> */}
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
