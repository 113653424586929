import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Bookings from "./Bookings";
import { getAdminProjects } from "../../../redux/actions/projectsAction";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { getWorkOrdersApi } from "../../../redux/actions/workOrderAction";

const AdminDashboard = () => {
  const [data, setData] = useState([]);
  const [balanceAmt, setBalanceAmt] = useState(0);
  const [type, setType] = useState("all");

  const getData = (type) => {
    getAdminProjects(JSON.stringify({ project_status: type })).then((res) => {
      if (res.status) {
        let balance = 0;
        let data = res.data;
        for (let idx in data) {
          let bamt = Number(data[idx].received) - Number(data[idx].to_pay);
          balance += bamt;
        }
        balance = Math.round(balance);
        setBalanceAmt(balance);

        if (
          Object.prototype.hasOwnProperty.call(res.projectValues, "totalExp")
        ) {
          for (let xidx in res.projectValues.totalExp) {
            let dataVal = res.projectValues.totalExp[xidx];
            let project_id = dataVal.project_id;
            let val = data.findIndex((o) => o.project_id === project_id);
            if (val >= 0) {
              data[val] = {
                ...data[val],
                ttotal_expense: dataVal.total_expense,
              };
            }
          }
        }
        if (
          Object.prototype.hasOwnProperty.call(res.projectValues, "totalVal")
        ) {
          for (let xidx in res.projectValues.totalVal) {
            let dataVal = res.projectValues.totalVal[xidx];
            let project_id = dataVal.project_id;
            let val = data.findIndex((o) => o.project_id === project_id);
            if (val >= 0) {
              data[val] = {
                ...data[val],
                ttotal_income: dataVal.total_value,
              };
            }
          }
        }
        if (
          Object.prototype.hasOwnProperty.call(
            res.projectValues,
            "totalCustomerPaid"
          )
        ) {
          for (let xidx in res.projectValues.totalCustomerPaid) {
            let dataVal = res.projectValues.totalCustomerPaid[xidx];
            let project_id = dataVal.project_id;
            let val = data.findIndex((o) => o.project_id === project_id);
            if (val >= 0) {
              data[val] = {
                ...data[val],
                totalcustomerpaid: dataVal.totalcustomerpaid,
              };
            }
          }
        }
        setData(res.data);
      }
    });
  };

  const [tabValue, setTabValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setData([]);
    if (newValue == "two") {
      setType("completed");
    } else if (newValue == "three") {
      setType("rejected");
    } else {
      setType("all");
    }
    setTabValue(newValue);
  };

  const onChangeWoPopup = (index) => {

    let list = [...data];
    list[index].showWo = !list[index].showWo;
    list[index].work_orders = [];

    console.log(index, "wotes")
    if (list[index].showWo) {
      console.log("ifffffffff")
      getWorkOrdersApi(
        { value: list[index].project_id },
        {
          category: "",
          person: "",
          date: "",
          comments: "",
          status: "",
        }
      ).then((res) => {
        if (res.status) {
          console.log("res ifff")
          list[index].work_orders = res.data;
          setData([...list]);
        }
      }).catch((res) => {
        console.log("yghwruyfguh")
      });
    } else {

      setData(list);
    }
  };

  useEffect(() => {
    getData(type);
  }, [type]);

  useEffect(() => { }, [data]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6}>
          <h1 className="page-title">
            <strong>Dashboard</strong>
          </h1>
        </Col>
        <Col md={3}>
          <h2 className="page-title">
            <strong>PRJ: </strong>
            <span>{data.length}</span>
          </h2>
        </Col>
        <Col md={3}>
          <h2 className="page-title">
            <strong>B: </strong>
            <span className={balanceAmt < 0 ? "colorRed" : "colorGreen"}>
              {balanceAmt}
            </span>
          </h2>
        </Col>
      </Row>
      <Row>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="one" label="All" />
            <Tab value="two" label="Completed" />
            <Tab value="three" label="Others" />
          </Tabs>
        </Box>

        {data.length ? (
          data.map((dat, key) => {
            return (
              <Bookings
                key={key}
                projectInfo={dat}
                index={key}
                onChangeWoPopup={onChangeWoPopup}
              // style={{
              //   boxShadow:
              //     " grey 2px 3px 10px, inset #dce7752b 3px 3px 2px 1px",
              //   marginTop: "20px",
              // }}
              />
            );
          })
        ) : (
          <div> No Projects found </div>
        )}
      </Row>
    </Container>
  );
};

export default AdminDashboard;
