import { Box, Input } from '@chakra-ui/react'
import React from 'react'
import FormField from './FormField'
import Select from "react-select";
import * as ChakraAll from "@chakra-ui/react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { useState } from 'react';
import { useEffect } from 'react';
import { getChildLedgerData } from '../../redux/actions/childLedgerActions';
import TableBody from './TableBody';
import AddCircleIcon from 'mdi-react/AddCircleIcon';
import DownloadBoxIcon from 'mdi-react/DownloadBoxIcon';

const TableLedger = (
    { onChangeTnx_id
        , filterData
        , control
        , errors
        , OnChangeProject
        , projectsList
        , document
        , onChangeFilters
        , PaymentType
        , PaymentMode
        , currentTableData
        , binaryconverter
        , loginState
        , confirmDeleteEntry
        , viewOrUpdate
        , childlegdata
        , download
        , totalExpense
        , totalIncome
        , createLedgerEntry
    }) => {

    const [modata, setmodata] = useState([])

    const [showmo, setshowmo] = useState(false)

    useEffect(() => {
        console.log(currentTableData, "useeffect")
        let sdata = []
        currentTableData.map((ele) => {
            if (ele.mother == 1) {
                let childs = []
                let maxam = 0
                childlegdata.map((e) => {
                    if (e.ledgerid == ele.txn_id) {
                        childs.push(e)
                        maxam = Number(maxam) + Number(e.amount)
                    }
                })

                sdata.push({
                    "mdata": ele,
                    "childs": childs,
                    "cdiff": Number(ele.amount) - Number(maxam)
                })
            }
        })

        sdata.sort((a, b) => Number(b.cdiff) - Number(a.cdiff))

        console.log(sdata, "sdata")
        setmodata(sdata)

    }, [currentTableData])


    return (<><Box w={"100%"} mb={"0.5rem"} display={"flex"} justifyContent={"space-between"} px={"0.5rem"} alignItems={"center"} borderRadius={"0.5rem"} boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}  >

        <Box>
            <Box color={"green"} > <strong>Total Income:</strong> {`₹${new Intl.NumberFormat("en-IN").format(
                parseInt(totalIncome)
            )}`}
            </Box>
            <Box color={"red"} > <strong>Total Expense</strong>:{`₹${new Intl.NumberFormat("en-IN").format(
                parseInt(totalExpense)
            )}`}</Box>
        </Box>
        <Box fontSize={"1.3rem"} fontWeight={"bold"} color={"blackAlpha.600"} >{showmo ? "MOTHER LEDGER" : "LEDGER"}</Box>
        <Box display={"flex"} alignItems={"center"}  >
            <ChakraAll.Button m={"0.5rem"} onClick={() => setshowmo(!showmo)} colorScheme={showmo ? "blue" : "red"} size={"sm"} variant={"outline"} >
                {
                    showmo ? "show Ledger" : "Show Mothers/Orphans"
                }
            </ChakraAll.Button>
            <Box>{binaryconverter(loginState.userDetails.access_control).charAt(
                4
            ) == 1 && (
                    <button
                        type="button"
                        color="primary"
                        className="clearFilter"
                        onClick={createLedgerEntry}
                    >
                        <AddCircleIcon size="2em" />
                    </button>
                )} </Box>
            <Box> {binaryconverter(loginState.userDetails.access_control).charAt(
                7
            ) == 1 && (
                    <button
                        type="button"
                        color="primary"
                        className="clearFilter"
                        onClick={download}
                    >
                        <DownloadBoxIcon size="2em" />
                    </button>
                )}</Box>


        </Box>

    </Box>
        <Box boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"} borderRadius={"0.5rem"} backgroundColor={"white"} h={"70vh"} overflow={"scroll"} >

            <TableContainer>
                <Table size="sm" variant='striped' colorScheme="linkedin" >
                    <Thead>
                        <Tr>
                            <Th>
                                <Input
                                    placeholder='Txnid'
                                    type="number"
                                    name="txn_id"
                                    onChange={onChangeTnx_id}
                                />
                            </Th>
                            <Th> <FormField
                                name="project_title"
                                control={control}
                                component="creatableSelect"
                                errors={errors}
                                selectRender={({ field }) => (
                                    <Select
                                        // value={value}
                                        {...field}
                                        onChange={OnChangeProject}
                                        options={projectsList}
                                        menuPortalTarget={document.body}
                                        clearable={false}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder={"Select Project"}
                                    />
                                )}
                            /></Th>
                            <Th>
                                <Input
                                    placeholder='Category'
                                    type="text"
                                    value={filterData.category}
                                    name="category"
                                    onChange={onChangeFilters}
                                /></Th>
                            <Th>
                                <Input
                                    placeholder='Person'
                                    type="text"
                                    value={filterData.person}
                                    name="person"
                                    onChange={onChangeFilters}
                                />
                            </Th>
                            <Th>
                                <Input

                                    type={"date"}
                                    name="date"
                                    value={filterData.date}
                                    onChange={onChangeFilters}
                                />
                            </Th>
                            <Th><Input
                                placeholder='Amount'
                                type="text"
                                name="amount"
                                value={filterData.amount}
                                onChange={onChangeFilters}
                            /></Th>
                            <Th>
                                <ChakraAll.Select
                                    style={{ width: "125px" }}
                                    name="paymentType"
                                    onChange={onChangeFilters}
                                    value={filterData.paymentType}
                                >
                                    {PaymentType.map((type, idx) => {
                                        return (
                                            <option
                                                style={{ color: "#000" }}
                                                key={idx}
                                                value={type.value}
                                            >
                                                {type.label}
                                            </option>
                                        );
                                    })}
                                </ChakraAll.Select>
                            </Th>
                            <Th>
                                <ChakraAll.Select
                                    style={{ width: "125px" }}
                                    name="paymentMode"
                                    onChange={onChangeFilters}
                                    value={filterData.paymentMode}
                                >
                                    {PaymentMode.map((type, idx) => {
                                        return (
                                            <option
                                                key={idx}
                                                value={type.value}
                                                style={{ color: "#000" }}
                                            >
                                                {type.label}
                                            </option>
                                        );
                                    })}
                                </ChakraAll.Select>

                            </Th>

                            <Th>
                                <ChakraAll.Select
                                    style={{ width: "125px" }}
                                    name="isGstBills"
                                    onChange={onChangeFilters}
                                    value={filterData.isGstBills}
                                >
                                    <option style={{ color: "#000" }} value={""}>
                                        Please Select
                                    </option>
                                    <option style={{ color: "#000" }} value={"true"}>
                                        Yes
                                    </option>
                                    <option style={{ color: "#000" }} value={"false"}>
                                        No
                                    </option>
                                </ChakraAll.Select>
                            </Th>

                            <Th>
                                <Input
                                    placeholder='TxnReference'
                                    type="text"
                                    name="txnReference"
                                    onChange={onChangeFilters}
                                    value={filterData.txnReference}
                                />
                            </Th>
                            <Th>
                                <Input
                                    placeholder='Comments'
                                    type="text"
                                    name="comments"
                                    onChange={onChangeFilters}
                                    value={filterData.isGscomments}
                                />
                            </Th>
                            <Th>
                                Attached Bills
                            </Th>
                            <Th>
                                Delete
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody >


                        {/* ////////////////////////////TBody/////////////////////////////// */}


                        {
                            showmo ? <>



                                {
                                    modata && modata.length && modata.map((data, idx) => {

                                        return <>
                                            <TableBody cdiff={data.cdiff} data={data.mdata} binaryconverter={binaryconverter} viewOrUpdate={viewOrUpdate} PaymentType={PaymentType} loginState={loginState} idx={idx} PaymentMode={PaymentMode} childlegdata={data.childs} md={true} confirmDeleteEntry={confirmDeleteEntry} />
                                        </>
                                    })
                                }


                            </> : <>

                                {
                                    currentTableData && currentTableData.length && currentTableData.map((data, idx) => {
                                        //  console.log(data, "253253253")
                                        return <>{
                                            data.mother == 0 &&

                                            <TableBody data={data} binaryconverter={binaryconverter} viewOrUpdate={viewOrUpdate} PaymentType={PaymentType} loginState={loginState} idx={idx} PaymentMode={PaymentMode} childlegdata={childlegdata} md={false} confirmDeleteEntry={confirmDeleteEntry} />
                                        }
                                        </>
                                    })
                                }
                            </>
                        }



                    </Tbody>

                </Table>
            </TableContainer>











        </Box >
    </>)
}

export default TableLedger




// {
//     currentTableData.length ? (
//         <>
//             {currentTableData.map((data, idx) => {
//                 //  console.log(data)
//                 return (<>
//                     <tr key={idx}>
//                         <td>
//                             {binaryconverter(
//                                 loginState.userDetails.access_control
//                             ).charAt(5) == 1 && (
//                                     <span
//                                         onClick={() => viewOrUpdate(data)}
//                                         style={{
//                                             color: "blue",
//                                             cursor: "pointer",
//                                             textDecoration: "underline",
//                                         }}
//                                     >
//                                         {data.txn_id}
//                                     </span>
//                                 )}
//                         </td>
//                         <td>{data.project_title}</td>

//                         <td>{data.category_name}</td>
//                         <td>{`${data.person_first_name} ${data.person_last_name}`}</td>
//                         <td>{data.date}</td>
//                         <td>{data.amount}</td>
//                         <td>
//                             {
//                                 PaymentType.filter(
//                                     (o) => o.value === data.payment_type_id
//                                 )[0]?.label
//                             }
//                         </td>
//                         <td>
//                             {
//                                 PaymentMode.filter(
//                                     (o) => o.value === data.payment_mode_id
//                                 )[0]?.label
//                             }
//                         </td>
//                         <td>{data.is_gst_bill ? "Yes" : "No"}</td>
//                         <td>{data.txn_reference}</td>
//                         <td>{data.comments}</td>
//                         <td>
//                             {data.attachments.length ? (
//                                 data.attachments.map((attach, xxidx) => {
//                                     return (
//                                         <div
//                                             style={{
//                                                 cursor: "pointer",
//                                                 color: "blue",
//                                                 textDecoration: "underline",
//                                             }}
//                                             onClick={() =>
//                                                 onClickAttachments(attach)
//                                             }
//                                         >
//                                             <a>Attachment-{`${xxidx + 1}`}</a>
//                                         </div>
//                                     );
//                                 })
//                             ) : (
//                                 <div>NA</div>
//                             )}
//                         </td>
//                         {
//                             <td>
//                                 {binaryconverter(
//                                     loginState.userDetails.access_control
//                                 ).charAt(6) == 1 && (
//                                         <span
//                                             onClick={() => confirmDeleteEntry(idx)}
//                                         >
//                                             <a href="#">Delete</a>
//                                         </span>
//                                     )}
//                             </td>
//                         }
//                     </tr>
//                     <tr>
//                         <td colSpan={"8"} >
//                             <ChakraProvider>
//                                 <LedgerEntries ldata={data} cldata={childlegdata} />
//                             </ChakraProvider>
//                         </td>
//                     </tr>
//                 </>);
//             })}
//         </>
//     ) : (
//     <tr>
//         {" "}
//         <td colSpan={11}> No entries found</td>
//     </tr>
// )
// }