import React from 'react'
import GeoLocationTracker from './GeoLocationTracker'
import Map from './Map'
import { useSelector } from 'react-redux';

const LocationTracker = () => {
    const loginState = useSelector((state) => state.login);
    const userId = loginState.userDetails.email;
    return (
        <div>
            <GeoLocationTracker />
            <Map emailid={userId} />
        </div>
    )
}

export default LocationTracker