import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import GoogleLogin from "react-google-login";
import { fetchLogin, loginReq } from "../../../redux/actions/loginActions";
const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const loginState = useSelector((state) => state.login);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const responseGoogle = (resp) => {
    // console.log(resp.profileObj);
    let obj = {
      googleId: resp.profileObj.googleId,
      lastName: resp.profileObj.familyName,
      firstName: resp.profileObj.givenName,
      email: resp.profileObj.email,
      name: resp.profileObj.name,
    };

    if (Object.prototype.hasOwnProperty.call(resp, "profileObj")) {
      loginReq(JSON.stringify(obj)).then((res) => {
        if (res.status) {
          dispatch(fetchLogin(res.data));
          localStorage.setItem("isLoggedin", true);
          localStorage.setItem("role", res.data.role_id);

          if (res.data.role_id === 1 || res.data.role_id === 8) {
            navigate("/user/Hello1");
          } else if (res.data.role_id === 2) {
            navigate("/app/pm/dashboard");
          } else if (res.data.role_id === 3) {
            navigate("/app/se/dashboard");
          } else if (res.data.role_id === 9) {
            navigate("/app/sales/leads/my");
          } else {
            navigate("/app/customer/dashboard");
          }
        } else {
          setErrorMessage(res.msg);
        }
      });
      // console.log(resp);
    } else console.log("false");
  };
  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            onClickMobile={changeMobileSidebarVisibility}
            onClickDesktop={changeSidebarVisibility}
          />
          <div className="topbar__logo"></div>
        </div>
        <div className="topbar__right">
          {!localStorage.getItem("isLoggedin") ? (
            <Button>
              {/* <div className="account__social"> */}
              <GoogleLogin
                clientId="25027215378-sg7g6jtgu3u5csocu1ibq1u8q323ovpc.apps.googleusercontent.com"
                // buttonText="LOGIN"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
              {/* </div> */}
            </Button>
          ) : (
            <TopbarProfile />
          )}{" "}
        </div>
      </div>
    </div>
  );
};
Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
