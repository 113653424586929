import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import Html from "react-pdf-html";
import { saveAs } from "file-saver";
import moment from "moment";

// import { useLocation } from "react-router-dom";
import logo from "./PdfLogo.png";
const Pdftemplate = ({ data, project_cx, p_id, email, name }) => {
  const styles = StyleSheet.create({
    page: {
      padding: "20px",
      // fontFamily: "josefin",
    },
    view: {
      padding: "20px",
      // fontFamily: "josefin",
    },
    logo12312: {
      display: "block",
      width: "200px",
      minHeight: "80px",
      textAlign: "center",
      border: "2px solid red",
      margin: "auto",
      marginTop: "20px",
      marginBottom: "0px",
    },
  });

  function abc() {
    let str = ``;
    if (data.length == 1) {
      //single
      for (let i = 0; i < data.length; i++) {
        str += `<br/>
          <p>
            It is to bring to your notice that the payment for<span
              style="
                font-weight: 600;
                font-style: italic;
                text-decoration: underline;
              "
            >
              the Stage ${data[i].id}</span
            >
            : ${data[i].stage} is ${
          data[i].work_status == 1 ? "due" : "completed"
        }.
          </p>
          <p>
            Task : <span
              style="
                font-weight: 600;
                font-style: italic;
                text-decoration: underline;
              "
            >
            ${data[i].task}</span>
          </p>
          <p>
            Due Date <span
              style="
                font-weight: 600;
                font-style: italic;
                text-decoration: underline;
              "
            >
              :${moment(data[i].payment_date).format("YYYY-MM-DD ")}</span>
          </p>
          <p>
            Amount payable to escrow with respect to payment
            <span
              style="
                font-weight: 600;
                font-style: italic;
                text-decoration: underline;
              "
            >
              stage ${data[i].id} : Rs ${
          data[i].work_status === 1
            ? data[i].to_pay
            : data[i].to_pay - data[i].paid
        }</span
            >
        </p>`;
      }
    } else {
      //multiple
      str = ` <table style="padding: 3px;">
        <thead>
          <tr>
            <td>Stage</td>
            <td>Status</td>
            <td>Task</td>
            <td>Due amount</td>
            <td>Due Date</td>
          </tr>
        </thead>
        <tbody> `;
      for (let i = 0; i < data.length; i++) {
        if (data[i].work_status === 1 && data[i].to_pay - data[i].paid == 0) {
          continue;
        }
        str += `
            <tr>
            <td>${data[i].id}</td>
            <td style="width: 20%; overflow: auto">${data[i].stage} is ${
          data[i].work_status == 1
            ? "due"
            : data[i].work_status == 3
            ? "completed"
            : "Yet to start"
        }</td>
            <td style="width: 20%; overflow: auto">${data[i].task}</td>
            <td>${
              data[i].work_status === 1
                ? data[i].to_pay
                : data[i].to_pay - data[i].paid
            }</td>
            <td>${moment(data[i].payment_date).format("YYYY-MM-DD ")}</td>
            </tr>
            `;
      }
      str += `</tbody>
      </table>`;
    }
    const template = `
    <html lang="en">
      <head>
        <style>
        *{
          font-size:12px;
          padding:0px;
          margin:0px;
        }
        p{
          margin:4px;
        }
          .container {
              color: #2d2d30;
              background: #f2f2f2;
              margin: 10px;
              padding: 10px;
            }
            td,
            th {
              border: solid rgb(159, 159, 200) 1px;
              padding: 3px;
            }

          table {
            border-collapse: collapse;
            border: 1px solid black;
            width: 100%;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <p>Dear Mr ${name}</p>
          <p>Greetings from Housing Factory!</p>
          <br />
          <p>
            This Message is regarding your project bearing :-
            <span
              style="
                font-weight: 600;
                font-style: italic;
                text-decoration: underline;
              "
              >${project_cx}</span
            >
            </p>
          ${str}
          <br />
          <p>
            Sharing you our current bank account details below when you proceed with
            NEFT/RTGS/IMPS in future for your reference :
          </p>
          <p>Epic Housing Factory</p>
          <p>Account Number: 39589727072</p>
          <p>IFSC Code - SBIN0040552</p>
          <p>Bank:State Bank Of India</p>
          <br/>
          <p>Best Regards</p>
          <p>Puneeth G (Housing Factory)</p>
        </div>
      </body>
    </html>
          `;
    return template;
  }

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.view}>
          <Image src={logo} style={styles.logo12312}></Image>
          {/* <Text style={styles.title12312}>
            Receipt Voucher No.3284CX -582 Sujith mahaveer palatium
          </Text> */}
          <Html>{abc()}</Html>
        </View>
      </Page>
    </Document>
  );
};
export default Pdftemplate;
