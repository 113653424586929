import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ic_content_copy_twotone } from "react-icons-kit/md/ic_content_copy_twotone";
import Icon from "react-icons-kit";
import { copy } from "react-icons-kit/icomoon/copy";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import { ic_note_add } from "react-icons-kit/md/ic_note_add";
import { ic_work } from "react-icons-kit/md/ic_work";
import { ic_bar_chart } from "react-icons-kit/md/ic_bar_chart";
import { ic_hourglass_empty_twotone } from "react-icons-kit/md/ic_hourglass_empty_twotone";
import { Card, CardBody, Col, Progress, Row, Badge, Alert } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "reactstrap";
import { AppContext } from "../../../Context/appContext";
import Collapse from "@mui/material/Collapse";
import Tooltips from "@mui/material/Tooltip";
import { workStatusList } from "../../../shared/helpers/constants";
import { Button, ChakraProvider, useDisclosure } from "@chakra-ui/react";
import WorkOrderPopUp from "./WorkOrderPopUp";

const Bookings = ({ projectInfo, index, onChangeWoPopup }) => {
  const appState = useSelector((state) => state.login);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [msg, setMsg] = useState({ txt: "", clr: "success" });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const AppProvider = useContext(AppContext);
  const loginState = useSelector((state) => state.login);

  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  // console.log(role, "buhjbehijve")

  const viewProject = (evt) => {
    navigate(`/app/${role}/view/${projectInfo.project_id}`);
  };

  const viewGraph = (evt) => {
    window.open(`/app/${role}/project/ledger/${projectInfo.project_id}`);
  };

  const materialTracking = (evt) => {
    window.open(`/app/${role}/project/material/${projectInfo.project_id}`);
  };

  const editProject = (evt) => {
    evt.stopPropagation();
    navigate(`/app/${role}/create-project/${projectInfo.project_id}/1`);
  };
  const editProjectWPS = (evt) => {
    evt.stopPropagation();
    AppProvider.setWpsAddAdditionalWork(true);
    navigate(`/app/${role}/create-project/${projectInfo.project_id}/1`);
  };

  const onDismissAlert = () => {
    setMsg(Object.assign({}, { txt: "" }, { clr: "danger" }));
  };

  const onCopy = () => {
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };

  const getWorkStatus = (val) => {
    let text = workStatusList.filter((o) => o.value === val);
    if (text.length) {
      return text[0].label;
    }
  };

  const getBalance = (projectInfo) => {
    let bal = 0;
    bal = projectInfo.received - projectInfo.to_pay;

    if (bal > 0) {
      return <span className="colorGreen">B: {Math.trunc(bal)}</span>;
    } else if (bal == 0) {
      return <span>B: {Math.trunc(bal)}</span>;
    } else {
      return <span className="colorRed">B: {Math.trunc(bal)}</span>;
    }
  };

  const getRBalance = (custPaid, totalExp) => {
    if (Number(custPaid) && Number(totalExp)) {
      if (!custPaid) {
        custPaid = !custPaid;
      }
      if (!totalExp) {
        totalExp = !totalExp;
      }
      return Math.trunc(Number(custPaid) - Number(totalExp).toFixed(2));
    } else {
      return 0;
    }
  };

  const getWIP = (stages) => {
    if (stages) {
      let wip = stages.filter((o) => o.work_status == 1);
      let yetToStart = stages.filter((o) => o.work_status == 2);
      let completed = stages.filter((o) => o.work_status == 3);

      if (completed.length === stages.length) {
        return (
          <tr>
            <td colSpan="2">completed</td>
          </tr>
        );
      }

      if (yetToStart.length === stages.length) {
        return (
          <tr>
            <td colSpan="2">Yet to Start</td>
          </tr>
        );
      }

      // let currentRole = "pm";

      // if (
      //   appState?.userDetails?.role_id === 1 ||
      //   appState?.userDetails?.role_id === 8
      // ) {
      //   currentRole = "admin";
      // } else if (appState?.userDetails?.role_id === 2) {
      //   currentRole = "pm";
      // }

      if (wip.length) {
        return wip.map((idx, val) => {
          return (
            <tr key={val}>
              <td>
                <a
                  href={`/app/${role}/create-project/${projectInfo.project_id}/3`}
                >
                  {idx.task}
                </a>
              </td>
              <td>{Moment(idx.end_date, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
            </tr>
          );
        });
      }
    }
  };

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  return (
    <>
      <Alert color={msg.clr} isOpen={!!msg.txt} toggle={onDismissAlert}>
        {msg.txt}
      </Alert>

      <Col md={12} xl={4} lg={6} xs={12}>
        <Card>
          <CardBody
            className="dashboard__booking-card"
            style={{
              boxShadow: "grey 2px 3px 10px, inset #dcd4c7a3 1px 1px 3px 1px",
              marginTop: "20px",
            }}
          >
            <div onClick={(evt) => viewProject(evt, projectInfo.project_id)}>
              <div className="justify-content-end d-flex">
                {binaryconverter(loginState.userDetails.access_control).charAt(
                  26
                ) == 1 && (
                  <a href="#" className="px-1" onClick={(e) => editProject(e)}>
                    <Tooltips title="Edit Project">
                      <span>
                        <Icon icon={ic_mode_edit} size={20} />
                      </span>
                    </Tooltips>
                  </a>
                )}
                {binaryconverter(loginState.userDetails.access_control).charAt(
                  27
                ) == 1 && (
                  <a
                    href="#"
                    className="px-1"
                    onClick={(e) => editProjectWPS(e)}
                  >
                    <Tooltips title="Edit Project WPS">
                      <span>
                        <Icon icon={ic_note_add} size={20} />
                      </span>
                    </Tooltips>
                  </a>
                )}
                {binaryconverter(loginState.userDetails.access_control).charAt(
                  28
                ) == 1 && (
                  <a href="#" className="px-1" onClick={(e) => viewGraph(e)}>
                    <Tooltips title="view Graph">
                      <span>
                        <Icon icon={ic_bar_chart} size={20} />
                      </span>
                    </Tooltips>
                  </a>
                )}
                {binaryconverter(loginState.userDetails.access_control).charAt(
                  29
                ) == 1 && (
                  <a
                    href="#"
                    className="px-1"
                    onClick={(e) => materialTracking(e)}
                  >
                    <Tooltips title="Material Tracking">
                      <span>
                        <Icon icon={ic_hourglass_empty_twotone} size={20} />
                      </span>
                    </Tooltips>
                  </a>
                )}
              </div>
              <div className="dashboard__booking-total-container">
                <Row>
                  <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
                    <strong>{projectInfo.project_title}</strong>
                  </h5>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    {/* {projectInfo.start_date} */}
                    Start:{" "}
                    {projectInfo.start_date
                      ? Moment(projectInfo.start_date, "YYYY-MM-DD").format(
                          "DD-MM-YYYY"
                        )
                      : ""}
                  </Col>
                  <Col>
                    End:
                    {projectInfo.to_be_complete
                      ? Moment(projectInfo.to_be_complete, "YYYY-MM-DD").format(
                          "DD-MM-YYYY"
                        )
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {projectInfo?.stage?.length && (
                      <table
                        className="table-bordered"
                        style={{ width: "100%", fontSize: "14px" }}
                      >
                        <thead>
                          <tr>
                            <th>Stage</th>
                            <th>TBC</th>
                          </tr>
                        </thead>
                        <tbody>{getWIP(projectInfo.stage)}</tbody>
                      </table>
                    )}
                  </Col>
                </Row>
                <Row>
                  <div className="tppb">
                    <span>
                      TP:{" "}
                      {projectInfo.to_pay ? Math.trunc(projectInfo.to_pay) : 0}
                    </span>
                    <span>
                      P:{" "}
                      {projectInfo.received
                        ? Math.trunc(projectInfo.received)
                        : 0}
                    </span>
                    {getBalance(projectInfo)}
                  </div>
                </Row>

                {binaryconverter(loginState.userDetails.access_control).charAt(
                  27
                ) == 1 && (
                  <>
                    <hr />
                    {/* <Row>
                      <div>
                        <span>
                          <strong>TI: {projectInfo.ttotal_income}</strong>
                        </span>
                      </div>
                    </Row> */}
                    <Row className="text-right">
                      <Col>
                        <span>
                          <strong>TE: {projectInfo.ttotal_expense}</strong>
                        </span>

                        <span>
                          {" "}
                          <strong>
                            R:{" "}
                            {getRBalance(
                              projectInfo.totalcustomerpaid,
                              projectInfo.ttotal_expense
                            )}
                          </strong>
                        </span>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>

            <div
              className="text-right"
              style={{ textAlign: "right", display: "flex" }}
            >
              <ChakraProvider>
                <Button
                  onClick={() => {
                    onOpen();
                  }}
                  w={"fit-content"}
                  height={"fit-content"}
                  p={"0"}
                  mx={"0.5rem"}
                >
                  <Icon icon={ic_work} size={20} />
                </Button>
                <WorkOrderPopUp
                  role={role}
                  onClose={onClose}
                  isOpen={isOpen}
                  data={projectInfo}
                />
              </ChakraProvider>

              <CopyToClipboard
                id={`copy${index}`}
                onCopy={() => onCopy()}
                options={{ format: "text/plain" }}
                // text={
                //   "Customer Name: 	&amp;" +
                //   projectInfo.customer_firstname +
                //   " " +
                //   projectInfo.customer_lastname +
                //   "\
                //   Customer Name: " +
                //   projectInfo.customer_firstname +
                //   " " +
                //   projectInfo.customer_lastname
                // }
                text={`Customer Name: ${projectInfo.customer_firstname} ${projectInfo.customer_lastname}\nSite Engineer: ${projectInfo.site_engineer_firstname} ${projectInfo.site_engineer_firstname}\nSite Engineer Contact #: ${projectInfo.site_engineer_contact_num}\nExecution Lead: ${projectInfo.service_provider_firstname} ${projectInfo.service_provider_lastname}\nExecution Lead Contact #: ${projectInfo.service_provider_contact_num}\nLocation: ${projectInfo.location}\nGeoLocation: ${projectInfo.lat_lng}`}
              >
                <Icon icon={ic_content_copy_twotone} />
              </CopyToClipboard>
              <Tooltip
                className="px-1"
                placement="top"
                isOpen={tooltipOpen}
                delay={{ show: "0", hide: "450" }}
                autohide={false}
                target={`copy${index}`}
                // toggle={tooltipToggle}
              >
                Copied!!
              </Tooltip>
            </div>
            <Collapse in={projectInfo.showWo}>
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Assigned to</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {projectInfo?.work_orders &&
                    projectInfo?.work_orders.map((wo, woIdx) => {
                      return (
                        <tr key={woIdx}>
                          <td>
                            <a>
                              {binaryconverter(
                                loginState.userDetails.access_control
                              ).charAt(29) == 1 ? (
                                <NavLink
                                  to={`/app/${role}/wo-detailed-view/${JSON.stringify(
                                    {
                                      value: projectInfo.project_id,
                                      label: projectInfo.project_title,
                                    }
                                  )}/${wo.id}`}
                                >
                                  {" "}
                                  {wo.category_name}
                                </NavLink>
                              ) : (
                                wo.category_name
                              )}
                            </a>
                          </td>
                          <td>
                            {wo.assigned_to_firstname} {wo.assigned_to_lastname}
                          </td>
                          <td>
                            <div>
                              M- T:{wo.ms_total_amt} B:{" "}
                              {wo.ms_total_balance_amount} P:
                              {wo.ms_total_total_paid}
                            </div>
                            <hr />
                            <div>
                              AW- T:{wo.a_total_amt} B:
                              {wo.a_total_balance_amount} P:
                              {wo.a_total_total_paid}
                            </div>
                          </td>
                          <td>{getWorkStatus(wo.work_status)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Collapse>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Bookings;
