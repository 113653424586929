import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import RegisterForm from "../../shared/components/login_register/LoginRegisterForm";
import { signupReq } from "../../redux/actions/loginActions";

// const auth = getAuth();

const Register = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const registerAccount = (data) => {
    setError("");
    // createUserWithEmailAndPassword(auth, user.email, user.password)
    //   .then(() => {
    //     history.push('/log_in');
    //   })
    //   .catch((err) => {
    //     setError(err.message);
    //   });
    if (
      !data.email ||
      !data.firstName ||
      !data.lastName ||
      !data.password ||
      !data.confirmPassword
    ) {
      setError("Please fill all the fields");
      return;
    }

    if (data.password !== data.confirmPassword) {
      setError("Password and confirm password field should match");
      return;
    }

    signupReq(JSON.stringify(data)).then((data) => {
      console.log(data);
      setError(data.msg);

      if (!data.status) {
      }
    });

    console.log(data);
  };

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title" style={{ color: "#000" }}>
              Welcome to
              <span className="account__logo">
                {" "}
                Housing Factory
                {/* <span className="account__logo-accent">DEV</span> */}
              </span>
            </h3>
            <h4 className="account__subhead subhead" style={{ color: "#000" }}>
              Create an account
            </h4>
          </div>
          <div className="account__have-account">
            <p style={{ color: "#000" }}>
              Already have an account? <NavLink to="/app/login">Login</NavLink>
            </p>
          </div>
          <RegisterForm onSubmit={registerAccount} errorMessage={error} />
        </div>
      </div>
    </div>
  );
};

// Register.propTypes = {
//   history: PropTypes.shape({
//     push: PropTypes.func,
//   }).isRequired,
// };

export default Register;
