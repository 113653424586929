import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Alert } from "reactstrap";
import { Routes, Route, useParams } from "react-router-dom";
import Moment from "moment";
// import Form from './components/Form';
import CreateProjectForm from "./createProjectForm";

import { useSelector } from "react-redux";
import { AppContext } from "../../Context/appContext";

const CreateProject = () => {
  const [pleaseWait, setPleasewait] = useState(false);

  const onChangePleaseWait = (status) => {
    setPleasewait(status);
  };

  return (
    <Container className="dashboard create-project">
      <Row>
        <Col md={12}>
          <h3 style={{ color: "#000" }}>Create Project</h3>
        </Col>
      </Row>
      <Row>
        <CreateProjectForm onChangePleaseWait={onChangePleaseWait} />
      </Row>
      {pleaseWait && (
        <div class="pleasewait-load">
          <div class="load__icon-wrap">
            <svg class="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CreateProject;
