
import { Box, Tooltip } from '@chakra-ui/react'
import React from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import { fetchTasks } from '../../redux/actions/taskManager'
import { useEffect } from 'react'
import { useState } from 'react'

const TaskChart = () => {

    const [tasksData, settasksData] = useState([])
    function taskFetcher() {
        fetchTasks().then((res) => {
            console.log("task fetcher", res.res)
            settasksData(res.res.rows)
        })
    }

    useEffect(() => {
        taskFetcher()
    }, [])

    return (
        <Box m={"1rem"} boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px" borderRadius={"1rem"} >
            <TableContainer borderRadius={"1rem"}  >
                <Table size="sm" variant='striped' colorScheme='teal'>
                    <Thead  >
                        <Tr>
                            <Th>TITLE</Th>
                            <Th>Date

                                <span> </span>
                                <span style={{ color: "blue" }} >AD</span>/
                                <span style={{ color: "green" }} >SD</span>/
                                <span style={{ color: "red" }} >ED</span>
                            </Th>
                            <Th>ASSIGNED <span style={{ color: "green", fontWeight: "1000" }} >BY</span>/TO</Th>
                            <Th>STATUS</Th>
                            <Th>Description</Th>

                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            tasksData?.length > 0 && tasksData.map((ele) => {
                                console.log(ele)
                                return <Tr>
                                    <Td>{ele.tasktitle?.toUpperCase()}</Td>
                                    <Td>
                                        <Tooltip borderRadius={"0.2rem"} w={"12rem"} label={`Assigned Date: ${new Date(ele.assigndate)?.toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                          
                                        Start Date: ${new Date(ele.taskstartdate)?.toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                        
                                          End Date: ${new Date(ele.taskenddate)?.toLocaleDateString('en-GB', { timeZone: 'UTC' })}
                                        
                                        `} hasArrow arrowSize={15}><Box>
                                                <Box color={"blue"} fontWeight="1000" >{new Date(ele.assigndate)?.toLocaleDateString('en-GB', { timeZone: 'UTC' })}</Box>
                                                <Box color={"green"} fontWeight="700" >{
                                                    new Date(ele.taskstartdate)?.toLocaleDateString('en-GB', { timeZone: 'UTC' })
                                                }</Box>
                                                <Box color={"red"} fontWeight="600" >{
                                                    new Date(ele.taskenddate)?.toLocaleDateString('en-GB', { timeZone: 'UTC' })
                                                }</Box></Box>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Box color={"green"} fontWeight="bold" >{ele.assignedby}</Box>
                                        {ele.assignedto?.split("$")?.map((ele, index) =>
                                            <Box >{ele?.split(" ")[1]}</Box>
                                        )}</Td>
                                    <Td>
                                        <Box color={ele.status} >{ele.status}</Box>
                                    </Td>
                                    <Td>{ele.task}</Td>
                                </Tr>
                            })

                        }
                    </Tbody>

                </Table>
            </TableContainer>
        </Box >
    )
}

export default TaskChart