import React from "react";
import { Document, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import Pdftemplate from "./Pdftemplate";

const ComponentPdf = () => {
  const location1 = useLocation();
  const { data, projectTitle, name, additional, email, mobile_no, location } =
    location1.state;
  console.log(data, "dsfdsf from");
  return (
    <PDFViewer style={{ width: "100%", height: "800px" }}>
      <Pdftemplate
        data={data}
        projectTitle={projectTitle}
        name={name}
        // additional={additional}
        email={email}
        location={location}
        mobile_no={mobile_no}
      />
    </PDFViewer>
  );
};

export default ComponentPdf;
