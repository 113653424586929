import React, { useEffect, useState } from "react";
import { fetchUsersList } from "../../../redux/actions/usersAction";
import { set } from "react-hook-form";
import { addUser } from "../../../redux/actions/loginActions";
import { ROLES } from "../../../shared/helpers/constants";
import { display } from "@mui/system";
import { Link, NavLink } from "react-router-dom";
// import { ChakraProvider, Input, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  Create,
  Delete,
  Edit,
  EditAttributes,
  Filter,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import AddBuilding from "./AddBuilding";
import {
  deleteBuilding,
  fetchBuildings,
} from "../../../redux/actions/rentalsActions";
import UpdateBuilding from "./UpdateBuilding";

const RantelDashboard = () => {
  const [loading, setLoading] = useState(true);
  const loginState = useSelector((state) => state.login);
  const [enableInput, setEnableInput] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [initialValues, setInitialValues] = useState({
    Address: "",
    Building_name: "",
    location: "",
    No_of_floor: 0,
    Owner: "",
    Unit_no: 0,
    Care_taker: "",
    Owner_contact: "",
    CareTaker_contact: "",
  });
  const [UpdateValues, setUpdateValues] = useState({
    Address: "",
    Building_name: "",
    location: "",
    No_of_floor: 0,
    Owner: "",
    Unit_no: 0,
    Care_taker: "",
    Owner_contact: "",
    CareTaker_contact: "",
  });

  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState([]);
  const [deleteId, setdeleteId] = useState();

  //__________________ Model______________
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  //__________________ Model__________________

  function UpdatePropValue(data) {
    const newData = {};
    newData.building_id = data.building_id;
    newData.Building_name = data.bname;
    newData.Unit_no = data.units;
    newData.Owner = data.owner;
    newData.No_of_floor = data.floors;
    newData.location = data.location;
    newData.Address = data.address;
    newData.Care_taker = data.caretaker;
    newData.Owner_contact = data.owner_contact;
    newData.CareTaker_contact = data.caretaker_contact;
    setUpdateValues({ ...newData });
  }

  const createUser = () => {
    setInitialValues({
      building_id: 0,
      Address: "",
      Building_name: "",
      location: "",
      No_of_floor: 0,
      Owner: "",
      Unit_no: 0,
      Care_taker: "",
      Owner_contact: "",
      CareTaker_contact: "",
    });

    // setShowCreateUserForm(true);
    handleOpen();
  };
  function delBuilding() {
    console.log(deleteId);
    deleteBuilding(deleteId).then((res) => {
      console.log(res, "deleted");
      setAlertMsg("Building Data Deleted successfully");
    });
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  useEffect(() => {
    fetchBuildings().then((res) => {
      console.log(res, "res of rental");
      setData([...res?.res?.rows]);
      setLoading(false);
    });
  }, [alertMsg]);

  return (
    <Container className="dashboard">
      <h3
        className="page-title"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "8px",
          wordSpacing: "15px",
        }}
      >
        Rental Building
      </h3>
      <Card>
        <CardContent sx={{ overflow: "scroll", height: "83vh" }}>
          {binaryconverter(loginState.userDetails.access_control).charAt(2) ==
            1 && (
            <Button
              color="primary"
              type="button"
              className="previous"
              onClick={() => createUser()}
            >
              <Create />
              Create Rental Building
            </Button>
          )}
          {/* <EditableReactTable reactTableData={reactTableData} /> */}

          <Table>
            <TableHead>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    padding: "7px 2px",
                  },
                }}
              >
                <TableCell>Id</TableCell>
                <TableCell>Building Name</TableCell>
                <TableCell>No. Of Floors</TableCell>
                <TableCell>No. Of Units</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Care Taker</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log(data, "data")}
              {data.map((obj) => (
                <TableRow
                  sx={{
                    ".MuiTableCell-root": {
                      padding: "10px 2px",
                    },
                  }}
                >
                  <NavLink to={`/app/${role}/Building/unit/${obj.building_id}`}>
                    <TableCell> {obj.building_id}</TableCell>
                  </NavLink>
                  <TableCell>{obj.bname}</TableCell>
                  <TableCell>{obj.floors}</TableCell>
                  <TableCell>{obj.units}</TableCell>
                  <TableCell>{obj.address}</TableCell>
                  <TableCell>{obj.location}</TableCell>
                  <TableCell>{obj.owner}</TableCell>
                  <TableCell>{obj.caretaker}</TableCell>
                  <TableCell sx={{ minWidth: 40 }}>
                    <Button
                      sx={{ minWidth: 10, p: 0 }}
                      onClick={() => {
                        UpdatePropValue(obj);
                        handleOpenUpdate();
                      }}
                    >
                      <Edit />
                    </Button>
                    <Button
                      sx={{ minWidth: 10, p: 0 }}
                      onClick={() => {
                        setdeleteId(obj.building_id);

                        handleOpenDialog();
                      }}
                    >
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box sx={{ height: 40, textAlign: "center " }}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </CardContent>
      </Card>
      {/* //_____________________________ADD Building_________________________ */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <AddBuilding
            closePopup={handleClose}
            initialValues={initialValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      {/* //_____________________________Delete Building_________________________ */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are U Sure You want to delete The building"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            if you click on Agree the building will be permanently delete from
            DataBase
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button
            onClick={() => {
              delBuilding();
              handleCloseDialog();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* //_____________________________Update Building_________________________ */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <UpdateBuilding
            closePopup={handleCloseUpdate}
            initialValues={UpdateValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      <Snackbar
        open={!!alertMsg}
        autoHideDuration={3000}
        onClose={() => setAlertMsg("")}
        sx={{
          "&.MuiSnackbar-root": { left: "50%", transform: "translateX(-50%)" },
        }}
      >
        <Alert
          onClose={() => setAlertMsg("")}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RantelDashboard;
