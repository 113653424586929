import React, { useEffect, useState } from 'react'
import { getprojectdisplaydata } from '../../../redux/actions/projectsData'
import { Box, CircularProgress, Grid, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import styles from "./procss.module.css"

const ProjectDisplayEdit = () => {
    const [Data, setData] = useState([])
    console.log(Data, "fhygcvgh")

    const [load, setload] = useState(true)

    function getProjectDisplayData() {
        setload(true)
        getprojectdisplaydata().then((res) => {
            setData(res.res.rows)
            setload(false)
            //console.log(res.res.rows, "16")
        })
    }
    let navigate = useNavigate()


    console.log(Data)
    useEffect(() => {
        getProjectDisplayData()
    }, [])

    return (<>
        <Box >

            {
                load ?
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"100vh"}
                    >
                        <CircularProgress size={"6rem"} isIndeterminate color="green.300" />
                    </Box>
                    : <>
                        <Box p={"1rem"} display={"flex"} flexWrap={"wrap"} >
                            {
                                Data && Data.length != 0 && Data.map((ele, ind) => {
                                    return <Box overflow={"hidden"} onClick={() => navigate(`${ele.projectdisplay_id}`)} w={"48%"} m={"auto"} boxShadow={"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"} my={"1.5rem"} borderRadius={"lg"} textAlign={"center"} key={ind}>
                                        <Box m={"auto"} >
                                            <Image borderRadius='lg' src={ele.cover_image[0]} />
                                        </Box>
                                        <Box textAlign={"center"} display={"flex"} fontSize={"1.5rem"} >
                                            <Box borderRadius={"0.5rem"} px={"0.5rem"} color={"blue.300"} >{ele.project_no}</Box>
                                            {
                                                ele.cover_title
                                            }

                                        </Box>

                                    </Box>
                                })
                            }
                        </Box>
                    </>
            }

        </Box>
    </>)
}

export default ProjectDisplayEdit