import { Box, Button, ChakraProvider, Image, Input } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import GeoLocationTracker from '../GeoLocation/GeoLocationTracker'
import Map from '../GeoLocation/Map'
import { addBooking, addBuilding, addInvoice, addUnit, deleteBooking, deleteBuilding, deleteInvoice, deleteTenentId, deleteUnit, fetchBookings, fetchBuildings, fetchInvoices, fetchUnits, updateBooking, updateBuilding, updateInvoice, updateUnit } from '../../redux/actions/rentalsActions'
import Droper from '../TaskManager/Droper'



const Expenditure = () => {

const [photos, setphotos] = useState([])
const [data, setdata] = useState()


function fetcher(){
    fetchBookings().then((res) => {
        console.log(res, "thgd")
         setdata(res.res.rows[0])
    })
    }
    
    function update() {
        updateBooking({...data,utenent_id:photos}).then((res) => {
             console.log(res)
         })
    }
    
    function adder() {
     console.log(photos)
     addBooking({
        building_id:"2742" ,
        unit_id:"2742" ,
        move_in:new Date(Date.now()) ,
        move_out:new Date(Date.now()) ,
        booking_date:new Date(Date.now()) ,
            rent:"fjhfgngn" ,
            deposit:"122" ,
            tenent_name : "rrrrrttttt",
         tenent_contact: "rrrrrttttt",
         tenent_id :photos,
            notes  : "rrrrrttttt"
     }).then((res) => {
        console.log(res)
    })
 }
 function deleter() {
    deleteBooking(data).then((res) => {
        console.log(res)
    })
    }

    return (
        <Box p={"1rem"} >
             
             <Droper value={photos} onChange={(e) => setphotos([...e])} uploadLabel={"Task Attachments"} />

            {
                data?.tenent_id?.map((ele) => 
                    <Image src={ele} />
                )
            }

             <Button onClick={adder}>adder</Button>
            <Button onClick={fetcher}>fetcher</Button>
            <Button onClick={update}>update</Button>
            <Button onClick={deleter}>delete</Button>  
            <ChakraProvider>
                <Map liveshow={true} />
            </ChakraProvider>
        </Box>
    )
}

export default Expenditure