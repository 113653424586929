import React from "react";
import { Route, Routes } from "react-router-dom";
import HFLProjects from "./ProjectsPage/HFLProjects";
import { ChakraProvider } from "@chakra-ui/react";
import Detailspage from "./ProjectsPage/Detailspage";

const HFL_Router = () => {
  return (
    <>
      <Routes>
        <Route
          path="/projects"
          element={
            <ChakraProvider>
              <HFLProjects />
            </ChakraProvider>
          }
        />

        <Route
          path="/projects/:id"
          element={
            <ChakraProvider>
              <Detailspage />
            </ChakraProvider>
          }
        />
      </Routes>
    </>
  );
};

export default HFL_Router;
