import { createAction } from "redux-actions";
import myPostReq from "../../utils/post";
import myGetReq, { downloadReq } from "../../utils/get";

export const postAttData = async (date, name, project_id, object) => {
  const file = object.File;
  let dat = new FormData();
  dat.append("data", JSON.stringify({ date, name, project_id, ...object }));
  dat.append("file", object.File);
  try {
    return await myPostReq(`/app/usr/labour-attendance`, dat, {});
  } catch (err) {
    return err;
  }
};
export const getAttData = async (project_id,labour) => {
  try {
    return await myGetReq(`/app/usr/labour-attendance/?project_id=${project_id}&labour=${labour}`);
  } catch (err) {
    return err;
  }
};
export const getAttDashboard = async (month,year,labour) => {
  try {
    return await myGetReq(`/app/usr/labour-attendance/dashboard/?month=${month}&year=${year}&labour=${labour}`);
  } catch (err) {
    return err;
  }
};
export const getWeekAttDashboard = async (month,year,labour) => {
  try {
    return await myGetReq(`/app/usr/labour-attendance/dashboard/?week=${month}&year=${year}&labour=${labour}`);
  } catch (err) {
    return err;
  }
};
