import { createAction } from "redux-actions";
import myPostReq from "../../utils/post";
import myGetReq, { downloadReq } from "../../utils/get";

export const basicInfoFieldsState = createAction("BasicInfoFieldState");

export const saveProject = async (obj) => {
  const fileUploadHeaders = {
    accept: "application/json",
    "Content-Type": `application/json`,
  };

  try {
    return await myPostReq("/app/usr/create-project", obj, {});
  } catch (err) {
    return err;
  }
};

export const saveConstrReq = async (data) => {
  try {
    return await myPostReq("/app/usr/save-const-req", data);
  } catch (err) {
    return err;
  }
};
export const saveWorkPaymentSchedule = async (data) => {
  try {
    return await myPostReq("/app/usr/save-workpayment", data);
  } catch (err) {
    return err;
  }
};

export const getAdminProjects = async (data) => {
  try {
    return await myPostReq("/app/usr/get-projects", data);
  } catch (err) {
    return err;
  }
};
export const getAdminProjectsAtt = async (data) => {
  try {
    return await myPostReq("/app/usr/get-projects-att", data);
  } catch (err) {
    return err;
  }
};

export const getUserProjects = async (data) => {
  try {
    return await myGetReq("/app/usr/get-customer-projects");
  } catch (err) {
    return err;
  }
};

export const getSEProjects = async (data) => {
  try {
    return await myGetReq("/app/usr/get-se-projects");
  } catch (err) {
    return err;
  }
};

export const getAllSEProjects = async (data) => {
  try {
    return await myGetReq("/app/usr/get-all-se-projects");
  } catch (err) {
    return err;
  }
};

export const getProjectInfoDetails = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-project-info-by-id?pid=${data}`);
  } catch (err) {
    return err;
  }
};

export const getDetailedInfo = async (data) => {
  // console.log(data, "7575755")
  try {
    return await myGetReq(`/app/usr/get-detailed-info?pid=${data}`);
  } catch (err) {
    return err;
  }
};

export const getCustomerDetailedInfo = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-customer-project-info?pid=${data}`);
  } catch (err) {
    return err;
  }
};

export const getSeDetailedInfo = async (data) => {
  //console.log("data", data)
  try {
    return await myGetReq(`/app/usr/get-se-project-info?pid=${data}`);
  } catch (err) {
    return err;
  }
};

export const getProjectFile = async (link, isPreview) => {
  try {
    return await downloadReq(
      `/app/usr/get-project-files?link=${link}`,
      isPreview
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getProjectFileImage = async (link, isPreview) => {
  try {
    return await downloadReq(
      `/app/usr/get-project-files-image?link=${link}`,
      isPreview
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getProjectFileImage2 = async (link, isPreview) => {
  try {
    return await downloadReq(
      `/app/usr/get-project-files-images?link=${link}`,
      isPreview
    );
  } catch (err) {
    console.log(err + "hello");
    return err;
  }
};

export const getBasicProjectInfo = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-basic-project-info?pid=${data.value}`);
  } catch (err) {
    return err;
  }
};

export const sendMailProjectMilstone = async (data) => {
  try {
    return await myPostReq(`/app/usr/send-mail-to-users`, JSON.stringify(data));
  } catch (err) {
    return err;
  }
};
export const getMailMilestoneData = async (id) => {
  try {
    return await myGetReq(`/app/usr/get-milestone-mail?id=${id}`);
  } catch (err) {
    return err;
  }
};
export const sendMailPdfDocs = async (formData) => {
  try {
    return await myPostReq("/app/usr/send-mail-pdf", formData, {});
  } catch (err) {
    return err;
  }
};
