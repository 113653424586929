import React, { useState, useEffect, useContext } from "react";
import Moment from "moment";

import DatepickerField from "../Datepicker/DatepickerField";
import {
  ProjectScope,
  paymentStatusList,
} from "../../shared/helpers/constants";
import AddCircleIcon from "mdi-react/AddCircleIcon";
import classNames from "classnames";
import { AppContext } from "../../Context/appContext";
import { Delete } from "@mui/icons-material";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const WorkAndPaymentSchedule = ({
  rows,
  setData,
  setTableDirty,
  setSelectedRow,
  toggleModal,
  setDisabledPay,
}) => {
  const [isSortable, setIsSortable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(false);
  const [withSearchEngine, setWithSearchEngine] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const AppProvider = useContext(AppContext);

  const reactTableData = {
    tableHeaderData: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 20,
        editFieldType: "input",
      },
      {
        Header: "Stage",
        accessor: "stage",
      },
      {
        Header: "Task",
        accessor: "task",
        disableGlobalFilter: true,
        editFieldType: "input",
      },
      {
        Header: "Date (dd-mm-yyyy)",
        accessor: "startDate",
        disableGlobalFilter: true,
        editFieldType: "dateType",
        width: 200,
      },
      {
        Header: "Pay Date",
        accessor: "payDate",
        disableGlobalFilter: true,
        editFieldType: "dateType",
        width: 150,
      },
      {
        Header: "% of Final Quote",
        accessor: "finalQuote",
        disableGlobalFilter: true,
        editFieldType: "input",
        width: 90,
      },
      {
        Header: "To Pay(Rs)",
        accessor: "toPay",
        disableGlobalFilter: true,
        editFieldType: "input",
        width: 90,
      },
      {
        Header: "Paid(Rs)",
        accessor: "paid",
        disableGlobalFilter: true,
        editFieldType: "input",
        width: 90,
      },
      {
        Header: "Payment status",
        accessor: "paymentStatus",
        disableGlobalFilter: true,
        editFieldType: "select",
        selectOptions: paymentStatusList,
        width: 50,
      },
      {
        Header: "Project Scope",
        accessor: "projectScope",
        disableGlobalFilter: true,
        editFieldType: "select",
        selectOptions: ProjectScope,
        width: 50,
      },
      {
        Header: "Remove",
        disableGlobalFilter: false,
        editFieldType: "remove",
        width: 50,
      },
    ],
    tableRowsData: [],
  };

  const tableConfig = {
    isEditable: true,
    isSortable,
    isResizable: false,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by First name...",
  };

  const addRow = () => {
    let newId = 1;
    if (rows.length) {
      newId = Math.max(...rows.map((o) => o.id)) + 1;
    }

    let obj = {
      id: newId,
      stage: "",
      task: "",
      startDate: new Date(),
      endDate: new Date(),
      payDate: new Date(),
      finalQuote: "",
      toPay: "",
      paid: "",
      workStatus: 2,
      paymentStatus: 3,
      ProjectScope: 0,
      index: rows.length,
    };

    if (AppProvider.WpsAddAdditionalWork) {
      AppProvider.setWpsAddAdditionalWork(false);
      obj.ProjectScope = 1;
      obj.workStatus = 1;
    }
    setSelectedRow(obj);
    setDisabledPay(false);
    toggleModal(true);
    setErrMsg("");
  };

  const getPaymentStats = (val) => {
    if (!isNaN(val)) {
      return paymentStatusList.filter((o) => o.value == val)[0].label;
    }
  };

  function checkAccess() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com"
    ) {
      return true;
    }
    return false;
  }
  const getProjectScopeStatus = (val) => {
    if (!isNaN(val)) {
      return ProjectScope.filter((o) => o.value == val)[0].label;
    }
  };

  useEffect(() => {
    if (rows.length) {
      if (AppProvider.WpsAddAdditionalWork) {
        setTimeout(() => {
          addRow();
        }, 1000);
      }
    }
  }, [rows]);

  return (
    <>
      <div>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          className="clearFilter"
          onClick={addRow}
        >
          <AddCircleIcon size="2em" />
        </Button>
      </div>

      {/* <div className="table react-table">
        <div className="table react-table table--not-pagination" id="wpsTable"> */}
      <Table style={{ boxShadow: "1px 1px 5px gray", padding: "3px" }}>
        <TableHead style={{ position: "relative", zIndex: 1 }}>
          <TableRow>
            {reactTableData.tableHeaderData.map((tHead, idx) => {
              return tHead.Header === "Remove" ? (
                checkAccess() && (
                  <TableCell key={idx} width={tHead?.width}>
                    {tHead.Header}
                  </TableCell>
                )
              ) : (
                <TableCell key={idx} width={tHead?.width}>
                  {tHead.Header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableCell-root": {
              padding: "5px 2px",
            },
          }}
        >
          {rows.map((row, xidx) => {
            return (
              <TableRow
                key={xidx}
                className={classNames({
                  wipClr: row.workStatus === 1,
                  completedClr: row.workStatus == 3,
                })}
              >
                <TableCell>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    onClick={(e) => {
                      checkAccess()
                        ? setDisabledPay(false)
                        : setDisabledPay(true);
                      toggleModal(true);
                      setSelectedRow({ ...row, index: xidx });
                    }}
                  >
                    {xidx + 1}
                  </span>
                </TableCell>
                <TableCell>{row.stage}</TableCell>
                <TableCell>{row.task}</TableCell>
                <TableCell>
                  <span>
                    Start Date :{" "}
                    <DatepickerField
                      selected={
                        row.startDate ? Moment(row.startDate).toDate() : ""
                      }
                      dateFormat="dd-MM-yyyy"
                      disabled={true}
                    />
                  </span>

                  <span>
                    End Date:{" "}
                    <DatepickerField
                      selected={row.endDate ? Moment(row.endDate).toDate() : ""}
                      dateFormat="dd-MM-yyyy"
                      disabled={true}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <DatepickerField
                    selected={row.payDate ? Moment(row.payDate).toDate() : ""}
                    dateFormat="dd-MM-yyyy"
                    disabled={true}
                  />
                </TableCell>
                <TableCell>{row.finalQuote}</TableCell>
                <TableCell>{row.toPay}</TableCell>
                <TableCell>{row.paid}</TableCell>
                {/* <TableCell>{workStatusList.filter(o => o.value == row.workStatus)[0].label}</TableCell> */}
                <TableCell>{getPaymentStats(row.paymentStatus)}</TableCell>
                <TableCell>
                  {" "}
                  {getProjectScopeStatus(row.ProjectScope)}
                </TableCell>
                {checkAccess() && (
                  <TableCell>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "black",
                        // textDecoration: "underline",
                      }}
                      onClick={(e) => {
                        setTableDirty(true);
                        let myrow = [...rows];
                        myrow.splice(xidx, 1);
                        setData(myrow);
                      }}
                    >
                      <Delete />
                    </span>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {errMsg && (
        <div className="errMsg" style={{ color: "#f00" }}>
          *{errMsg}
        </div>
      )}
      {/* </div>
      </div> */}
    </>
  );
};

export default WorkAndPaymentSchedule;
