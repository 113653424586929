import React, { useState, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { savePOFiles } from "../../redux/actions/poAction";
import { AppContext } from "../../Context/appContext";

function FileUpload(props) {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const AppProvider = useContext(AppContext);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const uploadFile = () => {
    setBtnDisabled(true);
    var poData = new FormData();

    for (let idx in acceptedFiles) {
      poData.append("files", acceptedFiles[idx]);
    }

    let data = {
      poId: props.PO.po_id,
      vendor: props.PO.vendor_id,
      selectedProject: props.PO.project_id,
    };
    poData.append("data", JSON.stringify(data));

    savePOFiles(poData).then((res) => {
      if (res?.result?.rows) {
        AppProvider.setAlertFunc({ txt: res.msg, clr: "success" });
        setBtnDisabled(false);
        props.toggleFileUploadingModal(false);
        props.getpo();
      }
    });
  };
  console.log("************************************************", props);
  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {acceptedFiles && acceptedFiles.length ? (
        <aside>
          <h4>Files to upload</h4>
          <ul>{files}</ul>
          <button disabled={btnDisabled} onClick={() => uploadFile()}>
            File Upload
          </button>
        </aside>
      ) : (
        ""
      )}
    </section>
  );
}

export default FileUpload;
