import { createAction } from "redux-actions";
import myPostReq from "../../utils/post";
import myGetReq, { downloadReq } from "../../utils/get";

export const saveVendorInfo = async (obj) => {
  try {
    return await myPostReq("/app/usr/save-vendor-info", obj, {});
  } catch (err) {
    return err;
  }
};
export const UpdateVendorInfo = async (obj) => {
  try {
    return await myPostReq("/app/usr/update-vendor-info", JSON.stringify(obj));
  } catch (err) {
    return err;
  }
};
export const savePurchaseOrder = async (obj) => {
  console.log("hii");
  try {
    return await myPostReq("/app/usr/save-po", obj, {});
  } catch (err) {
    return err;
  }
};

export const savePOFiles = async (obj) => {
  try {
    return await myPostReq("/app/usr/save-po-files", obj, {});
  } catch (err) {
    return err;
  }
};

export const updatePurchaseOrder = async (obj) => {
  try {
    return await myPostReq("/app/usr/update-po", obj);
  } catch (err) {
    return err;
  }
};

export const getVendorsList = async () => {
  try {
    return await myGetReq("/app/usr/get-vendors");
  } catch (err) {
    return err;
  }
};
export const getPurchaseOrders = async (selectedProject, filterData, type) => {
  try {
    return await myGetReq(
      `/app/usr/get-po?status=${filterData.status}&pid=${selectedProject}&id=${filterData.poid}&statusVal=${type}`
    );
  } catch (err) {
    return err;
  }
};
export const getPOById = async (poId) => {
  try {
    return await myGetReq(`/app/usr/get-po-by-id?po_id=${poId}`);
  } catch (err) {
    return err;
  }
};
export const getPOHistoryBYPId = async (poId, vendor, cat_id) => {
  try {
    return await myGetReq(
      `/app/usr/get-history?project_id=${poId}&vendor=${vendor}&catagory=${cat_id}`
    );
  } catch (err) {
    return err;
  }
};
export const getUnits = async (poId) => {
  try {
    return await myGetReq(`/app/usr/get-units`);
  } catch (err) {
    return err;
  }
};

export const getItemDetails = async (id) => {
  try {
    return await myGetReq(`/app/usr/get-po-item-details?po_id=${id}`);
  } catch (err) {
    return err;
  }
};
export const updatePOStatus = async (id, status) => {
  try {
    return await myGetReq(
      `/app/usr/update-po-status?po_id=${id}&status=${status}`
    );
  } catch (err) {
    return err;
  }
};

export const updatePoLedgerEntries = async (poId, ledgerId) => {
  try {
    return await myGetReq(
      `/app/usr/update-po-ledger?po_id=${poId}&lid=${ledgerId}`
    );
  } catch (err) {
    return err;
  }
};

export const viewPoLedgerEntries = async (ledgerId) => {
  try {
    return await myGetReq(
      `/app/usr/view-po-ledger-entries?ledgerIds=${ledgerId}`
    );
  } catch (err) {
    return err;
  }
};

export const getVendorsDetails = async (data, filterValues, canDownload) => {
  try {
    return await myGetReq(
      `/app/usr/get-vendors-details?download=${canDownload}&id=${data.value}&comments=${filterValues.comments}&paymentMode=${filterValues.paymentMode}&isGstBills=${filterValues.isGstBills}&txnReference=${filterValues.txnReference}&date=${filterValues.date}&paymentType=${filterValues.paymentType}&category=${filterValues.category}&person=${filterValues.person}&amount=${filterValues.amount}`
    );
  } catch (err) {
    return err;
  }
};
