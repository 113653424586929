import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Moment from "moment";

import { useForm } from "react-hook-form";
import { amountPattern } from "@/shared/helpers";
import { PaymentType, PaymentMode } from "@/shared/helpers/constants";

import { FormField } from "./FormField";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import DatepickerField from "../Datepicker/DatepickerField";
import renderDropZoneMultipleField from "../../shared/components/form/DropZone";
import { getCategories, fetchPersons } from "../../redux/actions/ledgerAction";

import { ChakraProvider } from "@chakra-ui/react";
import AddChildLedger from "./AddChildLedger";

const schema = yup.object().shape({
  category: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.number().required(),
    })
    .test("isvalue", "This field is required", (value) => value.value !== 0)
    .required("This is required field"),
  project_title: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.number().required("This is required field"),
    })
    .test("isvalue", "This field is required", (value) => value.value !== 0)
    .required("This is required field"),

  person: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.number().required("status is required"),
    })
    // .nullable() // for handling null value when clearing options via clicking "x"
    .required("status is required (from outter null check)"),

  paymentType: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.number().required("Payment type is required"),
    })
    .test("isvalue", "This field is required", (value) => value.value !== 0)
    .required("This field is required"),

  paymentMode: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.number().required("Payment mode is required"),
    })
    .test("isvalue", "This field is required", (value) => value.value !== 0)
    .required("This field is required"),

  date: yup.string().required("This field is required"),
  txnRef: yup.string().required("This field is required"),
  amount: yup
    .string()
    .test("isvalue", "Please enter a valid amount", (value) =>
      amountPattern.test(value)
    )
    .required("this field is required"),
});

const LedgerForm = ({
  isHorizontal,
  closePopup,
  onSubmit,
  initialValues,
  projectsList,
  submitBtnStatus,
  disableAmoutField,
  disablePaymentTypeField,
  addOnlyIncome,
  childlegdata,
  getchildledgerdata,
  setmotheren,
  returnid,
  ledger,
  getPaymentRequests,
}) => {
  const [isAboveError, setIsAboveError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  //console.log(disablePaymentTypeField,"yrujtyjtyjtyjtyuru")
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const [categoryList, setCategoryList] = useState([]);
  const [personList, setPersonList] = useState({});
  const [gst, setGst] = useState(false);
  //console.log(motheren, 113113113)
  console.log(initialValues, " initial value of state");

  useEffect(() => {
    setValue("category", initialValues.category);
    //console.log("person", initialValues.person)
    setValue("person", initialValues.person);
    setValue("date", initialValues.date);
    setValue("amount", initialValues.amount);
    //console.log(initialValues, "tjtujtdj");
    setValue("paymentType", initialValues.paymentType);
    setValue("paymentMode", initialValues.paymentMode);
    setValue("txnRef", initialValues.txnRef);
    setValue("gstBills", initialValues.gstBills);
    setValue("comments", initialValues.comments);

    let gst = initialValues.gstBills ? true : false;
    setGst(gst);
  }, [initialValues]);

  // console.log(initialValues, "120120120")

  useEffect(() => {
    Promise.all([getCategories(), fetchPersons()]).then((res) => {
      if (res[0].status) {
        let arr = [];
        for (let idx in res[0].data) {
          arr.push({
            value: res[0].data[idx].id,
            label: res[0].data[idx].name,
          });
        }
        setCategoryList(arr);
      }

      if (res[1].status) {
        let arr = [];
        for (let idx in res[1].data) {
          arr.push({
            value: res[1].data[idx].id,
            label: `${res[1].data[idx].first_name} ${res[1].data[idx].last_name}`,
          });
        }
        setPersonList(arr);
      }
    });
  }, []);
  function getUserName() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    // console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com"
    ) {
      return true;
    }
    return false;
  }

  console.log(childlegdata, "1631653163");

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <ChakraProvider>
            <AddChildLedger
              ledger={ledger}
              returnid={returnid}
              setmotheren={setmotheren}
              cpp={closePopup}
              rfun={getPaymentRequests}
              cld={childlegdata}
              data={initialValues}
            />
          </ChakraProvider>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <span className="form__form-group-label"> Select Project</span>
              <div className="form__form-group-field">
                <FormField
                  name="project_title"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={value}
                      {...field}
                      options={projectsList}
                      clearable={false}
                      className="react-select"
                      // onChange={OnChangeProject}
                      classNamePrefix="react-select"
                      placeholder={"Select Project"}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                />
              </div>
              {errors?.project_title?.value?.message && (
                <span className="form__form-group-error">
                  "This is required field
                </span>
              )}
            </div>

            <div className="form__half">
              <span className="form__form-group-label"> Category</span>
              <div className="form__form-group-field">
                <FormField
                  name="category"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={value}
                      {...field}
                      // onChange={onChangeCategory}
                      options={categoryList}
                      clearable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder={"Select Category"}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                />
              </div>
            </div>

            <div className="form__half">
              <span className="form__form-group-label">Person</span>
              <div className="form__form-group-field">
                <FormField
                  name="person"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={value}
                      {...field}
                      options={personList}
                      clearable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder={"Select Person"}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                />
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group-field">
                <FormField
                  name="date"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <DatepickerField
                      {...field}
                      control={control}
                      selected={selectedDate}
                      onChange={(event) => {
                        setValue("date", event);
                        setSelectedDate(event);
                      }}
                      dateFormat="yyyy-MM-dd"
                      // disabled={!row.isEditable}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                />
              </div>
            </div>

            <div className="form__half">
              <span className="form__form-group-label">Amount</span>
              <div className="form__form-group-field">
                <FormField
                  name="amount"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{
                    pattern: {
                      value: amountPattern,
                      message: "Please enter a valid amount",
                    },
                    required: "This is required field",
                  }}
                  defaultValue=""
                  // disabled={disableAmoutField}
                  isAboveError={isAboveError}
                  placeholder="Enter amount"
                />
              </div>
            </div>

            <div className="form__half">
              <span className="form__form-group-label">Payment Type</span>
              <div className="form__form-group-field">
                <FormField
                  name="paymentType"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={"expense"}
                      {...field}
                      // onChange={onChangePaymentType}
                      options={PaymentType}
                      clearable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder={"Select payment type"}
                      isDisabled={
                        disablePaymentTypeField ||
                        addOnlyIncome ||
                        !getUserName()
                      }
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                />
              </div>
            </div>

            <div className="form__half">
              <span className="form__form-group-label">Payment Mode</span>
              <div className="form__form-group-field">
                <FormField
                  name="paymentMode"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={value}
                      {...field}
                      // onChange={onChangePaymentMode}
                      options={PaymentMode}
                      clearable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder={"Select payment mode"}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                />
              </div>
            </div>

            <div className="form__half">
              <span className="form__form-group-label">Txn Ref</span>
              <div className="form__form-group-field">
                <FormField
                  name="txnRef"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{ required: "This is required field" }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Transaction Reference"
                />
              </div>
            </div>

            <div className="form__half">
              <span className="form__form-group-label">GST Bills</span>
              <div
                className="form__form-group-field"
                style={{ width: "10%", margin: "0" }}
              >
                <FormField
                  name={`gstBills`}
                  type="checkbox"
                  value={gst}
                  label="gst bills"
                  control={control}
                  onChange={() => {
                    setValue("gstBills", !gst);
                    setGst(!gst);
                  }}
                  checked={gst}
                  className={"checkbox"}
                  // disabled={item.disabled}
                />
              </div>
            </div>

            <div className="form__half">
              <span className="form__form-group-label">Comments</span>
              <div className="form__form-group-field">
                <FormField
                  name="comments"
                  control={control}
                  component="textarea"
                  errors={errors}
                  rules={{ required: "This is required field" }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Description"
                />
              </div>
            </div>

            <div className="form__half"></div>

            <div className="form form--horizontal" style={{ width: "100%" }}>
              {/* <span className="form__form-group-label">Attach Bills</span> */}
              {/* <div className="form__form-group-field"> */}
              <FormField
                name="files"
                control={control}
                // onChange={onFilesChange}
                component={renderDropZoneMultipleField}
              />
              {/* </div> */}
            </div>

            <div style={{ margin: "5px" }} className="form__form-group">
              <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                <Button
                  color="primary"
                  type="submit"
                  className="next"
                  //  disabled={submitBtnStatus}
                >
                  Submit
                </Button>

                <Button
                  disabled={ledger}
                  color="primary"
                  type="submit"
                  className="next"
                  onClick={() => setmotheren(true)}
                  //  disabled={submitBtnStatus}
                >
                  Mother Entry
                </Button>
                <Button type="button" onClick={() => closePopup(false)}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LedgerForm;
