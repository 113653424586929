import React, { useEffect, useState } from 'react';
import { fetchUsersList } from '../../redux/actions/usersAction';
import Access from './Access';
import { Accordion, Box, Button, Input, Spinner } from '@chakra-ui/react';

const Accesscontrol = () => {
  const [userData, setuserData] = useState([]);
  const [load, setload] = useState(false);
  const [page, setpage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  function getUsersList() {
    setload(false);
    fetchUsersList({
      first: searchTerm ? searchTerm : '',
      last: '',
      role_id: 0,
      email: '',
      contact_num: '',
    }).then((res) => {
      console.log(res.data, 'ac19');
      setuserData(res.data);
      setload(true);
    });
  }

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (searchTerm !== '') {
        getUsersList();
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);

  return (
    <div>
      <Box p={4}>
        <Input
          placeholder="Enter User Firstname or Lastname"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <Box>
        <Accordion allowToggle>
          {load ? (
            userData && userData?.length !== 0 ? (
              <>
                {userData
                  ?.slice(page, page + 10)
                  .map((ele) => (
                    <Box key={ele?.id}>
                      <Access getUsersList={getUsersList} data={ele} />
                    </Box>
                  ))}
              </>
            ) : (
              <Box color={'red'} fontSize={'1.2rem'} ml={'0.5rem'}>
                No results found
              </Box>
            )
          ) : (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Box>
          )}
        </Accordion>
        <Box>
          <Button disabled={page === 0} onClick={() => setpage((pre) => pre - 10)}>
            Pre
          </Button>
          {(page / 10) + 1}
          <Button disabled={page > (userData?.length - 10)} onClick={() => setpage((pre) => pre + 10)}>
            Next
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Accesscontrol;
