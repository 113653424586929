import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Moment from "moment";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useForm, useFormState } from "react-hook-form";
import { FormField } from "./FormField";
import AsyncSelect from "react-select/async";
import renderDropZoneMultipleField from "../../shared/components/form/DropZone";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Select from "react-select";

import {
  saveProject,
  getProjectInfoDetails,
  getProjectFile,
} from "../../redux/actions/projectsAction";
import {
  fetchCustomersList,
  fetchSiteEngineersList,
  fetchProjectManagerList,
  fetchServiceProvidersList,
} from "../../redux/actions/usersAction";

import { AppContext } from "../../Context/appContext";

import { emailPatter, projectNamePattern } from "@/shared/helpers";
import ConstructionRequirement from "./ConstructionRequirement";
import WorkAndPaymentSchedule from "./WorkAndPaymentSchedule";
import { downloadAttachments } from "../../redux/actions/workOrderAction";
import { projectStatus } from "../../shared/helpers/constants";
import WpsForm from "./WpsForm";

let customerDetails,
  siteEngDetails,
  pmDetails,
  serviceProvidersDetails = [];

const CreateProjectForm = ({}) => {
  const { pid } = useParams();
  const navigate = useNavigate();
  const AppProvider = useContext(AppContext);
  const [disabledPay, setDisabledPay] = useState(true);
  const [constructionInfo, setConstructionInfo] = useState([]);
  const [workPaymentScheduleList, setWorkPaymentScheduleList] = useState([]);
  const [isDropdownDirty, setIsDropdownDirty] = useState({});
  const [isTableRowsTouched, setIsTableRowsTouched] = useState(false);
  const [showWpsForm, setShowWpsForm] = useState(false);
  const [snackbar, setSnackbar] = useState({
    status: false,
    type: "success",
    msg: "success",
  });
  const [initialValues, setInitialValues] = useState({
    LatLng: "",
    contractor: "",
    customerEmail: "",
    customerFirstName: "",
    customerId: "",
    customerLastName: "",
    customerPhoneNumber: "",
    location: "",
    projectManagerId: "",
    projectMangerName: "",
    projectTitle: "",
    serviceProviderId: "",
    siteEngineerEmail: "",
    siteEngineerId: "",
    siteEngineerName: "",
    siteEngineerPhoneNumber: "",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({ defaultValues: initialValues });

  const { dirtyFields } = useFormState({
    control,
  });

  const [selectedWpsRow, setSelectedWpsRow] = useState({});

  const onChangeCustomerEmail = (val) => {
    setIsDropdownDirty({ ...isDropdownDirty, customerEmail: true });

    if (!val) {
      setValue("customerEmail", "");
      setValue("customerName", "");
      setValue("customerPhoneNumber", "");
      setValue("customerId", "");
      return;
    }

    for (let idx in customerDetails) {
      if (customerDetails[idx].id === val.value) {
        setValue(
          "customerName",
          `${customerDetails[idx].first_name} ${customerDetails[idx].last_name}`
        );
        setValue("customerPhoneNumber", customerDetails[idx].contact_num);
        break;
      }
    }
    setValue("customerId", val.value);
    setValue("customerEmail", val);
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) inputValue = "";
    const response = await fetchCustomersList(inputValue?.toLocaleLowerCase());
    let data = [];
    customerDetails = [...response.data];
    // setcustomerDetails(response.data);
    for (let idx in customerDetails) {
      data.push({
        value: customerDetails[idx].id,
        label: customerDetails[idx].email,
      });
    }
    return data;
  };

  const getProjectManagerList = async (inputValue) => {
    if (!inputValue) inputValue = "";

    const response = await fetchProjectManagerList(
      inputValue?.toLocaleLowerCase()
    );
    pmDetails = Object.assign({}, response.data);
    let data = [];
    for (let idx in pmDetails) {
      data.push({
        value: pmDetails[idx].id,
        label: `${pmDetails[idx].first_name} ${pmDetails[idx].last_name}`,
      });
    }
    return data;
  };

  const onChangePM = (val) => {
    setIsDropdownDirty({ ...isDropdownDirty, pm: true });

    if (!val) {
      setValue("projectMangerName", "");
      setValue("projectManagerEmail", "");
      setValue("projectManagerId", "");
      return;
    }

    for (let idx in pmDetails) {
      if (pmDetails[idx].id === val.value) {
        setValue("projectManagerEmail", pmDetails[idx].email);
        break;
      }
    }
    setValue("projectMangerName", val);
    setValue("projectManagerId", val.value);
  };

  const getSiteEngineersList = async (inputValue) => {
    if (!inputValue) inputValue = "";

    const response = await fetchSiteEngineersList(
      inputValue?.toLocaleLowerCase()
    );
    siteEngDetails = response.data;

    let data = [];
    for (let idx in siteEngDetails) {
      data.push({
        value: siteEngDetails[idx].id,
        label: `${siteEngDetails[idx].first_name} ${siteEngDetails[idx].last_name}`,
      });
    }
    return data;
  };

  const onChangeSEEmail = (val) => {
    setIsDropdownDirty({ ...isDropdownDirty, sEEmail: true });

    if (!val) {
      setValue("siteEngineerName", "");
      setValue("siteEngineerEmail", "");
      setValue("siteEngineerPhoneNumber", "");
      setValue("siteEngineerId", "");

      return;
    }
    for (let idx in siteEngDetails) {
      if (siteEngDetails[idx].id === val.value) {
        // setValue('siteEngineerName', `${siteEngDetails[idx].first_name} ${siteEngDetails[idx].last_name}`)
        setValue("siteEngineerEmail", siteEngDetails[idx].email);
        setValue("siteEngineerPhoneNumber", siteEngDetails[idx].contact_num);
        break;
      }
    }
    setValue("siteEngineerName", val);
    setValue("siteEngineerId", val.value);
  };

  const getServiceProvidersList = async (inputValue) => {
    const response = await fetchServiceProvidersList(
      inputValue.toLocaleLowerCase()
    );
    serviceProvidersDetails = response.data;

    let data = [];
    for (let idx in serviceProvidersDetails) {
      data.push({
        value: serviceProvidersDetails[idx].id,
        label: `${serviceProvidersDetails[idx].first_name} ${serviceProvidersDetails[idx].last_name}`,
      });
    }
    return data;
  };

  const onChangeServiceProvider = (val) => {
    setIsDropdownDirty({ ...isDropdownDirty, serviceProvider: true });
    if (!val) {
      setValue("contractor", "");
      return;
    }
    for (let idx in serviceProvidersDetails) {
      if (serviceProvidersDetails[idx].id === val.value) {
        // setValue('siteEngineerName', `${siteEngDetails[idx].first_name} ${siteEngDetails[idx].last_name}`)
        break;
      }
    }
    setValue("contractor", val);
  };

  const setTableDirty = () => {
    setIsTableRowsTouched(true);
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, ["status"]: false });
  };

  const resetFields = () => {
    setIsDropdownDirty(false);
    setIsTableRowsTouched(false);
    reset();
  };

  const onSubmit = (values) => {
    if (
      Object.keys(isDropdownDirty).length ||
      Object.keys(dirtyFields).length ||
      isTableRowsTouched
    ) {
      values.projectTitle = values.projectTitle.trim();
      if (pid) {
        values.project_id = pid;
      }

      values["constructionReq"] = constructionInfo;

      let wList = [...workPaymentScheduleList];
      for (let idx in wList) {
        wList[idx].workStatus = parseInt(wList[idx].workStatus);
        wList[idx].paymentStatus = parseInt(wList[idx].paymentStatus);
        wList[idx].ProjectScope = parseInt(wList[idx].ProjectScope);
        wList[idx].startDate = Moment(wList[idx].startDate).format(
          "yyyy-MM-DD"
        );
        wList[idx].endDate = Moment(wList[idx].endDate).format("yyyy-MM-DD");
        wList[idx].payDate = Moment(wList[idx].payDate).format("yyyy-MM-DD");
      }

      values["workPaymentSchdule"] = wList;

      let dat = new FormData();

      for (let idx in values.drawingFiles) {
        if (!values.drawingFiles[idx]?.fromsrv)
          dat.append("drawingFiles", values.drawingFiles[idx]);
      }

      for (let idx in values.floorPlans) {
        if (!values.floorPlans[idx]?.fromsrv)
          dat.append("floorPlans", values.floorPlans[idx]);
      }
      for (let idx in values.agreements) {
        if (!values.agreements[idx]?.fromsrv)
          dat.append("agreements", values.agreements[idx]);
      }
      for (let idx in values.ids) {
        if (!values.ids[idx]?.fromsrv) dat.append("ids", values.ids[idx]);
      }
      dat.append("data", JSON.stringify(values));

      const response = saveProject(dat);
      response.then((res) => {
        if (res.status) {
          // onChangeAlertMsg(
          //   Object.assign({}, { txt: res.msg }, { clr: "success" })
          // );
          setSnackbar({
            status: true,
            msg: res.msg,
            type: "success",
          });

          resetFields();
          setTimeout(() => {
            let url = window.location.href;
            let urlArr = url.split("/");
            url = urlArr[urlArr.length - 1];
            if (urlArr.includes("admin")) {
              navigate(`/app/admin/dashboard`);
            } else if (urlArr.includes("pm")) {
              navigate(`/app/pm/dashboard`);
            }
          }, 5000);
        } else {
          setSnackbar({
            status: true,
            msg: res.msg,
            type: "danger",
          });

          // onChangeAlertMsg(
          //   Object.assign({}, { txt: res.msg }, { clr: "danger" })
          // );
        }
      });
    } else {
    }
  };

  const setConstructionRequirements = (records) => {
    setConstructionInfo(records);
  };

  const updateWorkandPaymentSchdule = (records) => {
    setWorkPaymentScheduleList(records);
  };

  const onSelectWpsRow = (obj) => {
    setSelectedWpsRow(obj);
  };

  const onFilesChange = (data) => {
    console.log(data);
  };

  const toggleWpsModal = (flag) => {
    setShowWpsForm(flag);
  };

  const getFiles = async (filesList, fieldName) => {
    let fileArray = [];
    for (let idx in filesList) {
      filesList[idx] = JSON.parse(filesList[idx]);
      let fileName = filesList[idx].location.split("/");
      fileName = fileName[fileName.length - 1];

      const files = await getProjectFile(filesList[idx].location, true);
      let blob = window.URL.createObjectURL(files);
      let file = new File([blob], `${fileName}`, {
        preview: blob,
        type: files.type,
      });

      file.preview = blob;
      file.path = blob;
      file.fromsrv = filesList[idx];
      fileArray.push(file);
      setValue(fieldName, fileArray);
    }
  };

  const saveRecord = (data) => {
    setTableDirty(true);
    data.payDate = Moment(data.payDate).toDate();
    data.startDate = Moment(data.startDate).toDate();
    data.endDate = Moment(data.endDate).toDate();
    data.ProjectScope = data.ProjectScope.value;
    data.paymentStatus = data.paymentStatus.value;
    data.workStatus = data.workStatus.value;

    let rowsdata = [...workPaymentScheduleList];

    rowsdata[data.index] = data;
    updateWorkandPaymentSchdule(rowsdata);
    setShowWpsForm(false);
  };

  useEffect(() => {
    loadOptions();
    getProjectManagerList();
    getSiteEngineersList();
  }, []);

  useEffect(() => {
    if (pid) {
      getProjectInfoDetails(pid).then((res) => {
        if (res.status) {
          let workScheduleList = res.data.workNPaymentSche;
          let list = [];
          for (let index in workScheduleList) {
            list.push({
              id: workScheduleList[index].id,
              project_id: workScheduleList[index].project_id,
              stage: workScheduleList[index].stage,
              task: workScheduleList[index].task,
              startDate: Moment(workScheduleList[index].start_date).toDate(),
              endDate: Moment(workScheduleList[index].end_date).toDate(),
              payDate: Moment(workScheduleList[index].payment_date).toDate(),
              finalQuote: workScheduleList[index].percent_of_final_quote,
              toPay: workScheduleList[index].to_pay,
              paid: workScheduleList[index].paid,
              workStatus: workScheduleList[index].work_status,
              paymentStatus: workScheduleList[index].payment_status,
              ProjectScope: workScheduleList[index].project_scope,
              isEditable: false,
            });
          }

          setWorkPaymentScheduleList(list);

          let constReq = [];
          let data = res.data.constReq;
          if (data?.length) {
            for (let xidx in data) {
              constReq.push({
                id: data[xidx].id,
                projectId: data[xidx].project_id,
                subject: data[xidx].subject,
                description: data[xidx].description,
                computation: data[xidx].computation,
                projectScope: data[xidx].project_scope,
                isEditable: false,
              });
            }
          }

          setConstructionInfo(constReq);

          let basicInfo = res.data.basicInfo;
          let obj = {
            projectTitle: basicInfo.project_title,
            customerEmail: basicInfo.customer_email,
            customerId: basicInfo.customer_id,
            customerFirstName: basicInfo.customer_firstname,
            customerLastName: basicInfo.customer_lastname,
            customerLastName: basicInfo.customer_lastname,
            customerPhoneNumber: basicInfo.customer_contact_num,
            projectMangerName: `${basicInfo.project_manager_firstname} ${basicInfo.project_manager_lastname}`,
            projectManagerId: basicInfo.project_manager_id,
            siteEngineerName: `${basicInfo.site_engineer_firstname} ${basicInfo.site_engineer_lastname}`,
            siteEngineerEmail: basicInfo.site_engineer_email,
            siteEngineerPhoneNumber: basicInfo.site_engineer_contact_num,
            siteEngineerId: basicInfo.site_engineer_id,
            serviceProviderId: basicInfo.service_provider_id,
            contractor: `${basicInfo.service_provider_firstname} ${basicInfo.service_provider_lastname}`,
            location: basicInfo.location,
            LatLng: basicInfo.lat_lng,
            drawings: basicInfo.drawings,
            ids: basicInfo.ids,
            agreements: basicInfo.agreements,
            floor_plans: basicInfo.floor_plans,
            projectStatus: basicInfo.project_status,
          };
          getFiles(obj.drawings, "drawingFiles");
          getFiles(obj.ids, "ids");
          getFiles(obj.agreements, "agreements");
          getFiles(obj.floor_plans, "floorPlans");
          setValue("projectTitle", obj.projectTitle);
          let custEmail = {
            value: obj.customerId?.toString(),
            label: obj.customerEmail,
          };
          setValue("projectStatus", obj.projectStatus);
          setValue("customerEmail", custEmail);
          setValue(
            "customerName",
            `${obj.customerFirstName} ${obj.customerLastName}`
          );

          let siteEngName = {
            value: obj.siteEngineerId.toString(),
            label: obj.siteEngineerName,
          };
          setValue("siteEngineerName", siteEngName);
          setValue("customerPhoneNumber", obj.customerPhoneNumber);
          setValue("customerId", obj.customerId);
          let pm = {
            value: obj.projectManagerId.toString(),
            label: obj.projectMangerName,
          };
          setValue("projectMangerName", pm);
          setValue("projectManagerId", obj.projectManagerId);
          setValue("siteEngineerEmail", obj.siteEngineerEmail);
          setValue("siteEngineerId", obj.siteEngineerId);

          let sp = {
            value: obj.serviceProviderId.toString(),
            label: obj.contractor,
          };
          setValue("contractor", sp);
          setValue("siteEngineerPhoneNumber", obj.siteEngineerPhoneNumber);
          setValue("location", obj.location);
          setValue("LatLng", obj.LatLng);
        }
      });
    }
  }, []);

  return (
    <Col md={12} lg={12} className="workOrder">
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Basic Info</h5>
          </div>

          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Project Title</span>
                <div className="form__form-group-field">
                  <FormField
                    name="projectTitle"
                    control={control}
                    component="input"
                    errors={errors}
                    maxLength="50"
                    rules={{
                      required: "This is required field",
                      pattern: {
                        value: projectNamePattern,
                        message:
                          "User name may only contain alphanumeric and @-_ characters",
                      },
                    }}
                    defaultValue=""
                    placeholder="Project Title"
                  />
                </div>
                {errors?.project_title?.value?.message && (
                  <span className="form__form-group-error">
                    "This is required field
                  </span>
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Customer Email</span>
                <div className="form__form-group-field">
                  <FormField
                    name="customerEmail"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <AsyncSelect
                        {...field}
                        isClearable
                        defaultOptions
                        isSearchable
                        className="reactSelect"
                        placeholder={"Customer's E-mail"}
                        loadOptions={loadOptions}
                        onChange={onChangeCustomerEmail}
                        // styles={customStyles}
                      />
                    )}
                    maxLength="50"
                    rules={{
                      required: "This is required field",
                      pattern: {
                        value: emailPatter,
                        message: "Please enter a valid project name",
                      },
                    }}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Project Manager</span>
                <div className="form__form-group-field">
                  <FormField
                    name="projectMangerName"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <AsyncSelect
                        {...field}
                        isClearable
                        defaultOptions
                        isSearchable
                        placeholder={"Project Manager"}
                        loadOptions={getProjectManagerList}
                        onChange={onChangePM}
                        // styles={customStyles}
                      />
                    )}
                    maxLength="50"
                    rules={{
                      required: "This is required field",
                      pattern: {
                        value: emailPatter,
                        message: "Please enter a valid project name",
                      },
                    }}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Site Engineer</span>
                <div className="form__form-group-field">
                  <FormField
                    name="siteEngineerName"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <AsyncSelect
                        {...field}
                        isClearable
                        defaultOptions
                        isSearchable
                        placeholder={"Site Engineer Name"}
                        loadOptions={getSiteEngineersList}
                        onChange={onChangeSEEmail}
                        // styles={customStyles}
                      />
                    )}
                    maxLength="50"
                    rules={{
                      required: "This is required field",
                    }}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Execution Lead</span>
                <div className="form__form-group-field">
                  <FormField
                    name="contractor"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <AsyncSelect
                        {...field}
                        isClearable
                        defaultOptions
                        isSearchable
                        placeholder={"Service Provider Name"}
                        loadOptions={getServiceProvidersList}
                        onChange={onChangeServiceProvider}
                        // styles={customStyles}
                      />
                    )}
                    maxLength="50"
                    rules={{
                      required: "This is required field",
                    }}
                    defaultValue=""
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Location</span>
                <div className="form__form-group-field">
                  <FormField
                    name="location"
                    control={control}
                    component="textarea"
                    errors={errors}
                    rules={{ required: "This is required field" }}
                    defaultValue=""
                    placeholder="Address"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Map Location</span>
                <div className="form__form-group-field">
                  <FormField
                    name="LatLng"
                    control={control}
                    component="textarea"
                    errors={errors}
                    defaultValue=""
                    placeholder="Map location"
                  />
                </div>
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Customer Name</span>
                <div className="form__form-group-field">
                  <FormField
                    name="customerName"
                    control={control}
                    component="input"
                    errors={errors}
                    disabled
                    defaultValue=""
                    placeholder="Firstname"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Customer Phone Number
                </span>
                <div className="form__form-group-field">
                  <FormField
                    name="customerPhoneNumber"
                    control={control}
                    component="input"
                    errors={errors}
                    disabled
                    defaultValue=""
                    placeholder="Customer's Phone Number"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Site Engineer Email
                </span>
                <div className="form__form-group-field">
                  <FormField
                    name="siteEngineerEmail"
                    control={control}
                    component="input"
                    disabled
                    rules={{ required: "This is required field" }}
                    defaultValue=""
                    placeholder="Site Engineer's Email"
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Site Engineer Phone{" "}
                </span>
                <div className="form__form-group-field">
                  <FormField
                    name="siteEngineerPhoneNumber"
                    control={control}
                    component="input"
                    errors={errors}
                    disabled
                    defaultValue=""
                    placeholder="Site Engineer's Phone Number"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Status </span>
                <div className="form__form-group-field">
                  <FormField
                    name="projectStatus"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <Select {...field} options={projectStatus} />
                    )}
                    rules={{
                      required: "This is required field",
                    }}
                    defaultValue=""
                  />
                </div>
              </div>
            </div>

            <Container>
              <Row>
                <Col>
                  <span className="form__form-group-label">
                    Upload Drawings
                  </span>
                  {/* <div className="form__form-group-field"> */}

                  <FormField
                    name="drawingFiles"
                    control={control}
                    uploadLabel="Upload Drawing files"
                    component={renderDropZoneMultipleField}
                  />
                </Col>
                <Col>
                  <span className="form__form-group-label">
                    Upload Floor Plans
                  </span>
                  {/* <div className="form__form-group-field"> */}
                  <FormField
                    name="floorPlans"
                    control={control}
                    uploadLabel="Upload Floor files"
                    // onChange={onFilesChange}
                    component={renderDropZoneMultipleField}
                  />
                </Col>
              </Row>
            </Container>

            <Container>
              <Row>
                <Col>
                  <span className="form__form-group-label">
                    Upload Agreements
                  </span>
                  {/* <div className="form__form-group-field"> */}
                  <FormField
                    name="agreements"
                    control={control}
                    uploadLabel="Upload Agreements files"
                    // onChange={onFilesChange}
                    component={renderDropZoneMultipleField}
                  />
                </Col>
                <Col>
                  <span className="form__form-group-label">Upload IDs</span>
                  {/* <div className="form__form-group-field"> */}
                  <FormField
                    name="ids"
                    control={control}
                    uploadLabel="Upload IDs"
                    // onChange={onFilesChange}
                    component={renderDropZoneMultipleField}
                  />
                  {/* </div> */}
                </Col>
              </Row>
            </Container>
            <br />
            <Container>
              <Row>
                <div className="card__title">
                  <h5 className="bold-text">Construction Requirement</h5>
                </div>
              </Row>
              <Row>
                <ConstructionRequirement
                  rows={constructionInfo}
                  setData={setConstructionRequirements}
                  setTableDirty={setTableDirty}
                />
              </Row>
            </Container>
            <Container>
              <Row>
                <div className="card__title">
                  <h5 className="bold-text">Work And Payment Schdule</h5>
                </div>
              </Row>
              <Row>
                <WorkAndPaymentSchedule
                  setDisabledPay={setDisabledPay}
                  rows={workPaymentScheduleList}
                  setData={updateWorkandPaymentSchdule}
                  setTableDirty={setTableDirty}
                  setSelectedRow={onSelectWpsRow}
                  toggleModal={toggleWpsModal}
                />
              </Row>
              <Row className="stickyFooter">
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    type="button"
                    onClick={() => {
                      reset();
                      resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      !Object.keys(dirtyFields).length &&
                      !Object.keys(isDropdownDirty).length &&
                      !isTableRowsTouched
                    }
                  >
                    Submit
                  </Button>
                </ButtonToolbar>
              </Row>
            </Container>
          </form>
        </CardBody>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar.status}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <MuiAlert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity={snackbar.type}
          sx={{ width: "100%" }}
        >
          {snackbar.msg}
        </MuiAlert>
      </Snackbar>

      <Modal
        isOpen={showWpsForm}
        toggle={() => toggleWpsModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleWpsModal(false)}>
          <strong>Work and Payment Schedule</strong>
        </ModalHeader>
        <ModalBody>
          <WpsForm
            control={control}
            errors={errors}
            setValue={setValue}
            handleSubmit={handleSubmit}
            disabledPay={disabledPay}
            showForm={showWpsForm}
            toggleModal={toggleWpsModal}
            initialValues={{ ...selectedWpsRow }}
            onSubmit={saveRecord}
          />
        </ModalBody>
      </Modal>
    </Col>
  );
};

export default CreateProjectForm;
