import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HFSnackbar from "../../../Components/HFSnackbar";
import HFIconButton from "../../../Components/HFIconButton";
import HFDialog from "../../../Components/HFDialog";
import {
  HFAutoComplete,
  HFPicker,
  HFTextField,
} from "../../../Components/FormikFields";
import post from "../../../utils/post";
import * as Yup from "yup";
import { Formik, Form, connect } from "formik";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

const FormDialog = connect((props) => {
  const milestoneList = [
    "Footing",
    "Sump",
    "Plinth",
    "GF Masonry",
    "GF Lintel",
    "GF Slab",
    "1F Masonry",
    "1F Lintel",
    "1F Slab",
    "2F Masonry",
    "2F Lintel",
    "2F Slab",
    "3F Masonry",
    "3F Lintel",
    "3F Slab",
    "4F Masonry",
    "4F Lintel",
    "4F Slab",
    "5F Masonry",
    "5F Lintel",
    "5F Slab",
    "Parapet Wall",
    "Compound Wall",
    "Tank Room",
    "Head Room",
    "Plastering",
    "Painting",
    "Electricals",
    "Plumbing",
    "Flooring",
    "Fabrication",
    "Carpentry",
    "Interiors",
    "Others",
  ];

  return (
    <HFDialog
      open={props.dialog}
      handleSubmit={() => {
        props.formik.submitForm();
      }}
      handleClose={() => {
        props.setDialog(false);
      }}
      title={`${props.material.material} Available`}
    >
      <Form>
        <div className="row">
          <div className="col-md-6">
            <HFPicker
              name="date"
              label="Date"
              variant="outlined"
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div className="col-md-6">
            <HFTextField name="quantity" label="Quantity" />
          </div>
          <div className="col-md-6">
            <HFAutoComplete
              label="Milestone"
              name="milestone"
              options={milestoneList}
              getOptionLabel={(option) => option}
            />
          </div>
          <div className="col-md-6">
            <HFTextField name="description" label="Description" />
          </div>
          <div className="col-md-6"></div>
        </div>
      </Form>
    </HFDialog>
  );
});

const MaterialConsumption = ({ row, params, getData }) => {
  const [dialog, setDialog] = useState(false);
  const appState = useSelector((state) => state.login);
  const navigate = useNavigate();
  const validation = Yup.object().shape({
    date: Yup.string().required("Required"),
    quantity: Yup.string().required("Required"),
  });
  const initialValues = {
    project_id: params.id,
    category_id: row.id,
    date: new Date(),
    quantity: "",
    description: "",
    milestone: "",
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    post(
      `/app/usr/material-consumption`,
      JSON.stringify({ ...values, remaining: row.remaining })
    )
      .then((res) => {
        setSubmitting(false);
        resetForm();
        setDialog(false);
        getData();
      })
      .catch((err) => {
        console.log("error", err);
        setSubmitting(false);
      });
  };


  const loginState = useSelector((state) => state.login);

  let role = ""
  switch (loginState.userDetails.role_id) {

    case 1:
      role = "admin"
      break;
    case 2:
      role = "pm"
      break;
    case 3:
      role = "se"
      break;
    case 4:
      role = "customer"
      break;
    case 5:
      role = "serviceprovider"
      break;
    case 6:
      role = "vendor"
      break;
    case 7:
      role = "user"
      break;
    case 8:
      role = "admin"
      break;
    case 9:
      role = "sales"
      break;

    default:
      break;
  }



  function binaryconverter(hexadecimal) {

    let decimal = parseInt(hexadecimal, 16)
    let binary = (decimal).toString(2)
    return binary
  }

  return (
    <>
      <HFIconButton
        title="Add Material Order"
        icon={AddIcon}
        onClick={() => {
          if (
            binaryconverter(loginState.userDetails.access_control).charAt(12) == 1
          ) {
            navigate(`/app/${role}/po-create`, {
              state: { category: row, project: params },
            });
          }
        }}
      />
      <HFIconButton
        title="Material Available @ Site"
        icon={RemoveIcon}
        onClick={() => {
          setDialog(true);
        }}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        <FormDialog material={row} dialog={dialog} setDialog={setDialog} />
      </Formik>
    </>
  );
};
export default MaterialConsumption;
