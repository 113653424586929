import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { addBuilding } from "../../../redux/actions/rentalsActions";

const AddBuilding = ({
  alertMsg,
  setAlertMsg,
  isHorizontal,
  closePopup,
  initialValues,
}) => {
  const [isAboveError, setIsAboveError] = useState(false);
  // const [alertMsg, setAlertMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: initialValues });

  const onSubmit = (data) => {
    console.log(data, "create building data");
    const newData = {};
    newData.bname = data.Building_name;
    newData.units = data.Unit_no;
    newData.owner = data.Owner;
    newData.floors = data.No_of_floor;
    newData.location = data.location;
    newData.address = data.Address;
    newData.caretaker = data.Care_taker;
    newData.owner_contact = data.Owner_contact;
    newData.caretaker_contact = data.CareTaker_contact;
    setDisabled(true);
    addBuilding(newData).then((res) => {
      console.log(res);
      setDisabled(false);
      setAlertMsg("Building Data Added successfully");
    });
    reset({
      Address: "",
      Building_name: "",
      location: "",
      No_of_floor: 0,
      Owner: "",
      Unit_no: 0,
      Care_taker: "",
      Owner_contact: "",
      CareTaker_contact: "",
    });
  };

  const onchangeuserRole = (evt) => {
    // setUserRole(evt.target.value);
    setValue("userRole", parseInt(evt.target.value));
  };

  useEffect(() => {
    setValue("Building_name", initialValues.Building_name);
    setValue("Unit_no", initialValues.Unit_no);
    setValue("Address", initialValues.Address);
    setValue("No_of_floor", initialValues.No_of_floor);
    setValue("Owner", initialValues.Owner);
    setValue("location", initialValues.location);
    setValue("Care_taker", initialValues.Care_taker);
    // setUserRole(initialValues.user_role);
  }, [initialValues]);

  return (
    <Grid container>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h5">
            Create Rental Building
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/*_____________________________Building_name_________________________ */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  name="Building_name"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.Building_name}
                      margin="dense"
                      mb="20px"
                      id="Building-text"
                      label="Building Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.Building_name?.message}
                      {...rest}
                    />
                  )}
                  rules={{ required: "This is required field" }}
                />

                {/*__________________________Owner____________________________ */}
                <Controller
                  name="Owner"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.Owner}
                      margin="dense"
                      mb="20px"
                      id="Owner"
                      label="Owner"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.Owner?.message}
                      {...rest}
                    />
                  )}
                  rules={{ required: "This is required field" }}
                />
                {/*__________________________Care Taker____________________________ */}
                <Controller
                  name="Care_taker"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.Care_taker}
                      margin="dense"
                      mb="20px"
                      id="Care_taker"
                      label="Care Taker"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.Care_taker?.message}
                      {...rest}
                    />
                  )}
                  rules={{ required: "This is required field" }}
                />
                {/*___________________________Owner_contact__________________________ */}
                <Controller
                  name="Owner_contact"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.Owner_contact}
                      margin="dense"
                      mb="20px"
                      id="Owner_contact"
                      label="Owner Contact"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.Owner_contact?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit contact number",
                    },
                  }}
                />
                {/*___________________________CareTaker_contact__________________________ */}
                <Controller
                  name="CareTaker_contact"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.CareTaker_contact}
                      margin="dense"
                      mb="20px"
                      id="CareTaker_contact"
                      label="CareTaker Contact"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.CareTaker_contact?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit contact number",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {/*____________________________No Of Floors__________________________ */}
                <Controller
                  name="No_of_floor"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.No_of_floor}
                      margin="dense"
                      mb="20px"
                      id="No_of_floor"
                      label="Number Of Floors"
                      type="number"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.No_of_floor?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    min: { value: 0, message: "Value cannot be less than 0" },
                    max: {
                      value: 20,
                      message: "Value cannot be greater than 20",
                    },
                  }}
                />
                {/*_____________________________Unit_no_________________________ */}
                <Controller
                  name="Unit_no"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.Unit_no}
                      margin="dense"
                      mb="20px"
                      id="Unit_no"
                      label="Number of Unit"
                      type="number"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.Unit_no?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    min: { value: 0, message: "Value cannot be less than 0" },
                    max: {
                      value: 100,
                      message: "Value cannot be greater than 100",
                    },
                  }}
                />
                {/*____________________________Address__________________________ */}
                <Controller
                  name="Address"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.Address}
                      margin="dense"
                      mb="20px"
                      id="Address"
                      label="Address"
                      type="number"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.Address?.message}
                      multiline
                      {...rest}
                    />
                  )}
                  rules={{ required: "This is required field" }}
                />
                {/*____________________________Map Location__________________________ */}
                <Controller
                  name="location"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.location}
                      margin="dense"
                      mb="20px"
                      id="location"
                      label="Location"
                      type="number"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.location?.message}
                      multiline
                      {...rest}
                    />
                  )}
                  rules={{ required: "This is required field" }}
                />
              </Grid>
            </Grid>

            <ButtonGroup className="form__button-toolbar">
              <Button color="primary" type="submit" disabled={disabled}>
                Submit
              </Button>
              <Button
                type="button"
                onClick={() => {
                  closePopup();
                  setIsAboveError(false);
                  reset({
                    Address: "",
                    Building_name: "",
                    location: "",
                    No_of_floor: 0,
                    Owner: "",
                    Unit_no: 0,
                    Care_taker: "",
                    Owner_contact: "",
                    CareTaker_contact: "",
                  });
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddBuilding;
