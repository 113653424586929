import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { CustomFormField } from "../../shared/components/form/CustomFormField";
import AddCircleIcon from "mdi-react/AddCircleIcon";
import Moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import AttachmentIcon from "mdi-react/AttachmentIcon";
import { Create, Filter } from "@mui/icons-material";
import { Link } from "react-router-dom";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
// import LedgerForm from './LedgerForm';

import { workStatusList } from "@/shared/helpers/constants";
import { ChakraProvider, Input } from "@chakra-ui/react";
import * as chakra from "@chakra-ui/react";
import {
  getWorkOrdersApi,
  saveWorkOrderApi,
} from "../../redux/actions/workOrderAction";

import {
  fetchProjects,
  getCategories,
  fetchPersons,
} from "../../redux/actions/ledgerAction";
import Select from "react-select";
// import WorkOrderForm from './WorkOrderForm';
import { date } from "yup/lib/locale";
import { getProjectFile } from "../../redux/actions/projectsAction";
import { debounce } from "lodash";
import "./workOrder.css";

const wsList = [{ label: "Please Select", value: -1 }, ...workStatusList];
const ViewWorkOrders = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [pleaseWait, setPleaseWait] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [txns, setTxns] = useState({
    m_amount: 0,
    m_balance_amount: 0,
    m_total_paid: 0,
    a_amount: 0,
    a_balance_amount: 0,
    a_total_paid: 0,
  });
  const [filterData, setFilterData] = useState({
    pid: 0,
    comments: "",
    date: "",
    category: "",
    person: "",
    status: "",
  });
  const [selectedProject, setSelectedProject] = useState({});
  const [alertMsg, setAlertMsg] = useState({
    msg: "",
    color: "success",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [initialValues, setInitialValues] = useState({
    project_title: "",
    category: "",
    assigned_to: "",
    start_date: new Date(),
    end_date: new Date(),
    revision: 1,
    description: "",
    comments: "",
    status: 0,
    cost: 0,
  });

  const toggleModal = (flag) => {
    setShowForm(flag);
  };

  const loadOptions = async () => {
    const response = fetchProjects().then((res) => {
      let data = [];
      let arr = res.data;
      for (let idx in arr) {
        data.push({
          value: arr[idx].id,
          label: arr[idx].project_title,
        });
      }
      setProjectsList(data);
    });
  };
  const OnChangeProject = (val) => {
    console.log(val, 118118);
    if (!val) {
      return;
    }
    setValue("project_title", val);
    setSelectedProject(val);
    setSelectedProject(val);
    clearFilters();
  };

  const getWorkOrders = () => {
    if (Object.keys(selectedProject).length)
      getWorkOrdersApi(selectedProject, filterData).then((res) => {
        if (res.status) {
          let data = res.data;
          for (let idx in data) {
            console.log(data[idx]);
            data[idx].start_date = Moment(data[idx].start_date).format(
              "DD-MM-yyyy"
            );
            data[idx].end_date = Moment(data[idx].end_date).format(
              "DD-MM-yyyy"
            );
          }
          setRows(data);
          setTxns(res.txns);
        } else {
          setRows([]);
        }
      });
  };

  const onChangeFilters = (evt) => {
    let val = evt.target.value.toLocaleLowerCase();
    let key = evt.target.name;
    setFilterData({ ...filterData, [key]: val });
  };

  const getFiles = async (filesList) => {
    let fileArray = [];

    for (let idx in filesList) {
      filesList[idx] = JSON.parse(filesList[idx]);
      let fileName = filesList[idx].location.split("/");
      fileName = fileName[fileName.length - 1];
      const files = await getProjectFile(filesList[idx].location, true);
      let blob = window.URL.createObjectURL(files);
      let file = new File([blob], `${fileName}`, {
        preview: blob,
        type: files.type,
      });
      file.preview = blob;
      file.path = blob;
      file.fromsrv = filesList[idx];
      fileArray.push(file);
    }
  };

  const clearFilters = () => {
    setFilterData({
      comments: "",
      date: "",
      person: "",
      status: -1,
      category: "",
    });
  };

  const getWorkStatus = (val) => {
    let text = workStatusList.filter((o) => o.value === val);
    if (text.length) {
      return text[0].label;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getWorkOrders();
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedProject, filterData]);

  useEffect(() => {
    loadOptions();
  }, []);
  console.log(filterData);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }
  return (
    <>
      <Card>
        <CardBody className="wizard">
          <Container className="dashboard workOrder">
            {alertMsg.msg && (
              <Alert color={alertMsg.color} isOpen={!!alertMsg.msg}>
                {alertMsg.msg}
              </Alert>
            )}
            <Row>
              <Col md={12}>
                <h3
                  className="page-title"
                  style={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    letterSpacing: "8px",
                    wordSpacing: "15px",
                  }}
                >
                  Work Orders
                </h3>
              </Col>
            </Row>
            <Row>
              <Row>
                {/* <Col>
                  <h4>Project Title</h4>
                </Col> */}
                <Col className="colFlex">
                  <CustomFormField
                    name="project_title"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <Select
                        // value={value}
                        {...field}
                        onChange={OnChangeProject}
                        options={projectsList}
                        menuPortalTarget={document.body}
                        clearable={false}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={"Select Project"}
                      />
                    )}
                  />
                </Col>
                <Col style={{ width: "100px" }}>
                  {binaryconverter(
                    loginState.userDetails.access_control
                  ).charAt(9) == 1 && (
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        navigate(`/app/${role}/wo-create`);
                      }}
                    >
                      Create WO
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    style={{
                      paddingLeft: "20px",
                      fontWeight: "600",
                      color: "gray",
                    }}
                  >
                    M: <span> </span>T: {txns.m_amount}, B:{" "}
                    {txns.m_balance_amount}, P: {txns.m_total_paid}
                  </div>
                  <div
                    style={{
                      paddingLeft: "20px",
                      fontWeight: "600",
                      color: "gray",
                    }}
                  >
                    AW: T: {txns.a_amount}, B: {txns.a_balance_amount}, P:{" "}
                    {txns.a_total_paid}
                  </div>
                </Col>
                <Col style={{ textAlign: "end" }}>
                  <Button type="button" color="primary" onClick={clearFilters}>
                    <Filter /> Clear Filters
                  </Button>
                </Col>
              </Row>
              <ChakraProvider>
                <form className="form form--horizontal workOrder">
                  <div className="table react-table">
                    <div className="table react-table table--not-pagination">
                      <table className="react-table editable-table">
                        <thead style={{ textAlign: "center" }}>
                          <tr style={{ letterSpacing: "2px" }}>
                            <th style={{ minWidth: "50px" }}>WID</th>

                            <th width={100} style={{ minWidth: "70px" }}>
                              {/* Category */}
                              <Input
                                type="text"
                                value={filterData.category}
                                name="category"
                                onChange={onChangeFilters}
                                placeholder="Category"
                              />
                            </th>

                            <th width={110} style={{ minWidth: "70px" }}>
                              {/* Assigned to */}
                              <Input
                                type="text"
                                value={filterData.person}
                                name="person"
                                onChange={onChangeFilters}
                                placeholder="Assigned to"
                              />
                            </th>
                            <th width={300}>Amount</th>
                            <th width={175} style={{ minWidth: "100px" }}>
                              Date
                              {/* <input type="text" name="date" value={filterData.date} onChange={onChangeFilters} /> */}
                            </th>
                            <th width={175} style={{ minWidth: "70px" }}>
                              {/* Comments */}
                              <Input
                                type="text"
                                name="comments"
                                value={filterData.comments}
                                onChange={onChangeFilters}
                                placeholder="Comments"
                              />
                            </th>

                            <th
                              style={{
                                minWidth: "100px",
                                padding: "0px 0px 19px 5px",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  fontSize: ".71rem",
                                  top: "9px",
                                  right: "15px",
                                  background: "#025056",
                                  display: "inline",
                                  zIndex: 2,
                                }}
                              >
                                Status
                              </div>
                              <chakra.Select
                                name="status"
                                value={filterData.status}
                                onChange={onChangeFilters}
                                style={{
                                  paddingRight: "29px",
                                }}
                                // sx={{mb:"19px"}}
                              >
                                {wsList.map((wo, idx) => {
                                  return (
                                    <option
                                      key={idx}
                                      value={wo.value}
                                      style={{ color: "#000" }}
                                    >
                                      {wo.label}
                                    </option>
                                  );
                                })}
                              </chakra.Select>
                            </th>

                            <th style={{ minWidth: "100px" }}>Created By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.length ? (
                            <>
                              {rows.map((data, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>
                                        <span
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {binaryconverter(
                                            loginState.userDetails
                                              .access_control
                                          ).charAt(10) == 1 && (
                                            <NavLink
                                              to={`/app/${role}/wo-detailed-view/${JSON.stringify(
                                                selectedProject
                                              )}/${data.id}`}
                                            >
                                              {" "}
                                              {data.id}
                                            </NavLink>
                                          )}
                                          {/* {(loginState.userDetails.role_id ===
                                            1 && binaryconverter(loginState.userDetails.access_control).charAt(10) == 1) ||
                                            (loginState.userDetails.role_id ===
                                              8 && binaryconverter(loginState.userDetails.access_control).charAt(10) == 1) ? (
                                            <NavLink
                                              to={`/ app / admin / wo - detailed - view / ${
                          JSON.stringify(
                            selectedProject
                          )
                        } / ${ data.id }`}
                                            >
                                              {" "}
                                              {data.id}
                                            </NavLink>
                                          ) : (loginState.userDetails.role_id ===
                                            2 && binaryconverter(loginState.userDetails.access_control).charAt(10) == 1) ? (
                                            <NavLink
                                              to={`/ app / pm / wo - detailed - view / ${
                          JSON.stringify(
                            selectedProject
                          )
                        } / ${ data.id }`}
                                            >
                                              {" "}
                                              {data.id}
                                            </NavLink>
                                          ) : (
                                            data.id
                                          )} */}
                                        </span>
                                      </td>
                                      <td>{data.category_name}</td>

                                      <td>
                                        {data.assigned_to_firstname}{" "}
                                        {data.assigned_to_lastname}
                                      </td>
                                      <td>
                                        <table>
                                          <tbody style={{ padding: "8px" }}>
                                            <tr
                                              style={{
                                                display: "inline-block",
                                                width: "251px",
                                                lineHeight: "10px",
                                                height: "35px",
                                              }}
                                            >
                                              <td
                                                style={{
                                                  display: "inline",
                                                }}
                                              >
                                                M:
                                              </td>
                                              <td
                                                style={{
                                                  display: "inline",
                                                }}
                                              >
                                                T: {data.ms_total_amt}, B:{" "}
                                                {data.ms_total_balance_amount},
                                                P: {data.ms_total_total_paid}
                                              </td>
                                            </tr>
                                            <tr
                                              style={{
                                                display: "inline-block",
                                                width: "251px",
                                                lineHeight: "10px",
                                                height: "30px",
                                              }}
                                            >
                                              <td
                                                style={{
                                                  display: "inline",
                                                }}
                                              >
                                                AW:
                                              </td>
                                              <td
                                                style={{
                                                  display: "inline",
                                                }}
                                              >
                                                T: {data.a_total_amt}, B:{" "}
                                                {data.a_total_balance_amount},
                                                P: {data.a_total_total_paid}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        {data.milestones?.length ? (
                                          <Container>
                                            <Row>Start: {data.start_date}</Row>
                                            <Row>End: {data.end_date}</Row>
                                          </Container>
                                        ) : (
                                          "Yet to add Milestones"
                                        )}
                                      </td>
                                      <td>{data.comments}</td>
                                      <td>{getWorkStatus(data.work_status)}</td>

                                      <td>
                                        {" "}
                                        <div>
                                          {data.created_by_firstname}{" "}
                                          {data.created_by_lastname}
                                        </div>
                                        <div>
                                          {" "}
                                          {Moment(
                                            data.created_timestamp
                                          ).format("yyyy-MM-DD")}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan={10}> No entries found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </ChakraProvider>
            </Row>

            {pleaseWait && (
              <div class="pleasewait-load">
                <div class="load__icon-wrap">
                  <svg class="load__icon">
                    <path
                      fill="#4ce1b6"
                      d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                    />
                  </svg>
                </div>
              </div>
            )}
          </Container>
        </CardBody>
      </Card>

      <Modal
        isOpen={showForm}
        toggle={() => toggleModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleModal(false)}>
          <strong>Create Work Order</strong>
        </ModalHeader>
        <ModalBody>
          {/* <WorkOrderForm
            selectedProject={selectedProject}
            closePopup={toggleModal}
            onSubmit={onSubmit}
            initialValues={initialValues}
            projectsList={projectsList}
          /> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewWorkOrders;
