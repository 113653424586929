import React, { Fragment, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Outlet } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.css";
import "./../../scss/app.scss";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import storeConfiguration from "./store";
import { ChakraProvider } from "@chakra-ui/react";
import "./app.css";

const App = () => {
  const { store, persistor } = storeConfiguration();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener("load", () => {
      setIsLoading(false);
      setTimeout(() => setIsLoaded(true), 500);
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              <div>
                <Router />
              </div>
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
