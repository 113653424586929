import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
// import logo from "./a1.png";
import style from "./Header.module.css";
import { ChevronDownIcon } from "@chakra-ui/icons";
const Header = ({ onOpen }) => {
  const hambergerRef = useRef();
  const moreRref = useRef();
  const IconHambergerRef = useRef();
  const [width, setWidth] = useState(window.innerWidth);

  const getButton = function () {
    var options = {
      whatsapp: "+916360759762 ", // WhatsApp number
      call_to_action: "Message us", // Call to action
      position: "right", // Position may be 'right' or 'left'
    };
    var proto = document.location.protocol,
      host = "getbutton.io",
      url = proto + "//static." + host;
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = url + "/widget-send-button/js/init.js";
    s.onload = function () {
      WhWidgetSendButton.init(host, proto, options);
    };

    var x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    ToggleHamberger(1);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    getButton();
    document.addEventListener("click", OnClickOutside, true);
    if (window.innerWidth < 900) {
      hambergerRef.current.style.opacity = 0;
      hambergerRef.current.style.height = 0;
      hambergerRef.current.style.display = "none";
    }
  }, []);
  const OnClickOutside = (e) => {
    if (
      !hambergerRef.current.contains(e.target) &&
      !IconHambergerRef.current.contains(e.target) &&
      window.innerWidth < 900
    ) {
      ToggleHamberger(2);
    }
  };

  const ToggleHamberger = (el = 0) => {
    // console.log(hambergerRef.current.style);
    if ((hambergerRef.current.style.opacity == 0 || el == 1) && el !== 2) {
      setTimeout(() => {
        hambergerRef.current.style.display = "flex";
      }, 100);
      hambergerRef.current.style.opacity = 1;
      hambergerRef.current.style.height = "unset";
    } else {
      hambergerRef.current.style.opacity = 0;
      hambergerRef.current.style.height = 0;
      setTimeout(() => {
        hambergerRef.current.style.display = "none";
      }, 400);
    }
  };

  const ToggleMore = () => {
    // console.log(hambergerRef.current.style);
    if (moreRref.current.style.opacity == 0) {
      setTimeout(() => {
        moreRref.current.style.display = "block";
      }, 100);
      moreRref.current.style.opacity = 1;
      moreRref.current.style.height = "unset";
    } else {
      moreRref.current.style.opacity = 0;
      moreRref.current.style.height = 0;
      setTimeout(() => {
        moreRref.current.style.display = "none";
      }, 400);
    }
  };

  return (
    <Box>
      <header id={style.main_header} data-height-onload="60">
        <Box className={style.container}>
          <Box className={style.logo_container}>
            <a href="https://housingfactory.in/">
              <img
                // src={logo}
                src="https://housingfactory.s3.ap-south-1.amazonaws.com/attendanceFile/2022/b537ae0a-4444-48e6-b622-98733aafee96.png"
                // alt="Top House Construction Company in Bangalore | House construction, Building construction, Commercial construction and Construction Services in your Budget"
                id="logo"
                data-height-percentage="100"
              />
            </a>
          </Box>
          {/* ___________________Nav Bar______________________ */}
          <Box
            className={style.top_navigation}
            data-height="60"
            data-fixed-height="60"
          >
            <Box ref={hambergerRef} className={style.nev_toggle}>
              <nav id={style.top_menu_nav}>
                <ul id={style.top_menu} className={style.nav}>
                  <li
                    id={style.menu_item_4908}
                    className={style.menu_item_4908}
                  >
                    <a href="https://housingfactory.in/how-we-work/">
                      How it Works
                    </a>
                  </li>
                  <li id={style.menu_item_2421} className={style.item_2421}>
                    <a href="https://housingfactory.in/our-packages/">
                      Our Packages
                    </a>
                  </li>
                  <li id="menu-item-6124">
                    <a
                      href="https://housingfactory.in/app/hf/projects"
                      aria-current="page"
                      style={{ color: "#ff3500" }}
                    >
                      Our Projects
                    </a>
                  </li>
                  <li id="menu-item-4942" className={style.menu_item_4942}>
                    {/* <a href="#" onClick={ToggleMore}>
                      More
                    </a> */}
                    {/* <ul className={style.sub_menu} ref={moreRref}>
                      <li id="menu-item-2495" className={style.menu_item_2495}>
                        <a href="https://housingfactory.in/frequently-asked-questions/">
                          FAQs
                        </a>
                      </li>
                      <li id="menu-item-1528" className={style.menu_item_1528}>
                        <a href="https://housingfactory.in/stories/">Stories</a>
                      </li>
                      <li id="menu-item-5339" className={style.menu_item_5339}>
                        <a href="https://housingfactory.in/about-us/">
                          About-Us
                        </a>
                      </li>
                      <li id="menu-item-5353" className={style.menu_item_5353}>
                        <a href="https://housingfactory.in/contact-housing-factory/">
                          Contact Us
                        </a>
                      </li>
                    </ul> */}
                    <Menu className={style.sub_menu_desktop}>
                      <MenuButton
                        className={style.sub_menu_desktop_btn}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        More
                      </MenuButton>
                      <MenuList>
                        <MenuItem color="red">
                          <a href="https://housingfactory.in/frequently-asked-questions/">
                            FAQs
                          </a>
                        </MenuItem>
                        <MenuItem color="red">
                          <a href="https://housingfactory.in/stories/">
                            Stories
                          </a>
                        </MenuItem>
                        <MenuItem color="red">
                          <a href="https://housingfactory.in/about-us/">
                            About-Us
                          </a>
                        </MenuItem>
                        <MenuItem color="red">
                          <a href="https://housingfactory.in/contact-housing-factory/">
                            Contact Us
                          </a>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </li>
                  <li
                    id="menu-item-6196"
                    className={style.GetOuote}
                    onClick={onOpen}
                  >
                    Get Free Quote
                  </li>
                </ul>
              </nav>
            </Box>
          </Box>
          {/* <!--------------- #et-top-navigation---------------------- --> */}
          <Box
            id={style.mobile_nav_menu}
            onClick={ToggleHamberger}
            ref={IconHambergerRef}
          >
            <Box className={style.mobile_nav}>
              <FontAwesomeIcon icon={faBars} />
              {/* <span className={style.mobile_menu_bar_toggle}></span> */}
            </Box>
          </Box>
        </Box>
      </header>
    </Box>
  );
};

export default Header;
