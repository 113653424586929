"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isText = void 0;
// Is the element rendered as a "Text" tag
exports.isText = {
    html: false,
    body: false,
    h1: false,
    h2: false,
    h3: false,
    h4: false,
    h5: false,
    h6: false,
    div: false,
    p: false,
    blockquote: false,
    article: false,
    caption: false,
    form: false,
    hr: false,
    address: false,
    aside: false,
    pre: false,
    fieldset: false,
    header: false,
    footer: false,
    section: false,
    span: true,
    br: true,
    button: true,
    label: true,
    b: true,
    strong: true,
    q: true,
    i: true,
    em: true,
    u: true,
    s: true,
    cite: true,
    dfn: true,
    code: true,
    abbr: true,
    sup: true,
    sub: true,
    a: false,
    img: false,
    table: false,
    tr: false,
    td: false,
    th: false,
    thead: false,
    tbody: false,
    ul: false,
    ol: false,
    li: false,
    dd: false,
    dl: false,
    dt: false,
};
