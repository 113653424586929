import React, { useContext, useState } from "react";
import GoogleMapReact from "google-map-react";
import PlaceIcon from "@mui/icons-material/Place";
import { Badge, Typography, styled, ClickAwayListener } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const PlaceDetails = (props) => {
  return (
    <>
      <Typography color="inherit">{props.title}</Typography>
      {(props.data.type === "lead" || props.data.type === "mixed") && (
        <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Budget</TableCell>
                <TableCell>Start Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.payload.map(
                (lead) =>
                  lead.type === "lead" && (
                    <TableRow
                      key={lead.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Link
                          to={`${props.pathname}/update/${lead.id}`}
                          target="_blank"
                        >
                          {lead.id}
                        </Link>
                      </TableCell>
                      <TableCell>{lead.name}</TableCell>
                      <TableCell>{lead.budget}</TableCell>
                      <TableCell>{lead.startDate}</TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {(props.data.type === "project" || props.data.type === "mixed") && (
        <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Project Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.payload.map(
                (project) =>
                  project.type === "project" && (
                    <TableRow
                      key={project.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{project.id}</TableCell>
                      <TableCell>{project.project_title}</TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const HFTooltip = styled(({ className, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        {...props}
        classes={{ popper: className }}
        onClose={handleTooltipClose}
        onClick={handleTooltipOpen}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      />
    </ClickAwayListener>
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: "#f5f5f9",
    // color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const HFMap = ({ data, pathname }) => {
  console.log("map-data", data);
  const [mapData, setMapData] = useState([]);

  const defaultProps = {
    apiKey: { key: "AIzaSyB6xEjzV9o4ukyimy9KLMeTmhXXsWBmAZQ" },
    center: { lat: 12.9716, lng: 77.5946 },
    zoom: 11,
  };

  useEffect(() => {
    let tempData = [];
    for (let i = 0; i < data.length; i++) {
      data[i].payload.type=data[i].type;
      if (tempData[data[i].location]) {
        if (tempData[data[i].location].type !== data[i].type) {
          tempData[data[i].location].type = "mixed";
          tempData[data[i].location].color = "success";
        }
        tempData[data[i].location].payload.push(data[i].payload);
      } else {
        tempData[data[i].location] = {...data[i],['payload']:[data[i].payload]};
      }
    }
    console.log('tempdata',tempData);
    setMapData(Object.values(tempData));
  }, [data]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "80vh", maxWidth: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={defaultProps.key}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {mapData &&
          mapData.map(
            (item, index) =>
              item.lat &&
              item.lng && (
                <HFTooltip
                  title={
                    <PlaceDetails
                      data={item}
                      title={item.location}
                      pathname={pathname}
                    />
                  }
                  key={`mark-${index}`}
                  lat={item.lat}
                  lng={item.lng}
                  arrow
                >
                  <Badge badgeContent={item.payload.length} color={item.color}>
                    {/* <PlaceIcon
                      fontSize="large"
                      color={item.leads.length > 0 ? "primary" : "success"}
                    /> */}
                  </Badge>
                </HFTooltip>
              )
          )}
      </GoogleMapReact>
    </div>
  );
};

export default HFMap;
