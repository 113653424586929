import { Table, TableBody, TableCell, TableHead } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPackageDetails } from "../../../redux/actions/quotation";

const Test = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getPackageDetails().then((res) => {
      console.log(res, "packages");
      setPackages([...res?.requirements?.rows]);
    });
  }, []);

  return (
    <>
      <div>
        {packages?.map((el, ind) => {
          return (
            <Table>
              <TableHead>
                {Object.keys(el).map((elind) => (
                  <TableCell>{elind}</TableCell>
                ))}
              </TableHead>
              <TableBody>
                {Object.values(el).map((elval) => {
                  if (typeof elval === "string")
                    return (
                      <TableCell>
                        {elval?.split("/").map((val, valind) => {
                          return <tr>{`(${valind + 1}) : ${val}`}</tr>;
                        })}
                      </TableCell>
                    );
                  else return <TableCell>{elval}</TableCell>;
                })}
              </TableBody>
            </Table>
          );
        })}
      </div>
    </>
  );
};

export default Test;
