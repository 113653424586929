import React, { useState, useEffect, useRef } from "react";
// import { Button, ButtonToolbar, Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { SelectField } from "@/shared/components/form/Select";
import {
  emailPatter,
  firstNameLastNamePattern,
  mobileNumberPattern,
} from "@/shared/helpers";
import showResults from "../../utils/showResults";
import PasswordField from "@/shared/components/form/Password";
import { FormField } from "./FormField";
import { addUser } from "../../redux/actions/loginActions";
import { Button, ButtonGroup, Card, CardContent, Grid } from "@mui/material";

const AddUser = ({
  isHorizontal,
  closePopup,
  initialValues,
  displayMsgAndSubmitForm,
}) => {
  const [isAboveError, setIsAboveError] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: initialValues });
  const [userRole, setUserRole] = useState(4);
  const files = useRef();

  const onSubmit = (data) => {
    let dat = new FormData();
    var userFiles = files.current.files;

    for (let idx in userFiles) {
      if (typeof userFiles[idx] == "object")
        dat.append("files", userFiles[idx]);
    }

    dat.append("data", JSON.stringify(data));

    addUser(dat).then((data) => {
      displayMsgAndSubmitForm(data);
      reset({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
    });
  };

  const onchangeuserRole = (evt) => {
    setUserRole(evt.target.value);
    setValue("userRole", parseInt(evt.target.value));
  };

  useEffect(() => {
    setValue("firstName", initialValues.first);
    setValue("lastName", initialValues.last);
    setValue("userRole", initialValues.user_role);
    setValue("email", initialValues.email);
    setValue("phoneNumber", initialValues.contact_num);
    setValue("user_id", initialValues.id);
    setUserRole(initialValues.user_role);
  }, [initialValues]);

  return (
    <Grid container spacing={2}>
      <Card>
        <CardContent>
          <form
            className={`form ${isHorizontal && "form--horizontal"}`}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <input type="hidden" name="user_id" value="" />
            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">
                Firstname
              </span>
              <div className="form__form-group-field">
                <FormField
                  name="firstName"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  maxLength="20"
                  errors={errors}
                  rules={{
                    required: "This is required field",
                    pattern: {
                      value: firstNameLastNamePattern,
                      message: "Firstname must contain letters only",
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Firstname"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">
                Lastname
              </span>
              <div className="form__form-group-field">
                <FormField
                  name="lastName"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  errors={errors}
                  maxLength="20"
                  rules={{
                    required: "This is required field",
                    pattern: {
                      value: firstNameLastNamePattern,
                      message: "Lastname must contain letters only",
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Lastname"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">
                {" "}
                E-mail
              </span>
              <div className="form__form-group-field">
                <FormField
                  name="email"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{
                    required: "This is required field",
                    pattern: {
                      value: emailPatter,
                      message: "Entered value does not match email format",
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="example@gmail.com"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">
                Mobile Number
              </span>
              <div className="form__form-group-field">
                <FormField
                  name="phoneNumber"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{
                    required: "This is required field",
                    pattern: {
                      value: mobileNumberPattern,
                      message: "Please enter a valid contact number",
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Mobile number"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">
                User Role
              </span>
              <div className="form__form-group-field">
                <div className="cursor__pointer">
                  <select
                    name="userRole"
                    value={userRole}
                    onChange={onchangeuserRole}
                  >
                    <option value={1}>Admin</option>
                    <option value={2}>Project Manager</option>
                    <option value={3}>Site Engineer</option>
                    <option value={4}>Customer</option>
                    <option value={5}>Service Provider</option>
                    <option value={6}>Vendor</option>
                    <option value={7}>User</option>
                    <option value={8}>Super Admin</option>
                    <option value={9}>Sales Executive</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">
                Upload Attachments
              </span>
              <div className="form__form-group-field">
                <input type="file" name="files" ref={files} multiple />
              </div>
            </div>

            <div className="form__form-group">
              <ButtonGroup className="form__button-toolbar">
                <Button color="primary" type="submit">
                  Submit
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    closePopup();
                    setIsAboveError(false);
                    reset({
                      firstName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      password: "",
                      confirmPassword: "",
                    });
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddUser;
