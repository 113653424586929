import React, { useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";

import {
  Container,
  Row,
  Card,
  CardBody,
  ButtonToolbar,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormField } from "./FormField";
import { emailPatter } from "../../shared/helpers/index";
import * as yup from "yup";
import moment from "moment";
import Select from "react-select";
import { saveVendorInfo, UpdateVendorInfo } from "../../redux/actions/poAction";
import { DataArray } from "@mui/icons-material";
let emailRegex = new RegExp(emailPatter);

const schema = yup.object().shape({
  vendor_name: yup.string().required("This field is required"),
  vendor_email: yup
    .string()
    .nullable()
    .test("isvalidEmail", "Plesae enter valid email", (value) => {
      if (value) {
        if (emailRegex.test(value)) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }),
  vendor_contact_no: yup
    .string()
    .test("isvalidContactNo", "Please enter valid contact number", (value) => {
      if (value) {
        if (isNaN(value)) return false;
        return true;
      } else {
        return true;
      }
    }),
  vendor_alternate_contact_no: yup
    .string()
    .test(
      "isvalidContactNo",
      "Please enter valid alternate contact number",
      (value) => {
        if (value) {
          if (isNaN(value)) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  vendor_address: yup.string().min(8, "Please Enter valid address").required(),
});

const UpdateVendor = ({
  toggleModal,
  onSubmit,
  categoryList,
  onSaveVendorDetails,
  initialValues,
}) => {
  const [pleaseWait, setPleasewait] = useState(false);
  const loginState = useSelector((state) => state.login);
  console.log(initialValues);
  const panCardFiles = useRef();
  const aadharCardFiles = useRef();
  const acctDetailsFiles = useRef();
  const acctDetails2 = useRef();
  const upiFiles = useRef();
  const shopFiles = useRef();

  let initialValues1 = {
    // ...initialValues,
    created_date: moment(initialValues.created_date).format("DD-MM-YYYY"),
    contact_no: initialValues.contact_no,
    vendor_address: initialValues.address,
    upi_no: initialValues.upi_phone_no,
    vendor_email: initialValues.email,
    vendor_alternate_contact_no: initialValues.alternate_contact_no,
    vendor_contact_no: initialValues.contact_no,
    vendor_name: initialValues.vendor_name,
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: initialValues1,
    resolver: yupResolver(schema),
  });

  const submitVendorDetails = (data) => {
    console.log(data, "data for Updates");
    const data1 = {
      ...initialValues,
      age_of_shop: data.shop_age,
      deliver_to_site: data.deliver_to_site,
      id: initialValues.id,
      address: data.vendor_address,
      shop_name: initialValues.shop_name,
      shop_location: data.shop_location,
      alternate_contact_no: data.vendor_alternate_contact_no,
      contact_no: data.vendor_contact_no,
      created_date: data.created_date,
      email: data.vendor_email,
      google_location: data.google_location,
      shop_name: data.shop_name,
      upi_phone_no: data.upi_no,
      vendor_gst: data.vendor_gst,
      vendor_name: data.vendor_name,
    };
    UpdateVendorInfo(data1).then((res) => {
      console.log("changes done");
      onSaveVendorDetails();
    });
  };

  return (
    <Card>
      <CardBody className="wizard">
        <Container className="dashboard create-project">
          <Row>
            <form
              className="form"
              onSubmit={handleSubmit(submitVendorDetails)}
              autoComplete="off"
            >
              <div className="form__half">
                <span className="form__form-group-label">Created Date</span>
                <div className="form__form-group-field">
                  <FormField
                    name="created_date"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue={moment(new Date()).format("DD/MM/YYYY")}
                    placeholder="Date of Creation"
                    disabled
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Onboarded By</span>
                <div className="form__form-group-field">
                  <FormField
                    name="onboarded_by"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue={`${loginState.userDetails.first_name} ${loginState.userDetails.first_name}`}
                    disabled
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Shop Name</span>
                <div className="form__form-group-field">
                  <FormField
                    name="shop_name"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="Shop Name"
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Shop Sells</span>
                <div className="form__form-group-field">
                  <FormField
                    name="category"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <Select
                        {...field}
                        defaultValue={[]}
                        isMulti
                        name="category"
                        options={categoryList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Vendor Name</span>
                <div className="form__form-group-field">
                  <FormField
                    name="vendor_name"
                    maxLength={50}
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="Name of the vendor"
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Shop Address</span>
                <div className="form__form-group-field">
                  <FormField
                    name="vendor_address"
                    control={control}
                    component="textarea"
                    errors={errors}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Contact No</span>
                <div className="form__form-group-field">
                  <FormField
                    name="vendor_contact_no"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="Contact No"
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">
                  Alternate Contact No
                </span>
                <div className="form__form-group-field">
                  <FormField
                    name="vendor_alternate_contact_no"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="Contact No"
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">
                  Age of the shop (in years)
                </span>
                <div className="form__form-group-field">
                  <FormField
                    name="shop_age"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="Contact No"
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Deliver to Site</span>
                <div className="form__form-group-field">
                  <FormField
                    name={`deliver_to_site`}
                    type="checkbox"
                    control={control}
                    // checked={item.isGST}
                    className={"checkbox"}
                    // disabled={item.disabled}
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Vendor E-mail</span>
                <div className="form__form-group-field">
                  <FormField
                    name="vendor_email"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Vendor gst No</span>
                <div className="form__form-group-field">
                  <FormField
                    name="vendor_gst"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="GST of the Vendor"
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">PAN No.</span>
                <div className="form__form-group-field">
                  <input type="file" name="files" ref={panCardFiles} multiple />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">Aadhar Card</span>
                <div className="form__form-group-field">
                  <input
                    type="file"
                    name="files"
                    ref={aadharCardFiles}
                    multiple
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">
                  Account Details 1
                </span>
                <div className="form__form-group-field">
                  <input
                    type="file"
                    name="files"
                    ref={acctDetailsFiles}
                    multiple
                  />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">
                  Account Details 2
                </span>
                <div className="form__form-group-field">
                  <input type="file" name="files" ref={acctDetails2} multiple />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">UPI Scanner</span>
                <div className="form__form-group-field">
                  <input type="file" name="files" ref={upiFiles} multiple />
                </div>
              </div>
              <div className="form__half">
                <span className="form__form-group-label">UPI Phone No.</span>
                <div className="form__form-group-field">
                  <FormField
                    name="upi_no"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    placeholder="Contact No"
                  />
                </div>
              </div>

              <div className="form__half">
                <span className="form__form-group-label">
                  Shop/Shop keeper photo
                </span>
                <div className="form__form-group-field">
                  <input type="file" name="files" ref={shopFiles} multiple />
                </div>
              </div>

              <div className="form__half">
                <span className="form__form-group-label">Google Location</span>
                <div className="form__form-group-field">
                  <FormField
                    name="shop_location"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                  <Button color="primary" type="submit" className="next">
                    Submit
                  </Button>
                  <Button type="button" onClick={() => toggleModal(false)}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </div>
            </form>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default UpdateVendor;
