import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import Moment from "moment";

import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { CustomFormField } from "../../shared/components/form/CustomFormField";
import { useForm, useFormState } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import WoMilestones from "./WoMileStones";
import { FormField } from "./FormField";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import {
  fetchProjects,
  getCategories,
  fetchPersons,
} from "../../redux/actions/ledgerAction";
import { getBasicProjectInfo } from "../../redux/actions/projectsAction";

import {
  confirmSaveBasicWorkOrder,
  saveWorkOrderApi,
  getDetailedWorkOrderApi,
  updateBasicworkOrderApi,
  removeMilestone,
} from "../../redux/actions/workOrderAction";
import { AppContext } from "../../Context/appContext";

const schema = yup.object().shape({
  project_title: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.number(),
    })
    .required("This field is required")
    .nullable(),

  category: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.number(),
    })
    .required("This field is required")
    .nullable(),
});

const CreateWorkOrderForm = ({ setLoading }) => {
  const { wid } = useParams();
  const AppProvider = useContext(AppContext);
  const woFiles = useRef();

  const [categoryList, setCategoryList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [isDropdownDirty, setIsDropdownDirty] = useState({});
  const [initialValues, setInitialValues] = useState({
    project_title: "",
    category: "",
  });
  const [saveModalStatus, setSaveModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [isTableRowsTouched, setIsTableRowsTouched] = useState(false);
  const [snackbar, setSnackbar] = useState({
    status: false,
    type: "success",
    msg: "success",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const loadOptions = async () => {
    const response = fetchProjects().then((res) => {
      let data = [];
      let arr = res.data;
      for (let idx in arr) {
        data.push({
          value: arr[idx].id,
          label: arr[idx].project_title,
        });
      }
      setProjectsList(data);
      setLoading(false);
    });
  };

  const showHideWarningSaveModel = (status) => {
    setSaveModalStatus(status);
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, ["status"]: false });
  };

  const resetFields = () => {
    setIsDropdownDirty({});
    setValue("project_title", "");
  };

  const OnChangeProject = (val) => {
    setIsDropdownDirty({ ...isDropdownDirty, project_title: true });

    if (!val) {
      return;
    }
    setValue("project_title", val);

    getBasicProjectInfo(val).then((res) => {
      if (res.status) {
        setValue(
          "customerName",
          `${res.data.customer_firstname} ${res.data.customer_lastname}`
        );
        setValue(
          "siteEngineer",
          `${res.data.se_firstname} ${res.data.se_lastname}`
        );
        setValue(
          "projectManager",
          `${res.data.pm_firstname} ${res.data.pm_lastname}`
        );
      }
    });
  };

  useEffect(() => {
    let milestones = [];
    if (wid) {
      setIsDropdownDirty(true);
      getDetailedWorkOrderApi(wid).then((res) => {
        setValue(
          "customerName",
          `${res.data.customer_firstname} ${res.data.customer_lastname}`
        );
        setValue(
          "siteEngineer",
          `${res.data.se_firstname} ${res.data.se_lastname}`
        );
        setValue(
          "projectManager",
          `${res.data.pm_firstname} ${res.data.pm_lastname}`
        );
        setValue("comments", `${res.data.comments}`);

        setValue("project_title", {
          label: res.data.project_title,
          value: res.data.project_id,
        });
        setValue("category", {
          label: res.data.category_name,
          value: res.data.category_id,
        });
        let assignedToName = "";

        if (res.data.assigned_to_firstname) {
          assignedToName = `${res.data.assigned_to_firstname} ${res.data.assigned_to_lastname}`;
          setValue("assignedTo", {
            label: assignedToName,
            value: res.data.assigned_to,
          });
        }

        for (let xidx in res.milestones) {
          milestones.push({
            id: res.milestones[xidx].id,
            task: res.milestones[xidx].task,
            // start_date: Moment(res.milestones[xidx].start_date).format('DD-MM-yyyy'),
            // end_date: Moment(res.milestones[xidx].end_date).format('DD-MM-yyyy'),
            // pay_date: Moment(res.milestones[xidx].pay_date).format('DD-MM-yyyy'),

            start_date: res.milestones[xidx].start_date,
            end_date: res.milestones[xidx].end_date,
            pay_date: res.milestones[xidx].pay_date,
            percentage: res.milestones[xidx].percentage,
            workStatus: res.milestones[xidx].work_status,
            payStatus: res.milestones[xidx].paid_status,
            amount: res.milestones[xidx].amount,
            scope: res.milestones[xidx].scope,
            isEditable: false,
            isExist: true,
          });
        }
        setData(milestones);
      });
      // getDetailedWorkOrderApi(wid).then((res) => {
      //   if (res.status) {
      //     console.log(res.milestones);
      //   } else {
      //   }
      //   setData(milestones);
      // });
    }
  }, [wid]);

  useEffect(() => {
    setLoading(true);
    loadOptions();
    Promise.all([getCategories(), fetchPersons()]).then((res) => {
      if (res[0].status) {
        let arr = [];
        for (let idx in res[0].data) {
          arr.push({
            value: res[0].data[idx].id,
            label: res[0].data[idx].name,
          });
        }
        setCategoryList(arr);
      }

      if (res[1].status) {
        let arr = [];
        for (let idx in res[1].data) {
          arr.push({
            value: res[1].data[idx].id,
            label: `${res[1].data[idx].first_name} ${res[1].data[idx].last_name}`,
          });
        }
        setPersonList(arr);
      }
    });
  }, []);

  const onSubmit = (values) => {
    let dat = new FormData();
    var agreements = woFiles.current.files;

    for (let idx in agreements) {
      if (typeof agreements[idx] == "object")
        dat.append("agreements", agreements[idx]);
    }

    if (wid) {
      values["wid"] = wid;
      values = { ...values, milestones: data };

      dat.append("data", JSON.stringify(values));

      updateBasicworkOrderApi(dat).then((res) => {
        if (res.status) {
          // AppProvider.setAlertFunc({
          //   txt: res.msg,
          //   clr: "success",
          // });

          setSnackbar({
            status: true,
            msg: res.msg,
            type: "success",
          });

          setIsDropdownDirty(false);
          reset();
          resetFields();
          setIsTableRowsTouched(false);
          let dat = [...data];
          for (let idx in dat) {
            dat[idx].isEditable = false;
          }
          setData(dat);
          window.scrollTo(0, 0);
          return;
        }
        setSnackbar({
          status: true,
          msg: res.msg,
          type: "danger",
        });

        // onChangeAlertMsg({ txt: res.msg, clr: "danger" });
      });
      return;
    }

    confirmSaveBasicWorkOrder(JSON.stringify(values)).then((res) => {
      if (res.status) {
        if (res.data.length) {
          showHideWarningSaveModel(true);
        } else {
          saveWorkOrder();
        }
      }
    });
  };

  const saveWorkOrder = () => {
    let values = getValues();
    values = { ...values, milestones: data };

    let dat = new FormData();
    var agreements = woFiles.current.files;

    for (let idx in agreements) {
      if (typeof agreements[idx] == "object")
        dat.append("agreements", agreements[idx]);
    }

    dat.append("data", JSON.stringify(values));

    saveWorkOrderApi(dat).then((res) => {
      if (res.status) {
        setSnackbar({
          status: true,
          msg: res.msg,
          type: "success",
        });

        setIsDropdownDirty(false);
        reset();
        resetFields();
        setIsTableRowsTouched(false);
        let dat = [...data];
        for (let idx in dat) {
          dat[idx].isEditable = false;
        }
        setData(dat);
        window.scrollTo(0, 0);
        return;
      }
    });
    showHideWarningSaveModel(false);
  };

  return (
    <Col md={12} lg={12} className="workOrder">
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Basic Info</h5>
          </div>

          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Project Title</span>
                <div className="form__form-group-field">
                  <CustomFormField
                    name="project_title"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <Select
                        {...field}
                        options={projectsList}
                        menuPortalTarget={document.body}
                        clearable={false}
                        onChange={(e) => {
                          field.onChange();
                          OnChangeProject(e);
                        }}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={"Select Project"}
                      />
                    )}
                    rules={{
                      required: "This is required field",
                    }}
                  />
                </div>
                {errors?.project_title?.value?.message && (
                  <span className="form__form-group-error">
                    "This is required field
                  </span>
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Category</span>
                <div className="form__form-group-field">
                  <CustomFormField
                    name="category"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <Select
                        {...field}
                        options={categoryList}
                        clearable={false}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={"Select Category"}
                      />
                    )}
                  />
                </div>
                {errors?.category?.value?.message && (
                  <span className="form__form-group-error">
                    "This is required field
                  </span>
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Assigned to</span>
                <div className="form__form-group-field">
                  <CustomFormField
                    name="assignedTo"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <Select
                        {...field}
                        options={personList}
                        clearable={false}
                        className="react-select"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Customer Name</span>
                <div className="form__form-group-field">
                  <FormField
                    name="customerName"
                    control={control}
                    component="input"
                    errors={errors}
                    disabled
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Project Manager</span>
                <div className="form__form-group-field">
                  <CustomFormField
                    name="projectManager"
                    control={control}
                    component="input"
                    errors={errors}
                    disabled
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Site Engineer</span>
                <div className="form__form-group-field">
                  <CustomFormField
                    name="siteEngineer"
                    control={control}
                    component="input"
                    errors={errors}
                    defaultValue=""
                    disabled
                  />
                </div>
              </div>
            </div>
            <Container>
              <div className="form form--horizontal" style={{ width: "100%" }}>
                {/* <CustomFormField
                  type="file"
                  name="woFiles"
                  ref={woFiles}
                  control={control}
                  component="input"
                  errors={errors}
                  defaultValue=""
                  multiple
                /> */}

                <input
                  type="file"
                  name="woFiles"
                  ref={woFiles}
                  multiple
                  onChange={() => setIsTableRowsTouched(true)}
                />
              </div>
            </Container>
            <Container>
              <Row>
                <WoMilestones
                  data={data}
                  setData={setData}
                  isTableRowsTouched={isTableRowsTouched}
                  setIsTableRowsTouched={setIsTableRowsTouched}
                  removeMilestone={removeMilestone}
                />
              </Row>
              <Row className="stickyFooter">
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    type="button"
                    onClick={() => {
                      reset();
                      resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      !Object.keys(dirtyFields).length &&
                      !Object.keys(isDropdownDirty).length &&
                      !isTableRowsTouched
                    }
                  >
                    Submit 
                  </Button>
                </ButtonToolbar>
              </Row>
            </Container>
          </form>
        </CardBody>
      </Card>

      <Modal isOpen={saveModalStatus} toggle={showHideWarningSaveModel}>
        <ModalHeader toggle={showHideWarningSaveModel}>Warning</ModalHeader>
        <ModalBody>
          <div>
            Work order has already been created for{" "}
            <strong>{`${getValues("project_title")?.label} `}</strong>
            with category <strong>{`${getValues("category")?.label}  `}</strong>
          </div>
          <div>Would you like to create one one more work order?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => saveWorkOrder()}>
            Save
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              showHideWarningSaveModel(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar.status}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <MuiAlert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity={snackbar.type}
          sx={{ width: "100%" }}
        >
          {snackbar.msg}
        </MuiAlert>
      </Snackbar>
    </Col>
  );
};

export default CreateWorkOrderForm;
