import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "reactstrap";

import { ProjectScope } from "../../shared/helpers/constants";
import { useParams, useNavigate } from "react-router-dom";
import {
  saveConstrReq,
  getProjectInfoDetails,
} from "../../redux/actions/projectsAction";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ConstructionRequirement = ({ rows, setData, setTableDirty }) => {
  const { pid } = useParams();
  const [isSortable, setIsSortable] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(false);
  const [withSearchEngine, setWithSearchEngine] = useState(false);

  const reactTableData = {
    tableHeaderData: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
        editFieldType: "input",
      },
      {
        Header: "Subject",
        accessor: "subject",
      },
      {
        Header: "Description",
        accessor: "description",
        disableGlobalFilter: true,
        editFieldType: "input",
      },
      {
        Header: "Computation",
        accessor: "computation",
        disableGlobalFilter: true,
        editFieldType: "input",
      },
      {
        Header: "Project Scope",
        accessor: "projectScope",
        disableGlobalFilter: true,
        editFieldType: "select",
        selectOptions: ProjectScope,
      },
      {
        Header: "Edit",
        disableGlobalFilter: false,
        editFieldType: "edit",
      },
      {
        Header: "Remove",
        disableGlobalFilter: false,
        editFieldType: "remove",
      },
    ],
  };

  const tableConfig = {
    isEditable: true,
    isSortable,
    isResizable: false,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by First name...",
  };

  const addRow = () => {
    let newRows = [...rows];
    newRows.push({
      id: rows.length + 1,
      subject: "",
      description: "",
      computation: "",
      projectScope: 0,
      isEditable: true,
    });
    setData(newRows);
    setTableDirty();
  };

  const onChange = (event, rowIdx, field) => {
    setTableDirty();
    let myrow = [...rows];

    myrow[rowIdx][field] = event.target.value;
    setData(myrow);
  };

  return (
    <>
      {/* <ReactTableBase
        key={withSearchEngine ? 'searchable' : 'common'}
        columns={reactTableData.tableHeaderData}
        data={rows}
        updateEditableData={updateEditableData}
        tableConfig={tableConfig}
      /> */}

      <div>
        <Button
          color="primary"
          type="button"
          className="previous"
          onClick={addRow}
        >
          Add Row
        </Button>
      </div>

      <Table
        style={{ boxShadow: "1px 1px 5px gray", padding: "3px" }}
        sx={{
          "& .MuiTableCell-root": {
            padding: "5px 2px",
          },
        }}
      >
        <TableHead>
          <TableRow>
            {reactTableData.tableHeaderData.map((tHead, idx) => {
              return <TableCell key={idx}>{tHead.Header}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody className="table table--bordered">
          {rows.map((row, xidx) => {
            return (
              <TableRow key={xidx}>
                <TableCell>
                  {row.isEditable ? (
                    <input
                      type="text"
                      value={row.id}
                      onChange={(event) => onChange(event, xidx, "id")}
                    />
                  ) : (
                    row.id
                  )}
                </TableCell>
                <TableCell>
                  {row.isEditable ? (
                    <input
                      type="text"
                      value={row.subject}
                      onChange={(event) => onChange(event, xidx, "subject")}
                    />
                  ) : (
                    row.subject
                  )}
                </TableCell>
                <TableCell>
                  {row.isEditable ? (
                    <input
                      type="text"
                      value={row.description}
                      onChange={(event) => onChange(event, xidx, "description")}
                    />
                  ) : (
                    row.description
                  )}
                </TableCell>
                <TableCell>
                  {row.isEditable ? (
                    <input
                      type="text"
                      value={row.computation}
                      onChange={(event) => onChange(event, xidx, "computation")}
                    />
                  ) : (
                    row.computation
                  )}
                </TableCell>
                <TableCell>
                  {row.isEditable ? (
                    <select
                      value={row.projectScope}
                      onChange={(event) =>
                        onChange(event, xidx, "projectScope")
                      }
                    >
                      {ProjectScope.map((option, opidx) => {
                        return (
                          <option key={opidx} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    ProjectScope.filter((o) => o.value == row.projectScope)[0]
                      .label
                  )}
                </TableCell>
                <TableCell>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    onClick={(e) => {
                      let myrow = [...rows];
                      myrow[xidx].isEditable = !myrow[xidx].isEditable;
                      setData(myrow);
                      setTableDirty();
                    }}
                  >
                    Edit
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    onClick={(e) => {
                      setTableDirty();
                      let myrow = [...rows];
                      myrow.splice(xidx, 1);
                      setData(myrow);
                    }}
                  >
                    Delete
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {/* <div className="form form--horizontal wizard__form">
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button
            color="primary"
            type="button"
            className="previous"
            onClick={() => previousPage(rows)}
          >
            Back
          </Button>
          <Button
            color="primary"
            type="button"
            className="next"
            onClick={(e) => onSubmit(e, rows)}
          >
            Next
          </Button>
        </ButtonToolbar>
      </div> */}
    </>
  );
};

export default ConstructionRequirement;
