import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormField } from "./FormField";
import AsyncSelect from "react-select/async";
import AddCircleIcon from "mdi-react/AddCircleIcon";
import DownloadBoxIcon from "mdi-react/DownloadBoxIcon";
import Moment from "moment";
import csvDownload from "json-to-csv-export";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Container,
  Row,
  Col,
  ButtonToolbar,
} from "reactstrap";
import LedgerForm from "./LedgerForm";

import { PaymentType, PaymentMode } from "@/shared/helpers/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ChakraProvider } from "@chakra-ui/react";
import * as ChakraAll from "@chakra-ui/react";
import {
  fetchProjects,
  submitLedger,
  getLedgerData,
  downloadAttachments,
  updateLedger,
  deleteLedgerEntry,
} from "../../redux/actions/ledgerAction";

import Select from "react-select";
import Taskmanager from "../TaskManager/Taskmanager";
import { Input } from "@chakra-ui/input";
import { chakra } from "@chakra-ui/system";
import { useMemo } from "react";
import Pagination from "../Pagination/Pagination";
import { Filter } from "@mui/icons-material";
import { Box, CircularProgress, Fade } from "@mui/material";
import { getChildLedgerData } from "../../redux/actions/childLedgerActions";
import TableLedger from "./TableLedger";
// import { debounce } from "lodash";

let deleteTxnId = null;

const dataToConvert = {
  filename: "ledger.csv",
  delimiter: ",",
};

const Ledger = () => {
  const loginState = useSelector((state) => state.login);
  //  console.log(loginState.userDetails.access_control)
  function getUserName() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    // console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com"
    ) {
      return true;
    }
    return false;
  }
  const [childlegdata, setchildlegdata] = useState([]);

  function getchildledgerdata() {
    getChildLedgerData().then((res) => {
      //console.log()
      setchildlegdata(res.res.rows);
    });
  }

  useEffect(() => {
    getchildledgerdata();
  }, []);

  const [pleaseWait, setPleaseWait] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [deleteEntryModal, setDeleteEntryModal] = useState(false);
  const [entriesList, setEntriesList] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [projectsList, setProjectsList] = useState([]);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [motheren, setmotheren] = useState(false);

  const [filterData, setFilterData] = useState({
    txn_id: 0,
    comments: "",
    date: "",
    person: "",
    paymentType: "",
    paymentMode: "",
    isGstBills: "",
    category: "",
    amount: "",
    txnReference: "",
  });
  const [selectedProject, setSelectedProject] = useState({
    value: 0,
    label: "Select All",
  });
  const [alertMsg, setAlertMsg] = useState({
    msg: "",
    color: "success",
  });
  console.log(filterData, "dfsdj gaurav ");
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [initialValues, setInitialValues] = useState({
    category: "",
    person: "",
    date: new Date(),
    amount: "",
    paymentType: 1,
    paymentMode: 0,
    txnRef: "",
    gstBills: false,
    comments: "",
  });

  let projectTitleList = [];

  const toggleModal = (flag) => {
    setShowForm(flag);
  };

  const toggleDeleteEntryModal = (flag) => {
    setDeleteEntryModal(flag);
    deleteTxnId = null;
  };

  const loadOptions = async () => {
    const response = fetchProjects().then((res) => {
      let data = [{ value: 0, label: "Select All" }];
      let arr = res.data;
      for (let idx in arr) {
        data.push({
          value: arr[idx].id,
          label: arr[idx].project_title,
        });
      }
      setProjectsList(data);
    });
  };
  const OnChangeProject = (val) => {
    if (!val) {
      return;
    }
    setValue("project_title", val);
    setSelectedProject(val);
    setSelectedProject(val);
    clearFilters();
  };

  const createLedgerEntry = () => {
    setSubmitBtnStatus(false);
    setInitialValues({
      category: "",
      person: "",
      date: new Date(),
      amount: "",
      paymentType: { label: "Income", value: 1 },
      paymentMode: 0,
      txnRef: "",
      gstBills: false,
      comments: "",
      project_title: "",
    });
    toggleModal(true);
  };

  const onSubmit = (data) => {
    console.log("180180 onsubmit", data);
    //setSubmitBtnStatus(true);
    if (!data?.project_title?.value) {
      return;
    }

    data.date = Moment(data.date).format("yyyy-MM-DD");
    data.project_id = data.project_title;
    data.gstBills = data.gstBills ? 1 : 0;
    let dat = new FormData();
    let selectedFiles = data.files;

    /*******************Update Ledger Entry****************************** */
    if (data.txn_id) {
      let existingFiles = [];
      let tempArr = [];
      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          if (selectedFiles[i].fromsrv) {
            existingFiles.push(selectedFiles[i].fromsrv);
            delete selectedFiles[i];
          } else {
            tempArr.push(selectedFiles[i]);
            dat.append("files", selectedFiles[i]);
          }
        }
      }

      data.existingFiles = existingFiles;
      dat.append("data", JSON.stringify(data));

      updateLedger(dat).then((res) => {
        toggleModal(false);
        if (res.status) {
          setAlertMsg({ ...alertMsg, msg: res.msg, color: "success" });
          setTimeout(() => {
            setAlertMsg({ ...alertMsg, msg: "" });
          }, 4000);
          getLedgerEntries();
        } else {
          setAlertMsg({
            ...alertMsg,
            msg: "Failed to save ledger entry. Please try again",
            color: "danger",
          });
          setTimeout(() => {
            setAlertMsg({ ...alertMsg, msg: "" });
          }, 4000);
        }
      });
    } else {
      dat.append("data", JSON.stringify(data));

      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          dat.append("files", selectedFiles[i]);
        }
      }
      submitLedger(dat).then((res) => {
        console.log("momomomomo");
        toggleModal(motheren);
        if (res.status) {
          setAlertMsg({ ...alertMsg, msg: res.msg, color: "success" });
          setTimeout(() => {
            setAlertMsg({ ...alertMsg, msg: "" });
          }, 4000);
          getLedgerEntries();
        } else {
          setAlertMsg({
            ...alertMsg,
            msg: "Failed to save ledger entry. Please try again",
            color: "danger",
          });
          setTimeout(() => {
            setAlertMsg({ ...alertMsg, msg: "" });
          }, 4000);
        }
      });
    }
  };

  const getLedgerEntries = () => {
    // if (!selectedProject?.value) return;
    getLedgerData(selectedProject, filterData, false).then((res) => {
      // console.log(res, "qwert")
      if (res.status) {
        let data = res.data;
        for (let idx in data) {
          data[idx].date = Moment(data[idx].date).format("yyyy-MM-DD");
          if (data[idx].attachments) {
            data[idx].attachments = JSON.parse(data[idx].attachments);
          } else {
            data[idx].attachments = [];
          }
        }
        // console.log(selectedProject, filterData, res, 254254254254)
        setRows(data);
      } else {
        setRows([]);
      }
      setLoading(false);
    });
  };

  const onChangeFilters = (evt) => {
    let val = evt.target.value.toLocaleLowerCase();
    let key = evt.target.name;

    if (key === "" && val == 0) {
      val = "";
    }
    if (key === "paymentMode" && val == 0) {
      val = "";
    }

    setFilterData({ ...filterData, [key]: val });
  };
  const onChangeTnx_id = (evt) => {
    let val = evt.target.value;
    let key = evt.target.name;
    //  console.log(Number(val));
    setFilterData({ ...filterData, [key]: Number(val) });
  };

  const download = () => {
    dataToConvert.data = rows;
    csvDownload(dataToConvert);
  };

  const clearFilters = () => {
    setFilterData({
      txn_id: 0,
      comments: "",
      date: "",
      person: "",
      paymentType: "",
      paymentMode: "",
      isGstBills: "",
      category: "",
      amount: "",
      txnReference: "",
    });
  };

  const onClickAttachments = (link) => {
    downloadAttachments(link);
  };

  const viewOrUpdate = async (selectedRow) => {
    setPleaseWait(true);
    let fileArray = [];

    for (let idx in selectedRow.attachments) {
      let fileName = selectedRow.attachments[idx].split("/");
      fileName = fileName[fileName.length - 1];

      const files = await downloadAttachments(
        selectedRow.attachments[idx],
        true
      );
      let blob = window.URL.createObjectURL(files);
      let file = new File([blob], `${fileName}`, {
        preview: blob,
        type: files.type,
      });

      file.preview = blob;
      file.path = blob;
      file.fromsrv = selectedRow.attachments[idx];
      fileArray.push(file);
    }

    setInitialValues({
      category: {
        value: selectedRow.category_id,
        label: selectedRow.category_name,
      },
      person: {
        value: selectedRow.person_id,
        label: `${selectedRow.person_first_name} ${selectedRow.person_last_name}`,
      },
      date: selectedRow.date,
      amount: selectedRow.amount,
      paymentType: {
        value: selectedRow.payment_type_id,
        label: PaymentType.filter(
          (o) => o.value == selectedRow.payment_type_id
        )[0].label,
      },
      paymentMode: {
        value: selectedRow.payment_mode_id,
        label: PaymentMode.filter(
          (o) => o.value == selectedRow.payment_mode_id
        )[0].label,
      },
      txnRef: selectedRow.txn_reference,
      gstBills: selectedRow.is_gst_bill,
      comments: selectedRow.comments,
      txn_id: selectedRow.txn_id,
      files: fileArray,
      project_title: {
        value: selectedRow.project_id,
        label: selectedRow.project_title,
      },
    });
    setPleaseWait(false);
    toggleModal(true);
  };

  const confirmDeleteEntry = (index) => {
    toggleDeleteEntryModal(true);
    deleteTxnId = entriesList[index].txn_id;
  };

  const deleteEntry = () => {
    if (deleteTxnId) {
      deleteLedgerEntry(deleteTxnId).then((res) => {
        deleteTxnId = null;
        toggleDeleteEntryModal(false);
        if (res.status) {
          setAlertMsg({
            msg: res.msg,
            color: "success",
          });
          setTimeout(() => {
            setAlertMsg({ ...alertMsg, msg: "" });
          }, 4000);
        }
      });
    }
  };

  useEffect(() => {
    // const timer = setTimeout(() => {
    //console.log(rows, "415415415")
    rows.sort((a, b) => Number(b.mother) - Number(a.mother));

    //console.log(sdata, "seconddata")
    setEntriesList([...rows]);
    let ti = 0,
      te = 0;
    for (let idx in rows) {
      if (rows[idx].payment_type_id == 1) {
        ti += Number(rows[idx].amount);
      } else if (rows[idx].payment_type_id == 2) {
        te += Number(rows[idx].amount);
      }
    }
    setTotalExpense(Math.floor(te));
    setTotalIncome(Math.floor(ti));
    // }, 300);
    // return () => clearTimeout(timer);
  }, [rows]);

  function getchildledgerdata() {
    getChildLedgerData().then((res) => {
      //console.log()
      //let sdata = res.res.rows

      //console.log(sdata, "ssssss")

      setchildlegdata(res.res.rows);
    });
  }

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      getLedgerEntries();
    }, 500);
    return () => {
      setLoading(false);
      clearTimeout(timer);
    };
  }, [selectedProject, filterData]);

  useEffect(() => {
    setLoading(true);
    getLedgerEntries();
    loadOptions();
  }, []);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 20;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return entriesList.slice(firstPageIndex, lastPageIndex);
  }, [entriesList, currentPage]);

  return (
    <>
      <Container className="dashboard ledger">
        {alertMsg.msg && (
          <Alert color={alertMsg.color} isOpen={!!alertMsg.msg}>
            {alertMsg.msg}
          </Alert>
        )}

        <form className="form form--horizontal">
          {/* <Row className="form__form-group">
            <Col>
              <div
                style={{
                  fontWeight: "600",
                  color: "gray",
                }}
              >
                <strong>Total Income:</strong> {totalIncome}
              </div>
              <div
                style={{
                  fontWeight: "600",
                  color: "gray",
                }}
              >
                <strong>Total Expense</strong>:{totalExpense}
              </div>
            </Col>

            <Col>
              <Button
                type="button"
                color="primary"
                onClick={clearFilters}
                style={{ textAlign: "center" }}
              >
                <Filter />
                Clear Filters
              </Button>
            </Col>

            <Col style={{ textAlign: "right" }}>
              {binaryconverter(loginState.userDetails.access_control).charAt(
                4
              ) == 1 && (
                  <button
                    type="button"
                    color="primary"
                    className="clearFilter"
                    onClick={createLedgerEntry}
                  >
                    <AddCircleIcon size="2em" />
                  </button>
                )}

              {binaryconverter(loginState.userDetails.access_control).charAt(
                7
              ) == 1 && (
                  <button
                    type="button"
                    color="primary"
                    className="clearFilter"
                    onClick={download}
                  >
                    <DownloadBoxIcon size="2em" />
                  </button>
                )}
            </Col>
          </Row> */}

          {/* <ChakraAll.Box border={"1px solid red"} >

          </ChakraAll.Box> */}

          <ChakraProvider>
            <TableLedger
              createLedgerEntry={createLedgerEntry}
              totalExpense={totalExpense}
              totalIncome={totalIncome}
              download={download}
              childlegdata={childlegdata}
              viewOrUpdate={viewOrUpdate}
              confirmDeleteEntry={confirmDeleteEntry}
              loginState={loginState}
              binaryconverter={binaryconverter}
              currentTableData={currentTableData}
              PaymentMode={PaymentMode}
              PaymentType={PaymentType}
              document={document}
              projectsList={projectsList}
              errors={errors}
              control={control}
              OnChangeProject={OnChangeProject}
              filterData={filterData}
              onChangeTnx_id={onChangeTnx_id}
              onChangeFilters={onChangeFilters}
            />
          </ChakraProvider>
        </form>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={entriesList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>

        <Box
          sx={{
            height: 40,
            textAlign: "center ",
            display: `${loading ? "block" : "none"}`,
          }}
        >
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </Box>
      </Container>

      <Modal
        isOpen={showForm}
        toggle={() => toggleModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleModal(false)}>
          <strong>Create Entry</strong>
        </ModalHeader>
        <ModalBody>
          <LedgerForm
            getchildledgerdata={getchildledgerdata}
            selectedProject={selectedProject}
            closePopup={toggleModal}
            onSubmit={onSubmit}
            submitBtnStatus={submitBtnStatus}
            initialValues={initialValues}
            projectsList={projectsList}
            addOnlyIncome={!getUserName()}
            childlegdata={childlegdata}
            setmotheren={setmotheren}
            ledger={true}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteEntryModal}
        toggle={() => toggleDeleteEntryModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleDeleteEntryModal(false)}>
          <strong>Delete Entry</strong>
        </ModalHeader>
        <ModalBody>Are you sure you want to delete?</ModalBody>
        <ModalFooter>
          <ButtonToolbar className="form__button-toolbar wizard__toolbar">
            <Button
              color="primary"
              type="button"
              className="next"
              onClick={() => deleteEntry()}
            >
              Yes
            </Button>
            <Button type="button" onClick={() => toggleDeleteEntryModal(false)}>
              No
            </Button>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Ledger;
