import React, { useState, ChangeEvent } from "react";
import { useDropzone } from "react-dropzone";
import DataGrid, { textEditor } from "react-data-grid";
import { Spinner } from "reactstrap";
import { read, utils, WorkSheet, writeFile } from "xlsx";

const getRowsCols = (data, sheetName) => {
  const rows = utils.sheet_to_json(data[sheetName], { header: 1 });
  let columns = [];

  for (let row of rows) {
    const keys = Object.keys(row);

    if (keys.length > columns.length) {
      columns = keys.map((key) => {
        return { key, name: utils.encode_col(+key), editor: textEditor };
      });
    }
  }
  return { rows, columns };
};
const Reader = (props) => {
  const [showLoading, setLoading] = useState(false);
  const [workBook, setWorkBook] = useState({});
  const [sheets, setSheets] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [current, setCurrent] = useState("");

  const exportTypes = ["xlsx", "xlsb", "csv", "html"];

  async function handleFile(ev) {
    setLoading(true);
    setTimeout(async () => await readFile(ev), 300);
  }

  async function readFile(ev) {
    const file = await ev.target.files?.[0]?.arrayBuffer();
    const data = read(file);
    console.log(file,data,"uytyrutut")
    //setWorkBook(data.Sheets);
    //setSheets(data.SheetNames);
    //console.log("working done here", data.Sheets);
    let sheetName = data.SheetNames[0];
    const currentSheet = data.Sheets[sheetName];
    const parsed = await utils.sheet_to_json(currentSheet, { raw: false });
    //console.log('parsed data', parsed);
    props.dataProcessor(parsed);
    setLoading(false);
  }

  function selectSheet(name, reset = true) {
    if (reset)
      workBook[current] = utils.json_to_sheet(rows, {
        header: columns.map((col) => col.key),
        skipHeader: true,
      });

    const { rows: new_rows, columns: new_columns } = getRowsCols(
      workBook,
      name
    );
    console.log("fjdksjfjsdflkfj", new_rows, new_columns);
    setRows(new_rows);
    setColumns(new_columns);
    setCurrent(name);
  }

  function saveFile() {
    //TODO update data to server
  }
  console.log("sheets.leng", sheets.length);
  return (
    <>
      <input type="file" onChange={handleFile} />
      <div className="flex-cont">
        {/* {sheets.map((sheet) => (
                    <button key={sheet} onClick={(e) => selectSheet(sheet)}>
                        {sheet}
                    </button>
                ))} */}
        {showLoading ? <Spinner animation="grow" /> : ""}
      </div>
      {/* <div className="flex-cont">
                <b>Current Sheet: {current}</b>
            </div> */}
      {/* <DataGrid columns={columns} rows={rows} onRowsChange={setRows} /> */}
      {/* <div className="flex-cont">
                <button onClick={() => saveFile()}>
                    Upload Work Order
                </button>
            </div> */}
    </>
  );
};

export default Reader;
