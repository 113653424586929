import { Route, Routes } from "react-router-dom";
import { SmartPrivateRouter } from "../App/PrivateRouter";
import ProjectUnit from "./Units/ProjectUnit";
import RantelDashboard from "./Building/RentalDashboard";
import Booking from "./Booking/Booking";
import Invoice from "./Invoice/Invoice";
import Ledger from "./Ledger/Ledger";
import ViewImage3 from "../ViewImage3";
const Rental = ({ pathname }) => {
  console.log("hello world");
  return (
    <Routes>
      <Route
        path=""
        element={<RantelDashboard pathname={pathname} type="all" />}
      />
      <Route path="unit/:id" element={<ProjectUnit pathname={pathname} />} />
      <Route
        path="unit/:id/Booking/:uid"
        element={<Booking pathname={pathname} />}
      />
      <Route
        path="unit/:id/Booking/:uid/Invoice/:bookid"
        element={<Invoice pathname={pathname} />}
      />
      <Route path="ledger" element={<Ledger pathname={pathname} />} />
      <Route path="ledger/vimgArr/" element={<ViewImage3 />} />
    </Routes>
  );
};
export default Rental;
