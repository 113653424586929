import React from "react";
import { Document, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import Pdftemplate from "./PdftemplateMail";

const ComponentPdfMail = () => {
  const location = useLocation();
  const { email, name, data, p_id, project_cx } = location.state;
  console.log(data, "dsfdsf from");
  return (
    <PDFViewer style={{ width: "100%", height: "800px" }}>
      <Pdftemplate
        data={data}
        name={name}
        email={email}
        p_id={p_id}
        project_cx={project_cx}
      />
    </PDFViewer>
  );
};

export default ComponentPdfMail;
