import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TextField } from "@mui/material";

const SimpleTable = ({ headers, body, action, params, getData }) => {
  console.log("simple-table-body", body);
  const [filterState, setFilterState] = useState({ material: "" });
  const [newBody, setNewBody] = useState([...body]);
  const Action = action;

  useEffect(() => {
    body.sort((obj1, obj2) => {
      if (obj1.material < obj2.material) {
        return -1;
      } else if (obj1.material > obj2.material) {
        return 1;
      } else {
        return 0;
      }
    });

    setNewBody([
      ...body
        .filter((obj) => {
          const objMaterials = obj.material.toLocaleLowerCase().split(""); // split obj.material into an array of materials
          return (
            filterState.material
              .toLocaleLowerCase()
              .split("")
              .every((m) => objMaterials.includes(m.trim())) ||
            filterState.material === ""
          );
        })
        .sort((obj1, obj2) => {
          if (obj1.material < obj2.material) {
            return -1;
          } else if (obj1.material > obj2.material) {
            return 1;
          } else {
            return 0;
          }
        }),
    ]);
  }, [filterState]);

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headers &&
              headers.map((header, index) =>
                index !== 0 ? (
                  <TableCell key={header.key}>{header.value}</TableCell>
                ) : (
                  <TextField
                    key={header.key}
                    sx={{ m: 1 }}
                    id="outlined-basic"
                    label={header.value}
                    onChange={(e) =>
                      setFilterState({ material: e.target.value })
                    }
                    variant="outlined"
                  />
                )
              )}
            {action && <TableCell>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {newBody &&
            newBody.map((row, rowIndex) => {
              return (
                <TableRow
                  key={`row-${rowIndex}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {headers.map((item, colIndex) => {
                    const Component = item.component;
                    return (
                      <TableCell key={`col-${colIndex}`}>
                        {item.component ? (
                          <Component row={row} />
                        ) : row[item.key] ? (
                          row[item.key]
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    );
                  })}
                  {action && (
                    <TableCell>
                      <Action row={row} params={params} getData={getData} />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default SimpleTable;
