import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
// import { ChakraProvider, Input, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  Create,
  CurrencyRupee,
  Delete,
  Edit,
  OpenWithOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  addInvoice,
  deleteBooking,
  deleteBuilding,
  deleteInvoice,
  deleteUnit,
  fetchBookings,
  fetchBuildings,
  fetchInvoices,
  fetchRentalLedgers,
  fetchUnit,
  fetchUnits,
  updateInvoice,
} from "../../../redux/actions/rentalsActions";

import { styled } from "@mui/system";
import moment from "moment";
import AddInvoice from "./AddInvoice";
import UpdateBooking from "../Booking/UpdateBooking";
import UpdateInvoice from "./UpdateInvoice";
import CreateLedger from "./CreateLedger";
import { invoice_type_arr, pay_status_arr } from "../Constant";
import LedgerModel from "./LedgerModel";
const Invoice = () => {
  const [loading, setLoading] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [enableInput, setEnableInput] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [initialValues, setInitialValues] = useState({
    rent: "",
    invoice_type: "",
    status: 1,
    description: "",
  });
  const [initialValuesLed, setInitialValuesLed] = useState({
    type: "",
    amount: "",
    invoice_type: "",
    payment_type: "",
    description: "",
  });
  const [UpdateValues, setUpdateValues] = useState({
    rent: "",
    status: 1,
    description: "",
  });

  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState([]);
  const [dataBuilding, setDataBuilding] = useState({});
  const [deleteId, setdeleteId] = useState({});
  const [invoice, setInvoice] = useState(1);
  const [invoiceStatus, setInvoiceStatus] = useState(1);
  const [currBookingObj, setCurrBookingObj] = useState({});
  const [currLedgerObj, setCurrLedgerObj] = useState([]);
  const [ledgerModelObj, setLedgerModelObj] = useState([]);

  const [disabled, setDisabled] = useState(false);

  //__________________ Model______________
  const [openLed, setOpenLed] = React.useState(false);
  const handleOpenLed = () => setOpenLed(true);
  const handleCloseLed = () => setOpenLed(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  //__________________ Model__________________

  const { id, uid, bookid } = useParams();
  function UpdatePropValue(data) {
    setUpdateValues({ ...data });
  }
  const StatusArr = ["", "Unpaid", "Paid", "Cancelled", "partially paid"];

  const createUser = () => {
    setInitialValues({
      rent: "",
      status: 1,
      invoice_type: 1,
      description: "",
    });

    // setShowCreateUserForm(true);
    handleOpen();
  };
  function delUnits() {
    console.log(deleteId);
    deleteInvoice(deleteId).then((res) => {
      console.log(res, "deleted");
      setAlertMsg("Unit Data Deleted successfully");
    });
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  useEffect(() => {
    fetchInvoices().then((res) => {
      setData([
        ...res?.res?.rows.filter(
          (el) =>
            el.building_id === Number(id) &&
            el.unit_id === Number(uid) &&
            el.booking_id === Number(bookid)
        ),
      ]);
    });
    fetchBookings().then((res) => {
      setCurrBookingObj(
        res?.res?.rows?.find((el) => el.booking_id === Number(bookid))
      );
    });
    fetchRentalLedgers().then((res) => {
      setCurrLedgerObj([...res?.res?.rows]);
    });
  }, [alertMsg]);
  function GetPaidValue(obj) {
    return currLedgerObj
      ?.filter((el) => el.invoice_id == obj.invoice_id)
      .reduce((prev, curr) => {
        prev = Number(prev) + Number(curr.amount);
        return prev;
      }, 0);
  }
  function generateInvoice() {
    const moveIn = moment(currBookingObj.move_in);
    const moveOut = moment(currBookingObj.move_out);

    console.log(
      moveIn.format("DD-MM-YYYY"),
      moveOut.format("DD-MM-YYYY"),
      currBookingObj.rent,
      "rent"
    );
    console.log(moveOut.diff(moveIn, "months"), "diff");
    // const monthsDiff = moveOut.diff(moveIn, "months");
    const monthsDiff = moveOut.month() - moveIn.month();

    let currMonth = moveIn.clone();
    const data = {
      startdate: 0,
      enddate: 0,
      rent: currBookingObj.rent,
      invoice_type: 1,
      status: 1,
      description: "System Generated Invoice",
    };
    data.building_id = Number(id);
    data.unit_id = Number(uid);
    data.booking_id = Number(bookid);

    for (let i = 0; i <= monthsDiff; i++) {
      let daysInMonth = currMonth.daysInMonth();
      if (i === 0) {
        //first month
        let curr = new Date(currBookingObj.move_in);
        let nextMonth = new Date(
          new Date(currBookingObj.move_in).getFullYear(),
          new Date(currBookingObj.move_in).getMonth() + 1
        );
        if (i == monthsDiff) {
          nextMonth = new Date(currBookingObj.move_out);
          daysInMonth = moveOut.date() - currMonth.date();
          console.log(curr, nextMonth, "start end date diff", monthsDiff);
        } else {
          nextMonth.setDate(nextMonth.getDate() - 1);
          daysInMonth = currMonth.daysInMonth() - currMonth.date();
          console.log(curr, nextMonth, "start end date diff", monthsDiff);
        }

        data.rent = Math.floor((data.rent / 30) * daysInMonth);
        data.startdate = curr;
        data.enddate = nextMonth;
      } else if (i == monthsDiff) {
        //last month
        let curr = new Date(
          new Date(currBookingObj.move_in).getFullYear(),
          new Date(currBookingObj.move_in).getMonth() + i
        );
        let nextMonth = new Date(
          new Date(currBookingObj.move_in).getFullYear(),
          new Date(currBookingObj.move_in).getMonth() + i,
          new Date(currBookingObj.move_out).getDate()
        );
        daysInMonth = moveOut.date();
        console.log(curr, nextMonth, "start end date last");

        data.rent = Math.floor((data.rent / 30) * daysInMonth);
        data.startdate = curr;
        data.enddate = nextMonth;
      } else {
        // middle years
        let curr = new Date(
          new Date(currBookingObj.move_in).getFullYear(),
          new Date(currBookingObj.move_in).getMonth() + i
        );
        let nextMonth = new Date(
          new Date(currBookingObj.move_in).getFullYear(),
          new Date(currBookingObj.move_in).getMonth() + i + 1
        );
        nextMonth.setDate(nextMonth.getDate() - 1);
        daysInMonth = currMonth.daysInMonth();
        console.log(curr, nextMonth, "start end date");

        data.rent = currBookingObj.rent;
        data.startdate = curr;
        data.enddate = nextMonth;
      }
      console.log(`Month ${i + 1}: ${daysInMonth} days`);
      currMonth.add(1, "months");
      data.startdate = moment(data.startdate).format("YYYY-MM-DD HH:mm:ss");
      data.enddate = moment(data.enddate).format("YYYY-MM-DD HH:mm:ss");
      console.log(data, "data ss");
      addInvoice(data).then((res) => {
        console.log(res, "");
        setAlertMsg("Booking Data Added successfully");
        setDisabled(false);
      });
    }
    const data1 = {
      startdate: moveIn.format("YYYY-MM-DD HH:mm:ss"),
      enddate: moveOut.format("YYYY-MM-DD HH:mm:ss"),
      rent: currBookingObj.deposit,
      invoice_type: 2,
      status: 1,
      description: "System Generated Invoice",
    };
    data1.building_id = Number(id);
    data1.unit_id = Number(uid);
    data1.booking_id = Number(bookid);
    addInvoice(data1).then((res) => {
      console.log(res, "");
      setAlertMsg("Booking Data Added successfully");
    });
  }

  return (
    <Container className="dashboard">
      <h3
        className="page-title"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "8px",
          wordSpacing: "15px",
        }}
      >
        Invoice Details
      </h3>
      <Card>
        <CardContent sx={{ overflow: "scroll", height: "83vh" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {binaryconverter(loginState.userDetails.access_control).charAt(2) ==
              1 && (
              <Button
                color="primary"
                type="button"
                className="previous"
                onClick={() => createUser()}
                variant="contained"
              >
                <Create />
                Create Invoice
              </Button>
            )}
            <Button
              color="primary"
              type="button"
              className="previous"
              onClick={() => {
                generateInvoice();
              }}
              variant="contained"
              disabled={data.length}
            >
              <Create />
              Generate Invoice
            </Button>
          </Box>

          {/* <EditableReactTable reactTableData={reactTableData} /> */}

          <Table>
            <TableHead>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    padding: "7px 2px",
                  },
                }}
              >
                {/* startdate , enddate , rent , status , description */}
                <TableCell>Invoice Id</TableCell>
                <TableCell>Invoice Type</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Paid</TableCell>
                <TableCell>Rent</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log(data, "data")}
              {data.map((obj) => (
                <TableRow
                  sx={{
                    ".MuiTableCell-root": {
                      padding: "10px 2px",
                    },
                  }}
                >
                  <TableCell>
                    <NavLink
                      to={`/app/${role}/Building/unit/${obj.building_id}/Booking/${obj.unit_id}/Invoice/${obj.booking_id}`}
                    >
                      {" "}
                      {obj.invoice_id}
                    </NavLink>
                  </TableCell>
                  {/* <TableCell>{obj.booking_id}</TableCell> */}
                  <TableCell>
                    {invoice_type_arr[Number(obj.invoice_type)]}
                  </TableCell>
                  <TableCell>
                    {moment(obj.startdate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(obj.enddate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{GetPaidValue(obj)}</TableCell>
                  <TableCell>{obj.rent}</TableCell>
                  <TableCell>
                    <FormControl
                      fullWidth
                      sx={{ margin: "9px 0px", mb: "20px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="status"
                        label="Status"
                        variant="outlined"
                        fullWidth
                        value={obj.status}
                        disabled={obj.status == 3 || obj.status == 2}
                        onChange={(e) => {
                          if (e.target.value != 3) {
                            setInvoice(obj);
                            console.log(e.target.value, "value &&&&&&&&&&&&");
                            setInvoiceStatus(e.target.value);
                            setInitialValuesLed({
                              type: "1",
                              amount: "",
                              invoice_type: obj.invoice_type,
                              payment_type: "1",
                              description: "",
                            });
                            obj.paid = GetPaidValue(obj);
                            setdeleteId(obj);
                            handleOpenLed();
                          } else {
                            obj.status = 3;
                            updateInvoice(obj).then((res) => {
                              setAlertMsg("Cancelled successfully");
                            });
                          }
                        }}
                      >
                        {pay_status_arr
                          .map((el, ind) => (
                            <MenuItem value={ind}>{el}</MenuItem>
                          ))
                          .filter((el, ind) => ind !== 0)}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>{obj.description}</TableCell>
                  <TableCell>
                    <ButtonGroup>
                      <Button
                        sx={{ maxWidth: 5, p: 0 }}
                        variant="contained"
                        onClick={() => {
                          setLedgerModelObj([
                            ...currLedgerObj?.filter(
                              (el) => el.invoice_id === obj.invoice_id
                            ),
                          ]);
                          handleOpen1();
                        }}
                      >
                        <CurrencyRupee />
                      </Button>
                      <Button
                        sx={{ maxWidth: 5, p: 0 }}
                        variant="contained"
                        onClick={() => {
                          UpdatePropValue(obj);
                          handleOpenUpdate();
                        }}
                      >
                        <Edit />
                      </Button>

                      <Button
                        sx={{ maxWidth: 5, p: 0 }}
                        variant="contained"
                        onClick={() => {
                          setdeleteId(obj.invoice_id);
                          handleOpenDialog();
                        }}
                      >
                        <Delete />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box sx={{ height: 40, textAlign: "center " }}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </CardContent>
      </Card>
      {/* //______________________________Create Ledger____________________ */}
      <Modal
        open={openLed}
        onClose={handleCloseLed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <CreateLedger
            closePopup={handleCloseLed}
            initialValues={initialValuesLed}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            invid={invoice.invoice_id}
            InvoiceObj={deleteId}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      {/* //_____________________________ ADD Invoic _______________________ */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <AddInvoice
            closePopup={handleClose}
            initialValues={initialValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            currentRent={currBookingObj?.rent}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      {/* //_____________________________ Delete Unit _________________________ */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are U Sure You want to delete The building"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            if you click on Agree the building will be permanently delete from
            DataBase
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button
            onClick={() => {
              delUnits();
              handleCloseDialog();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* //_____________________________ Update Unit _____________________________ */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <UpdateInvoice
            closePopup={handleCloseUpdate}
            initialValues={UpdateValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            currentRent={currBookingObj?.rent}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>

      <Snackbar
        open={!!alertMsg}
        autoHideDuration={3000}
        onClose={() => setAlertMsg("")}
        sx={{
          "&.MuiSnackbar-root": { left: "50%", transform: "translateX(-50%)" },
        }}
      >
        <Alert
          onClose={() => setAlertMsg("")}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>

      {/* _______________________________Ledger Entry Show___________________________ */}
      <LedgerModel
        handleClose1={handleClose1}
        handleOpen1={handleOpen1}
        open1={open1}
        ledgerModelObj={ledgerModelObj}
      />
    </Container>
  );
};
export default Invoice;
