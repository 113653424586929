import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Col, Container, Row, Card, CardBody } from "reactstrap";
import {
  ProjectScope,
  workStatusList,
  paymentStatusList,
} from "../../shared/helpers/constants";
import Collapse from "../../shared/components/Collapse";
import Moment from "moment";
import classNames from "classnames";

// import Form from './components/Form';
import { getDetailedInfo } from "../../redux/actions/projectsAction";
import { yearMonthDateFormat } from "../../shared/helpers";
import DownloadBoxIcon from "mdi-react/DownloadBoxIcon";
import csvDownload from "json-to-csv-export";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

const dataToConvert = {
  filename: "wps.csv",
  delimiter: ",",
};
const PmViews = () => {
  const { pid } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    getDetailedProjectInfo(pid);
  }, []);

  const getDetailedProjectInfo = (pid) => {
    getDetailedInfo(pid).then((res) => {
      if (res.status) {
        setData({ ...res.data });
      }
    });
  };

  const wpsDownload = () => {
    let downloadData = [...data.ws];

    let projectTitle = data?.bi?.project_title;
    for (let idx in downloadData) {
      downloadData[idx].project_title = projectTitle;
      downloadData[idx].work_status = workStatusList.filter(
        (o) => o.value === downloadData[idx].work_status
      )[0].label;

      downloadData[idx].payment_date = Moment(
        downloadData[idx].payment_date
      ).format(yearMonthDateFormat);

      downloadData[idx].start_date = Moment(
        downloadData[idx].start_date
      ).format(yearMonthDateFormat);
      downloadData[idx].end_date = Moment(downloadData[idx].end_date).format(
        yearMonthDateFormat
      );

      downloadData[idx].payment_status = paymentStatusList.filter(
        (o) => o.value === downloadData[idx].payment_status
      )[0].label;

      downloadData[idx].project_scope = ProjectScope.filter(
        (o) => o.value === downloadData[idx].project_scope
      )[0].label;
    }

    dataToConvert.data = downloadData;
    csvDownload(dataToConvert);
  };

  const loginState = useSelector((state) => state.login);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  return (
    <Container className="dashboard projectView">
      <Row>
        <Col md={12}>
          <h2 className="page-title">Project Info</h2>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <fieldset>
              <legend style={{ color: "#70bbfd" }}>Basic Info</legend>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Project Title:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.project_title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Customer Name:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {`${data?.bi?.customer_firstname} ${data?.bi?.customer_lastname}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Customer Email:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.customer_email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Customer Contact #:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.customer_contact_num}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Project Manger:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {`${data?.bi?.project_manager_firstname} ${data?.bi?.project_manager_lastname}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Site Engineer:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {`${data?.bi?.site_engineer_firstname} ${data?.bi?.site_engineer_lastname}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Site Engineer Email:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.site_engineer_email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Site Engineer Contact #:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.site_engineer_contact_num}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Execution Lead:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.service_provider_firstname}{" "}
                      {data?.bi?.service_provider_lastname}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Execution Lead Contact #:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.service_provider_contact_num}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Location:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.location}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "8px" }}>
                      <strong>Geolocation:</strong>
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      {" "}
                      {data?.bi?.lat_lng}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </fieldset>
            <div style={{ border: "1px solid #CCC" }}></div>

            <fieldset>
              <Collapse title={"Construction Requirement"}>
                <div className="table react-table">
                  <div className="table react-table table--not-pagination-view-tables">
                    <Table className="react-table editable-table">
                      <TableHead sx={{ background: "#3aaeb754" }}>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Subject</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Computation</TableCell>
                          <TableCell>Project Scope</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.cr?.length &&
                          data.cr.map((row, idx) => {
                            return (
                              <TableRow key={idx}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.subject}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.computation}</TableCell>
                                <TableCell>
                                  {
                                    ProjectScope.filter(
                                      (o) => o.value === row.project_scope
                                    )[0].label
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </Collapse>
            </fieldset>

            <div style={{ border: "1px solid #CCC" }}></div>
            <fieldset>
              <Collapse title={"Work & Payment Schedule"}>
                {binaryconverter(loginState.userDetails.access_control).charAt(
                  24
                ) == 1 && (
                  <button
                    type="button"
                    color="primary"
                    className="clearFilter downloadIcon"
                    onClick={wpsDownload}
                  >
                    <DownloadBoxIcon size="2em" />
                  </button>
                )}
                <div className="table react-table">
                  <div className="table react-table table--not-pagination-view-tables">
                    <Table className="react-table editable-table">
                      <TableHead sx={{ background: "#3aaeb754" }}>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Stage</TableCell>
                          <TableCell>Task</TableCell>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Payment Date</TableCell>
                          <TableCell>% of Final Quote</TableCell>
                          <TableCell>To Pay(Rs)</TableCell>
                          <TableCell>Work Status</TableCell>
                          <TableCell>Payment Status</TableCell>
                          <TableCell>Project Scope</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.ws?.length &&
                          data.ws.map((row, idx) => {
                            if (
                              row.project_scope == 0 ||
                              row.project_scope == 2
                            ) {
                              return (
                                <TableRow
                                  key={idx}
                                  className={classNames({
                                    wipClr: row.work_status === 1,
                                    completedClr: row.work_status == 3,
                                  })}
                                >
                                  <TableCell>{row.id}</TableCell>
                                  <TableCell>{row.stage}</TableCell>
                                  <TableCell>{row.task}</TableCell>
                                  <TableCell>
                                    {Moment(row.start_date).format(
                                      yearMonthDateFormat
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {Moment(row.end_date).format(
                                      yearMonthDateFormat
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {Moment(row.payment_date).format(
                                      yearMonthDateFormat
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {row.percent_of_final_quote}
                                  </TableCell>
                                  <TableCell>{row.to_pay}</TableCell>
                                  <TableCell>
                                    {
                                      workStatusList.filter(
                                        (o) => o.value === row.work_status
                                      )[0].label
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {
                                      paymentStatusList.filter(
                                        (o) => o.value === row.payment_status
                                      )[0].label
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {
                                      ProjectScope.filter(
                                        (o) => o.value === row.project_scope
                                      )[0].label
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}

                        <TableRow class="regularWorkNpayments">
                          <TableCell colSpan={11}>
                            <span>Additonal & Reimbursements </span>
                          </TableCell>
                        </TableRow>
                        {data?.ws?.length &&
                          data.ws.map((row, idx) => {
                            if (
                              row.project_scope == 1 ||
                              row.project_scope == 3
                            ) {
                              return (
                                <TableRow
                                  key={idx}
                                  className={classNames({
                                    wipClr: row.work_status === 1,
                                    completedClr: row.work_status == 3,
                                  })}
                                >
                                  <TableCell>{row.id}</TableCell>
                                  <TableCell>{row.stage}</TableCell>
                                  <TableCell>{row.task}</TableCell>
                                  <TableCell>
                                    {Moment(row.start_date).format(
                                      yearMonthDateFormat
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {Moment(row.end_date).format(
                                      yearMonthDateFormat
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {Moment(row.payment_date).format(
                                      yearMonthDateFormat
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {row.percent_of_final_quote}
                                  </TableCell>
                                  <TableCell>{row.to_pay}</TableCell>
                                  <TableCell>
                                    {
                                      workStatusList.filter(
                                        (o) => o.value === row.work_status
                                      )[0].label
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {
                                      paymentStatusList.filter(
                                        (o) => o.value === row.payment_status
                                      )[0].label
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {
                                      ProjectScope.filter(
                                        (o) => o.value === row.project_scope
                                      )[0].label
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </Collapse>
            </fieldset>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default PmViews;
