import myDeleteReq from "../../utils/delete";
import myGetReq from "../../utils/get";
import myPostReq from "../../utils/post";

export const getprojectdisplaydata = async () => {
  try {
    return await myGetReq(`/app/usr/getprojectdisplaydata`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};

export const addProjectData = async (data) => {
  console.log(data, "data from addProjectData");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data));
  formdata.append("di", data.cover_image[0]);

  for (let i = 0; i < data.detail_images.length; i++) {
    formdata.append("di", data.detail_images[i]);
  }
  try {
    return await myPostReq("/app/usr/addprojectdisplaydata", formdata, {});
  } catch (err) {
    return err;
  }
};

export const updateaddprojectsdisplayphotos = async (data) => {
  console.log(data, "data from addProjectData");

  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data.Data));
  //formdata.append("di", data.cover_image[0])

  for (let i = 0; i < data.imgsData.length; i++) {
    formdata.append("di", data.imgsData[i]);
  }
  try {
    return await myPostReq(
      "/app/usr/updateaddprojectsdisplayphotos",
      formdata,
      {}
    );
  } catch (err) {
    return err;
  }
};

export const uploadprojectsdisplaycoverphoto = async (data) => {
  console.log(data, "data from addProjectData");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data.Data));
  formdata.append("di", data.newCoverImg[0]);

  try {
    return await myPostReq(
      "/app/usr/uploadprojectsdisplaycoverphoto",
      formdata,
      {}
    );
  } catch (err) {
    return err;
  }
};

export const GetDetailProjectDisplayData = async (id) => {
  console.log(id, "data from addProjectData");
  let jdata = JSON.stringify({ id });
  try {
    return await myPostReq("/app/usr/getdetailprojectdisplaydata", jdata);
  } catch (err) {
    return err;
  }
};

export const updateprojectdisplaydata = async (data) => {
  console.log(data, "data from addProjectData");
  let jdata = JSON.stringify({ data });
  try {
    return await myPostReq("/app/usr/updateprojectdisplaydata", jdata);
  } catch (err) {
    return err;
  }
};
export const deleteprojectsdisplayphoto = async (data) => {
  console.log(data, "data from addProjectData");
  let jdata = JSON.stringify({ data });
  try {
    return await myDeleteReq("/app/usr/deleteprojectsdisplayphoto", jdata);
  } catch (err) {
    return err;
  }
};

export const deleteprojectsdisplay = async (data) => {
  console.log(data, "data from addProjectData");
  let jdata = JSON.stringify({ data });
  try {
    return await myDeleteReq("/app/usr/deleteprojectsdisplay", jdata);
  } catch (err) {
    return err;
  }
};

export const UploadContectUsDetails = async (data) => {
  console.log(data, "data from addProjectData");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data));
  try {
    return await myPostReq("/app/usr/uploadContectus", formdata, {});
  } catch (err) {
    return err;
  }
};
export const verifyToken = async (token) => {
  try {
    console.log(token, "token dfdsfdsfdsfdsfdsfdsf");
    let response = await myPostReq(
      `/app/usr/verify-token`,
      JSON.stringify({ token })
    );
    return response;
  } catch (err) {
    return err;
  }
};
