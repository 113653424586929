import React, { useState, createContext, useEffect } from "react";
import { Route, Routes, matchPath, Link } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";
import LogIn from "../LogIn/index";
import Logout from "../Logout/index";
import Register from "../Register/index";
import UsersList from "../UsersList/index";
import AdminDashboard from "../Dashboard/AdminDashboard";
import ProjectView from "../ProjectView";
import PmViews from "../ProjectView/PmViews";
import CustomerViews from "../ProjectView/CustomerViews";
import SiteEngineerViews from "../ProjectView/SiteEngineerViews";
import Ledger from "../Ledger";
import PMLedger from "../Ledger/pmLedger";
import WorkOrder from "../WorkOrders";
import ViewWorkOrders from "../WorkOrders/ViewWorkOrders";
import DetailedWorkOrderView from "../WorkOrders/DetailedWorkOrderView";
import CreatePO from "../PurchaseOrders/CreatePO";
import ViewPO from "../PurchaseOrders/ViewPO";
import { Alert } from "reactstrap";
import { AppContext } from "../../Context/appContext";
import CustomerDashboard from "../Dashboard/CustomerDashboard/CustomerDashboard";
import SiteEngineerDashboard from "../Dashboard/SEDashboard/SiteEngineerDashboard";
import Requests from "../Finance/Requests";
import CreateProject from "../CreateProject";
import Leads from "../Leads";
import PrivateRouter, { SmartPrivateRouter } from "./PrivateRouter";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import ViewImage from "../ViewImage";
import Project from "../Project";
import VendorsList from "../PurchaseOrders/VendorsList";
import Attendance from "../LabourAttendence&WorkDone";
import Taskmanager from "../TaskManager/Taskmanager";
import { ChakraProvider } from "@chakra-ui/react";
import TaskChart from "../TaskManager/TaskChart";
import Accesscontrol from "../AccessControl/Accesscontrol";
import HFL_Router from "../../HF_LandingPages/HFL_Router";
import ProjectsDisplay from "../ProjectsDisplay/ProjectsDisplay";
import ProjectDisplayEdit from "../ProjectsDisplay/ProjectDisplayEdit/ProjectDisplayEdit";
import ProjectDisplayEditor from "../ProjectsDisplay/ProjectDisplayEdit/ProjectDisplayEditor";
import Expenditure from "../Expenditure/Expenditure";
import Pdftemplate from "../ProjectView/PDFDownload/Pdftemplate";
import { PDFViewer } from "@react-pdf/renderer";
import ComponentPdf from "../ProjectView/PDFDownload/ComponentPdf";
import RouterUQ from "./UserQuotation/RouterUQ";
import GeoLocationTracker from "../GeoLocation/GeoLocationTracker";
import LocationTracker from "../GeoLocation/LocationTracker";
import Rental from "../Rental";
import ComponentPdfMail from "../ProjectView/PDFMailDownload/ComponentPdfMail";
import ViewImage2 from "../ViewImage2";
import UnitDashboard from "../Rental/Unit Dashborad/Index";
import ViewImage3 from "../ViewImage3";
const AllRoutes = () => {
  const loginState = useSelector((state) => state.login);
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  //console.log(role, "ryujyrujkm")

  function checkAccess() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com"
    ) {
      return true;
    }
    return false;
  }
  function checkAccess22() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com" ||
      useremail == "nehag.ehf@gmail.com"
    ) {
      return true;
    }
    return false;
  }

  return (
    <Routes>
      <Route
        path="/users-list"
        element={
          <SmartPrivateRouter charat={"1"}>
            <UsersList />
          </SmartPrivateRouter>
        }
      />

      <Route
        path="ledger"
        element={
          <SmartPrivateRouter charat={"3"}>
            <Ledger />
          </SmartPrivateRouter>
        }
      />

      <Route
        path="wo-view"
        element={
          <SmartPrivateRouter charat={"8"}>
            <ViewWorkOrders />
          </SmartPrivateRouter>
        }
      />

      <Route
        path="wo-detailed-view/:pid/:wid"
        element={
          <SmartPrivateRouter charat={"8"}>
            <ChakraProvider>
              <DetailedWorkOrderView />
            </ChakraProvider>
          </SmartPrivateRouter>
        }
      />

      <Route
        path="wo-create"
        element={
          <SmartPrivateRouter charat={"9"}>
            <WorkOrder />
          </SmartPrivateRouter>
        }
      />

      <Route
        path="wo-create/:wid/:selectedpage"
        element={
          <SmartPrivateRouter charat={"10"}>
            <WorkOrder />
          </SmartPrivateRouter>
        }
      />

      <Route
        path="po-create"
        element={
          <SmartPrivateRouter charat={"12"}>
            <CreatePO />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="locationtracker"
        element={
          <ChakraProvider>
            <LocationTracker />
          </ChakraProvider>
        }
      />
      <Route
        path="po-edit/:poId"
        element={
          <SmartPrivateRouter charat={"13"}>
            <CreatePO />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="po-view"
        element={
          <SmartPrivateRouter charat={"11"}>
            <ViewPO />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="vendors-list"
        element={
          <SmartPrivateRouter charat={"14"}>
            <VendorsList />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="requests"
        element={
          <SmartPrivateRouter charat={"15"}>
            <Requests />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="labour-attendance/*"
        element={
          <SmartPrivateRouter charat={"17"}>
            <Attendance pathname={`/app/${role}/labour-attendance`} />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="leads/*"
        element={<Leads pathname={`/app/${role}/leads`} />}
      />
      <Route
        path="view/:pid"
        element={
          <SmartPrivateRouter charat={"25"}>
            {role == "se" ? (
              <SiteEngineerViews />
            ) : role == "pm" ? (
              <PmViews />
            ) : role == "customer" ? (
              <CustomerViews />
            ) : (
              <ProjectView />
            )}
          </SmartPrivateRouter>
        }
      />

      <Route
        path="/dashboard"
        element={
          <SmartPrivateRouter charat={"25"}>
            {role == "se" ? (
              <SiteEngineerDashboard />
            ) : role == "customer" ? (
              <CustomerDashboard />
            ) : (
              <AdminDashboard />
            )}
          </SmartPrivateRouter>
        }
      />

      <Route
        path="create-project"
        element={
          <SmartPrivateRouter charat={"26"}>
            <CreateProject />
          </SmartPrivateRouter>
        }
      ></Route>

      <Route
        path="create-project/:pid/:selectedPage"
        element={
          <SmartPrivateRouter charat={"27"}>
            <CreateProject />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="project/*"
        element={<Project pathname={`/app/${role}/project`} />}
      />

      <Route
        path="tasks"
        element={
          <SmartPrivateRouter charat={"31"}>
            <ChakraProvider>
              <TaskChart />
            </ChakraProvider>
          </SmartPrivateRouter>
        }
      />
      <Route
        path="accesscontrol"
        element={
          checkAccess() && (
            <ChakraProvider>
              <Accesscontrol />
            </ChakraProvider>
          )
        }
      />
      <Route
        path="projectsdisplay"
        element={
          checkAccess() && (
            <ChakraProvider>
              <ProjectsDisplay />
            </ChakraProvider>
          )
        }
      />
      <Route
        path="expenditure"
        element={
          checkAccess22() && (
            <ChakraProvider>
              <Expenditure />
            </ChakraProvider>
          )
        }
      />
      <Route
        path="projectsdisplayedit"
        element={
          checkAccess() && (
            <ChakraProvider>
              <ProjectDisplayEdit />
            </ChakraProvider>
          )
        }
      />
      <Route
        path="projectsdisplayedit/:id"
        element={
          checkAccess() && (
            <ChakraProvider>
              <ProjectDisplayEditor />
            </ChakraProvider>
          )
        }
      />
      <Route
        path="Building/*"
        element={<Rental pathname={`/app/${role}/Building`} />}
      />
      <Route
        path="UnitDashboard/"
        element={<UnitDashboard pathname={`/app/${role}/UnitDashboard`} />}
      />

      <Route path="*" element={<div> 404 Not found</div>} />
    </Routes>
  );
};

const WrappedRoutes = () => {
  const loginState = useSelector((state) => state.login);
  const [WpsAddAdditionalWork, setWpsAddAdditionalWork] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ txt: "", clr: "success" });
  const onDismissAlert = () => {
    setAlertMsg({});
  };

  const setAlertFunc = (obj) => {
    setAlertMsg({
      txt: obj.txt,
      clr: obj.clr,
    });
    setTimeout(() => {
      setAlertMsg({});
    }, 5000);
  };

  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }
  console.log(role, "etthrhrfhtfdrh");

  const appCtx = {
    setAlertFunc: setAlertFunc,
    alertMsg: alertMsg,
    setWpsAddAdditionalWork,
    WpsAddAdditionalWork,
  };

  return (
    <AppContext.Provider value={appCtx}>
      <div>
        <Layout />
        <div className="container__wrap">
          <ChakraProvider>
            {" "}
            <Taskmanager />
          </ChakraProvider>

          <Alert
            color={alertMsg.clr}
            isOpen={!!alertMsg.txt}
            toggle={onDismissAlert}
          >
            {alertMsg.txt}
          </Alert>
          <Routes>
            {
              <Route
                path={`/${role}/*`}
                element={
                  <SmartPrivateRouter charat={"0"}>
                    <AllRoutes />
                  </SmartPrivateRouter>
                }
              />
            }
            {/* 
            {loginState.userDetails.role_id == 8 && (
              <Route path="/pm/*" element={<PmPages />} />
            )}

            {loginState.userDetails.role_id == 8 && (
              <Route path="/se/*" element={<AdminPages />} />
            )}

            {loginState.userDetails.role_id == 4 && (
              <Route path="/customer/*" element={<CustomerPages />} />
            )} */}
            {/* {loginState.userDetails.role_id == 7 && (
              <Route
                path="/user/dashboard"
                element={
                  <>
                    No Projects are assigned.
                    <Link to={`/`}>Click here to go to Home Page.</Link>
                  </>
                }
              />
            )} */}
            {/* {loginState.userDetails.role_id == 9 && (
              <Route path="/sales/*" element={<SalesPages />} />
            )} */}
            <Route path="*" element={<div>404 Not found</div>} />
          </Routes>
        </div>
      </div>
    </AppContext.Provider>
  );
};

const Router = () => {
  return (
    <MainWrapper>
      <main>
        <Routes>
          {console.log("slslfsflsjfl")}
          <Route path="/app/login" element={<LogIn />} />
          <Route path="/app/logout" element={<Logout />} />
          <Route path="/app/register" element={<Register />} />
          <Route path="/app/vimg/:id" element={<ViewImage />} />
          <Route path="/app/vimg2/:id" element={<ViewImage2 />} />
          {/* <Route
            path="/app/admin/Building/ledger/vimgArr/"
            element={<ViewImage3 />}
          /> */}
          <Route path="/user/*" element={<RouterUQ />} />

          <Route
            path="/app/*"
            element={
              <PrivateRouter>
                <WrappedRoutes />
              </PrivateRouter>
            }
          />
          <Route path="/app/hf/*" element={<HFL_Router />} />
          <Route path="app/admin/view/pdf" element={<ComponentPdf />} />
          <Route path="app/customer/view/pdf" element={<ComponentPdf />} />
          <Route path="app/admin/view/pdfmail" element={<ComponentPdfMail />} />
          <Route path="*" element={<LogIn />} />
        </Routes>
      </main>
    </MainWrapper>
  );
};
export default Router;

// const PmPages = () => {
//   return (
//     <Routes>
//       <Route
//         path="/dashboard"
//         element={
//           <PrivatePMRouter>
//             <AdminDashboard />
//           </PrivatePMRouter>
//         }
//       />
//       <Route
//         path="/create-project"
//         element={
//           <PrivatePMRouter>
//             <CreateProject />
//           </PrivatePMRouter>
//         }
//       />

//       <Route
//         path="view/:pid"
//         element={
//           <PrivatePMRouter>
//             <PmViews />
//           </PrivatePMRouter>
//         }
//       />

//       <Route
//         path="create-project/:pid"
//         element={
//           <PrivatePMRouter>
//             <CreateProject />
//           </PrivatePMRouter>
//         }
//       />

//       <Route
//         path="ledger"
//         element={
//           <PrivatePMRouter>
//             <Ledger />
//           </PrivatePMRouter>
//         }
//       />

//       <Route
//         path="wo-create"
//         element={
//           <PrivatePMRouter>
//             <WorkOrder />
//           </PrivatePMRouter>
//         }
//       />

//       <Route
//         path="wo-create/:wid/:selectedpage"
//         element={
//           <PrivatePMRouter>
//             <WorkOrder />
//           </PrivatePMRouter>
//         }
//       />

//       <Route
//         path="wo-view"
//         element={
//           <PrivatePMRouter>
//             <ViewWorkOrders />
//           </PrivatePMRouter>
//         }
//       />

//       <Route
//         path="wo-detailed-view/:pid/:wid"
//         element={
//           <PrivatePMRouter>
//             <DetailedWorkOrderView />
//           </PrivatePMRouter>
//         }
//       />
//       <Route
//         path="/labour-attendance/*"
//         element={
//           // <PrivateAdminRouter>
//           <PrivatePMRouter>
//             <Attendance pathname={"/app/pm/labour-attendance"} />
//           </PrivatePMRouter>
//           // </PrivateAdminRouter>
//         }
//       />
//     </Routes>
//   );
// };

// const CustomerPages = () => {
//   return (
//     <Routes>
//       <Route
//         path="/dashboard"
//         element={
//           <PrivateCustomerRouter>
//             <CustomerDashboard />
//           </PrivateCustomerRouter>
//         }
//       />
//       <Route
//         path="view/:pid"
//         element={
//           <PrivateCustomerRouter>
//             <CustomerViews />
//           </PrivateCustomerRouter>
//         }
//       />
//     </Routes>
//   );
// };

// const SePages = () => {
//   return (
//     <Routes>
//       <Route
//         path="/dashboard"
//         element={
//           <PrivateSERouter>
//             <SiteEngineerDashboard />
//           </PrivateSERouter>
//         }
//       />

//       <Route
//         path="view/:pid"
//         element={
//           <PrivateSERouter>
//             <SiteEngineerViews />
//           </PrivateSERouter>
//         }
//       />

//       <Route
//         path="po-create"
//         element={
//           <PrivateSERouter>
//             <CreatePO />
//           </PrivateSERouter>
//         }
//       />

//       <Route
//         path="po-edit/:poId"
//         element={
//           <PrivateSERouter>
//             <CreatePO />
//           </PrivateSERouter>
//         }
//       />
//       <Route
//         path="po-view"
//         element={
//           <PrivateSERouter>
//             <ViewPO />
//           </PrivateSERouter>
//         }
//       />
//       <Route
//         path="/labour-attendance/*"
//         element={
//           <PrivateSERouter>
//             <Attendance pathname={"/app/se/labour-attendance"} />
//           </PrivateSERouter>
//         }
//       />
//     </Routes>
//   );
// };

// const SalesPages = () => {
//   return (
//     <Routes>
//       <Route
//         path="/leads/*"
//         element={<Leads pathname={"/app/sales/leads"} />}
//       />
//       <Route
//         path="/labour-attendance/*"
//         element={
//           // <PrivateAdminRouter>
//           <Attendance pathname={"/app/sales/labour-attendance"} />
//           // </PrivateAdminRouter>
//         }
//       />
//     </Routes>
//   );
// };
