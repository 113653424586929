import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function binaryconverter(hexadecimal) {

  let decimal = parseInt(hexadecimal, 16)
  let binary = (decimal).toString(2)
  return binary
}


function PrivateRouter({ children }) {
  const location = useLocation();

  let isLoggedin = localStorage.getItem("isLoggedin");

  if (isLoggedin) {
    return children;
  }
  return <Navigate to="/app/login" state={{ from: location }} replace />;
}


export function SmartPrivateRouter({ children, charat }) {
  // console.log(role)
  const loginState = useSelector((state) => state.login);
  const location = useLocation();

  let isLoggedin = localStorage.getItem("isLoggedin");

  if (isLoggedin) {
    if (
      binaryconverter(loginState.userDetails.access_control).charAt(charat) == 1
    ) {
      return children;
    }
  }
  return <Navigate to={`/app/dashboard`} state={{ from: location }} replace />;
}



export default PrivateRouter



// export function PrivatePMRouter({ children }) {
//   const loginState = useSelector((state) => state.login);
//   const location = useLocation();

//   let isLoggedin = localStorage.getItem("isLoggedin");

//   if (isLoggedin) {
//     if (loginState.userDetails.role_id === 8) {
//       return children;
//     } else if (
//       loginState.userDetails.role_id === 1 ||
//       loginState.userDetails.role_id === 8
//     ) {
//       return (
//         <Navigate
//           to="/app/admin/dashboard"
//           state={{ from: location }}
//           replace
//         />
//       );
//     } else if (loginState.userDetails.role_id === 3) {
//       return (
//         <Navigate to="/app/se/dashboard" state={{ from: location }} replace />
//       );
//     } else if (loginState.userDetails.role_id === 4) {
//       return (
//         <Navigate
//           to="/app/customer/dashboard"
//           state={{ from: location }}
//           replace
//         />
//       );
//     }
//     //return children;
//   }
//   return <Navigate to="/app/login" state={{ from: location }} replace />;
// }

// export function PrivateCustomerRouter({ children }) {
//   const loginState = useSelector((state) => state.login);
//   const location = useLocation();
//   let isLoggedin = localStorage.getItem("isLoggedin");

//   if (isLoggedin) {
//     if (loginState.userDetails.role_id === 8) {
//       return children;
//     } else if (loginState.userDetails.role_id === 2) {
//       return (
//         <Navigate to="/app/pm/dashboard" state={{ from: location }} replace />
//       );
//     } else if (loginState.userDetails.role_id === 3) {
//       return (
//         <Navigate to="/app/se/dashboard" state={{ from: location }} replace />
//       );
//     } else if (
//       loginState.userDetails.role_id === 1 ||
//       loginState.userDetails.role_id === 8
//     ) {
//       return (
//         <Navigate
//           to="/app/admin/dashboard"
//           state={{ from: location }}
//           replace
//         />
//       );
//     }
//     //return children;
//   }
//   return <Navigate to="/app/login" state={{ from: location }} replace />;
// }

// export function PrivateSERouter({ children }) {
//   const loginState = useSelector((state) => state.login);
//   const location = useLocation();

//   let isLoggedin = localStorage.getItem("isLoggedin");

//   if (isLoggedin) {
//     if (loginState.userDetails.role_id === 8) {
//       return children;
//     } else if (loginState.userDetails.role_id === 2) {
//       return (
//         <Navigate to="/app/pm/dashboard" state={{ from: location }} replace />
//       );
//     } else if (loginState.userDetails.role_id === 4) {
//       return (
//         <Navigate
//           to="/app/customer/dashboard"
//           state={{ from: location }}
//           replace
//         />
//       );
//     } else if (
//       loginState.userDetails.role_id === 1 ||
//       loginState.userDetails.role_id === 8
//     ) {
//       return (
//         <Navigate
//           to="/app/admin/dashboard"
//           state={{ from: location }}
//           replace
//         />
//       );
//     }
//     //return children;
//   }
//   return <Navigate to="/app/login" state={{ from: location }} replace />;
// }


//
// export function PrivateAdminRouter({ children }) {
//   const loginState = useSelector((state) => state.login);
//   const location = useLocation();

//   let isLoggedin = localStorage.getItem("isLoggedin");

//   if (isLoggedin) {
//     if (
//       loginState.userDetails.role_id === 1 ||
//       loginState.userDetails.role_id === 8
//     ) {
//       return children;
//     } else if (loginState.userDetails.role_id === 2) {
//       return (
//         <Navigate to="/app/pm/dashboard" state={{ from: location }} replace />
//       );
//     } else if (loginState.userDetails.role_id === 3) {
//       return (
//         <Navigate to="/app/se/dashboard" state={{ from: location }} replace />
//       );
//     } else if (loginState.userDetails.role_id === 4) {
//       return (
//         <Navigate
//           to="/app/customer/dashboard"
//           state={{ from: location }}
//           replace
//         />
//       );
//     }
//     //return children;
//   }
//   return <Navigate to="/app/login" state={{ from: location }} replace />;
// }
