
const myUpdateReq = async (url, data,
    headers = new Headers({ 'Content-Type': 'application/json' })) => {

    console.log('updating data', data);
    try {
        console.log('fetching');
        const res = await fetch(url, {
            body: data,
            withCredentials: true,
            headers: headers,
            method: 'PUT',
            mode: 'cors'
        });

        if (!res.ok) {
            window.location.href = '/app/login';
        }

        return await res.json();
    } catch (err) {
        return await err;
    }
}

export default myUpdateReq;