import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Button, Stack, Typography } from "@mui/material";
import HFBox from "../../../Components/HFBox";
import HFHeader from "../../../Components/HFHeader";
import SimpleTable from "../../../Components/SimpleTable";
import HFSnackbar from "../../../Components/HFSnackbar";
import get from "../../../utils/get";
import AddConsumption from "./MaterialActions";
import Link from "@mui/material/Link";
import AddRequirement from "./AddRequirement";
import MaterialDetails from "./MaterialDetails";

const ProjectMaterials = (props) => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [material, setMaterial] = useState();

  const [snackbar, setSnackbar] = useState({
    status: false,
    type: "success",
    msg: "success",
  });

  const getData = () => {
    get(`/app/usr/project-materials?id=${id}`)
      .then((res) => {
        let temp = {
          project: res.data.project,
          summary: [],
          materialList: [],
          unitList: [],
          milestoneList: [],
        };
        for (let i = 0; i < res.data.materialList.length; i++) {
          if (
            res.data.materialList[i].required > 0 ||
            res.data.materialList[i].ordered > 0 ||
            res.data.materialList[i].consumed > 0
          ) {
            temp.summary.push(res.data.materialList[i]);
          }
          temp.materialList.push({
            id: res.data.materialList[i].id,
            material: res.data.materialList[i].material,
            unit: res.data.materialList[i].unit,
          });
          temp.unitList[res.data.materialList[i].unit] =
            res.data.materialList[i].unit;
        }
        temp.milestoneList = res.data.milestones;
        setData(temp);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const MaterialComponent = ({ row }) => {
    console.log("component", row);
    return (
      <Link
        component="button"
        onClick={() => {
          setMaterial(row);
        }}
      >
        {row.material}
      </Link>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    data && (
      <>
        <Container>
          <HFHeader
            title={`Material Tracking - ${data?.project?.project_title}`}
          />
          <HFBox title="Add Material Requirement">
            <AddRequirement
              project={data.project}
              materialList={data.materialList}
              unitList={data.unitList}
              milestoneList={data.milestoneList}
              setSnackbar={setSnackbar}
              getData={getData}
            />
          </HFBox>
          {data.summary.length > 0 && (
            <HFBox title="Material Summary">
              <SimpleTable
                headers={[
                  {
                    key: "material",
                    value: "Material",
                    component: MaterialComponent,
                  },
                  { key: "unit", value: "Unit" },
                  { key: "required", value: "Total Required" },
                  { key: "ordered", value: "Total Received" },
                  { key: "consumed", value: "Total Consumed" },
                  { key: "remaining", value: "Current Stock" },
                ]}
                body={data.summary}
                action={AddConsumption}
                params={data.project}
                getData={getData}
              />
            </HFBox>
          )}
        </Container>
        {material && (
          <MaterialDetails
            material={material}
            setMaterial={setMaterial}
            project={data.project}
          />
        )}
        <HFSnackbar
          snackbar={snackbar}
          handleClose={() => {
            setSnackbar({ ...snackbar, ["status"]: false });
          }}
        />
      </>
    )
  );
};
export default ProjectMaterials;
