import { Route, Routes } from "react-router-dom";
import AddProject from "./AddProject";
import UpdateProject from "./UpdateProject";
import ProjectExpenses from "./ProjectLedger";
import ProjectMaterials from "./ProjectMaterials";
import { SmartPrivateRouter } from "../App/PrivateRouter";

const Project = () => {
  return (
    <Routes>
      <Route path="/ledger/:id" element={
        <SmartPrivateRouter charat={"28"} >
          <ProjectExpenses />
        </SmartPrivateRouter>
      } />
      <Route path="/material/:id" element={
        <SmartPrivateRouter charat={"29"} >
          <ProjectMaterials />
        </SmartPrivateRouter>
      } />
      {/* <Route path="/:id" element={<UpdateProject />} />
      <Route path="*" element={<AddProject />} /> */}
    </Routes>
  );
};
export default Project;
