import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { Row, Col } from "reactstrap";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import HFBox from "../../../Components/HFBox";
import HFHeader from "../../../Components/HFHeader";
import get from "../../../utils/get";
import post from "../../../utils/post";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import HFDialog from "../../../Components/HFDialog";
import SimpleTable from "../../../Components/SimpleTable";
import moment from "moment/moment";

const VerticalChart = (props) => {
  console.log("bar-graph-data", props);
  return (
    <div style={{ width: "100%", height: props.data.length * 30 }}>
      <ResponsiveContainer>
        <BarChart
          layout="vertical"
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onClick={props.onClick}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="amount"
            orientation="top"
            allowDecimals={false}
            interval="preserveStartEnd"
            ticks={[
              25000, 50000, 75000, 100000, 125000, 150000, 175000, 200000,
              225000, 250000, 275000, 300000, 325000, 350000, 375000, 400000,
              425000, 450000, 475000, 500000, 525000, 550000, 575000, 600000,
              625000, 650000, 675000, 700000, 725000, 750000, 775000, 800000,
              825000, 850000, 875000, 900000,
            ]}
          />
          <YAxis
            type="category"
            dataKey="category"
            tickCount={props.data.length}
            width={120}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="amount" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const HorizontalChart = (props) => {
  console.log("bar-graph-data", props);
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onClick={props.onClick}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tickCount={props.data.length} />
          <YAxis
            dataKey="amount"
            allowDecimals={false}
            tickCount={8}
            interval="preserveEnd"
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="amount" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const HFPaper = ({ value, title, ...props }) => {
  return (
    <Paper
      sx={{ py: 2, px: 4, borderRadius: 3, width: "100%" }}
      elevation={2}
      {...props}
    >
      <Typography sx={{ fontSize: 20 }}>{value}</Typography>
      <Typography variant="caption">{title}</Typography>
    </Paper>
  );
};

const ExpenseSmmary = ({ project }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 2,
      }}
    >
      <HFPaper title="Payment Received" value={project.income} />
      <HFPaper
        title="Project Expense"
        value={project.expenses}
        className="mx-3"
      />
      <HFPaper
        title="Remaining Balance"
        value={project.income - project.expenses}
      />
    </Box>
  );
};

const ProjectExpenses = (props) => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [dialog, setDialog] = useState({ status: false, title: "" });
  const [expenseList, setExpenseList] = useState([]);

  useEffect(() => {
    get(`/app/usr/project-ledgers?id=${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const handleClick = (e) => {
    console.log("clicked", e);
    const payload = e.activePayload[0].payload;
    const title = `Expenses for ${
      payload.month
        ? moment(payload.month).format("MMM-YYYY")
        : payload.category
    }`;
    post(
      `/app/usr/project-ledger-details`,
      JSON.stringify({
        id: id,
        payload: payload,
      })
    )
      .then((res) => {
        setExpenseList(res.data);
        setDialog({ status: true, title: title });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      <Container className="dashboard">
        <HFHeader
          title={`Project Expensed - ${data?.project?.project_title}`}
        />
        {data && <ExpenseSmmary project={data.project} />}
        <HFBox title="Expenses by Months">
          {data && (
            <HorizontalChart data={data.byMonth} onClick={handleClick} />
          )}
        </HFBox>
        <HFBox title="Expenses by Category">
          {data && (
            <VerticalChart data={data.byCategory} onClick={handleClick} />
          )}
        </HFBox>
      </Container>
      <HFDialog
        open={dialog.status}
        title={dialog.title}
        handleClose={() => {
          setDialog(false);
        }}
        width="md"
      >
        <SimpleTable
          headers={["Category", "Amount", "Comments", "Date"]}
          body={expenseList}
        />
      </HFDialog>
    </>
  );
};
export default ProjectExpenses;
