import { Route, Routes } from "react-router-dom";
// import LeadList from "./LeadList";
// import AddLead from "./AddLead";
// import UpdateLead from "./UpdateLead";
// import Dashboard from "./LeadDashboard";
import LabourAttendance from "./labourAttendance";
import LabourView from "./LabourView";
import LabourDashboard from "./LabourDashboard";
import { ChakraProvider } from "@chakra-ui/react";
import { SmartPrivateRouter } from "../App/PrivateRouter";
const Attendance = ({ pathname }) => {
  console.log("hello world");
  return (
    <Routes>
      <Route
        path="*"
        element={
          <SmartPrivateRouter charat={"19"}>
            <LabourAttendance pathname={pathname} type="all" />
          </SmartPrivateRouter>
        }
      />
      <Route
        path="dashboard"
        element={
          // <ChakraProvider>
          <SmartPrivateRouter charat={"18"}>
            <LabourDashboard pathname={pathname} />
          </SmartPrivateRouter>
          //  </ChakraProvider>
        }
      />
      <Route
        path="view"
        element={
          <ChakraProvider>
            <SmartPrivateRouter charat={"17"}>
              <LabourView pathname={pathname} type="all" />
            </SmartPrivateRouter>
          </ChakraProvider>
        }
      />
    </Routes>
  );
};
export default Attendance;
