import myDeleteReq from "../../utils/delete";
import myGetReq from "../../utils/get";
import myPostReq from "../../utils/post";

export const SendMailLead = async (data, attachmt) => {
  // console.log(data, "data from addProjectData");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data));
  for (var i = 0; i < attachmt.length; i++) {
    formdata.append("attachments", attachmt[i]);
  }
  try {
    return await myPostReq(
      "/app/usr/sendMailLead",
      formdata,
      {}
      // ,new Headers({ "Content-Type": "multipart/form-data" })
    );
  } catch (err) {
    return err;
  }
};
