import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Moment from "moment";

import { useForm } from "react-hook-form";
import {
  ProjectScope,
  workStatusList,
  paymentStatusList,
} from "../../shared/helpers/constants";

import { FormField } from "./FormField";
import Select from "react-select";

import DatepickerField from "../Datepicker/DatepickerField";

const schema = yup.object().shape({
  startDate: yup.string().required("This field is required"),
  endDate: yup.string().required("This field is required"),
  payDate: yup.string().required("This field is required"),
});

const WpsForm = ({ initialValues, onSubmit, toggleModal, disabledPay }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [payDate, setPayDate] = useState(new Date());
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    let ps = ProjectScope.filter((o) => o.value === initialValues.ProjectScope);
    if (ps.length) setValue("ProjectScope", ps[0]);

    let payStatus = paymentStatusList.filter(
      (o) => o.value === initialValues.paymentStatus
    );
    if (payStatus.length) setValue("paymentStatus", payStatus[0]);

    let ws = workStatusList.filter((o) => o.value === initialValues.workStatus);
    if (ws.length) setValue("workStatus", ws[0]);

    setValue("startDate", initialValues.startDate);
    setValue("endDate", initialValues.endDate);
    setValue("payDate", initialValues.payDate);
    setStartDate(initialValues.startDate);
    setEndDate(initialValues.endDate);
    setPayDate(initialValues.payDate);
  }, [initialValues]);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <span className="form__form-group-label">Stage</span>
              <div className="form__form-group-field">
                <FormField
                  name="stage"
                  control={control}
                  component="input"
                  errors={errors}
                  defaultValue=""
                  placeholder="Stage"
                />
              </div>
            </div>
            <div className="form__half">
              <span className="form__form-group-label">Task</span>
              <div className="form__form-group-field">
                <FormField
                  name="task"
                  control={control}
                  component="input"
                  errors={errors}
                  defaultValue=""
                  placeholder="Task"
                />
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group-field">
                <FormField
                  name="startDate"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <DatepickerField
                      {...field}
                      control={control}
                      selected={startDate}
                      onChange={(event) => {
                        setValue("startDate", event);
                        setStartDate(event);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                  )}
                />
              </div>
              {errors?.startDate && (
                <span className="form__form-group-error">
                  Please enter a valid start date
                </span>
              )}
            </div>
            <div className="form__half">
              <div className="form__form-group-field">
                <FormField
                  name="endDate"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <DatepickerField
                      {...field}
                      control={control}
                      selected={endDate}
                      onChange={(event) => {
                        setValue("endDate", event);
                        setEndDate(event);
                      }}
                      dateFormat="dd-MM-yyyy"
                      // disabled={!row.isEditable}
                    />
                  )}
                />
              </div>
              {errors?.project_title?.value?.message && (
                <span className="form__form-group-error">
                  "This is required field
                </span>
              )}
            </div>
            <div className="form__half">
              <div className="form__form-group-field">
                <FormField
                  name="payDate"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <DatepickerField
                      {...field}
                      control={control}
                      selected={payDate}
                      onChange={(event) => {
                        setValue("payDate", event);
                        setPayDate(event);
                      }}
                      dateFormat="dd-MM-yyyy"
                      // disabled={!row.isEditable}
                    />
                  )}
                />
              </div>
              {errors?.project_title?.value?.message && (
                <span className="form__form-group-error">
                  "This is required field
                </span>
              )}
            </div>

            <div className="form__half">
              <span className="form__form-group-label">% of Final Quote</span>
              <div className="form__form-group-field">
                <FormField
                  name="finalQuote"
                  control={control}
                  component="input"
                  errors={errors}
                  defaultValue=""
                  placeholder="final Quote"
                />
              </div>
            </div>
            <div className="form__half">
              <span className="form__form-group-label">To Pay</span>
              <div className="form__form-group-field">
                <FormField
                  name="toPay"
                  control={control}
                  component="input"
                  errors={errors}
                  defaultValue=""
                  disabled={disabledPay}
                />
              </div>
              {errors?.project_title?.value?.message && (
                <span className="form__form-group-error">
                  "This is required field
                </span>
              )}
            </div>
            <div className="form__half">
              <span className="form__form-group-label">Paid</span>
              <div className="form__form-group-field">
                <FormField
                  name="paid"
                  control={control}
                  component="input"
                  errors={errors}
                  // disabled={disabledPay}
                  defaultValue=""
                />
              </div>
              {errors?.project_title?.value?.message && (
                <span className="form__form-group-error">
                  "This is required field
                </span>
              )}
            </div>

            <div className="form__half">
              <span className="form__form-group-label">Work Status</span>
              <div className="form__form-group-field">
                <FormField
                  name="workStatus"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={value}
                      {...field}
                      // onChange={onChangePaymentMode}
                      options={workStatusList}
                      clearable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__half">
              <span className="form__form-group-label">Payment Status</span>
              <div className="form__form-group-field">
                <FormField
                  name="paymentStatus"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={value}
                      {...field}
                      // onChange={onChangePaymentMode}
                      options={paymentStatusList}
                      clearable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__half">
              <span className="form__form-group-label">Project Scope</span>
              <div className="form__form-group-field">
                <FormField
                  name="ProjectScope"
                  control={control}
                  component="creatableSelect"
                  errors={errors}
                  selectRender={({ field }) => (
                    <Select
                      // value={value}
                      {...field}
                      // onChange={onChangePaymentMode}
                      options={ProjectScope}
                      clearable={false}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
            </div>

            <div className="form__form-group">
              <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                <Button color="primary" type="submit" className="next">
                  Submit
                </Button>
                <Button type="button" onClick={() => toggleModal(false)}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WpsForm;
