import { Box, Button, CircularProgress, Editable, EditableInput, EditablePreview, EditableTextarea, Grid, Image, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetDetailProjectDisplayData, deleteprojectsdisplay, deleteprojectsdisplayphoto, updateaddprojectsdisplayphotos, updateprojectdisplaydata, uploadprojectsdisplaycoverphoto } from '../../../redux/actions/projectsData'
import { useEffect } from 'react'
import ProImg from './ProImg'

import styles from "./procss.module.css"
import Droper from '../Droper'
import ProAleartBox from './ProAleartBox'

const ProjectDisplayEditor = () => {
    let { id } = useParams()


    const [Data, setData] = useState({})

    const [imgsData, setimgsData] = useState([])

    const [load, setload] = useState(false)

    const [Order, setOrder] = useState([])

    const [newCoverImg, setnewCoverImg] = useState([])


    function getDetailProjectDisplayData(reset) {
        console.log("getDetailProjectDisplayData")
        setload(false)
        GetDetailProjectDisplayData(id).then((res) => {
            if (reset) {
                toast({
                    title: 'Reset Successfull.',
                    description: "Project Data Reset Successfull",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
            setData(res.res.rows[0])
            setOrder(res.res.rows[0].detail_images_order)
            setload(true)
        }).catch((res) => {
            toast({
                title: 'Request Unsuccessfull.',
                description: "Internal Error",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    // console.log(Data, "hgguh")

    function handleOrder(ele, val) {

        //  console.log(ele, val, "dsdf")
        if (val) {
            setOrder([...Order, ele])
        } else {
            let newOrder = Order.filter((el) => el != ele)
            setOrder([...newOrder])
        }
    }

    let navigate = useNavigate()


    useEffect(() => {
        getDetailProjectDisplayData()
    }, [])

    function handleupdate() {
        setload(false)
        updateprojectdisplaydata({ Order, Data }).then((res) => {
            // console.log(res, "hgsdbvchujsbvkhj")
            if (res.status) {
                getDetailProjectDisplayData()
                toast({
                    title: 'Updated Successfully.',
                    description: "Project Updated Successfully",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                setload(true)
                toast({
                    title: 'Update unsuccessfull.',
                    description: "Internal Error",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }

        })

    }
    const toast = useToast()

    function handledeleteproject() {
        setload(false)
        deleteprojectsdisplay({ Data }).then((res) => {
            toast({
                title: 'Deleted Successfully.',
                description: "Entire Project is Deleted",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            navigate("../projectsdisplayedit")
        }).catch((res) => {
            setload(true)
            toast({
                title: 'Deleted Unsuccessfull.',
                description: "Internal Error",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    function handleediting(val) {
        // console.log("handleediting", val)
        let { value, name } = val
        setData(
            {
                ...Data,
                [name]: value
            }
        )
    }

    function handlenewimgs(e) {
        setimgsData(e)
    }

    function handleaddphotos() {
        setload(false)
        updateaddprojectsdisplayphotos({ imgsData, Data }).then((res) => {
            if (res.status) {
                getDetailProjectDisplayData()
                setimgsData([])
                toast({
                    title: 'Photo added Successfully.',
                    description: "New Photos Added Successfully",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

            } else {
                toast({
                    title: 'Photo Adding Unsuccessfull.',
                    description: "Internal Error",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }

    function handlephotodelete(ele) {
        setload(false)
        deleteprojectsdisplayphoto({ ele, Data }).then((res) => {
            console.log(res, "94 resss")
            if (res.status) {
                getDetailProjectDisplayData()
                toast({
                    title: 'Deleted Successfully.',
                    description: "Details Photo Deleted Successfull",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

            } else {
                setload(true)
                toast({
                    title: 'Deleted unsuccessfull.',
                    description: "Internal Error",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        })


    }

    function handlereplace() {
        setload(false)
        uploadprojectsdisplaycoverphoto({ Data, newCoverImg }).then((res) => {
            if (res.status) {
                getDetailProjectDisplayData()
                setnewCoverImg([])
                toast({
                    title: 'Replaced Successfully.',
                    description: "Project cover image replaced",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })

            } else {
                setload(true)
                toast({
                    title: 'Deleted unsuccessfull.',
                    description: "Internal Error",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })

            }
        })
    }


    //console.log(Data, "878787")
    //console.log(imgsData, "egber")
    return (
        <>
            {
                load ?
                    <Box p={"1rem"}>

                        {Data && Data.length != 0 && <Box >

                            <ProAleartBox btnm={"1rem"} btncs={"red"} cbtn={"Delete"} btn={"Delete Full Project"} delfun={handledeleteproject}
                                msg={"Delete Entire Project"} />

                            <ProAleartBox btnm={"1rem"} btnv={"outline"} btncs={"red"} cbtn={"Reset"} btn={"Reset Data"} delfun={() => getDetailProjectDisplayData("true")}
                                msg={"Reset Entire Project"} />

                            <Box display={"flex"} fontSize="1.5rem" width={"fit-content"} m={"auto"}  >
                                Project Position
                                <Editable mx={"1rem"} borderRadius={"1rem"} textAlign={"center"} w={"fit-content"} minW={"2rem"} border={"2px solid black"} placeholder='Enter the Project No Title' defaultValue={Data.project_no} onChange={(e) => handleediting({
                                    value: e,
                                    name: "project_no"
                                })} >
                                    <EditablePreview />
                                    <EditableInput />
                                </Editable>
                            </Box>
                            <Box my={"3rem"}>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} maxW={"30rem"} p={"1rem"} margin={"auto"} >
                                    <Image
                                        src={Data?.cover_image?.[0]}
                                        alt='Green double couch with wooden legs'
                                        borderRadius='lg'
                                    />
                                </Box>

                                <Droper value={newCoverImg} onChange={(e) => setnewCoverImg(e)} singleFile={true} uploadLabel={"New Cover Image"} />

                                <ProAleartBox btndis={newCoverImg.length == 0} btnm={"1rem"} btnv={"outline"} btncs={"red"} cbtn={"Replace"} btn={"Replace Cover Image"} delfun={handlereplace}
                                    msg={"Replace Cover Image"} />

                                <Box textAlign={"center"} fontWeight={"700"} color={"#04b8ae"} fontSize={"1.5rem"} m={"1.2rem"} >
                                    <Editable placeholder='Enter the Project Cover Title' defaultValue={Data.cover_title} onChange={(e) => handleediting({
                                        value: e,
                                        name: "cover_title"
                                    })} >
                                        <EditablePreview />
                                        <EditableInput />
                                    </Editable>

                                    <Editable placeholder='Enter the Project Title' defaultValue={Data.project_title} onChange={(e) => handleediting({
                                        value: e,
                                        name: "project_title"
                                    })} >
                                        <EditablePreview />
                                        <EditableInput />
                                    </Editable>

                                </Box>
                                <Box color={"blackAlpha.700"} p={"1rem"} fontSize={"1.2rem"} textAlign={"center"} >
                                    <Editable placeholder='Enter the Project Description' onChange={(e) => handleediting({
                                        value: e,
                                        name: "description"
                                    })} defaultValue={Data.description}>
                                        <EditablePreview />
                                        <EditableTextarea />
                                    </Editable>
                                </Box>
                                <Box>

                                    <Box w={"80%"} p={"1rem"} m={"auto"} fontWeight={"semibold"} color={"#04b8ae"} fontSize={"1.5rem"} >
                                        Project Details –
                                    </Box>
                                    <Box px={"2.5rem"} display={"flex"} justifyContent={"space-evenly"} flexWrap={"wrap"} color={"#04b8ae"} margin={"auto"} w={"90%"} >

                                        <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"} >

                                            <Box fontSize={"1.2rem"} color={"blackAlpha.700"} >
                                                Plot Size:  <Editable placeholder='Enter the Project Plot Size' onChange={(e) => handleediting({
                                                    value: e,
                                                    name: "plot_size"
                                                })} defaultValue={Data.plot_size}>
                                                    <EditablePreview />
                                                    <EditableInput />
                                                </Editable>
                                            </Box>
                                        </Box>

                                        <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"} >

                                            <Box fontSize={"1.2rem"} color={"blackAlpha.700"} >
                                                Package: <Editable placeholder='Enter the Project Package' defaultValue={Data.package}

                                                    onChange={(e) => handleediting({
                                                        value: e,
                                                        name: "package"
                                                    })}
                                                >
                                                    <EditablePreview />
                                                    <EditableInput />
                                                </Editable>
                                            </Box>
                                        </Box>
                                        <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"} >

                                            <Box fontSize={"1.2rem"} color={"blackAlpha.700"} >
                                                Location:
                                                <Editable placeholder='Enter the Project Location' defaultValue={Data.location}
                                                    onChange={(e) => handleediting({
                                                        value: e,
                                                        name: "location"
                                                    })}
                                                >
                                                    <EditablePreview />
                                                    <EditableInput />
                                                </Editable>

                                            </Box>
                                        </Box>
                                        <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"} >
                                            <Box fontSize={"1.2rem"} color={"blackAlpha.700"} >
                                                No of Floors: <Editable placeholder='Enter the Project No of Floors' defaultValue={Data.no_of_floors}
                                                    onChange={(e) => handleediting({
                                                        value: e,
                                                        name: "no_of_floors"
                                                    })}
                                                >
                                                    <EditablePreview />
                                                    <EditableInput />
                                                </Editable>
                                            </Box>
                                        </Box>
                                        <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"} >
                                            <Box fontSize={"1.2rem"} color={"blackAlpha.700"} >
                                                Duration:<Editable placeholder='Enter the Project Duration' defaultValue={Data.project_duration}
                                                    onChange={(e) => handleediting({
                                                        value: e,
                                                        name: "project_duration"
                                                    })}
                                                >
                                                    <EditablePreview />
                                                    <EditableInput />
                                                </Editable>
                                            </Box>
                                        </Box>
                                    </Box>


                                    <Box color={"#04b8ae"} w={"80%"} p={"1rem"} m={"auto"} fontWeight={"semibold"} fontSize={"1.5rem"} >
                                        Journey Milestones –
                                    </Box>
                                    <Grid className={styles.imggrid} p={"2rem"} columnGap={"1rem"} rowGap={"2rem"} width={"90%"} margin={"auto"} gap={1} >

                                        {
                                            Data && Data.detail_images && Data.detail_images.map((ele) => {
                                                return <Box h={"fit-content"} w={"fit-content"} borderRadius='lg' boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} >
                                                    <ProImg Order={Order} data={ele} handleOrder={handleOrder} />

                                                    <ProAleartBox btnm={"0.4rem"} btnv={"outline"} btncs={"red"} cbtn={"Delete"} btn={"Delete Image"} delfun={() => handlephotodelete(ele)}
                                                        msg={"Delete Image From Project"} />

                                                </Box>
                                            })
                                        }
                                    </Grid>
                                </Box>
                                <Droper value={imgsData} onChange={(e) => handlenewimgs(e)} singleFile={false} uploadLabel={"Detail Images"} />
                                <Button m={"1rem"} disabled={imgsData.length == 0} colorScheme={"teal"} variant='outline' onClick={handleaddphotos} >Upload Photos</Button>
                            </Box>

                        </Box>}



                        <Button m={"1rem"} colorScheme={"blue"} onClick={() => handleupdate()} >Update</Button>
                    </Box>
                    :
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"100vh"}
                    >
                        <CircularProgress size={"6rem"} isIndeterminate color="green.300" />
                    </Box>
            }
        </>)
}

export default ProjectDisplayEditor