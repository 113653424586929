import React from "react";
import PropTypes from "prop-types";
import { ThemeProps, RTLProps } from "../../shared/prop-types/ReducerProps";
import { useSelector, useDispatch } from "react-redux";
import { rtlReducer, themeReducer } from "../../redux/reducers";
import { useLocation } from "react-router-dom";

const MainWrapper = ({ children }) => {
  const theme = useSelector((state) => state.theme);
  const rtl = useSelector((state) => state.rtl);
  const location = useLocation();

  const direction = location.pathname === "/" ? "ltr" : rtl.direction;
  return (
    <div className={`${theme.className} ${direction}-support`} dir={direction}>
      <div className="wrapper">{children}</div>
    </div>
  );
};

// MainWrapper.propTypes = {
//   theme: ThemeProps.isRequired,
//   rtl: RTLProps.isRequired,
//   children: PropTypes.node.isRequired,
//   location: PropTypes.shape().isRequired,
// };

export default MainWrapper;

// export default withRouter(connect(state => ({
//   theme: state.theme,
//   rtl: state.rtl,
// }))(MainWrapper));
