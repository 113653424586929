import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ButtonToolbar,
} from "reactstrap";
import Moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "mdi-react/FilterListIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

import DatepickerField from "../Datepicker/DatepickerField";
import Reader from "../../shared/components/XLSX/Reader";
import moment from "moment";
import {
  paidStatusList,
  workStatusList,
  paymentStatusList,
  ProjectScope,
} from "../../shared/helpers/constants";
import { getPurchaseOrders } from "../../redux/actions/poAction";
import { useEffect } from "react";
let counter = 0;

const createData = (name, calories, fat, carbs, protein) => {
  counter += 1;
  return {
    id: counter,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
};

const WoMilestones = ({
  data,
  setData,
  isTableRowsTouched,
  setIsTableRowsTouched,
  removeMilestone,
}) => {
  const { wid } = useParams();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");

  const [podata, setpodata] = useState([]);

  useEffect(() => {
    getPurchaseOrders()
      .then((res) => {})
      .catch((res) => {});
  }, []);

  const getSorting = (order, orderBy) => {
    if (order === "desc") {
      return (a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return -1;
        }
        if (a[orderBy] > b[orderBy]) {
          return 1;
        }
        return 0;
      };
    }
    return (a, b) => {
      if (a[orderBy] > b[orderBy]) {
        return -1;
      }
      if (a[orderBy] < b[orderBy]) {
        return 1;
      }
      return 0;
    };
  };

  const getWorkStatus = (val) => {
    let text = workStatusList.filter((o) => o.value == val);

    if (text.length) {
      return text[0].label;
    }
  };

  const getPaidStatus = (val) => {
    let text = paidStatusList.filter((o) => o.value === val);
    if (text.length) {
      return text[0].label;
    }
  };
  const getScope = (val) => {
    let text = ProjectScope.filter((o) => o.value === val);
    if (text.length) {
      return text[0].label;
    }
  };

  const onChange = (event, rowIdx, field) => {
    setIsTableRowsTouched(true);
    let myrow = [...data];

    if (field == "start_date" || field == "end_date" || field == "pay_date") {
      myrow[rowIdx][field] = Moment(event).format("yyyy-MM-DD");
    } else myrow[rowIdx][field] = event.target.value;
    setData(myrow);
  };

  const addRow = () => {
    let newRows = [...data];
    newRows.push({
      id: data.length + 1,
      task: "",
      start_date: new Date(),
      end_date: new Date(),
      workStatus: 2,
      payStatus: 3,
      percentage: 0,
      amount: 0,
      scope: 0,
      pay_date: new Date(),
      isEditable: true,
      isExist: false,
    });
    setData(newRows);
  };

  const toggleXLSXModal = (flag) => {
    setShowModal(flag);
  };
  const toggleDeleteModal = (flag) => {
    setShowDeleteModal(flag);
  };
  const toggleInfoModal = (flag) => {
    setShowInfoModal(flag);
  };

  const setDataFromXLSX = (dat) => {
    let mod = dat
      .map((a, index) => {
        if (!a["SN"]) {
          return false;
        }
        // let start = moment(a["Start date"], "D-MMM-YYYY").toDate();
        // let end_date = moment(a["End Date"], "D-MMM-YYYY").toDate();
        let start = Moment(a["Start date"]).format("yyyy-MM-DD");
        let end_date = Moment(a["End Date"]).format("yyyy-MM-DD");
        return {
          id: data.length + (index + 1),
          task: a["Milestone"],
          start_date: start,
          end_date: end_date,
          workStatus: 2,
          payStatus: 3,
          percentage: a["%"],
          amount: a["Amount"],
          pay_date: new Date(),
          isEditable: true,
          scope: 0,
        };
      })
      .filter((a) => a);
    setData([...data, ...mod]);
    setIsTableRowsTouched(true);
    toggleXLSXModal(false);
  };

  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = "desc";
    if (orderBy === property && order === "desc") {
      orderTemp = "asc";
    }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleToolbarClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(Number(event.target.value));
  //   setPage(0);
  // };

  // const handleDeleteSelected = () => {
  //   let copyData = [...data];
  //   for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
  //     copyData = copyData.filter((obj) => obj.id !== selected[i]);
  //   }
  //   setData(copyData);
  //   setSelected(new Map([]));
  // };

  function checkAccess() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com"
    ) {
      return true;
    }
    return false;
  }
  const isSelected = (id) => !!selected.get(id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (property) => (event) => {
    handleRequestSort(event, property);
    handleClose();
  };

  const deleteSelectedMilestone = () => {
    let index = showDeleteModal - 1;

    removeMilestone(JSON.stringify({ wid: wid, id: data[index].id })).then(
      (res) => {
        setShowDeleteModal(false);
        if (res.status) {
          setIsTableRowsTouched(true);
          let myrow = [...data];
          myrow.splice(index, 1);
          setData(myrow);
        } else {
          setInfoMsg(
            "Payment request has been raised. Cannot delete this milestone"
          );
          setShowInfoModal(true);
        }
      }
    );
  };

  const rows = [
    {
      id: "id",
      disablePadding: false,
      label: "#",
    },
    {
      id: "task",
      disablePadding: false,
      label: "Task",
    },
    {
      id: "date",
      disablePadding: false,
      label: "Date",
    },
    {
      id: "percentage",
      disablePadding: false,
      label: "Percentage(%)",
    },
    {
      id: "amount",
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "pay_date",
      disablePadding: true,
      label: "Pay Date",
    },
    {
      id: "work_status",
      disablePadding: false,
      label: "Work Status",
    },
    {
      id: "paid_status",
      disablePadding: false,
      label: "Paid Status",
    },
    {
      id: "scope",
      disablePadding: false,
      label: "Scope",
    },
    {
      id: "edit",
      disablePadding: true,
      label: "Action",
    },
    {
      id: "delete",
      disablePadding: true,
      label: "Action",
    },
  ];

  const createSortHandler = (property, onRequestSort) => (event) => {
    onRequestSort(event, property);
  };

  let numSelected = 2;
  let rowCount = 10;

  return (
    <Col md={12} lg={12} className="workOrder">
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">WO Milestones</h5>
          </div>

          <div className="material-table__toolbar-wrap">
            <Toolbar className="material-table__toolbar">
              <div>
                <Button
                  color="primary"
                  type="button"
                  className="previous"
                  onClick={addRow}
                >
                  Add Row
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="clearFilter"
                  onClick={() => toggleXLSXModal(!showModal)}
                >
                  Upload Excel
                </Button>
                <IconButton
                  className="material-table__toolbar-button"
                  aria-owns={anchorEl ? "simple-menu" : null}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <FilterListIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="material-table__filter-menu"
                >
                  <MenuItem
                    onClick={handleSort("name")}
                    className="material-table__filter-menu-item"
                  >
                    Name
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </div>
          <div className="material-table__wrap">
            <Table className="material-table">
              <TableHead>
                <TableRow>
                  {rows.map((row) => (
                    <TableCell
                      className="material-table__cell material-table__cell--sort material-table__cell-right"
                      key={row.id}
                      //   align={rtl.direction === "rtl" ? "right" : "left"}
                      padding={row.disablePadding ? "none" : "default"}
                      sortDirection={orderBy === row.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={createSortHandler(row.id, handleRequestSort)}
                        className="material-table__sort-label"
                        dir="ltr"
                      >
                        {row.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.sort(getSorting(order, orderBy)).map((d, idx) => {
                  if (d.scope !== 1) {
                    return (
                      <TableRow
                        className="material-table__row"
                        role="checkbox"
                        tabIndex={-1}
                        key={d.id}
                      >
                        <TableCell className="material-table__cell material-table__cell-right">
                          {d.id}
                        </TableCell>
                        <TableCell
                          width="30%"
                          className="material-table__cell material-table__cell-right"
                        >
                          {d.isEditable ? (
                            <textarea
                              type="text"
                              value={d.task}
                              onChange={(event) => onChange(event, idx, "task")}
                            />
                          ) : (
                            d.task
                          )}
                        </TableCell>
                        <TableCell
                          width="30%"
                          className="material-table__cell material-table__cell-right"
                        >
                          {d.isEditable ? (
                            <>
                              <label for="startdate">
                                Start:
                                <DatepickerField
                                  selected={new Date(d.start_date)}
                                  onChange={(event) =>
                                    onChange(event, idx, "start_date")
                                  }
                                  dateFormat="dd-MM-yyyy"
                                />
                              </label>
                              <label for="enddate">
                                End
                                <DatepickerField
                                  selected={new Date(d.end_date)}
                                  onChange={(event) =>
                                    onChange(event, idx, "end_date")
                                  }
                                  dateFormat="dd-MM-yyyy"
                                />
                              </label>
                            </>
                          ) : (
                            <Container>
                              <Row>
                                <div>Start:</div>
                                {moment(d.start_date).format("DD-MM-yyyy")}
                              </Row>
                              <Row>
                                <div>End:</div>
                                {moment(d.end_date).format("DD-MM-yyyy")}
                              </Row>
                            </Container>
                          )}
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          {d.isEditable ? (
                            <input
                              type="text"
                              value={d.percentage}
                              onChange={(event) =>
                                onChange(event, idx, "percentage")
                              }
                            />
                          ) : (
                            `${d.percentage ? d.percentage : ""}`
                          )}
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          {d.isEditable ? (
                            <input
                              type="text"
                              value={d.amount}
                              onChange={(event) =>
                                onChange(event, idx, "amount")
                              }
                              disabled={d.amount == 0 ? false : !checkAccess()}
                            />
                          ) : (
                            `${d.amount ? d.amount : ""}`
                          )}
                        </TableCell>
                        <TableCell
                          width="30%"
                          className="material-table__cell material-table__cell-right"
                        >
                          {d.isEditable ? (
                            <DatepickerField
                              selected={new Date(d.pay_date)}
                              onChange={(event) =>
                                onChange(event, idx, "pay_date")
                              }
                              dateFormat="dd-MM-yyyy"
                            />
                          ) : (
                            Moment(d.pay_date).format("DD-MM-yyyy")
                          )}
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          {d.isEditable ? (
                            <select
                              value={d.workStatus}
                              onChange={(event) =>
                                onChange(event, idx, "workStatus")
                              }
                            >
                              {workStatusList.map((option, opidx) => {
                                return (
                                  <option key={opidx} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            getWorkStatus(d.workStatus)
                          )}
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          {d.isEditable ? (
                            <select
                              value={d.payStatus}
                              onChange={(event) =>
                                onChange(event, idx, "payStatus")
                              }
                            >
                              {paidStatusList.map((option, opidx) => {
                                return (
                                  <option key={opidx} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            getPaidStatus(d.payStatus)
                          )}
                        </TableCell>

                        <TableCell className="material-table__cell material-table__cell-right">
                          {d.isEditable ? (
                            <select
                              value={d.scope}
                              onChange={(event) =>
                                onChange(event, idx, "scope")
                              }
                            >
                              {ProjectScope.map((option, opidx) => {
                                return (
                                  <option key={opidx} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            getScope(d.scope)
                          )}
                        </TableCell>

                        <TableCell className="material-table__cell material-table__cell-right">
                          <span
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline",
                            }}
                            onClick={(e) => {
                              let myrow = [...data];
                              myrow[idx].isEditable = !myrow[idx].isEditable;
                              setData(myrow);
                            }}
                          >
                            Edit
                          </span>
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          <span
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline",
                            }}
                            onClick={(e) => {
                              if (d.isExist) {
                                setShowDeleteModal(idx + 1);
                              } else {
                                setIsTableRowsTouched(true);
                                let myrow = [...data];
                                myrow.splice(idx, 1);
                                setData(myrow);
                              }
                            }}
                          >
                            Delete
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={10}>No Records Found</TableCell>
                  </TableRow>
                )}

                <TableRow
                  className="material-table__row"
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    colSpan="11"
                    className="material-table__cell material-table__cell-right addtional-col"
                  >
                    Additional
                  </TableCell>
                </TableRow>
                {data.sort(getSorting(order, orderBy)).map((d, idx) => {
                  if (d.scope == 1) {
                    return (
                      <>
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          tabIndex={-1}
                          key={d.id}
                        >
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.id}
                          </TableCell>
                          <TableCell
                            width="30%"
                            className="material-table__cell material-table__cell-right"
                          >
                            {d.isEditable ? (
                              <textarea
                                type="text"
                                value={d.task}
                                onChange={(event) =>
                                  onChange(event, idx, "task")
                                }
                              />
                            ) : (
                              d.task
                            )}
                          </TableCell>
                          <TableCell
                            width="30%"
                            className="material-table__cell material-table__cell-right"
                          >
                            {d.isEditable ? (
                              <>
                                <label for="startdate">
                                  Start:
                                  <DatepickerField
                                    selected={new Date(d.start_date)}
                                    onChange={(event) =>
                                      onChange(event, idx, "start_date")
                                    }
                                    dateFormat="dd-MM-yyyy"
                                  />
                                </label>
                                <label for="enddate">
                                  End
                                  <DatepickerField
                                    selected={new Date(d.end_date)}
                                    onChange={(event) =>
                                      onChange(event, idx, "end_date")
                                    }
                                    dateFormat="dd-MM-yyyy"
                                  />
                                </label>
                              </>
                            ) : (
                              <Container>
                                <Row>
                                  <div>Start:</div>
                                  {moment(d.start_date).format("DD-MM-yyyy")}
                                </Row>
                                <Row>
                                  <div>End:</div>
                                  {moment(d.end_date).format("DD-MM-yyyy")}
                                </Row>
                              </Container>
                            )}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.isEditable ? (
                              <input
                                type="text"
                                value={d.percentage}
                                onChange={(event) =>
                                  onChange(event, idx, "percentage")
                                }
                              />
                            ) : (
                              `${d.percentage ? d.percentage : ""}`
                            )}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.isEditable ? (
                              <input
                                type="text"
                                value={d.amount}
                                onChange={(event) =>
                                  onChange(event, idx, "amount")
                                }
                              />
                            ) : (
                              `${d.amount ? d.amount : ""}`
                            )}
                          </TableCell>
                          <TableCell
                            width="30%"
                            className="material-table__cell material-table__cell-right"
                          >
                            {d.isEditable ? (
                              <DatepickerField
                                selected={new Date(d.pay_date)}
                                onChange={(event) =>
                                  onChange(event, idx, "pay_date")
                                }
                                dateFormat="dd-MM-yyyy"
                              />
                            ) : (
                              Moment(d.pay_date).format("DD-MM-yyyy")
                            )}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.isEditable ? (
                              <select
                                value={d.workStatus}
                                onChange={(event) =>
                                  onChange(event, idx, "workStatus")
                                }
                              >
                                {workStatusList.map((option, opidx) => {
                                  return (
                                    <option key={opidx} value={option.value}>
                                      {option.label}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              getWorkStatus(d.workStatus)
                            )}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.isEditable ? (
                              <select
                                value={d.payStatus}
                                onChange={(event) =>
                                  onChange(event, idx, "payStatus")
                                }
                              >
                                {paidStatusList.map((option, opidx) => {
                                  return (
                                    <option key={opidx} value={option.value}>
                                      {option.label}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              getPaidStatus(d.payStatus)
                            )}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.isEditable ? (
                              <select
                                value={d.scope}
                                onChange={(event) =>
                                  onChange(event, idx, "scope")
                                }
                              >
                                {ProjectScope.map((option, opidx) => {
                                  return (
                                    <option key={opidx} value={option.value}>
                                      {option.label}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              getScope(d.scope)
                            )}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            <span
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              onClick={(e) => {
                                let myrow = [...data];
                                myrow[idx].isEditable = !myrow[idx].isEditable;
                                setData(myrow);
                              }}
                            >
                              Edit
                            </span>
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            <span
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              onClick={(e) => {
                                if (d.isExist) {
                                  setShowDeleteModal(idx + 1);
                                } else {
                                  setIsTableRowsTouched(true);
                                  let myrow = [...data];
                                  myrow.splice(idx, 1);
                                  setData(myrow);
                                }
                              }}
                            >
                              Delete
                            </span>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </div>
          {/* <TablePagination
            component="div"
            className="material-table__pagination"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
            dir="ltr"
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
          /> */}
        </CardBody>
      </Card>
      <Modal
        isOpen={showModal}
        toggle={() => toggleXLSXModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleXLSXModal(false)}>
          <strong>Upload Work Order</strong>
        </ModalHeader>
        <ModalBody>
          <Reader dataProcessor={(data) => setDataFromXLSX(data)} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showDeleteModal}
        toggle={() => toggleDeleteModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleDeleteModal(false)}>
          <strong>Warning!</strong>
        </ModalHeader>
        <ModalBody>{`Are you Sure you want to delete Milestone Task - ${
          data[showDeleteModal - 1]?.task
        }?`}</ModalBody>
        <ModalFooter>
          <ButtonToolbar className="form__button-toolbar">
            <Button type="button" onClick={() => toggleDeleteModal(false)}>
              No
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => deleteSelectedMilestone()}
            >
              Yes
            </Button>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showInfoModal}
        toggle={() => toggleInfoModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleInfoModal(false)}>
          <strong>Warning!</strong>
        </ModalHeader>
        <ModalBody>{infoMsg}</ModalBody>
        <ModalFooter>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                toggleInfoModal(false);
              }}
            >
              OK
            </Button>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

export default WoMilestones;
