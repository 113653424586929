import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Heading,
    Button,
    Text,
    Editable,
    EditablePreview,
    EditableInput,
    FormControl,
    RadioGroup,
    Stack,
    Radio,
    EditableTextarea,
    Image,

} from '@chakra-ui/react'

import { useState } from 'react'
import { updateTask } from '../../redux/actions/taskManager'
import AlertBox from './AlertBox'
import CheckBoxes from './Check'
import Droper from './Droper'
import styles from './taskmanager.module.css'
import { RWebShare } from 'react-web-share'
import ShareIcon from "@mui/icons-material/Share";

const MyTasks = ({ data, taskFetcher }) => {

    const [edit, setedit] = useState(true)


    const [TaskData, setTaskData] = useState(

        {
            taskid: data.taskid
            , assigndate: data.assigndate
            , assignedby: data.assignedby
            , assignedto: data.assignedto
            , task: data.task
            , taskstartdate: data.taskstartdate
            , taskenddate: data.taskenddate
            , status: data.status
            , comments: data.comments
            , remainder: data.remainder
            , remainderdate: data.remainderdate
            , newattachments: []
            , attachments: data.attachments
        }
    )

    function getUserName() {
        let useremail = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).login).userDetails.email
        // console.log(useremail)
        return useremail
    }

    function handleTaskData(e) {
        // console.log(e)
        let { name, value } = e;
        setTaskData({
            ...TaskData,
            [name]: value,
        })

    }

    function handleNewAttach(e) {
        setTaskData({
            ...TaskData,
            newattachments: [...e]
        })
    }


    function handleSubmit() {
        // console.log(TaskData, "submitttsyvfhgduiv")
        if (!edit) {

            //  console.log(TaskData)
            updateTask(TaskData).then((res) => {
                //  console.log(res)
                taskFetcher()
            }
            )
        }
    }

    function shareData() {
        let sdata = `\n\t1)TaskTitle =  ${data?.tasktitle?.toUpperCase()}
                \n\t2)Assigned Date: ${TaskData?.assigndate}
                \n\t3)Assigned By: ${TaskData?.assignedby}
                \n\t4)Assigned To: ${TaskData?.assignedto?.includes("ALL$") ? "ALL" :
                TaskData?.assignedto?.split("$")?.map((ele, index) =>
                    `\n\t${index + 1}.${ele?.split(" ")[1]}`
                )}
                \n\t5)Task: ${TaskData?.task}
            \n\t6)Task Start Date: ${TaskData?.taskstartdate}
            \n\t7)Task End Date: ${TaskData?.taskenddate}
            \n\t8)Status: ${TaskData?.status}
            \n\t9)Comments: ${TaskData?.comments}
            \n\t10)Attachments: ${TaskData?.attachments?.map((ele, index) => `\n\t${ele}`)}
            \n\t
            \n\t`
        return sdata
    }

    return (


        <AccordionItem borderRadius={"0.5rem"} border="1px solid rgba(128, 128, 128, 0.432)" m={3} >
            <h2>
                <AccordionButton >
                    <Box textOverflow={"ellipsis"} overflow={"hidden"} as="span" flex='1' textAlign='left'>
                        <Heading display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} fontSize={"lg"} >

                            <Box display={"flex"} flexDir={"column"} maxW={"30rem"}  >

                                <Text fontWeight={"600"} color={"GrayText"} fontSize='large' >
                                    Assignedby
                                </Text>
                                <Text fontWeight={"600"} fontSize="large">
                                    {`${data?.assignedby?.split("@")[0]?.toUpperCase()}`}
                                </Text>

                                <Text p='1' px={"5"} w={"fit-content"} backgroundColor={data?.status == "Completed" ? "rgb(71, 200, 0)" : "rgb(255,69,60)"}
                                    color={"white"} borderRadius={"0.5rem"} fontWeight={"550"} >
                                    {data?.status}
                                </Text>
                            </Box>

                            <Box display={"flex"} maxW={"30rem"}  >

                                <Text p='2' fontWeight={"600"} color={"GrayText"} fontSize='large' >
                                    {data?.tasktitle?.toUpperCase()}
                                </Text>

                            </Box>




                            <Box display={"flex"} maxW={"30rem"} >

                                <Text p='2' fontWeight={"600"} color={"GrayText"} fontSize='large' >
                                    {new Date(data?.assigndate)?.toDateString()}
                                </Text>

                            </Box>

                        </Heading>
                    </Box>

                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel borderRadius={"0.3rem"} borderTop="1px solid rgba(128, 128, 128, 0.300)" pb={4}>

                {edit ?
                    <>


                        <Box>
                            <Box flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                                <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"} >
                                    Task Start Date:
                                </Text>
                                <Text pt='1' fontWeight={"550"} >
                                    {new Date(data?.taskstartdate)?.toLocaleString()}
                                </Text>
                            </Box>

                            <Box flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                                <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"}  >
                                    Task End Date:
                                </Text>
                                <Text pt='1' fontWeight={"550"} >
                                    {new Date(data?.taskenddate)?.toLocaleString()}
                                </Text>
                            </Box>


                            {/* <Box flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                                <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"} >
                                    Remainder:
                                </Text>
                                <Text pt='1' fontWeight={"550"} >
                                    {`${data.remainder} 
                            ${new Date(data.remainderdate).toDateString()}`}
                                </Text>
                            </Box> */}





                            <Box flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                                <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"}  >
                                    Comments:
                                </Text>
                                <Text pt='1' fontWeight={"550"} w={"80%"} >
                                    {data?.comments}
                                </Text>
                            </Box>

                            <Box mt={2} flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                                <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"}  >
                                    Task Description:
                                </Text>
                                <Box borderRadius={"0.5rem"} p={"0.5rem"} width={"80%"} border="1px solid rgb(142, 142, 142) ">
                                    <Text pt='1' fontWeight={"550"} >
                                        {data?.task}
                                    </Text>
                                </Box>
                            </Box>





                        </Box>




                    </> : <>



                        {
                            !edit && <Text fontSize={"2rem"} color={"red"} pt='2' fontWeight={"550"} >
                                Editor:
                            </Text>
                        }


                        <Box>




                            {/* <Box flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                            <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"} >
                                Remainder:
                            </Text>
                            <Text pt='1' fontWeight={"550"} >
                                {`${data.remainder} 
                            ${new Date(data.remainderdate).toDateString()}`}
                            </Text>
                        </Box> */}

                            <Box w={"100%"} flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                                <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"}  >
                                    Status:
                                </Text>
                                {/* <Text p='1' px={"5"} backgroundColor={data.status == "Completed" ? "rgb(71, 200, 0)" : "rgb(255,69,60)"}
                                color={"white"} borderRadius={"0.5rem"} fontWeight={"550"} >
                                {data.status}
                            </Text> */}

                                <FormControl mt={4} >
                                    <RadioGroup onChange={(e) => handleTaskData({ "value": e, "name": "status" })} value={TaskData?.status} defaultValue={TaskData?.status}>
                                        <Stack flexWrap={"wrap"} display={"flex"} direction='row'>
                                            <Radio value='ToDo'>ToDo</Radio>
                                            <Radio value='WIP'>WIP</Radio>
                                            <Radio value='Pending'>Pending</Radio>
                                            <Radio value='Completed'>Completed</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                            </Box>

                            <Box flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"} textAlign={"center"} fontSize={"large"}  >
                                <Text color={"rgb(142, 142, 142)"} pt='2' fontWeight={"550"} >
                                    Comments:
                                </Text>
                                <Editable pt={1} placeholder='Enter Comments Here' defaultValue={TaskData?.comments}>
                                    <EditablePreview />
                                    <EditableTextarea p={4} minH={"7rem"} minW={"17rem"} textAlign={"left"} value={TaskData?.comments} onChange={(e) => handleTaskData({ "value": e?.target?.value, "name": "comments" })} />
                                </Editable>

                            </Box>
                        </Box>
                    </>}



                <Box p={"1rem"} flexWrap={"wrap"} display={"flex"} justifyContent={"space-around"} textAlign={"center"}  >
                    {
                        data?.attachments?.map((ele) => {
                            //   console.log(ele, "uhygujrvb")
                            return <Box key={ele} display={"flex"} justifyContent={"center"} flexDir={"column"} alignItems={"center"} >
                                <Box fontSize={"2rem"} fontWeight={600} color={"red"} borderRadius={"1rem"} display={"flex"} justifyContent={"center"} alignItems={"center"} m={"1rem auto"} className={styles.attachBox} w={"15rem"} h={"15rem"} >
                                    <a target={"_blank"} href={ele}  >
                                        <Image display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={"1rem"} fit={"cover"} src={ele} alt={ele?.split("TaskmanagerFiles")[1]?.split(".")[1]?.toUpperCase()} />
                                    </a>
                                </Box>
                            </Box>
                        })
                    }

                </Box>

                {!edit && <Droper value={TaskData?.newattachments} onChange={(e) => handleNewAttach(e)} uploadLabel={"Add Attachments"} />}


                {!edit && <Button m={1} onClick={() => {

                    setedit(!edit)
                    setTaskData({
                        ...TaskData,
                        ["status"]: data?.status,
                        ["comments"]: data?.comments,
                        ["newattachments"]: []
                    })
                }} >Set Default</Button>}
                <Button m={1} onClick={() => {
                    setedit(!edit)
                    handleSubmit()
                    setTaskData({
                        ...TaskData,
                        ["newattachments"]: []
                    })
                }} colorScheme={"blue"} >{
                        edit ? "Edit" : "Save"}</Button>


                {edit && <RWebShare
                    data={{
                        text: shareData(),
                        title: "Task Details",
                    }}
                >
                    <Button mx={1} >
                        <ShareIcon />
                    </Button>
                </RWebShare>}

            </AccordionPanel>
        </AccordionItem>

    )
}

export default MyTasks