import React from "react";
import style from "./ProjectMain.module.css";
// import img from "./pexels-thisisengineering-3862365.jpg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ContectUs from "../ContectUs/ContectUs";
// import { useDisclosure } from "@chakra-ui/react";
const ProjectMain = ({ onOpen }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div className={style.main}>
        <div className={style.main_container}>
          {/* --------------------------Text ------------------ */}
          <div className={style.main_1}>
            <div className={style.long_text}>
              Whatever good things we build end up building us.
            </div>
            <div className={style.para}>
              WE LISTEN TO YOUR NEEDS, DESIGN IT TO YOUR SPECIFICATIONS, AND
              BUILT IT TO YOUR DREAMS!
            </div>
            <div className={style.Lets_Talk} onClick={onOpen}>
              {/* <a href="tel:6360759762"> */}
              <span>
                Lets Talk
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color="#ff4332"
                  style={{ padding: "0px 10px" }}
                />
              </span>
              {/* </a> */}
            </div>
          </div>
          {/* -----------------------First box image------------------ */}
          <div className={style.main_2}>
            <div className={`${style.image} ${style.image1}`}>
              <img
                src="https://housingfactory.s3.ap-south-1.amazonaws.com/attendanceFile/2022/eac60098-5294-4d54-ba57-db63a8578ffd.jpg"
                alt="no images"
                srcset=""
              />
            </div>
          </div>
          {/* -----------------------Seond box image---------------------- */}
          <div className={style.main_3}>
            <div
              className={`${style.image} ${style.image2}`}
              style={{ width: "80%", margin: "20px" }}
            >
              <img
                src="https://housingfactory.in/Assets/wp-content/uploads/2021/12/Untitled-design.png"
                alt="no images"
                srcset=""
              />
            </div>
            <div className={`${style.image} ${style.image3}`}>
              <img
                src="https://housingfactory.in/Assets/wp-content/uploads/2021/12/Untitled-design-4.png"
                alt="no images"
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* <ContectUs isOpen={isOpen} onClose={onClose} onOpen={onOpen} /> */}
    </>
  );
};

export default ProjectMain;
