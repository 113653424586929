import { handleActions } from 'redux-actions';
import {
  fetchLoginLogutReq,
  fetchLogout,
  fetchLogin
} from '../actions/loginActions';

const defaultState = {
  userDetails: {},
  isFetching: false,
  error: null,
  basicInfoflds: {}
};

export default handleActions(
  {
    [fetchLoginLogutReq](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
        userDetails: {}
      };
    },
    [fetchLogout](state, { payload }) {
      localStorage.clear();
      return {
        ...state,
        userDetails: {},
        isFetching: false,
        error: null
      };
    },
    [fetchLogin](state, { payload }) {
      return {
        ...state,
        userDetails: payload,
        isFetching: false,
        error: null
      };
    }
  },
  defaultState,
);
