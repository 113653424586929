import { createAction } from "redux-actions";
import myPostReq from "../../utils/post";
import myGetReq from "../../utils/get";

export const fetchLoginLogutReq = createAction("DO_LOGIN");
export const fetchLogout = createAction("DO_LOGOUT");
export const fetchLogin = createAction("LOGIN_SUCCESS");

export const loginReq = async (data) => {
  try {
    return await myPostReq("/app/usr/login", data);
  } catch (err) {
    return err;
  }
};

export const signupReq = async (data) => {
  try {
    return await myPostReq("/app/usr/signup", data);
  } catch (err) {
    return err;
  }
};

export const addUser = async (data) => {
  try {
    return await myPostReq("/app/usr/add-user", data, {});
  } catch (err) {
    return err;
  }
};

export const logoutReq = async (data) => {
  try {
    return await myGetReq("/api/usr/logout");
  } catch (err) {
    return err;
  }
};
