import React, { useState, useEffect, useContext, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { FormField } from "./FormField";
import moment from "moment/moment";

import Icon from "react-icons-kit";
import { location } from "react-icons-kit/icomoon/location";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getVendorsDetails,
  getVendorsList,
  updatePOStatus,
} from "../../redux/actions/poAction";
import { Button } from "@mui/material";
import CreateVendor from "./CreateVendor";
import { fetchProjects, getCategories } from "../../redux/actions/ledgerAction";
import { Edit } from "@mui/icons-material";
import UpdateVendor from "./UpdateVendor";

const schema = yup.object().shape({
  startDate: yup.string().required("This field is required"),
  endDate: yup.string().required("This field is required"),
  payDate: yup.string().required("This field is required"),
});

const VendorsList = () => {
  const [selectedProject, setSelectedProject] = useState({
    value: 0,
    label: "Select All",
  });
  const [projectsList, setProjectsList] = useState([]);
  const [initialValuesVendor, setInitialValuesVendor] = useState({});
  const [showCreateVendorForm, setShowCreateVendorForm] = useState(false);
  const [showCreateVendorForm1, setShowCreateVendorForm1] = useState(false);
  const [categoryList, setCategoryList] = useState(false);

  const [errors, setErrors] = useState("");

  const [type, setType] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [filterData, setFilterData] = useState({
    poid: 0,
    status: 0,
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: {},
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadOptions = async () => {
    const response = fetchProjects().then((res) => {
      let data = [{ value: 0, label: "Select All" }];
      let arr = res.data;
      for (let idx in arr) {
        data.push({
          value: arr[idx].id,
          label: arr[idx].project_title,
        });
      }
      setProjectsList(data);
    });
  };

  const toggleFileUploadingModal = (isActive, PO) => {
    setShowFileUploading(isActive);
    if (PO) {
      setSelectedPO(PO);
    }
  };

  const OnChangeProject = (val) => {
    if (!val) {
      return;
    }
    setValue("project_title", val);
    setSelectedProject(val);
    clearFilters();
  };

  const onChangeStatus = (index, evt) => {
    let list = itemsList;
    list[index].status = Number(evt.target.value);
    setItemsList(list);

    updatePOStatus(list[index].po_id, evt.target.value);
  };

  const clearFilters = () => {
    setFilterData({
      poid: 0,
      status: 0,
    });
  };

  const getpo = () => {
    Promise.all([
      getVendorsDetails(selectedProject.value, filterData, type),
    ]).then((res) => {
      if (res[0].status) {
        console.log(res[0]);
        setItemsList(res[0].data);
      }
    });
  };

  const onChangeFilters = (evt) => {
    let val = evt.target.value.toLocaleLowerCase();
    let key = evt.target.name;

    if (key === "status" && val == 0) {
      val = 0;
    }
    if (key === "poid" && val == 0) {
      val = 0;
    }

    setFilterData({ ...filterData, [key]: val });
  };

  const setData = (list) => {
    setItemsList(list);
  };
  const toggleModal = (flag) => {
    setShowCreateVendorForm(flag);
  };
  const toggleModal1 = (flag) => {
    setShowCreateVendorForm1(flag);
  };
  const onSaveVendorDetails = (res) => {
    let clr = "success";
    if (!res.status) {
      clr = "danger";
    }
    setShowCreateVendorForm(false);
    AppProvider.setAlertFunc({
      txt: res.msg,
      clr: "success",
    });
    getVendorsList().then((res) => {
      if (res.status) {
        let arr = [];
        for (let idx in res.data) {
          arr.push({
            value: res.data[idx].id,
            label: res.data[idx].vendor_name,
          });
        }
        setVendors(arr);
      }
    });
  };

  useEffect(() => {
    Promise.all([
      getCategories(),
      // getVendorsList(),
      // fetchProjects(),
      // getUnits(),
    ]).then((res) => {
      if (res[0].status) {
        let arr = [];
        for (let idx in res[0].data) {
          arr.push({
            value: res[0].data[idx].id,
            label: res[0].data[idx].name,
          });
        }
        // setCatgegoryWithUnits(res[0].data);
        setCategoryList(arr);
      }
    });
  }, []);

  useEffect(() => {
    getpo();
    loadOptions();
  }, [selectedProject, filterData, type]);

  return (
    <>
      <Card paddingBottom="0px">
        <CardBody className="wizard">
          <Container className="dashboard create-project">
            <Row>
              <Col md={10} style={{ flex: "1 0 69%" }}>
                <h3
                  style={{
                    color: "#646777",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    textTransform: "uppercase",
                    letterSpacing: "8px",
                    wordSpacing: "15px",
                    paddingBottom: "20px",
                  }}
                >
                  Vendors List
                </h3>
              </Col>
              <Col style={{ flex: "1 0 29%" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  type="submit"
                  className="next"
                  onClick={() => toggleModal(true)}
                >
                  Add new vendor
                </Button>
              </Col>
            </Row>

            <CustomPOTable
              onChangeFilters={onChangeFilters}
              filterData={filterData}
              control={control}
              errors={errors}
              itemsList={itemsList}
              OnChangeProject={OnChangeProject}
              projectsList={projectsList}
              onChangeStatus={onChangeStatus}
              setData={setData}
              toggleFileUploadingModal={toggleFileUploadingModal}
              type={type}
              setInitialValuesVendor={setInitialValuesVendor}
              toggleModal1={toggleModal1}
            />
            {errors && <div style={{ color: "#F00" }}>* {errors}</div>}
          </Container>
        </CardBody>
        <Modal
          isOpen={showCreateVendorForm}
          toggle={() => toggleModal(false)}
          className="customModalDialog"
        >
          <ModalHeader toggle={() => toggleModal(false)}>
            <strong>Add new Vendor</strong>
          </ModalHeader>
          <ModalBody>
            <CreateVendor
              toggleModal={toggleModal}
              onSaveVendorDetails={onSaveVendorDetails}
              categoryList={categoryList}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showCreateVendorForm1}
          toggle={() => toggleModal1(false)}
          className="customModalDialog"
        >
          <ModalHeader toggle={() => toggleModal1(false)}>
            <strong>Update Vendor</strong>
          </ModalHeader>
          <ModalBody>
            <UpdateVendor
              toggleModal={toggleModal1}
              onSaveVendorDetails={onSaveVendorDetails}
              categoryList={categoryList}
              initialValues={initialValuesVendor}
            />
          </ModalBody>
        </Modal>
      </Card>
    </>
  );
};

export default VendorsList;

export const CustomPOTable = ({
  onChangeFilters,
  filterData,
  control,
  errors,
  itemsList,
  OnChangeProject,
  projectsList,
  setInitialValuesVendor,
  toggleModal1,
}) => {
  const navigate = useNavigate();

  const onClickImages = (images) => {
    console.log(images);
    if (images && images.length) {
      setDisplayImagesList(images);
      toggleImgModal(true);
    }
  };

  const [showImgModal, setShowImgModal] = useState(false);
  const [displayImagesList, setDisplayImagesList] = useState([]);
  const [currentImg, setCurrentImg] = useState([]);

  const toggleImgModal = (status) => {
    setShowImgModal(status);
  };

  useEffect(() => {
    if (!displayImagesList) {
      setDisplayImagesList([]);
    }
  }, [showImgModal]);
  console.log(itemsList, "vendor list");
  return (
    <div>
      <div className="table react-table table--not-pagination">
        <table className="react-table editable-table">
          <thead>
            <tr>
              <th>
                #
                {/* <input
                  type="text"
                  name="poid"
                  onChange={onChangeFilters}
                  value={filterData.poid}
                /> */}
              </th>
              <th>Shop Name</th>
              <th>Shop Sells</th>
              <th>Vendor Name</th>
              <th>Shop Address </th>
              <th>Contact No.</th>
              <th>Age of the Shop </th>
              <th>Deliver to Site </th>
              <th>Vendor E-Mail </th>
              <th>GST </th>
              <th>Pan Card </th>
              <th>Aadhar Card </th>
              <th>Account Details 1 </th>
              <th>Account Details 2 </th>
              <th>UPI Scanner </th>
              <th>UPI Phone No. </th>
              <th width={"25%"}>Shop/Shop keeper Photo </th>
              <th>Google Location</th>
              <th>Created Date</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(itemsList) &&
              itemsList.map((item, idx) => {
                return (
                  <>
                    <tr key={idx}>
                      <td>{item.id}</td>
                      <td>{item.shop_name}</td>
                      <td>
                        {console.log(item.shop_sells)}
                        {item.shop_sells &&
                          item.shop_sells.length &&
                          item.shop_sells.map((ss, ssIdx) => {
                            return (
                              <>
                                <div key={ssIdx}>{JSON.parse(ss)?.label} </div>
                              </>
                            );
                          })}
                      </td>
                      <td>{item.vendor_name}</td>

                      <td>{item.address}</td>
                      <td>{item.contact_no}</td>
                      <td>{item.age_of_shop}</td>
                      <td>{item.deliver_to_site ? "Yes" : "No"}</td>

                      <td>{item.email}</td>
                      <td>{item.vendor_gst}</td>

                      <td
                        onClick={() => {
                          onClickImages(item.pan_cards);
                        }}
                      >
                        {item.pan_cards && item.pan_cards.length ? (
                          <a href="#">PAN card</a>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td
                        onClick={() => {
                          onClickImages(item.aadhar_cards);
                        }}
                      >
                        {item.aadhar_cards && item.aadhar_cards.length ? (
                          <a href="#">Aadhar card</a>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td
                        onClick={() => {
                          onClickImages(item.acct_details1);
                        }}
                      >
                        {item.acct_details1 && item.acct_details1.length ? (
                          <a href="#">Account Details 1</a>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td
                        onClick={() => {
                          onClickImages(item.acct_details2);
                        }}
                      >
                        {item.acct_details2 && item.acct_details2.length ? (
                          <a href="#">Account Details 2</a>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td
                        onClick={() => {
                          onClickImages(item.upi_files);
                        }}
                      >
                        {item.upi_files && item.upi_files.length ? (
                          <a href="#">UPI Images</a>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td>{item.upi_phone_no}</td>
                      <td
                        onClick={() => {
                          onClickImages(item.shop_files);
                        }}
                      >
                        {item.shop_files && item.shop_files.length ? (
                          <a href="#">Shop Images</a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {console.log(item.google_location)}
                        {item.google_location && item.google_location.length ? (
                          <a target="_blank" href={item.google_location}>
                            {" "}
                            <Icon icon={location} size={20} />
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{moment(item.created_date).format("DD-MM-YYYY")}</td>
                      <td
                        onClick={() => {
                          setInitialValuesVendor(item);
                          toggleModal1(true);
                        }}
                      >
                        <Edit />
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={showImgModal}
        toggle={() => toggleImgModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleImgModal(false)}></ModalHeader>
        <ModalBody className="modalBodyImg">
          {currentImg && (
            <>
              <img src={currentImg} />
              <a target="_blank" href={currentImg}>
                Download
              </a>
            </>
          )}
        </ModalBody>
        <ModalFooter className="imgModalFooter">
          <div className="displayImageflex">
            {displayImagesList &&
              displayImagesList.length &&
              displayImagesList.map((pCard, pIdx) => {
                let loc = JSON.parse(pCard.toString());
                let img = loc.location.split("/");
                img = img[img.length - 1];
                return (
                  <div
                    key={pIdx}
                    className=" images-list"
                    onClick={() => setCurrentImg(loc.location)}
                  >
                    <img target="_blank" src={loc.location} />
                  </div>
                );
              })}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
