import myDeleteReq from "../../utils/delete";
import myGetReq from "../../utils/get";
import myPostReq from "../../utils/post";
import myUpdateReq from "../../utils/update";

export const fetchBuildings = async () => {
  try {
    return await myGetReq(`/app/usr/getbuildings`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};

export const updateBuilding = async (data) => {
  let jdata = JSON.stringify({ data });
  try {
    return await myUpdateReq("/app/usr/updatebuilding", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteBuilding = async (data) => {
  let jdata = JSON.stringify({ id: data });
  console.log("action dela", data);
  try {
    return await myDeleteReq("/app/usr/deletebuilding", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBuilding = async (data) => {
  let jdata = JSON.stringify({ data });
  try {
    return await myPostReq("/app/usr/addbuilding", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

//////////////////////////////////////////////////////////

export const fetchUnits = async () => {
  try {
    return await myGetReq(`/app/usr/getunits`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};
export const fetchUnit = async (id) => {
  try {
    return await myGetReq(`/app/usr/getunit?id=${id}`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};

export const updateUnit = async (data) => {
  let jdata = JSON.stringify({ data });
  try {
    return await myUpdateReq("/app/usr/updateunit", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteUnit = async (data) => {
  let jdata = JSON.stringify({ id: data });
  console.log("action dela", data);
  try {
    return await myDeleteReq("/app/usr/deleteunit", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addUnit = async (data) => {
  let jdata = JSON.stringify({ data });
  try {
    return await myPostReq("/app/usr/addunit", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

////////////////////////////////////////////////

export const fetchBookings = async () => {
  try {
    return await myGetReq(`/app/usr/getbookings`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};

export const updateBooking = async (data) => {
  // let jdata = JSON.stringify({ data })
  // try {
  //     return await myUpdateReq("/app/usr/updatebooking", jdata);
  // } catch (err) {
  //     console.log(err)
  //     return err;
  // }
  console.log(data.utenent_id, "data from update");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data));

  for (let i = 0; i < data.utenent_id.length; i++) {
    formdata.append("files", data.utenent_id[i]);
  }
  try {
    return await myUpdateReq("/app/usr/updatebooking", formdata, {});
  } catch (err) {
    return err;
  }
};

export const deleteBooking = async (data) => {
  let jdata = JSON.stringify({ data: data });
  console.log("action dela", data);
  try {
    return await myDeleteReq("/app/usr/deletebooking", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBooking = async (data) => {
  // let jdata = JSON.stringify({ data })
  // try {
  //     return await myPostReq("/app/usr/addbooking", jdata);
  // } catch (err) {
  //     console.log(err)
  //     return err;
  // }

  console.log(data.tenent_id, "data from add");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data));

  for (let i = 0; i < data.tenent_id.length; i++) {
    formdata.append("files", data.tenent_id[i]);
  }
  try {
    return await myPostReq("/app/usr/addbooking", formdata, {});
  } catch (err) {
    console.log(err, "add err");
    return err;
  }
};

/////////////////////////////////////////////////////

export const fetchInvoices = async () => {
  try {
    return await myGetReq(`/app/usr/getinvoices`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};

export const updateInvoice = async (data) => {
  let jdata = JSON.stringify({ data });
  try {
    return await myUpdateReq("/app/usr/updateinvoice", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteInvoice = async (data) => {
  let jdata = JSON.stringify({ id: data });
  console.log("action dela", data);
  try {
    return await myDeleteReq("/app/usr/deleteinvoice", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addInvoice = async (data) => {
  let jdata = JSON.stringify({ data });
  try {
    return await myPostReq("/app/usr/addinvoice", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};
/////////////////////////////////////////////////////

export const fetchRentalLedgers = async () => {
  try {
    return await myGetReq(`/app/usr/getrentalledgers`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};

export const updateRentalLedger = async (data) => {
  // let jdata = JSON.stringify({ data })
  // try {
  //     return await myUpdateReq("/app/usr/updatebooking", jdata);
  // } catch (err) {
  //     console.log(err)
  //     return err;
  // }
  // console.log(data.utenent_id, "data from update");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data));

  for (let i = 0; i < data.uattachment.length; i++) {
    formdata.append("files", data.uattachment[i]);
  }
  try {
    return await myUpdateReq("/app/usr/updaterentalledger", formdata, {});
  } catch (err) {
    return err;
  }
};

export const deleteRentalLedgers = async (data) => {
  let jdata = JSON.stringify({ data: data });
  console.log("action dela", data);
  try {
    return await myDeleteReq("/app/usr/deleterentalledger", jdata);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addRentalLedgers = async (data) => {
  // let jdata = JSON.stringify({ data })
  // try {
  //     return await myPostReq("/app/usr/addbooking", jdata);
  // } catch (err) {
  //     console.log(err)
  //     return err;
  // }

  console.log(data.tenent_id, "data from add");
  const formdata = new FormData();
  formdata.append("data", JSON.stringify(data));

  for (let i = 0; i < data.attachment.length; i++) {
    formdata.append("files", data.attachment[i]);
  }
  try {
    return await myPostReq("/app/usr/addrentalledger", formdata, {});
  } catch (err) {
    console.log(err, "add err");
    return err;
  }
};
