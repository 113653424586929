import { Typography, Box } from "@mui/material";

const HFBox = ({ title, children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        bgcolor: "background.paper",
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
        minWidth: 300,
        marginBottom: 2,
      }}
    >
      {title && (
        <Typography variant="body1" className="mb-3">
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
export default HFBox;
