import React, { useEffect, useState } from 'react'

import TaskInput from './TaskInput'
import styles from "./taskmanager.module.css"
import { deleteTask, fetchTasks } from '../../redux/actions/taskManager'


import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Stack,
    Text,
    Box,
    StackDivider,
    Button,
    Image,
    Select,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,

    FormControl,
    FormLabel,
    useDisclosure,
    useToast,
    Avatar
} from "@chakra-ui/react";
import { ChatIcon, DragHandleIcon } from "@chakra-ui/icons"
import TasksAssigned from './TasksAssigned'
import MyTasks from './MyTasks'
import Draggable from 'react-draggable';

import myTasksImg from "./icons/mytasks.png"
import assignedTasksImg from "./icons/assignedTasks.png"


const Taskmanager = () => {
    const [tasksData, settasksData] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()


    const [pendingMT, setpendingMT] = useState(0)
    const [pendingAT, setpendingAT] = useState(0)

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const [taskShower, settaskShower] = useState(false)

    function taskFetcher() {
        fetchTasks().then((res) => {
            // console.log("task fetcher", res.res)
            settasksData(res.res.rows)
            notifyCounter(res.res.rows)
        })
    }
    const toast = useToast()

    function notifyCounter(data) {
        //  console.log(data, "ncncnc")
        setpendingAT(0)
        setpendingMT(0)
        if (data.length != 0) {
            data.map((ele) => {
                if ((ele?.assignedto?.includes(getUserName()) && ele.status == "ToDo") || (ele?.assignedto?.includes("ALL$") && ele.status == "ToDo")) {
                    // console.log(ele)
                    setpendingMT((el) => el + 1)
                } if (ele.assignedby == getUserName() && ele.status != "Completed") {
                    setpendingAT((el) => el + 1)
                }
            })
        }

    }


    function handleDelete(ele, data) {
        deleteTask(ele, data).then((res) => {
            // console.log(res, "deleter")
            if (res.status) {

                toast({
                    title: 'Task deleted.',
                    description: "Task has been deleted.",
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                    size: "lg"
                })
                onClose()

            } else {
                toast({
                    title: 'Task not deleted.',
                    description: "Internal Error",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    size: "5rem"
                })

            }
            taskFetcher()
            //console.log(res, "delete res")
        })
    }

    // setInterval(() => {
    //     // console.log("first")
    //     for (let i = 0; i < tasksData.length; i++) {
    //         checker(tasksData[i])
    //     }
    // }, 60000);


    // function checker(data) {

    //     let [remmonth, remdate, remyear] = new Date(data.remainderdate).toLocaleDateString().split("/")


    //     let [currmonth, currdate, curryear] = new Date(Date.now()).toLocaleDateString().split("/")

    //     let [currhour, currmin] = new Date(Date.now()).toTimeString().split(" ")[0].split(":")
    //     //console.log(data.remainder)
    //     let [remhour, remmin] = data.remainder.split(" ")[0].split(":")

    //     // console.log(currdate, remdate, currmonth, remmonth, curryear, remyear)
    //     //console.log(currhour, remhour, currmin, remmin)
    //     if (currdate == remdate && currmonth == remmonth && curryear == remyear) {
    //         //console.log("date is same ssssssss")
    //         if (currhour == remhour) {
    //             //console.log("hour is same")
    //             if (currmin == remmin || currmin == Number(remmin) + 1 || currmin == Number(remmin - 1)) {
    //                 remaindAlert(data)
    //             }
    //         }
    //     }
    // }

    // let remCache = []
    // function remaindAlert(data) {
    //     let checkKey = `${data.taskid}${data.remainder}${data.remainderdate}`
    //     //  console.log(checkKey, remCache)
    //     if (!remCache.includes(checkKey)) {
    //         remCache.push(checkKey)
    //         alert(checkKey)
    //     }
    // }

    function getUserName() {
        let useremail = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).login).userDetails.email
        // console.log(useremail)
        return useremail
    }


    useEffect(() => {
        taskFetcher()
    }, [])

    const [drag, setdrag] = useState(true)

    function dragChanger() {
        setTimeout(() => {
            setdrag(true)
        }, 2500);
    }



    return (
        <div className={styles.divcont} >
            <Draggable disabled={drag}  >
                <Box border={"1px solid white"} px={"0.5rem"} py={"0.1rem"} borderRadius={"2rem"} backdropFilter='blur(10px) hue-rotate(50deg)' bg={"blackAlpha.300"} alignItems={"center"} display={"flex"} gap={"0.5rem"} className={styles.floatingbutton} >
                    {drag && <>
                        <Box onClick={() => {
                            settaskShower(false)
                            onOpen()
                        }} p={"0.3rem"} border={"3px solid white"} borderRadius={"50%"} bg='blackAlpha.100' w={"2.5rem"} h={"2.5rem"} >
                            <Image src={myTasksImg} />
                            {pendingMT != 0 && <Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius="50%" backgroundColor={"red"} color="white" fontWeight={"700"} position={"absolute"} top={"0"} left={"0"} height={"1.5rem"} w={"1.5rem"} border={"1px solid white"} >
                                {pendingMT}
                            </Box>}
                        </Box>

                        <Box onClick={() => {
                            settaskShower(true)
                            onOpen()
                        }} p={"0.3rem"} border={"3px solid white"} borderRadius={"50%"} bg='blackAlpha.100' w={"2.5rem"} h={"2.5rem"} >
                            <Image src={assignedTasksImg} />
                            {pendingAT != 0 && <Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius="50%" backgroundColor={"red"} color="white" fontWeight={"700"} position={"absolute"} top={"0"} left={"12"} height={"1.5rem"} w={"1.5rem"} border={"1px solid white"} >
                                {
                                    pendingAT
                                }
                            </Box>}
                        </Box>

                        <Box  >
                            <TaskInput asstasks={taskShower} taskFetcher={taskFetcher} />
                        </Box>
                    </>}
                    <Box onClick={() => {
                        setdrag(false)
                        dragChanger()
                    }} display={"flex"} alignItems={"center"} justifyContent="center" p={"0.1rem"} border={"3px solid white"} borderRadius={"50%"} bg='blackAlpha.100' w={"2.5rem"} h={"2.5rem"} my="0.3rem" >
                        <DragHandleIcon />
                    </Box>

                </Box>
            </Draggable>


            <Modal
                size={"6xl"}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(50deg)' />
                <ModalContent borderRadius={"1rem"} overflowX={"hidden"} mx={3}
                >
                    <ModalHeader display={"flex"} flexWrap={"wrap-reverse"} justifyContent={"space-between"} fontSize={"large"} paddingLeft={"4"} paddingRight={"10"} pb={0} paddingTop={"6"} >
                        <Text px={2} fontSize={"1.5rem"} color={"rgb(2,80,86)"} fontWeight={"550"}  >
                            Task Manager
                        </Text>
                        <Text px={2} fontWeight={"550"}  >
                            {
                                taskShower ? "Assigned Tasks" : "My Tasks"
                            }
                        </Text>
                    </ModalHeader>



                    <ModalCloseButton />

                    {taskShower ?
                        <ModalBody overflowY={"scroll"} maxH={"60vh"} px={2} py={1} >
                            {tasksData?.length != 0 &&
                                tasksData.map((ele) => {
                                    if (ele.assignedby == getUserName()) {
                                        return <div key={ele.taskid} >
                                            <Accordion allowToggle>
                                                <TasksAssigned taskFetcher={taskFetcher} deleteFunction={handleDelete} data={ele} />
                                            </Accordion>
                                        </div>
                                    }
                                })
                            }

                        </ModalBody>
                        :
                        <ModalBody overflowY={"scroll"} maxH={"60vh"} px={2} py={1} >
                            {tasksData?.length != 0 &&
                                tasksData.map((ele) => {

                                    if (ele?.assignedto?.includes(getUserName()) || ele?.assignedto?.includes("ALL$")) {

                                        return <div key={ele.taskid} >

                                            <Accordion allowToggle>
                                                <MyTasks taskFetcher={taskFetcher} data={ele} />
                                            </Accordion>
                                        </div>
                                    }
                                })
                            }

                        </ModalBody>
                    }
                    <ModalFooter>
                        <Button colorScheme='red' variant='outline' onClick={() => {
                            onClose()
                        }}>Close</Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>

        </div>
    )
}

export default Taskmanager