import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import get from "../../utils/get";
import LeadForm from "./LeadForm";
import moment from "moment";
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Stack,
  Paper,
  Divider,
  Button,
} from "@mui/material";
import { Row, Col } from "reactstrap";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/material/styles";

const Header = ({ info }) => {
  console.log("info-props", info);
  return (
    <Row>
      <Col md={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <h3 className="page-title">Update Lead</h3>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ marginRight: 3 }}>
              <Typography variant="body2">
                {moment(info.created_on).format("DD-MM-YYYY hh:ss A")} (
                {info.added_by_name})
              </Typography>
              <Typography variant="caption">Added on</Typography>
            </Box>
            <Box sx={{ marginRight: 3 }}>
              <Typography variant="body2">
                {moment(info.updated_on).format("DD-MM-YYYY hh:ss A")} (
                {info.updated_by_name})
              </Typography>
              <Typography variant="caption">Updated on</Typography>
            </Box>
            <Box>
              <Button
                color="success"
                variant="outlined"
                onClick={() => {
                  window.open(`whatsapp://send?phone=${info.number}`);
                }}
                endIcon={<WhatsAppIcon />}
              >
                WhatsApp
              </Button>
            </Box>
          </Box>
        </Box>
      </Col>
    </Row>
  );
};

const LeadHistory = ({ history, mailHistory }) => {
  return (
    <Grid item xs={12} md={4}>
      <Card variant="outlined" className="h-100">
        <CardContent>
          <Typography
            variant="body1"
            align="center"
            style={{ backgroundColor: "#e0f2f1" }}
            className="mb-2"
          >
            Lead History
          </Typography>
          {history.map((item, index) => (
            <Typography
              variant="caption"
              display="block"
              key={`key-${item.id}`}
            >
              <strong>
                {moment(item.updated_on).format("DD-MM-YYYY || hh:mm A ||")}
              </strong>{" "}
              ({item.first_name}) {item.follow_up}
            </Typography>
          ))}
          <Typography
            variant="body1"
            align="center"
            style={{ backgroundColor: "#e1c4c4" }}
            className="mb-2"
          >
            Mail History
          </Typography>
          {mailHistory.map((item, index) => (
            <Typography
              variant="caption"
              display="block"
              key={`key-${item.id}`}
            >
              <strong>
                {moment(item.updated_on).format("DD-MM-YYYY || hh:mm A ||")}
              </strong>{" "}
              <strong>
                <em>{item.template_name}</em>
              </strong>
              {" ||  Count "}
              {item.mail}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};

const UpdateLead = (props) => {
  console.log("update-lead-props", props);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState();
  const [leadInfo, setLeadInfo] = useState({});
  const [leadHistory, setLeadHistory] = useState([]);
  const [mailHistory, setMailHistory] = useState([]);

  useEffect(() => {
    get(`/app/usr/lead?id=${id}`)
      .then((res) => {
        setInitialValues({
          id: res.lead.id,
          name: res.lead.name,
          number: res.lead.number,
          email: res.lead.email ? res.lead.email : "",
          location: res.lead.location ? res.lead.location : "",
          origin: res.lead.origin ? res.lead.origin : "",
          assign_to: res.lead.assign_to ? res.lead.assign_to : 0,
          start_date: res.lead.start_date ? res.lead.start_date : null,
          budget: res.lead.budget ? res.lead.budget : "",
          follow_up: res.lead.last_followup,
          requirement: res.lead.requirement,
          feedback: res.lead.feedback,
          rating: res.lead.rating,
          status: res.lead.status,
          prop_type: res.lead.prop_type,
          remind_on: res.lead.remind_on ? res.lead.remind_on : null,
          comment: "",
        });
        setLeadInfo(res.lead);
        setLeadHistory(res.followup);
      })
      .catch((err) => {
        console.log("error", err);
      });

    get(`/app/usr/leadMail?id=${id}`).then((res) => {
      console.log(res, "mail history ");
      setMailHistory([...res.leads]);
    });
  }, [id]);
  console.log(initialValues);
  return (
    initialValues && (
      <Container className="dashboard">
        <Header info={leadInfo} />
        <Grid container spacing={1}>
          <Grid item xs>
            <LeadForm
              cols="col-md-6"
              initialValues={initialValues}
              pathname={props.pathname}
              setLeadHistory={setLeadHistory}
            />
          </Grid>
          {leadHistory && (
            <LeadHistory history={leadHistory} mailHistory={mailHistory} />
          )}
        </Grid>
      </Container>
    )
  );
};

export default UpdateLead;
