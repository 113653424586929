// import { Heading } from "@chakra-ui/react";
// import React from "react";
import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Download } from "@mui/icons-material";
import {
  getAdminProjects,
  getAdminProjectsAtt,
} from "../../redux/actions/projectsAction";
import {
  getAttDashboard,
  getWeekAttDashboard,
} from "../../redux/actions/attendanceAction";
import DatepickerField from "../Datepicker/DatepickerField";
import {
  Box,
  CircularProgress,
  Fade,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import "./attendanceClass.css";
import {
  background,
  Button,
  ButtonGroup,
  ChakraProvider,
  Input,
  Select,
} from "@chakra-ui/react";
import { filter, map } from "lodash";
import XLSX from "xlsx";
import csvDownload from "json-to-csv-export";
import moment from "moment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import * as Btn from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const dataToConvert = {
  filename: "wps.csv",
  delimiter: ",",
};

const attendance = [
  "Mason",
  "Helper",
  "Tile",
  "Painters",
  "Carpenters",
  "Electrician",
  "Fabricator",
  "Bar bending",
  "Shuttering",
  "other",
];
Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof dowOffset == "number" ? dowOffset : 0; //default dowOffset to zero
  var newYear = new Date(this.getFullYear(), 0, 1);
  var day = newYear.getDay() - dowOffset; //the day of week the year begins on
  day = day >= 0 ? day : day + 7;
  var daynum =
    Math.floor(
      (this.getTime() -
        newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
        86400000
    ) + 1;
  var weeknum;
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      nYear = new Date(this.getFullYear() + 1, 0, 1);
      nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53;
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  return weeknum;
};
const weeks = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
const ViewDetails = [
  "Name/attendance_taken_by",
  "Date/date",
  "Work Force Name/name_attendance",
  "Comment/comment",
  "Work force count/no_of_worker",
  "Project/project_id",
  "Attachment/attachment",
];
const colorScheme = [
  "#f7e8e8", //0
  "#f2c4c4", //1
  "#e89d9d", //2
  "#ee6363", //3
  "#ff0000", //4
];
const LabourDashboard = () => {
  // _________________________STATES______________________________
  const [cntRender, setCntRender] = useState(1);
  const [projectsList, setProjectsList] = useState([]);
  const [selectDate, setSelectDate] = useState({
    week:
      Number(new Date().getWeek()) < 10
        ? `0${new Date().getWeek()}`
        : Number(new Date().getWeek()),
    year: Number(new Date().getFullYear()),
  });
  const [attendenceList, setAttendenceList] = useState([0]);
  const [labour, setLabour] = useState("");
  const [allData, setAllData] = useState([]);
  const [mapData2, setMapData2] = useState([]);
  const [selectProject, setselectProject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [perticularDataArr, setPerticularDataArr] = useState([]);
  let mapData = [],
    rows = [
      ...mapData2.filter(
        (obj) => obj.project_id == selectProject || selectProject == ""
      ),
    ];
  console.log(rows, mapData2, "rows");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // console.log(mapData2, rows, "mapData2");
  // _________________________FUNCTIONS______________________________
  // console.log(cntRender);
  // function addProject(allData) {
  //   console.log(allData, mapData, "all data set");
  //   allData.forEach((obj, ind) => {
  //     if (!mapData.find((curr) => obj.project_id == curr.project_id)) {
  //       const All_same_Pid = allData.filter(
  //         (data) => data.project_id === obj.project_id
  //       );
  //       const ans_arr = new Array(31).fill(0); //INITIAL

  //       //----------Problem when same day multiple attendance take latest
  //       All_same_Pid.forEach((objSid, ind2) => {
  //         ans_arr[new Date(objSid.date).getDate() - 1] = objSid.no_of_worker;
  //       });

  //       mapData = [
  //         ...mapData,
  //         { project_id: obj.project_id, Days: [...ans_arr] },
  //       ];
  //       setMapData2(mapData);
  //     }
  //   });
  //   setMapData2(mapData);
  // }
  function addProject(allData1) {
    mapData = [];
    console.log(allData1, mapData, "all data set");
    allData1.forEach((obj, ind) => {
      if (
        mapData.find(
          (el) =>
            el.project_id == obj.project_id &&
            el.name_attendance == obj.name_attendance
        )
      ) {
        const changeObj = mapData.find(
          (el) =>
            el.project_id == obj.project_id &&
            el.name_attendance == obj.name_attendance
        );
        console.log(
          moment(`${selectDate.year}-W${selectDate.week}`).diff(
            moment(`${selectDate.year}-W${selectDate.week}`).add(2, "days"),
            "days"
          ),
          "dfsdfdsf uuuuuuuuuu"
        );
        changeObj.Days[
          Math.abs(
            moment(`${selectDate.year}-W${selectDate.week}`).diff(
              moment(obj.date),
              "days"
            )
          )
        ] =
          Number(
            changeObj.Days[
              Math.abs(
                moment(`${selectDate.year}-W${selectDate.week}`).diff(
                  moment(obj.date),
                  "days"
                )
              )
            ]
          ) + Number(obj.no_of_worker);
        // console.log(
        //   obj.name_attendance,
        //   changeObj.Days[
        //     new Date(obj.date).getDate() -
        //       Number(
        //         moment(`${selectDate.year}-W${selectDate.week}`).format("DD")
        //       )
        //   ]
        // );
        mapData
          .filter(
            (el) =>
              !(
                el.project_id == obj.project_id &&
                el.name_attendance == obj.name_attendance
              )
          )
          .push({ ...changeObj });
      } else {
        const mapDataObj = {};
        mapDataObj.project_id = obj.project_id;
        mapDataObj.name_attendance = obj.name_attendance;
        const ans_arr = new Array(7).fill(0); //INITIAL
        ans_arr[
          Math.abs(
            moment(`${selectDate.year}-W${selectDate.week}`).diff(
              moment(obj.date),
              "days"
            )
          )
        ] = Number(obj.no_of_worker);
        mapDataObj.Days = ans_arr;
        mapData.push({ ...mapDataObj });
      }
    });
    console.log(mapData, "hello");
    setMapData2(mapData);
  }

  const getData = () => {
    getAdminProjectsAtt(JSON.stringify({ project_status: "all" })).then(
      (res) => {
        if (res.status) {
          let data1 = [];
          let arr = res.data;
          for (let idx in arr) {
            data1.push({
              value: arr[idx].project_id,
              label: arr[idx].project_title,
            });
          }
          setProjectsList(data1);
        }
      }
    );
    getWeekAttDashboard(selectDate.week, selectDate.year, labour).then(
      (res) => {
        if (res.status) {
          setAllData([...res.result]);
          // console.log(res.result, "data");
          addProject(res.result);
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      }
    );
    setAttendenceList(modify(attendance));
    // setLoading(false);
  };

  function modify(arr) {
    let ansArr = [];
    arr.forEach((str, i) => {
      ansArr.push({
        label: str,
        value: i,
      });
    });
    return ansArr;
  }
  // console.log(mapData, mapData2, "map data");
  const onChangeProject = (e) => {
    setselectProject(e.target.value);
    console.log(e.target.value, "empty");
    rows = [
      ...mapData2.filter(
        (obj) => obj.project_id == e.target.value || e.target.value == ""
      ),
    ];
  };
  const DownloadProject = () => {
    dataToConvert.data = allData.filter(
      (el) => el.project_id == selectProject || selectProject == ""
    );
    csvDownload(dataToConvert);
    // var csv = XLSX.utils.sheet_to_csv(rows, opts);
  };
  const convertPrec = (prev) => {
    if (Number(prev) > 0 && Number(prev) < 9) {
      prev = Number(prev) + 1;
      return `0${prev}`;
    } else {
      // console.log("yesdfds");
      return Number(prev) + 1;
    }
  };
  const convertPrecBack = (prev) => {
    if (Number(prev) > 0 && Number(prev) < 11) {
      prev = Number(prev) - 1;
      return `0${prev}`;
    } else {
      // console.log("yesdfds");
      return Number(prev) - 1;
    }
  };
  const handleTableDateTaggle = (indRow, ind) => {
    const p_id = rows[indRow].project_id;
    const labour_Name = rows[indRow].name_attendance;
    const Date1 = new Date(
      moment(`${selectDate.year}-W${selectDate.week}`).day(ind + 1)
    ).toLocaleDateString();
    const perticularData = allData.filter(
      (el) =>
        el.project_id === p_id &&
        (el.date == Date1 ||
          new Date(el.date).toLocaleDateString() === Date1) &&
        el.name_attendance === labour_Name
    );
    let perticularData2 = JSON.stringify(perticularData);
    perticularData2 = JSON.parse(perticularData2);
    let pd = perticularData2.map((el, ind) => {
      el.project_id = projectsList.filter(
        (pr) => pr.value == el.project_id
      )[0].label;
      el.date = new Date(el.date).toLocaleDateString();
      return el;
    });
    setPerticularDataArr((prev) => [...pd]);
    handleClickOpen();
  };
  //--------------------------Component----------------------------------

  // _________________________USE_EFFECT______________________________
  useEffect(() => {
    setLoading(true);
    getData();
  }, [selectDate.week, selectDate.year, labour]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);
  // console.log(selectDate);
  return (
    <>
      <ChakraProvider>
        <div className="selectField">
          <div className="selectLab">
            <Select
              variant="filled"
              placeholder="Select Labour"
              onChange={(e) => setLabour(e.target.value)}
              bg="#ececec"
              defaultValue={labour}
            >
              {attendenceList?.map((option, ind) => (
                <option key={ind} value={option.label}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>
          <div className="selectDate">
            <Input
              type="week"
              // defaultValue={`${selectDate.year}-W${selectDate.week}`}
              value={`${selectDate.year}-W${selectDate.week}`}
              onChange={
                (e) =>
                  setSelectDate({
                    year: e.target.value.split("-")[0],
                    week: e.target.value.split("-W")[1],
                  })
                // console.log(moment(e.target.value).format("DD"))
                // console.log(e.target.value)
              }
            />
          </div>
          <div>
            <Button onClick={DownloadProject} padding="20px">
              <Download />
              Download
            </Button>
          </div>
        </div>
      </ChakraProvider>

      <div className="dashboardFlex">
        <Button
          variant="Outlined"
          style={{ paddingLeft: "10px" }}
          onClick={() => {
            setSelectDate((prev) =>
              Number(prev.week) !== 1
                ? { ...prev, week: convertPrecBack(prev.week) }
                : { year: Number(prev.year) - 1, week: 52 }
            );
          }}
        >
          <ArrowBackIosIcon />
        </Button>

        <TableContainer
          component={Paper}
          sx={{ overflowY: "scroll", maxHeight: "70vh" }}
          className="tableLabourDashboard"
        >
          <Table
            sx={{ minWidth: 20 }}
            stickyHeader
            aria-label="sticky table"
            // aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {/* _________________________________ */}
                <TableCell
                  style={{
                    zIndex: 4,
                  }}
                  className="selectProject1"
                >
                  <ChakraProvider>
                    <Select
                      variant="contained"
                      placeholder="Select Project"
                      onChange={onChangeProject}
                      bg="#ececec"
                      defaultValue={selectProject}
                      style={{ zIndex: 4, padding: "7px" }}
                    >
                      {projectsList?.map((option, ind) => (
                        <option key={ind} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </ChakraProvider>
                </TableCell>
                {Array(7)
                  .fill(1)
                  .map((i, ind) => (
                    <TableCell
                      key={Number(
                        moment(`${selectDate.year}-W${selectDate.week}`).day(
                          ind
                        )
                      )}
                      align="right"
                      style={{
                        padding: "7px",
                        border: "2px solid #fff",
                      }}
                    >
                      <div className="tableEle2">
                        {Number(
                          new Date(
                            moment(
                              `${selectDate.year}-W${selectDate.week}`
                            ).day(ind + 1)
                          ).getDate()
                        ) < 10
                          ? "0" +
                            Number(
                              new Date(
                                moment(
                                  `${selectDate.year}-W${selectDate.week}`
                                ).day(ind + 1)
                              ).getDate()
                            ) +
                            "/" +
                            weeks[ind]
                          : Number(
                              new Date(
                                moment(
                                  `${selectDate.year}-W${selectDate.week}`
                                ).day(ind + 1)
                              ).getDate()
                            ) +
                            "/" +
                            weeks[ind] +
                            ""}
                      </div>
                    </TableCell>
                  ))}
                {/* <TableCell align="right">Fat&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody style={{ textAlign: "center" }}>
              {rows.map((row, indRow) => (
                <TableRow
                  key={`${row.project_id}.${row.name_attendance}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {/* ________________PROJECT LIST__________________ */}
                  <TableCell
                    component="th"
                    scope="row"
                    className="projectElement"
                  >
                    {projectsList.find((nobj) => nobj.value == row.project_id)
                      ?.label +
                      " (" +
                      row.name_attendance +
                      ")"}
                  </TableCell>
                  {/* ________________Days = NO OF WORKER__________________ */}
                  {console.log(rows, "dfdsfdsf")}
                  {row.Days.map((el, ind) =>
                    (selectDate.week >= new Date().getWeek() + 2 &&
                      selectDate.year >= new Date().getFullYear()) ||
                    selectDate.year > new Date().getFullYear() ? (
                      <TableCell
                        align="right"
                        className="TableElement"
                        style={{
                          background: "#fff",
                          color: "#fff",
                          padding: "7px",
                          textAlign: "center",
                        }}
                        name={`${indRow}/${ind}`}
                        title={"No Of Worker-" + el}
                        onClick={() => handleTableDateTaggle(indRow, ind)}
                      >
                        {el}
                      </TableCell>
                    ) : (
                      <TableCell
                        align="right"
                        className="TableElement"
                        name={`${indRow}+${ind}`}
                        style={{
                          background:
                            colorScheme[
                              4 - Number(el) >= 0 ? 4 - Number(el) : 0
                            ],
                          color: el < 3 ? "#fff" : "#000",
                          padding: "7px",
                          textAlign: "center",
                          transition: "all 1s ease-in 0s",
                        }}
                        onClick={() => handleTableDateTaggle(indRow, ind)}
                        title={"No Of Worker-" + el}
                      >
                        {el}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="Outlined"
          style={{ paddingRightt: "10px" }}
          onClick={() => {
            setSelectDate((prev) =>
              Number(prev.week) < 52
                ? { ...prev, week: convertPrec(prev.week) }
                : { year: Number(prev.year) + 1, week: "01" }
            );
          }}
        >
          <ArrowForwardIosIcon />
        </Button>
      </div>
      {/* _____________________________DIALOG____________________________ */}
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>View Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Table>
                {ViewDetails.map((el, ind) => (
                  <TableRow key={ind}>
                    <TableCell variant="head">{el.split("/")[0]}</TableCell>
                    {perticularDataArr.map((row, ind1) => (
                      <TableCell key={ind1}>
                        {row?.[`${el.split("/")[1]}`]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </Table>
            </DialogContentText>
            {/* <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
            /> */}
          </DialogContent>
          <DialogActions>
            {/* <Btn onClick={handleClose}>Cancel</Btn>
            <Btn onClick={handleClose}>Subscribe</Btn> */}
          </DialogActions>
        </Dialog>
      </div>
      {/* _____________________________LOADING ICON____________________________ */}
      <Box sx={{ height: 40, textAlign: "center", paddingTop: "10px" }}>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      </Box>
    </>
  );
};

export default LabourDashboard;
