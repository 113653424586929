import { Box, Button, CircularProgress, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text
} from '@chakra-ui/react'
import { getWorkOrdersApi } from '../../../redux/actions/workOrderAction'
import { useState } from 'react'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

const WorkOrderPopUp = ({ role, data, isOpen, onClose }) => {

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    console.log(data, isOpen, 'data')

    const [selectedProject, setSelectedProject] = useState({
        value: data.project_id,
        lable: data.project_title
    });

    const [resdata, setresdata] = useState([])
    const [load, setload] = useState(true)

    const [filterData, setFilterData] = useState({
        pid: 0,
        comments: "",
        date: "",
        category: "",
        person: "",
        status: -1,
    });

    function gettingworkorderlist() {
        console.log("393939")
        setload(true)
        getWorkOrdersApi(selectedProject, filterData).then((res) => {
            console.log(res, "res 232323")
            setresdata(res?.data)
            setload(false)
        }).catch((res) => {
            console.log(res, "424242")
        })
    }

    useEffect(() => {
        if (isOpen) {
            gettingworkorderlist()
            console.log("useeffect 494949")
        }
    }, [isOpen])


    return (
        <Box>

            <Modal
                size={"6xl"}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(50deg)' />
                <ModalContent borderRadius={"1rem"} overflowX={"hidden"} mx={3}
                >
                    <ModalHeader display={"flex"} flexWrap={"wrap-reverse"} justifyContent={"space-between"} fontSize={"large"} paddingLeft={"4"} paddingRight={"10"} pb={0} paddingTop={"6"} >
                        <Text px={2} fontSize={"1.5rem"} color={"rgb(2,80,86)"} fontWeight={"550"}  >
                            Work Orders
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody overflowY={"scroll"} maxH={"60vh"} px={2} py={1} >
                        {
                            load ? <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}

                            >
                                <CircularProgress size={"6rem"} isIndeterminate color="green.300" />
                            </Box> :


                                <> {
                                    resdata && resdata.length != 0 && resdata.map((ele) => {
                                        return <Box display={"flex"} backgroundColor={"blue.300"} color={"white"} justifyContent={"space-between"} alignItems={"center"} m={"0.5rem"} p={"0.3rem"} px={"1rem"} borderRadius={"0.5rem"} fontSize={"1.2rem"} >
                                            <Box border={"1px solid white"} borderRadius={"0.5rem"} fontSize={"1.5rem"} backgroundColor={"red.400"} px={"0.5rem"} >
                                                <NavLink
                                                    to={`/app/${role}/wo-detailed-view/${JSON.stringify(selectedProject)}/${ele.id}`}
                                                >
                                                    {" "}
                                                    {ele.id}
                                                </NavLink>
                                            </Box>
                                            <Box>{ele.category_name}</Box>
                                            <Box>
                                                {
                                                    ele.assigned_to_firstname
                                                }{
                                                    ele.assigned_to_lastname
                                                }
                                            </Box>
                                            <Box>
                                                <Box>
                                                    M:
                                                    T: {ele.ms_total_amt}, B:{" "}
                                                    {ele.ms_total_balance_amount},
                                                    P: {ele.ms_total_total_paid}
                                                </Box>
                                                <Box>
                                                    AW:
                                                    T: {ele.a_total_amt}, B:{" "}
                                                    {ele.a_total_balance_amount},
                                                    P: {ele.a_total_total_paid}
                                                </Box>

                                            </Box>

                                        </Box>
                                    })
                                }

                                    {
                                        resdata && resdata.length == 0 &&
                                        <Box display={"flex"} backgroundColor={"red.400"} color={"white"} justifyContent={"center"} alignItems={"center"} m={"0.5rem"} p={"0.3rem"} px={"1rem"} borderRadius={"0.5rem"} fontSize={"1.5rem"} >No Data There</Box>
                                    }
                                </>
                        }
                    </ModalBody>



                    <ModalFooter>
                        <Button colorScheme='red' variant='outline' onClick={() => {
                            onClose()
                        }}>Close</Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </Box>
    )
}

export default WorkOrderPopUp