import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import { Link } from 'react-router-dom';

const Bookings = ({ projectInfo }) => {


  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Link to={`/app/customer/view/${projectInfo.project_id}`}>
        <Card onClick={() => { }} style={{ cursor: 'pointer' }}>
          <CardBody className="dashboard__booking-card">
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
                {projectInfo.project_title}
              </h5>
              {/* <TrendingDownIcon className="dashboard__trend-icon" /> */}
            </div>
            <h5 className="dashboard__booking-total-description">
              <strong>Customer Name:</strong> {`${projectInfo.customer_firstname} ${projectInfo.customer_lastname}`}</h5>
            <h5 className="dashboard__booking-total-description">
              <strong>Site Engineer Name:</strong> {`${projectInfo.site_engineer_firstname} ${projectInfo.site_engineer_firstname}`}</h5>
            <h5 className="dashboard__booking-total-description"><strong>Location:</strong> {projectInfo.location}</h5>
            {/* <div className="progress-wrap progress-wrap--small progress-wrap--pink-gradient progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">87%</p>
            <Progress value={87} />
          </div> */}
          </CardBody>
        </Card>
      </Link>

    </Col >
  );
}

export default Bookings;
