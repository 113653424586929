import { Box, Button, Card, CardActions, CardMedia } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getProjectFileImage,
  getProjectFileImage2,
} from "../redux/actions/projectsAction";

const ViewImage3 = () => {
  const location = useLocation();
  const myArray = location.state.myState;
  const [images, setImages] = useState([]);
  const [ids, setIds] = useState([]);
  console.log(myArray, "sdfdsklfds  dsf dsf dsfdsfdsfsdfsd dsfds");

  async function getImage(id) {
    const location = `https://housingfactory.s3.ap-south-1.amazonaws.com/rentalfiles/${id}`;
    let fileName = location.split("/");
    fileName = fileName[fileName.length - 1];
    console.log(location, "location");
    const files = await getProjectFileImage2(location, true);
    console.log(files, "start");
    let blob = window.URL.createObjectURL(files);
    let file = new File([blob], `${fileName}`, {
      preview: blob,
      type: files.type,
    });
    file.preview = blob;
    file.path = blob;
    // file.fromsrv = filesList;
    // return file;
    setImages((prev) => [...prev, file]);
  }
  useEffect(() => {
    !images.length &&
      myArray.forEach((el, ind) => {
        let id = el.split("/");
        id = id[id.length - 1];
        //   setIds((prev) => [...prev, id]);
        getImage(id);
      });

    !ids.length &&
      myArray.forEach((el, ind) => {
        let id = el.split("/");
        id = id[id.length - 1];
        setIds((prev) => [...prev, id]);
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyItems: "space-around",
        flexWrap: "wrap",
        alignContent: "space-around",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {ids?.map((id, indId) => {
        console.log(ids, id, "ids sd fsd fds fds");
        if (id?.split(".")[1] != "pdf") {
          return (
            <Card sx={{ maxWidth: 345, minWidth: 300 }}>
              <CardMedia
                sx={{ height: 140, backgroundSize: "contain" }}
                image={`https://housingfactory.s3.ap-south-1.amazonaws.com/rentalfiles/${id}`}
                title={`image ${indId}`}
              />
              {/* <img
                src={`https://housingfactory.s3.ap-south-1.amazonaws.com/rentalfiles/${id}`}
                target="_blank"
                style={{ width: "250px" }}
              /> */}
              <CardActions>
                <Button size="small">
                  <a
                    href={`https://housingfactory.s3.ap-south-1.amazonaws.com/rentalfiles/${id}`}
                  >
                    Download
                  </a>
                </Button>
              </CardActions>
            </Card>
          );
        } else {
          return (
            <Card sx={{ maxWidth: 345, minWidth: 300 }}>
              <CardMedia
                sx={{
                  height: 140,
                  backgroundSize: "contain",
                  margin: "auto",
                  textAlign: "center",
                  padding: "20px 0px",
                }}
                image={`https://housingfactory.s3.ap-south-1.amazonaws.com/rentalfiles/${id}`}
                title={`image ${indId}`}
              >
                PDF can't be viewed To view click on view
              </CardMedia>
              <CardActions>
                <Button>
                  <a href={`${images[indId]?.preview}`} target="_blank">
                    <Button>View PDF</Button>
                  </a>
                </Button>
                <Button size="small">
                  <a
                    href={`https://housingfactory.s3.ap-south-1.amazonaws.com/rentalfiles/${id}`}
                  >
                    Download
                  </a>
                </Button>
              </CardActions>
            </Card>
          );
        }
      })}
    </Box>
  );
};

export default ViewImage3;
