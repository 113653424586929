import { createAction } from "redux-actions";
import myPostReq from "../../utils/post";
import myGetReq, { downloadReq } from "../../utils/get";

export const getSelectedProjectInfo = async () => {
  try {
    return await myGetReq("/app/usr/get-basic-project-info");
  } catch (err) {
    return err;
  }
};

export const fetchPersons = async (data) => {
  try {
    return await myGetReq("/app/usr/get-persons-list");
  } catch (err) {
    return err;
  }
};

export const fetchProjects = async (data) => {
  try {
    return await myGetReq("/app/usr/get-projects-list");
  } catch (err) {
    return err;
  }
};

export const getLedgerData = async (data, filterValues) => {
  console.log(data, "data", filterValues, "filtervalues", "30.30.30");
  try {
    return await myGetReq(
      `/app/usr/display-ledger-entries?id=${data.value}&comments=${filterValues.comments}&paymentMode=${filterValues.paymentMode}&isGstBills=${filterValues.isGstBills}&txnReference=${filterValues.txnReference}&date=${filterValues.date}&paymentType=${filterValues.paymentType}&category=${filterValues.category}&person=${filterValues.person}&amount=${filterValues.amount}`
    );
  } catch (err) {
    return err;
  }
};

export const downloadAttachments = async (link, isPreview) => {
  try {
    return await downloadReq(
      `/app/usr/download-attachments?link=${link}`,
      isPreview
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const submitLedger = async (obj) => {
  const fileUploadHeaders = {
    accept: "application/json",
    "Content-Type": `application/json`,
  };

  try {
    return await myPostReq("/app/usr/add-ledger-entry", obj, {});
  } catch (err) {
    return err;
  }
};

export const updateLedger = async (obj) => {
  const fileUploadHeaders = {
    accept: "application/json",
    "Content-Type": `application/json`,
  };

  try {
    return await myPostReq("/app/usr/update-entry", obj, {});
  } catch (err) {
    return err;
  }
};

export const confirmSaveBasicWorkOrder = async (obj) => {
  try {
    return await myPostReq("/app/usr/confirm-save-work-order", obj);
  } catch (err) {
    return err;
  }
};

export const savePaymentRequest = async (obj) => {
  console.log(obj, 878787);
  try {
    return await myPostReq("/app/usr/create-payment-req", obj);
  } catch (err) {
    return err;
  }
};
export const updateWoPaymentStatus = async (obj) => {
  try {
    return await myPostReq("/app/usr/update-wo-payment-status", obj);
  } catch (err) {
    return err;
  }
};

export const fetchPaymentRequests = async (data, catg, assignto) => {
  try {
    return await myGetReq(
      `/app/usr/get-payment-req?selectedProject=${data}&selectedCategory=${catg}&selectedAssignTo=${assignto}`
    );
  } catch (err) {
    return err;
  }
};
export const fetchPaymentRequestsCount = async (data, catg, assignto) => {
  try {
    return await myGetReq(
      `/app/usr/get-payment-req-count?selectedProject=${data}&selectedCategory=${catg}&selectedAssignTo=${assignto}`
      // {
      //   headers: new Headers({
      //     "Clear-Site-Data": "cache",
      //     "Cache-Control": "no-store",
      //   }),
      // }
    );
  } catch (err) {
    return err;
  }
};

export const saveWorkOrderApi = async (obj) => {
  try {
    return await myPostReq("/app/usr/save-work-order", obj, {});
  } catch (err) {
    return err;
  }
};

export const updateBasicworkOrderApi = async (obj) => {
  try {
    return await myPostReq("/app/usr/update-work-order", obj, {});
  } catch (err) {
    return err;
  }
};

export const removeMilestone = async (obj) => {
  console.log(obj, "144144144");
  try {
    return await myPostReq("/app/usr/del-milestone", obj);
  } catch (err) {
    return err;
  }
};

export const saveWorkOrderDescription = async (obj) => {
  try {
    return await myPostReq("/app/usr/save-wo-desc", obj);
  } catch (err) {
    return err;
  }
};

export const saveMilestones = async (obj) => {
  try {
    return await myPostReq("/app/usr/save-wo-milestones", obj);
  } catch (err) {
    return err;
  }
};

export const updateWorkOrder = async (obj) => {
  try {
    return await myPostReq("/app/usr/update-work-order", obj);
  } catch (err) {
    return err;
  }
};

export const getLedgerDataApi = async (obj) => {
  try {
    return await myPostReq("/app/usr/get-wo-ledger-info", obj);
  } catch (err) {
    return err;
  }
};

export const getWorkOrdersApi = async (data, filterValues) => {
  console.log(data, filterValues, 183183);
  try {
    return await myGetReq(
      `/app/usr/get-work-orders?pid=${data.value}&category=${filterValues.category}&assigned_to=${filterValues.person}&start_date=${filterValues.date}&comments=${filterValues.comments}&status=${filterValues.status}`
    );
  } catch (err) {
    console.log(err, "err in 189189189");
    return err;
  }
};

export const getWorkOrdersApiUserList = async () => {
  console.log(183183);
  try {
    return await myGetReq(`/app/usr/get-work-orders-userList`);
  } catch (err) {
    console.log(err, "err in 189189189");
    return err;
  }
};

export const getDetailedWorkOrderApi = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-detailed-wo?wid=${data}`);
  } catch (err) {
    return err;
  }
};

export const updateWoLedgerEntries = async (id, ledgerId, milestoneId) => {
  console.log(id, ledgerId, milestoneId, "230500205");

  try {
    return await myGetReq(
      `/app/usr/update-wo-ledger?ticket_id=${id}&lid=${ledgerId}&milestoneId=${milestoneId}`
    );
  } catch (err) {
    return err;
  }
};
