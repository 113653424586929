import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    useDisclosure,
    Button,
    Text,
    Tooltip,
    Input,
    Textarea,
    useToast,
} from '@chakra-ui/react'
import { savePaymentRequest } from '../../redux/actions/workOrderAction'

const ScheduleRequestPopup = ({ pdata, data }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    // console.log(data, pdata, 202020)

    const toast = useToast()

    const [reqdata, setreqdata] = useState({
        // { "payDate": "2023-01-11", "toPay": "1000", "milestoneId": 1, "wid": "132", "comments": "tdjndtjtdhnethn" }
        toPay: "",
        payDate: new Date(Date.now()).toISOString().split("T")[0],
        milestoneId: data.id,
        wid: data.wid,
        comments: "",
    })

    function handlechange(ele) {
        let { name, val } = ele
        setreqdata({
            ...reqdata,
            [name]: val
        })

    }

    function handlesubmit(e) {
        e.preventDefault()

        savePaymentRequest(JSON.stringify(reqdata)).then((res) => {
            // console.log(res)
            if (res.msg == "Payment request already raised for this milestone") {
                toast({
                    title: 'Payment Schedule',
                    description: res.msg,
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                    size: "lg"
                })
            } else if (res.msg == "Payment request added successfully") {
                toast({
                    title: 'Payment Schedule',
                    description: res.msg,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    size: "lg"
                })
            } else {
                toast({
                    title: 'Payment Schedule',
                    description: res.msg,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    size: "lg"
                })
            }

            onClose()

        })
    }

    // console.log(reqdata)
    return (
        <Box>
            <Button m={"0.5rem"} w={"fit-content"} colorScheme={"green"} cursor={"pointer"} border={"1px solid white"} borderRadius={"0.3rem"} px={"0.5rem"} mx={"0.6rem"} onClick={onOpen}> Schedule Payment</Button>

            <Modal
                size={"6xl"}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <form onSubmit={handlesubmit} >
                    <ModalOverlay bg='blackAlpha.300'
                        backdropFilter='blur(10px) hue-rotate(50deg)' />
                    <ModalContent borderRadius={"1rem"} overflowX={"hidden"} mx={3}
                    >
                        <ModalHeader display={"flex"} flexWrap={"wrap-reverse"} justifyContent={"space-between"} fontSize={"large"} paddingLeft={"4"} paddingRight={"10"} pb={0} paddingTop={"6"} >
                            <Text px={2} fontSize={"1.5rem"} color={"rgb(2,80,86)"} fontWeight={"550"}  >
                                Payment Request
                            </Text>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody overflowY={"scroll"} maxH={"60vh"} px={2} py={1} >

                            <Box px={"0.3rem"} >
                                <Box my={"0.5rem"} backgroundColor={"violet"} color={"white"} justifyContent={"space-between"} p={"0.5rem"} px={"1rem"} flexWrap={"wrap"} borderRadius={"0.5rem"} fontSize={"1.3rem"} display={"flex"} alignItems={"center"} >

                                    <Box border={"1px solid white"} borderRadius={"0.5rem"} p={"0.5rem"} backgroundColor={"red.400"} >
                                        <Box>Total Amount:               {
                                            `₹${new Intl.NumberFormat('en-IN').format(data.amount)}`
                                        }</Box>
                                        <Box>Balance Amount:               {
                                            `₹${new Intl.NumberFormat('en-IN').format(data.balance_amount)}`
                                        }</Box>
                                        <Box>Total Paid Amount:              {
                                            `₹${new Intl.NumberFormat('en-IN').format(data.total_paid)}`
                                        }</Box>
                                    </Box>
                                    <Box   >
                                        <Box>Id: {data.id}</Box>
                                        <Box>WID: {data.wid}</Box>
                                        <Box>Task: {data.task}</Box>
                                    </Box>
                                </Box>

                                <Box>

                                    <Input my={"0.2rem"} required min={0} max={data.balance_amount} type={"number"} onChange={(e) => handlechange({ name: "toPay", val: e.target.value })} placeholder={"To Pay"} />

                                    <Input my={"0.2rem"} required value={
                                        reqdata.payDate
                                    } onChange={(e) => handlechange({ name: "payDate", val: e.target.value })} type={"date"} placeholder={"Pay Date"} />

                                    <Textarea my={"0.2rem"} required onChange={(e) => handlechange({ name: "comments", val: e.target.value })} placeholder={"Enter Comments Here"} />

                                </Box>
                                <Box my={"0.5rem"} backgroundColor={"teal.400"} color={"white"} justifyContent={"space-between"} px={"1rem"} flexWrap={"wrap"} borderRadius={"0.5rem"} fontSize={"1rem"} display={"flex"} >
                                    <Tooltip p={"0.5rem"} borderRadius={"0.5rem"} hasArrow label='Project Id' bg='blue.400'>
                                        <Box m={"0.5rem"} >{pdata.project_id}</Box>
                                    </Tooltip>

                                    <Tooltip p={"0.5rem"} borderRadius={"0.5rem"} hasArrow label='Project Title' bg='blue.400'>
                                        <Box m={"0.5rem"} >{pdata.project_title}</Box>
                                    </Tooltip>

                                    <Tooltip p={"0.5rem"} borderRadius={"0.5rem"} hasArrow label='Assaigned To' bg='blue.400'>
                                        <Box m={"0.5rem"} >{pdata.assigned_to_firstname}{pdata.assigned_to_lastname}</Box>
                                    </Tooltip>

                                    <Tooltip p={"0.5rem"} borderRadius={"0.5rem"} hasArrow label='Category Name' bg='blue.400'>
                                        <Box m={"0.5rem"} >{pdata.category_name}</Box>
                                    </Tooltip>

                                    <Tooltip p={"0.5rem"} borderRadius={"0.5rem"} hasArrow label='Customer Name' bg='blue.400'>
                                        <Box m={"0.5rem"} >{pdata.customer_firstname}{pdata.customer_lastname}</Box>
                                    </Tooltip>

                                    <Tooltip p={"0.5rem"} borderRadius={"0.5rem"} hasArrow label='Project Manager' bg='blue.400'>
                                        <Box m={"0.5rem"} >{pdata.pm_firstname}{pdata.pm_last_name}</Box>

                                    </Tooltip>

                                    <Tooltip p={"0.5rem"} borderRadius={"0.5rem"} hasArrow label='Site Engineer' bg='blue.400'>
                                        <Box m={"0.5rem"} >{pdata.se_firstname}{pdata.se_lastname}</Box>
                                    </Tooltip>

                                </Box>



                            </Box>
                        </ModalBody>



                        <ModalFooter>
                            <Button colorScheme={"linkedin"} mx={"0.5rem"} type={"submit"} >submit</Button>

                            <Button colorScheme='red' variant='outline' onClick={() => {
                                onClose()
                            }}>Close</Button>
                        </ModalFooter>

                    </ModalContent>

                </form>
            </Modal>
        </Box>
    )
}

export default ScheduleRequestPopup