import myUpdateReq from "../../utils/update";

export const updateAccessControl = async (data) => {
    console.log(data)
    try {
        return await myUpdateReq("/app/usr/update_access_control", JSON.stringify(data));
    } catch (err) {
        console.log(err, "update err")
        return err;
    }
}