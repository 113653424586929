import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box } from '@chakra-ui/react';

const AllLocs = ({ allpaths }) => {
    console.log(allpaths,"yguytg")
  const [map, setMap] = useState(null);

  const onMapLoaded = ({ map }) => {
    setMap(map);
  };

  const colors = ['#1978c8', '#FF0000', '#00FF00', '#FFA195', '#800080']; // array of colors for markers

  const renderMarkers = () => {
    return allpaths?.map((path, index) => (
      <Marker key={path?.id}
        lat={path?.path[path?.path?.length - 1].lat}
        lng={path?.path[path?.path?.length - 1].lng}
        userid={path?.ele?.userid}
        color={colors[index % colors.length]} // set color based on index of path
      />
    ));
  };

  const Marker = ({ userid, color }) => {
    const [showText, setShowText] = useState(false);

    return (
        <Box
            
            opacity={"0.8"}
        style={{
          position: 'relative',
          top: '-20px',
          left: '-20px',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: color,
          textAlign: 'center',
          color: '#FFF',
          fontSize: 14,
          fontWeight: 'bold',
          padding: 4,
            }}
            
            onMouseOver={() =>
            {
                setShowText(true)
            }}
        onMouseOut={() => setShowText(false)}
      >
        {showText && <Box top={"-10"} position={"absolute"} w={"fit-content"} p={"0.5rem"} borderRadius={"0.5rem"}  bgColor={"blue"} >{userid}</Box>}
      </Box>
    );
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'YOUR_API_KEY' }}
        defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapLoaded}
      >
        {renderMarkers()}
      </GoogleMapReact>
    </div>
  );
};

export default AllLocs;
