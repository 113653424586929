import React, { useEffect } from 'react'
import { useState } from 'react';
import Moment from "moment";
import SearchAddOutlineIcon from "mdi-react/SearchAddOutlineIcon";
import { Table } from 'reactstrap';



const WopBlock = ({ PaymentMode, PaymentType, getLedgerData, idx, dat, currentPage, woPaymentStatus, onChangeStatus }) => {
    const [tds, settds] = useState(true)

    // console.log(acamount, "acam")
    // console.log(dat.amount, "dat am")

    useEffect(() => {
        settds(true)
    }, [dat])


    return (
        <>
            <tr key={idx}>
                <td>{20 * (currentPage - 1) + idx + 1}</td>
                <td>{dat.project_title}</td>
                <td>{dat.task}</td>

                <td>{dat.category_name}</td>
                <td>
                    {dat.first_name} {dat.last_name}
                </td>
                <td>

                    {dat.status == 1 || dat.status === 2 ? (
                        <select
                            onChange={(evt) =>
                                onChangeStatus(
                                    evt,
                                    dat.wo_milestones_id,
                                    dat.wid,
                                    dat.id,
                                    dat,
                                    tds ? (Number(dat.amount) - (Number(dat.amount) / 100)) : (Number(dat.amount))

                                )
                            }
                            value={dat.status}
                        >
                            {woPaymentStatus.map((stat, sidx) => {
                                return (
                                    <option idx={sidx} value={stat.value}>
                                        {stat?.label}
                                    </option>
                                );
                            })}

                        </select>
                    ) : (
                        woPaymentStatus.filter(
                            (o) => o.value == dat.status
                        )[0]?.label
                    )}
                </td>
                <td>{dat.amount}</td>

                <td>
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <div onClick={() => {
                            settds(!tds)

                        }} style={{ backgroundColor: tds ? "red" : "green", padding: "0.3rem 0.5rem", color: "white", borderRadius: "0.5rem", margin: "auto", cursor: "pointer" }} >{tds ? "No Tds" : "Yes Tds"}</div>
                        <strong style={{ margin: "auto" }} >
                            {tds ? Number(dat.amount) / 100 : 0}
                        </strong>
                    </div>
                </td>
                <td>{tds ? (Number(dat.amount) - (Number(dat.amount) / 100)) : (Number(dat.amount))}</td>
                <td>

                    {Moment(dat.payment_date).format("yyyy-MM-DD")}
                </td>
                <td>{dat?.comments}</td>
                <td>
                    {dat.status == 4 ? (
                        <span
                            style={{ color: "#00f", cursor: "pointer" }}
                            onClick={() => {
                                getLedgerData(idx + 20 * (currentPage - 1));
                            }}
                        >
                            <SearchAddOutlineIcon size="1.2em" />
                        </span>
                    ) : null}
                </td>
            </tr>
            {dat.viewLedgerEntries && (
                <>
                    <tr>
                        <td colSpan={9}>
                            <Table
                                style={{ width: "100%" }}
                                className="subTable"
                            >
                                <thead>
                                    <tr styele={{ zIndex: 4 }}>
                                        <th>Txn ID</th>
                                        <th>Person</th>
                                        <th>Amount</th>
                                        <th>Payment Type</th>
                                        <th>Payment Mode</th>
                                        <th>Gst Bills</th>
                                        <th>Txn Reference</th>
                                        <th>Comments</th>
                                        <th>Attachments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dat?.ledgerInfo?.length &&
                                        dat?.ledgerInfo.map((lItem, xidx) => {
                                            // console.log(lItem)
                                            return (
                                                <>
                                                    {/* {console.log(lItem)} */}
                                                    <tr key={xidx + "l"}>
                                                        <td>{lItem.txn_id}</td>
                                                        <td>{`${lItem.person_first_name} ${lItem.person_last_name}`}</td>
                                                        <td>{lItem.amount}</td>
                                                        <td>
                                                            {
                                                                PaymentType.filter(
                                                                    (o) =>
                                                                        o.value ===
                                                                        lItem.payment_type_id
                                                                )[0]?.label
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                PaymentMode.filter(
                                                                    (o) =>
                                                                        o.value ===
                                                                        lItem.payment_mode_id
                                                                )[0]?.label
                                                            }
                                                        </td>
                                                        <td>
                                                            {lItem.is_gst_bill
                                                                ? "Yes"
                                                                : "No"}
                                                        </td>
                                                        <td>{lItem.txn_reference}</td>
                                                        <td>{lItem.comments}</td>
                                                        <td>
                                                            {lItem.attachments.length ? (
                                                                JSON.parse(
                                                                    lItem.attachments
                                                                ).map((attach, xxidx) => {
                                                                    let imgLink =
                                                                        attach.split("/");
                                                                    imgLink =
                                                                        imgLink[
                                                                        imgLink.length - 1
                                                                        ];

                                                                    return (
                                                                        <div>
                                                                            <Link
                                                                                to={`/app/vimg/${imgLink}`}
                                                                                target="_blank"
                                                                            >
                                                                                <span>{`Attachment ${xxidx + 1
                                                                                    }`}</span>
                                                                            </Link>
                                                                        </div>
                                                                    );
                                                                })
                                                            ) : (
                                                                <div>NA</div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                </>
            )}
        </>
    )
}

export default WopBlock