import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import styles from "./ContectUs.module.css";
import React from "react";
import { fetchCustomersList } from "../../redux/actions/usersAction";
import {
  UploadContectUsDetails,
  verifyToken,
} from "../../redux/actions/projectsData";
// import Recapcha from "./Recapcha";
import ReCAPTCHA from "react-google-recaptcha";

function ContectUs({ isOpen, onOpen, onClose }) {
  const captchaRef = React.useRef(null);

  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    // shouldUseNativeValidation: true,
  });

  console.log(errors, "error");
  const onSubmit = async (data) => {
    // console.log("HELLO");
    if (!captchaRef.current.getValue()) {
      // console.log("HELLO2", captchaRef.current);
      //Set Error
      setError("captcha", {
        type: "required",
        message: "please fill the captcha",
      });

      console.log(errors);
    } else {
      verifyToken(captchaRef.current.getValue()).then((resp) => {
        // console.log(resp, "dsfsdfdsfsdfdsfdf rep");
        if (resp?.success) {
          UploadContectUsDetails(data).then((res) => {
            reset();
            captchaRef.current.reset();
          });
        } else {
          //set Error
          setError("captcha", {
            type: "required",
            message: "you are not Human Act like Human",
          });
          //  varification fail you are not Humen
        }
      });
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} mt={"38px"}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(5px) hue-rotate(10deg) brightness(0.7)"
        />
        <ModalContent
          bg="whiteAlpha.600"
          backdropFilter="blur(10px) hue-rotate(20deg)"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader sx={{ padding: "10px !important" }}>
              Contact Us
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={errors?.Name}>
                {/* <FormLabel>First name</FormLabel> */}
                <Input
                  {...register("Name", {
                    required: "Please enter your Name",
                  })}
                  variant="filled"
                  placeholder="Name"
                  onChange={() => {
                    console.log(errors);
                  }}
                />
                <FormErrorMessage
                  style={{
                    fontWeight: 500,
                    textShadow: "rgb(240 234 234) 2px 4px 10px",
                  }}
                >
                  {errors.Name && errors.Name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors?.Email}>
                {/* <FormLabel>Last name</FormLabel> */}
                <Input
                  {...register("Email", {
                    required: "Please enter your Email",
                  })}
                  variant="filled"
                  type={"email"}
                  placeholder="Email"
                />
                <FormErrorMessage
                  style={{
                    fontWeight: 500,
                    textShadow: "rgb(240 234 234) 2px 4px 10px",
                  }}
                >
                  {errors.Email && errors.Email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors?.Mobile_No}>
                <InputGroup>
                  <InputLeftAddon children="+91" />
                  <Input
                    {...register("Mobile_No", {
                      required: "Please enter your Number",
                    })}
                    variant="filled"
                    type="tel"
                    placeholder="Mobile No."
                  />
                  {/* <Input type="tel" placeholder="phone number" /> */}
                </InputGroup>
                <FormErrorMessage
                  style={{
                    fontWeight: 500,
                    textShadow: "rgb(240 234 234) 2px 4px 10px",
                  }}
                >
                  {errors.Mobile_No && errors.Mobile_No.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors?.Construction}>
                <Select
                  name="Construction"
                  {...register("Construction", {
                    required: "Please enter your Construction",
                  })}
                  variant="filled"
                  className={styles?.ContectSelect}
                >
                  <option value="Construction">Construction</option>
                  <option value="Compliants">Compliants</option>
                  <option value="Careers">Careers</option>
                  <option value="Channel Partners">Channel Partners</option>
                  <option value="Others">Others</option>
                </Select>
              </FormControl>
              <FormControl mt={4} isInvalid={errors?.Message}>
                {/* <Input placeholder="Message" /> */}
                <Textarea
                  {...register("Message", {
                    required: "Please enter your Message",
                  })}
                  variant="filled"
                  borderRadius={"lg"}
                  // value={value}
                  // onChange={handleInputChange}
                  placeholder="Message"
                  name="Message"
                  size="sm"
                />
                <FormErrorMessage
                  style={{
                    fontWeight: 500,
                    textShadow: "rgb(240 234 234) 2px 4px 10px",
                  }}
                >
                  {errors.Message && errors.Message.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors?.captcha}>
                {/* <Recapcha captchaRef={captchaRef} /> */}
                <div
                  {...register("captcha")}
                  style={{ textAlign: "center", margin: "auto" }}
                >
                  <ReCAPTCHA
                    sitekey="6Let1wckAAAAANIzw5r8wBesuuFOgbZklVYuaCl5"
                    // onChange={onChange}
                    style={{ margin: "auto" }}
                    ref={captchaRef}
                  />
                </div>
                <FormErrorMessage
                  style={{
                    fontWeight: 500,
                    textShadow: "rgb(240 234 234) 2px 4px 10px",
                  }}
                >
                  {errors.captcha && errors.captcha.message}
                </FormErrorMessage>
              </FormControl>{" "}
            </ModalBody>
            {/* <script
              src="https://www.google.com/recaptcha/api.js"
              async
              defer
            ></script> */}

            <ModalFooter sx={{ padding: "5px !important" }}>
              <Button
                type="submit"
                colorScheme="green"
                mr={3}
                // isLoading={isSubmitting}
              >
                Submit
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ContectUs;
//   <Button onClick={onOpen}>Open Modal</Button>
