import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Test from "./Test";
import Layout from "../../LayoutUser";
import Logout from "../../Logout/UserIndex";
import Attendance from "../../LabourAttendence&WorkDone";
import { useSelector } from "react-redux";

const RouterUQ = () => {
  const state = useSelector((state) => state);
  console.log(state, "state redux");
  return (
    <>
      <div>
        <Layout />
        <div className="container__wrap">
          <Routes>
            <Route path="/Packages" element={<Test />} />
            <Route path="/hello2" element={<div>hello2</div>} />
            <Route path="/hello3" element={<div>hello3</div>} />
            <Route path="/hello4" element={<div>hello4</div>} />
            <Route path="/hello5" element={<div>hello5</div>} />
            <Route path="/hello6" element={<div>hello6</div>} />
            <Route path="/dashboard" element={<div>Dashboard</div>} />
            <Route
              path="/labour-attendance/*"
              element={<Attendance pathname={`/user/labour-attendance`} />}
            />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default RouterUQ;
