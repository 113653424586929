import { createAction } from "redux-actions";
import myPostReq from "../../utils/post";
import myGetReq, { downloadReq } from "../../utils/get";

export const getCategories = async () => {
  try {
    return await myGetReq("/app/usr/get-category-list");
  } catch (err) {
    return err;
  }
};

export const fetchPersons = async (data) => {
  try {
    return await myGetReq("/app/usr/get-persons-list");
  } catch (err) {
    return err;
  }
};

export const fetchPersonsVS = async (data) => {
  try {
    return await myGetReq("/app/usr/get-persons-listvs");
  } catch (err) {
    return err;
  }
};

export const fetchProjects = async (data) => {
  try {
    return await myGetReq("/app/usr/get-projects-list");
  } catch (err) {
    return err;
  }
};
export const deleteLedgerEntry = async (id) => {
  try {
    return await myGetReq(`/app/usr/delete-ledger-entry?id=${id}`);
  } catch (err) {
    return err;
  }
};

export const getLedgerData = async (data, filterValues, canDownload) => {
  try {
    return await myGetReq(
      `/app/usr/display-ledger-entries?download=${canDownload}&id=${data.value}&txn_id=${filterValues.txn_id}&comments=${filterValues.comments}&paymentMode=${filterValues.paymentMode}&isGstBills=${filterValues.isGstBills}&txnReference=${filterValues.txnReference}&date=${filterValues.date}&paymentType=${filterValues.paymentType}&category=${filterValues.category}&person=${filterValues.person}&amount=${filterValues.amount}`
    );
  } catch (err) {
    return err;
  }
};
export const getLedgerDataIncome = async (projects_id) => {
  try {
    return await myGetReq(
      `/app/usr/display-ledger-entries-income?&id=${projects_id}&paymentType=${1}`
    );
  } catch (err) {
    return err;
  }
};

export const downloadAttachments = async (link, isPreview) => {
  try {
    return await downloadReq(
      `/app/usr/download-attachments?link=${link}`,
      isPreview
    );
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const submitLedger = async (obj) => {
  console.log(obj, 595959);

  try {
    return await myPostReq("/app/usr/add-ledger-entry", obj, {});
  } catch (err) {
    return err;
  }
};

export const updateLedger = async (obj) => {
  try {
    return await myPostReq("/app/usr/update-entry", obj, {});
  } catch (err) {
    return err;
  }
};

// export const saveWorkOrder = async (obj) => {

//   try {
//     return await myPostReq('/app/usr/confirm-save-work-order', obj);
//   } catch (err) {
//     return err;
//   }
// };

// export const updateWorkOrder = async (obj) => {

//   try {
//     return await myPostReq('/app/usr/update-work-order', obj);
//   } catch (err) {
//     return err;
//   }
// };

// export const getWorkOrdersApi = async (data, filterValues) => {
//   try {
//     return await myGetReq(`/app/usr/get-work-orders?pid=${data.value}&comments=${filterValues.comments}&cost=${filterValues.cost}&task=${filterValues.task}&start_date=${filterValues.date}&category=${filterValues.category}&assigned_to=${filterValues.person}`);
//   } catch (err) {
//     return err;
//   }
// };
