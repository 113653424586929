import React from "react";
import { Col, Container, Row } from "reactstrap";
import Box from "@mui/material/Box";

const Header = (props) => {
  return (
    <Row className="mb-2">
      <Col md={12}>
        <h3 className="page-title" style={{marginBottom:'4px'}}>{props.title}</h3>
      </Col>
    </Row>
  );
};
export default Header;
