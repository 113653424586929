import myPostReq from "../../utils/post";
import myGetReq from "../../utils/get";

export const fetchUsersList = async (data) => {
  console.log("fetch users action", data)
  try {
    return await myGetReq(
      `/app/usr/get-users-list?first_name=${data.first}&last_name=${data.last}&contact_num=${data.contact_num}&email=${data.email}&role=${data.role_id}`
    );
  } catch (err) {
    console.log(err)
    return err;
  }
};

export const updateUserRole = async (data) => {
  try {
    return await myPostReq("/app/usr/update-usr-role", data);
  } catch (err) {
    return err;
  }
};

export const updateContactNum = async (data) => {
  try {
    return await myPostReq("/app/usr/update-usr-contact-num", data);
  } catch (err) {
    return err;
  }
};

export const fetchCustomersList = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-customers-list?email=${data}`);
  } catch (err) {
    return err;
  }
};
export const fetchSiteEngineersList = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-site-engineers-list?str=${data}`);
  } catch (err) {
    return err;
  }
};

export const fetchServiceProvidersList = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-service-providers?str=${data}`);
  } catch (err) {
    return err;
  }
};

export const fetchProjectManagerList = async (data) => {
  try {
    return await myGetReq(`/app/usr/get-pm-list?str=${data}`);
  } catch (err) {
    return err;
  }
};
