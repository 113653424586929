import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Moment from "moment";
import { useParams, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';

import { Container, Row, Col, Card, CardBody } from "reactstrap";
// import LedgerForm from './LedgerForm';
import classNames from "classnames";

import { paidStatusList, workStatusList } from "@/shared/helpers/constants";

import {
  fetchPaymentRequests,
  getDetailedWorkOrderApi,
} from "../../redux/actions/workOrderAction";
import CreatePaymentRequest from "./CreatePaymentRequest";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ProjectScope } from "../../shared/helpers/constants";
import DropdownMilestone from "./DropdownMilestone";
import { Accordion, Box, CircularProgress, Tooltip } from "@chakra-ui/react";
import { getLedgerData } from "../../redux/actions/ledgerAction";

const schema = yup.object().shape({
  startDate: yup.string().required("This field is required"),
  endDate: yup.string().required("This field is required"),

  payDate: yup
    .string()
    .test("isvalue", "This field is required", (value) => {
      // console.log(value);
    })
    .required("This field is required"),
});

const DetailedWorkOrderView = () => {
  const param = useParams();
  const selectedProject = JSON.parse(param.pid);
  const wid = param.wid;
  const loginState = useSelector((state) => state.login);
  const [pageSliderStatus, setPageSliderStatus] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [selectedMilestone, setSelectedMilestone] = useState({});

  const [data, setData] = useState({});
  const [woDesc, setWoDesc] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [mdata, setmdata] = useState([]);
  const [legdata, setlegdata] = useState([]);

  const [load, setload] = useState(false);

  const togglePageSlideModal = (flag) => {
    setPageSliderStatus(flag);
  };

  function getledgerdata() {
    // console.log(data, "64.64.64")
    setload(false);
    getLedgerData(
      {
        label: data.project_title,
        value: data.project_id,
      },
      {
        txn_id: 0,
        comments: "",
        date: "",
        person: "",
        paymentType: "",
        paymentMode: "",
        isGstBills: "",
        category: data.category_name,
        amount: "",
        txnReference: "",
      },
      false
    )
      .then((res) => {
        // console.log(res, "178.178.178")
        if (res.status) {
          //  console.log(res, "36.36.36")
          //  console.log(res.data)
          setlegdata(res.data);
          setload(true);
        }
      })
      .catch((res) => {
        // console.log(res, "103.103.103")
      });
  }

  const getDetailedWorkOrder = () => {
    setload(false);
    getDetailedWorkOrderApi(wid).then((res) => {
      if (res.status) {
        //  console.log(res);
        setData(res.data);
        if (res?.wo_desc) {
          setWoDesc(res.wo_desc);
        }
        if (res?.milestones) {
          setMilestones(res.milestones);
        }
        // console.log(res, "727272")
        fetchPaymentRequests(
          res?.data?.project_id,
          res?.data?.category_id,
          res?.data?.assigned_to
        ).then((res) => {
          if (res.data) {
            console.log(res, "797979");
            setmdata(res?.data);
            setload(true);
          } //
        });
      } else {
        // setRows([]);
      }
    });
  };

  console.log(milestones, "114");
  // console.log(data, "86.86.86")

  const createWoTicket = (row) => {
    // console.log(row, "row127127127")
    setSelectedMilestone(row);
    setPageSliderStatus(true);
  };

  useEffect(() => {
    getDetailedWorkOrder();
  }, []);

  useEffect(() => {
    getledgerdata();
  }, [data]);

  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  let LedgerAmount = 0;
  let idnx = 0;

  // console.log(new Intl.NumberFormat('en-IN').format(LedgerAmount));
  let idx = -1;
  //console.log(data, "jrjytryjytj")

  return (
    <Box  m={"0.5rem"} p={"0.5rem"} >
      
      {load ? (
        <>
              <>
                <Box m={"0.5rem"} boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}  p={"0.2rem"} fontWeight={"500"} borderRadius={"0.5rem"} textAlign={"center"} fontSize={"1.5rem"} color={"#70bbfd"} > {binaryconverter(
                    loginState.userDetails.access_control
                  ).charAt(9) == 1 ? (
                    <NavLink to={`/app/${role}/wo-create/${wid}/1`}>
                      Work Order Basic Info
                    </NavLink>
                  ) : (
                    "Work Order Basic Info"
                  )}</Box>
                <Box m={"1rem 0.5rem"} boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"} borderRadius={"0.7rem"} p={"0.5rem"}  >
                <TableContainer>
  <Table size={"sm"} mt={"0.5rem"} colorScheme={"messenger"} variant={"striped"}>
     <Tbody>
    <Tr>
  <Td>
    <strong>Project Title:</strong>
  </Td>
  <Td>{selectedProject.label}</Td>
</Tr>
<Tr>
  <Td>
    <strong>Category:</strong>
  </Td>
  <Td>{data.category_name}</Td>
</Tr>
<Tr>
  <Td>
    <strong>Customer Name:</strong>
  </Td>
  <Td>
    {`${data?.customer_firstname} ${data?.customer_lastname}`}
  </Td>
</Tr>
<Tr>
  <Td>
    <strong>Project Manger:</strong>
  </Td>
  <Td>{`${data?.pm_firstname} ${data?.pm_lastname}`}</Td>
</Tr>
<Tr>
  <Td>
    <strong>Site Engineer:</strong>
  </Td>
  <Td>{`${data.se_firstname} ${data?.se_lastname}`}</Td>
</Tr>
<Tr>
  <Td>
    <strong>Assigned to:</strong>
  </Td>
  <Td>
    {data?.assigned_to_firstname} {data?.assigned_to_firstname}
  </Td>
</Tr>
<Tr>
  <Td>
    <strong>Execution Lead Contact #: </strong>
  </Td>
  <Td>{data?.assigned_to_contact_num}</Td>
</Tr>
<Tr>
  <Td>
    <strong>Attachments: </strong>
  </Td>
  <Td>
    {Array.isArray(data.agreements) && data.agreements.length ? (
      data.agreements.map((bill, billIdx) => {
        bill = JSON.parse(bill);
        bill = bill.location;
        let imgLink = bill.split("/");
        imgLink = imgLink[imgLink.length - 1];
        return (
          <div>
            <Link to={`/app/vimg/${imgLink}`} target="_blank">
              <span>{`Attachment ${billIdx + 1}`}</span>
            </Link>
          </div>
        );
      })
    ) : (
      <></>
    )}
  </Td>
</Tr></Tbody>
  </Table>
</TableContainer>
</Box>              
              </>

              <>
                <Box m={"0.5rem"} boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}  p={"0.2rem"} fontWeight={"500"} borderRadius={"0.5rem"} textAlign={"center"} fontSize={"1.5rem"} color={"#70bbfd"} >
                  <NavLink to={`/app/admin/wo-create/${wid}/3`}>
                    Milestones
                    </NavLink>
                  </Box>
           
                
              </>

              <Box
                backgroundColor={"whiteAlpha.100"}
                borderRadius={"0.5rem"}
                p={"0.5rem"}
                boxShadow={
                  "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                }
            m={"1rem 0.5rem"}
            
              >
                <Accordion defaultIndex={[0]} allowMultiple>
                  {milestones?.length &&
                    milestones.map((row, ind) => {
                      if (row.scope !== 1) {
                        return (
                          <Box key={ind}>
                            <Box display={"none"}> {idx++}</Box>
                            <DropdownMilestone
                              mdata={mdata}
                              rdata={row}
                              idx={idx}
                              createWoTicket={createWoTicket}
                              pdata={data}
                            />
                          </Box>
                        );
                      }
                    })}
                </Accordion>

                <Box
                  w={"98%"}
                  h={"2rem"}
                  textAlign={"center"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  p={"1.5rem"}
                  backgroundColor={"blue.400"}
                  color={"white"}
                  fontWeight={"500"}
                  fontSize={"1.5rem"}
                  m={"auto"}
                  my={"1.5rem"}
                  borderRadius={"0.5rem"}
                >
                  Additional
                </Box>

                <Accordion defaultIndex={[0]} allowMultiple>
                  {milestones?.length &&
                    milestones.map((row) => {
                      if (row.scope === 1) {
                        return (
                          <>
                            <Box display={"none"}> {idx++}</Box>

                            <DropdownMilestone
                              mdata={mdata}
                              rdata={row}
                              idx={idx}
                              createWoTicket={createWoTicket}
                              pdata={data}
                            />
                          </>
                        );
                      }
                    })}
                </Accordion>
              </Box>
            
  <Box
              
              m={"0.5rem"} boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}  p={"0.2rem"} fontWeight={"500"} borderRadius={"0.5rem"} textAlign={"center"} fontSize={"1.5rem"} color={"#70bbfd"}
            >
              Total Ledger Info
            </Box>
          <Box
            backgroundColor={"whiteAlpha.800"}
            borderRadius={"0.5rem"}
            p={"0.5rem"}
            boxShadow={
              "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
            }
            m={"1rem 0.5rem"}
          >
          

            <Box>
              {legdata &&
                legdata.length != 0 &&
                legdata.map((el) => {
                  // setledgermc(ledgermc + el.amount)
                  //  console.log(el)
                  return (
                    <>
                      {(data.assigned_to_firstname == el.person_first_name ||
                        el.person_first_name == "General") && (
                          <Box>
                            {
                              <Box
                                display={"flex"}
                                flexWrap={"wrap"}
                                alignItems={"center"}
                                justifyContent={"space-evenly"}
                                borderRadius={"0.5rem"}
                                color={idnx % 2 == 0 ? "white" : "black"}
                                backgroundColor={
                                  idnx % 2 == 0 ? "#ab96db" : "#c6fad6"
                                }
                                m={"auto"}
                                mt={"0.7rem"}
                      
                                py={"0.2rem"}
                                px={"0.5rem"}
                              >
                                <Tooltip
                                  p={"0.5rem"}
                                  borderRadius={"0.5rem"}
                                  hasArrow
                                  label="ledger TXN ID"
                                  bg="blue.200"
                                >
                                  <Box px={"0.5rem"} m={"0.2rem"}>
                                    {el.txn_id}
                                  </Box>
                                </Tooltip>
                                <Box display={"none"}>
                                  {
                                    (LedgerAmount =
                                      Number(LedgerAmount) + Number(el.amount))
                                  }
                                  {idnx++}
                                </Box>
                                <Tooltip
                                  p={"0.5rem"}
                                  borderRadius={"0.5rem"}
                                  hasArrow
                                  label="Amount"
                                  bg="blue.200"
                                >
                                  <Box
                                    m={"0.2rem"}
                                    p={"0.3rem"}
                                    px={"0.5rem"}
                                    borderRadius={"0.3rem"}
                                    color={"white"}
                                    backgroundColor={"red.400"}
                                  >{`₹${new Intl.NumberFormat("en-IN").format(
                                    el.amount
                                  )}`}</Box>
                                </Tooltip>

                                <Tooltip
                                  p={"0.5rem"}
                                  borderRadius={"0.5rem"}
                                  hasArrow
                                  label="Payment Date in Ledger"
                                  bg="blue.200"
                                >
                                  <Box px={"0.5rem"} m={"0.2rem"}>
                                    {new Date(el.date).toDateString()}
                                  </Box>
                                </Tooltip>

                                <Tooltip
                                  p={"0.5rem"}
                                  borderRadius={"0.5rem"}
                                  hasArrow
                                  label="ledger person"
                                  bg="blue.200"
                                >
                                  <Box px={"0.5rem"} m={"0.2rem"}>
                                    {`${el.person_first_name} 
                                  ${el.person_last_name}`}
                                  </Box>
                                </Tooltip>

                                <Tooltip
                                  p={"0.5rem"}
                                  borderRadius={"0.5rem"}
                                  hasArrow
                                  label="Mode of Payment"
                                  bg="blue.200"
                                >
                                  <Box px={"0.5rem"} m={"0.2rem"}>
                                    {el.payment_mode_id == 1
                                      ? "Bank"
                                      : el.payment_mode_id == 2
                                        ? "Cash"
                                        : "Others"}
                                  </Box>
                                </Tooltip>
                                <Tooltip
                                  p={"0.5rem"}
                                  borderRadius={"0.5rem"}
                                  hasArrow
                                  label="Payment Type"
                                  bg="blue.200"
                                >
                                  <Box px={"0.5rem"} m={"0.2rem"}>
                                    {el.payment_type_id == 1
                                      ? "Income"
                                      : "Expense"}
                                  </Box>
                                </Tooltip>
                                <Tooltip
                                  p={"0.5rem"}
                                  borderRadius={"0.5rem"}
                                  hasArrow
                                  label="GST Bill"
                                  bg="blue.200"
                                >
                                  <Box px={"0.5rem"} m={"0.2rem"}>
                                    {el.is_gst_bill ? "Yes" : "No"}
                                  </Box>
                                </Tooltip>
                              </Box>
                            }
                          </Box>
                        )}
                    </>
                  );
                })}
            </Box>
            <Box
              w={"fit-content"}
              fontSize={"1.2rem"}
              color={"white"}
              p={"0.5rem"}
              m={"1rem"}
              borderRadius={"0.3rem"}
              backgroundColor={"red.500"}
            >
              {`₹${new Intl.NumberFormat("en-IN").format(LedgerAmount)}`}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100vh"}
        >
          <CircularProgress size={"6rem"} isIndeterminate color="green.300" />
        </Box>
      )}
    </Box>
  );
};

export default DetailedWorkOrderView;
