const myPostReq = async (
  url,
  data,
  headers = new Headers({ "Content-Type": "application/json" })
) => {
  console.log("posting data", url,data);
  try {
    console.log("fetching");
    const res = await fetch(url, {
      body: data,
      withCredentials: true,
      headers: headers,
      method: "POST",
      mode: "cors",
    });
    if (!res.ok) {
      window.location.href = "/app/login";
    }
    return await res.json();
  } catch (err) {
    return await err;
  }
};

export default myPostReq;
