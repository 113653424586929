import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Input,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Checkbox,
} from '@chakra-ui/react'
import React from 'react'
import { updateAccessControl } from '../../redux/actions/accessControl'
import { useState } from 'react'
import { set } from 'lodash'


const Access = ({ data, getUsersList }) => {

    function handleUpdate() {
        // console.log("first")
        alert("changing codes")
        // console.log(data.access_control)
        console.log(binary, "2626")
        
        let hexa = heaxaconverter(binary==NaN ? "1000000000000000000000000000000000000000" : binary)
        
        console.log(hexa,"272727")
        updateAccessControl({ id: data.id, access_control: hexa }).then((res) => {
            // console.log(res)
            getUsersList()
        })

    }
    const [binary, setbinary] = useState(binaryconverter(data.access_control))

    function changeHandler(e) {
        let { name, value } = e
        if (value) {
            let tempb = binary.split("")
            tempb[name] = 1
            //  console.log(typeof binary, tempb.join(""))
            setbinary(tempb.join(""))
        } else {
            let tempb = binary.split("")
            tempb[name] = 0
            //  console.log(typeof binary, tempb.join(""))
            setbinary(tempb.join(""))
        }

        // console.log(name, value)
    }


    function heaxaconverter(binary) {
        console.log(binary,575757)
        let decimal = parseInt(binary, 2)
        let hexadecimal = (decimal).toString(16)
        return hexadecimal
    }

    function binaryconverter(hexadecimal) {

        let decimal = parseInt(hexadecimal, 16)
        let binary = (decimal).toString(2)
        return binary
    }

    // console.log(binary, heaxaconverter(binary), "binary li 68")


    return (
        <div>

            <AccordionItem>
                <h2>

                    <AccordionButton color={"black"} >
                        <Box color={"black"} as="span" flex='1' textAlign='left'>
                            <Box>
                                {
                                    data.email
                                }

                            </Box>
                            <Box color={"white"} borderRadius="0.5rem" px="1rem" width="fit-content" backgroundColor="blue" >
                                {
                                    data.user_role
                                }
                            </Box>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    {/* <Input onChange={(e) => setaccessControl(e.target.value)} defaultValue={accessControl} /> */}
                    {binaryconverter(data.access_control)}
                    <Box>
                        <TableContainer>
                            <Table size={"sm"} variant='striped' colorScheme='facebook'>
                                <Tbody>
                                    <Tr>

                                        <Td fontSize={"1rem"} >
                                            Dashboard
                                        </Td>

                                        <Td>
                                            <Checkbox isChecked={binary[25] == 1 ? true : false} onChange={(e) => changeHandler({ name: "25", value: e.target.checked })} defaultChecked>View</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[24] == 1 ? true : false} onChange={(e) => changeHandler({ name: "24", value: e.target.checked })} defaultChecked>Download</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[26] == 1 ? true : false} onChange={(e) => changeHandler({ name: "26", value: e.target.checked })} defaultChecked>Create Project</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[27] == 1 ? true : false} onChange={(e) => changeHandler({ name: "27", value: e.target.checked })} defaultChecked>Work & Payment</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[28] == 1 ? true : false} onChange={(e) => changeHandler({ name: "28", value: e.target.checked })} defaultChecked>Project Expense</Checkbox>
                                        </Td>

                                        <Td>
                                            <Checkbox isChecked={binary[29] == 1 ? true : false} onChange={(e) => changeHandler({ name: "29", value: e.target.checked })} defaultChecked>Material Tracker</Checkbox>
                                        </Td>
                                    </Tr>

                                    <Tr>

                                        <Td fontSize={"1rem"} >
                                            User List
                                        </Td>

                                        <Td>
                                            <Checkbox isChecked={binary[1] == 1 ? true : false} onChange={(e) => changeHandler({ name: "1", value: e.target.checked })} defaultChecked>View</Checkbox>



                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[2] == 1 ? true : false} onChange={(e) => changeHandler({ name: "2", value: e.target.checked })} >Edit</Checkbox>
                                        </Td>
                                    </Tr>

                                    <Tr>

                                        <Td fontSize={"1rem"} >
                                            Ledger
                                        </Td>

                                        <Td>
                                            <Checkbox isChecked={binary[3] == 1 ? true : false} onChange={(e) => changeHandler({ name: "3", value: e.target.checked })} defaultChecked>View</Checkbox>
                                        </Td> <Td>
                                            <Checkbox isChecked={binary[4] == 1 ? true : false} onChange={(e) => changeHandler({ name: "4", value: e.target.checked })} defaultChecked>Add</Checkbox>
                                        </Td> <Td>
                                            <Checkbox isChecked={binary[5] == 1 ? true : false} onChange={(e) => changeHandler({ name: "5", value: e.target.checked })} defaultChecked>Edit</Checkbox>
                                        </Td> <Td>
                                            <Checkbox isChecked={binary[6] == 1 ? true : false} onChange={(e) => changeHandler({ name: "6", value: e.target.checked })} defaultChecked>Delete</Checkbox>
                                        </Td> <Td>
                                            <Checkbox isChecked={binary[7] == 1 ? true : false} onChange={(e) => changeHandler({ name: "7", value: e.target.checked })} defaultChecked>Download</Checkbox>

                                        </Td>

                                    </Tr>

                                    <Tr>
                                        <Td>
                                            Work Orders
                                        </Td><Td>
                                            <Checkbox isChecked={binary[8] == 1 ? true : false} onChange={(e) => changeHandler({ name: "8", value: e.target.checked })} defaultChecked>View</Checkbox>
                                        </Td><Td>
                                            <Checkbox isChecked={binary[9] == 1 ? true : false} onChange={(e) => changeHandler({ name: "9", value: e.target.checked })} defaultChecked>Create</Checkbox>
                                        </Td><Td>
                                            <Checkbox isChecked={binary[10] == 1 ? true : false} onChange={(e) => changeHandler({ name: "10", value: e.target.checked })} defaultChecked>Edit</Checkbox></Td>
                                    </Tr>

                                    <Tr>
                                        <Td>
                                            Purchase Orders
                                        </Td><Td>
                                            <Checkbox isChecked={binary[11] == 1 ? true : false} onChange={(e) => changeHandler({ name: "11", value: e.target.checked })} defaultChecked>View</Checkbox>
                                        </Td><Td>
                                            <Checkbox isChecked={binary[12] == 1 ? true : false} onChange={(e) => changeHandler({ name: "12", value: e.target.checked })} defaultChecked>Add</Checkbox>
                                        </Td><Td>
                                            <Checkbox isChecked={binary[13] == 1 ? true : false} onChange={(e) => changeHandler({ name: "13", value: e.target.checked })} defaultChecked>Edit</Checkbox>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td>
                                            Vendors List
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[14] == 1 ? true : false} onChange={(e) => changeHandler({ name: "14", value: e.target.checked })} defaultChecked>View</Checkbox>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td>
                                            WO Payments
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[15] == 1 ? true : false} onChange={(e) => changeHandler({ name: "15", value: e.target.checked })} defaultChecked>View</Checkbox>
                                        </Td><Td>
                                            <Checkbox isChecked={binary[16] == 1 ? true : false} onChange={(e) => changeHandler({ name: "16", value: e.target.checked })} defaultChecked>Download</Checkbox>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td>
                                            Attendance
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[17] == 1 ? true : false} onChange={(e) => changeHandler({ name: "17", value: e.target.checked })} defaultChecked>View</Checkbox>
                                        </Td><Td>
                                            <Checkbox isChecked={binary[18] == 1 ? true : false} onChange={(e) => changeHandler({ name: "18", value: e.target.checked })} defaultChecked>Dashboard</Checkbox>
                                        </Td><Td>
                                            <Checkbox isChecked={binary[19] == 1 ? true : false} onChange={(e) => changeHandler({ name: "19", value: e.target.checked })} defaultChecked>Add</Checkbox>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td>
                                            Leads
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[22] == 1 ? true : false} onChange={(e) => changeHandler({ name: "22", value: e.target.checked })} defaultChecked>Add</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[32] == 1 ? true : false} onChange={(e) => changeHandler({ name: "32", value: e.target.checked })} defaultChecked>Edit</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[21] == 1 ? true : false} onChange={(e) => changeHandler({ name: "21", value: e.target.checked })} defaultChecked>All Leads</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[20] == 1 ? true : false} onChange={(e) => changeHandler({ name: "20", value: e.target.checked })} defaultChecked>Dashboard</Checkbox>
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[23] == 1 ? true : false} onChange={(e) => changeHandler({ name: "23", value: e.target.checked })}>My Leads</Checkbox>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td>
                                            Tasks
                                        </Td>
                                        <Td>
                                            <Checkbox isChecked={binary[31] == 1 ? true : false} onChange={(e) => changeHandler({ name: "31", value: e.target.checked })} defaultChecked>Chart</Checkbox>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Button m={"1rem"} onClick={handleUpdate}>Update</Button>
                    {/* <Box>
                        {binaryconverter(data.access_control)}
                        <Box >
                            Dashboard
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[25] == 1 ? true : false} onChange={(e) => changeHandler({ name: "25", value: e.target.checked })} defaultChecked>View</Checkbox>

                                <Checkbox isChecked={binary[24] == 1 ? true : false} onChange={(e) => changeHandler({ name: "24", value: e.target.checked })} defaultChecked>Download</Checkbox>

                                <Checkbox isChecked={binary[26] == 1 ? true : false} onChange={(e) => changeHandler({ name: "26", value: e.target.checked })} defaultChecked>Create Project</Checkbox>

                                <Checkbox isChecked={binary[27] == 1 ? true : false} onChange={(e) => changeHandler({ name: "27", value: e.target.checked })} defaultChecked>Work And Payment</Checkbox>

                                <Checkbox isChecked={binary[28] == 1 ? true : false} onChange={(e) => changeHandler({ name: "28", value: e.target.checked })} defaultChecked>Project Expenses</Checkbox>

                                <Checkbox isChecked={binary[29] == 1 ? true : false} onChange={(e) => changeHandler({ name: "29", value: e.target.checked })} defaultChecked>Material Tracking</Checkbox>
                            </Box>
                        </Box>

                        <Box>

                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[1] == 1 ? true : false} onChange={(e) => changeHandler({ name: "1", value: e.target.checked })} defaultChecked>View</Checkbox>


                                <Checkbox isChecked={binary[2] == 1 ? true : false} onChange={(e) => changeHandler({ name: "2", value: e.target.checked })} >Edit</Checkbox>
                            </Box>
                        </Box>

                        <Box >
                            Ledger
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[3] == 1 ? true : false} onChange={(e) => changeHandler({ name: "3", value: e.target.checked })} defaultChecked>View</Checkbox>

                                <Checkbox isChecked={binary[4] == 1 ? true : false} onChange={(e) => changeHandler({ name: "4", value: e.target.checked })} defaultChecked>Add</Checkbox>

                                <Checkbox isChecked={binary[5] == 1 ? true : false} onChange={(e) => changeHandler({ name: "5", value: e.target.checked })} defaultChecked>Edit</Checkbox>

                                <Checkbox isChecked={binary[6] == 1 ? true : false} onChange={(e) => changeHandler({ name: "6", value: e.target.checked })} defaultChecked>Delete</Checkbox>

                                <Checkbox isChecked={binary[7] == 1 ? true : false} onChange={(e) => changeHandler({ name: "7", value: e.target.checked })} defaultChecked>Download</Checkbox>
                            </Box>
                        </Box>

                        <Box>
                            Work Orders
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[8] == 1 ? true : false} onChange={(e) => changeHandler({ name: "8", value: e.target.checked })} defaultChecked>View</Checkbox>

                                <Checkbox isChecked={binary[9] == 1 ? true : false} onChange={(e) => changeHandler({ name: "9", value: e.target.checked })} defaultChecked>Create</Checkbox>

                                <Checkbox isChecked={binary[10] == 1 ? true : false} onChange={(e) => changeHandler({ name: "10", value: e.target.checked })} defaultChecked>Edit</Checkbox>
                            </Box>
                        </Box>

                        <Box>
                            Purchase Orders
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[11] == 1 ? true : false} onChange={(e) => changeHandler({ name: "11", value: e.target.checked })} defaultChecked>View</Checkbox>

                                <Checkbox isChecked={binary[12] == 1 ? true : false} onChange={(e) => changeHandler({ name: "12", value: e.target.checked })} defaultChecked>Add</Checkbox>

                                <Checkbox isChecked={binary[13] == 1 ? true : false} onChange={(e) => changeHandler({ name: "13", value: e.target.checked })} defaultChecked>Edit</Checkbox>
                            </Box>
                        </Box>

                        <Box>
                            Vendors List
                            <Box>
                                <Checkbox isChecked={binary[14] == 1 ? true : false} onChange={(e) => changeHandler({ name: "14", value: e.target.checked })} defaultChecked>View</Checkbox>
                            </Box>
                        </Box>

                        <Box>
                            Work Order Payments
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[15] == 1 ? true : false} onChange={(e) => changeHandler({ name: "15", value: e.target.checked })} defaultChecked>View</Checkbox>

                                <Checkbox isChecked={binary[16] == 1 ? true : false} onChange={(e) => changeHandler({ name: "16", value: e.target.checked })} defaultChecked>Download</Checkbox>
                            </Box>
                        </Box>

                        <Box>
                            Attendance
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[17] == 1 ? true : false} onChange={(e) => changeHandler({ name: "17", value: e.target.checked })} defaultChecked>View</Checkbox>

                                <Checkbox isChecked={binary[18] == 1 ? true : false} onChange={(e) => changeHandler({ name: "18", value: e.target.checked })} defaultChecked>Dashboard</Checkbox>

                                <Checkbox isChecked={binary[19] == 1 ? true : false} onChange={(e) => changeHandler({ name: "19", value: e.target.checked })} defaultChecked>Add</Checkbox>
                            </Box>
                        </Box>

                        <Box>
                            Leads
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-evenly"} >
                                <Checkbox isChecked={binary[20] == 1 ? true : false} onChange={(e) => changeHandler({ name: "20", value: e.target.checked })} defaultChecked>Dashboard</Checkbox>

                                <Checkbox isChecked={binary[21] == 1 ? true : false} onChange={(e) => changeHandler({ name: "21", value: e.target.checked })} defaultChecked>All Leads View</Checkbox>

                                <Checkbox isChecked={binary[22] == 1 ? true : false} onChange={(e) => changeHandler({ name: "22", value: e.target.checked })} defaultChecked>Add Leads</Checkbox>

                                <Checkbox isChecked={binary[23] == 1 ? true : false} onChange={(e) => changeHandler({ name: "23", value: e.target.checked })}>My Leads View</Checkbox>

                            </Box>
                        </Box>

                        <Box>
                            Tasks
                            <Box>
                                <Checkbox isChecked={binary[31] == 1 ? true : false} onChange={(e) => changeHandler({ name: "31", value: e.target.checked })} defaultChecked>Tasks Chart</Checkbox>
                            </Box>
                        </Box>
                        <Button onClick={handleUpdate}>Update</Button>
                    </Box> */}

                </AccordionPanel>

            </AccordionItem>

        </div>
    )
}

export default Access