import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Bookings from "./Bookings";
import { getSEProjects } from "../../../redux/actions/projectsAction";

const SiteEngineerDashboard = () => {
  const [data, setData] = useState([]);

  const getData = () => {
    getSEProjects().then((res) => {
      if (res.status) {
        setData(res.data);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <Row>
        {/* <ExampleCard /> */}
        {data.length ? (
          data.map((dat, key) => {
            return <Bookings key={key} projectInfo={dat} index={key} />;
          })
        ) : (
          <div> No Projects found</div>
        )}
      </Row>
    </Container>
  );
};

export default SiteEngineerDashboard;
