import React, { useEffect } from 'react'
import ProjectCard from './ProjectCard'
import { Box, Grid } from '@chakra-ui/react'
import { useState } from 'react'
import styles from "./hflprojects.module.css"
import { getprojectdisplaydata } from '../../redux/actions/projectsData'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import ProjectMain from './ProjectMain'
import SkelitonCard from './SkelitonCard'
import ContectUs from "../ContectUs/ContectUs";
import { useDisclosure } from "@chakra-ui/react";
import LazyLoad from "react-lazy-load";
const HFLProjects = () => {
  const [Data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [load, setload] = useState(false);

  function getProjectDisplayData() {
    getprojectdisplaydata().then((res) => {
      setData(res.res.rows);
      setload(true);
      //console.log(res.res.rows, "16")
    });
  }
  console.log(Data);
  useEffect(() => {
    getProjectDisplayData();
  }, []);

  return (
    <>
      <Header onOpen={onOpen} />
      <Box overflowY={"scroll"} h={"100vh"} className={styles.divcont}>
        <ProjectMain onOpen={onOpen} />
        <Box minH={"100vh"} py={"1rem"}>
          {load ? (
            <Grid
              width={"fit-content"}
              px={"0.5rem"}
              m={"auto"}
              gridGap={"1.5rem"}
              rowGap={"2rem"}
              className={styles.gridbox}
              gap={1}
            >
              {Data &&
                Data?.length != 0 &&
                Data?.map((ele) => {
                  return (
                    <LazyLoad offset={100}>
                      <ProjectCard data={ele} />
                    </LazyLoad>
                  );
                })}
            </Grid>
          ) : (
            <Grid
              className={styles.gridbox}
              margin={"auto"}
              p={"3rem"}
              gap={"2.5rem"}
            >
              {Array(12)
                .fill(1)
                .map(() => {
                  return <SkelitonCard />;
                })}
            </Grid>
          )}
        </Box>
        <Footer />
      </Box>
      <ContectUs isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
};
export default HFLProjects
