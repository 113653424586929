
import myDeleteReq from "../../utils/delete";
import myGetReq from "../../utils/get";
import myPostReq from "../../utils/post";
import mytaskpost from "../../utils/tpost";
import myUpdateReq from "../../utils/update";


export const getChildLedgerData = async () => {
    try {
        return await myGetReq(
            `/app/usr/childledger/get`
        );
    } catch (err) {
        console.log("get req", err)
        return err;
    }
};


export const addChildLedgerData = async (data) => {
    // let files = data.attachments
    console.log(data, "data from acld")
    let jdata = JSON.stringify({ data: data })
    try {
        return await myPostReq("/app/usr/childledger/add", jdata);
    } catch (err) {
        return err;
    }
};


export const updateTask = async (data) => {
    console.log(data.newattachments, "data from updateData")
    const formdata = new FormData();
    formdata.append("data", JSON.stringify(data))

    for (let i = 0; i < data.newattachments.length; i++) {
        formdata.append("photos", data.newattachments[i])
    }
    try {
        return await myUpdateReq("/app/usr/taskmanager-update-task", formdata, {});
    } catch (err) {
        console.log(err, "update err")
        return err;
    }
};


export const deleteAttach = async (data) => {

    let jdata = JSON.stringify({ data: data })
    console.log("action dela", data)
    try {
        return await myDeleteReq("/app/usr/taskmanager-delete-attach", jdata);
    } catch (err) {
        console.log(err)
        return err;
    }
};


export const deleteTask = async (id, data) => {

    let jdata = JSON.stringify({ id, data })
    try {
        return await myDeleteReq("/app/usr/taskmanager-delete-task", jdata);
    } catch (err) {
        console.log(err)
        return err;
    }
};

