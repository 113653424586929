import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import Html from "react-pdf-html";
import { saveAs } from "file-saver";

// import { useLocation } from "react-router-dom";
import logo from "./PdfLogo.png";
const Pdftemplate = ({
  data,
  projectTitle,
  name,
  email,
  mobile_no,
  location,
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: "20px",
      // fontFamily: "josefin",
    },
    view: {
      padding: "20px",
      // fontFamily: "josefin",
    },
    logo12312: {
      display: "block",
      width: "200px",
      minHeight: "80px",
      textAlign: "center",
      border: "2px solid red",
      margin: "auto",
      marginTop: "20px",
      marginBottom: "0px",
    },
  });

  function abc() {
    data.sort((a, b) => {
      if (Number(new Date(a.date)) < Number(new Date(b.date))) {
        return 1;
      } else if (Number(new Date(a.date)) > Number(new Date(b.date))) {
        return -1;
      } else {
        return 0;
      }
    });

    const total = data.reduce((prev, curr) => {
      return prev + Number(curr.amount);
    }, 0);

    let str = ``;
    str += `<span>Milestones</span>`;
    for (let i = 0; i < data?.length; i++) {
      str += `
      <table>
      <tr>
        <td class="pdf-col1">Amount Received</td>
        <td>${new Intl.NumberFormat("en-IN").format(
          parseInt(data[i].amount)
        )}</td>
      </tr>
      <tr>
        <td class="pdf-col1">Date</td>
        <td>${data[i].date}</td>
      </tr>
      <tr>
        <td class="pdf-col1">Payment Mode</td>
        <td >${
          data[i].payment_mode_id === 1
            ? "Bank"
            : data[i].payment_mode_id === 2
            ? "Cash"
            : "Other"
        } Transfer</td>
      </tr>
    </table>
    
  `;
    }
    const html = `<html>
  <body>
    <style>
    table{
      font-size: 9px;
        border-collapse: collapse;
        width: 80%;
        text-align: center;
        margin: 20px auto;
      }

      td,
      th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      .pdf-col1 {
        font-weight: 700;
      }
      .Pdf-image {
        width: 200px;
        height:80px
        margin: auto;
        border:"2px solid green";
        display: block;
      }
      .pdf-h1{
        font-weight:700;
        font-size:11px;
        margin:auto;
      }
      .pdf-font{
        font-weight:700;
        font-size:14px;
        margin-left:"30px";
      }
    </style>
    <h3 class="pdf-h1">
      Receipt Voucher No.3284 ${projectTitle}
    </h3>
    <h3 class="pdf-h1">
      Customer Name : ${name}
    </h3>
    <h3 class="pdf-h1">
      Customer Email : ${email}
    </h3>
    <h3 class="pdf-h1">
      Customer Location : ${location}
    </h3>
    <h3 class="pdf-h1">
      Customer Contact No : ${mobile_no}
    </h3>
    ${str}
      <p>Total amount : ${Math.floor(total)}</p>
      <span>Building and Tracking, Just like you do !</span>
    </body>
</html>
`;
    return html;
  }

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.view}>
          <Image src={logo} style={styles.logo12312}></Image>
          {/* <Text style={styles.title12312}>
            Receipt Voucher No.3284CX -582 Sujith mahaveer palatium
          </Text> */}
          <Html>{abc()}</Html>
        </View>
      </Page>
    </Document>
  );
};
export default Pdftemplate;
