import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const HFDialog=(props)=> {
  return (
    <div>
      <Dialog fullWidth={true}  maxWidth={props.width?props.width:'sm'} open={props.open} onClose={props.handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContentText></DialogContentText>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>CANCEL</Button>
          <Button onClick={props.handleSubmit}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default HFDialog;
