import React, { useEffect, useState } from "react";
import {
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableFooter,
  Typography,
  LinearProgress,
  IconButton,
  Tooltip,
  TableSortLabel,
  Box,
  Checkbox,
  Badge,
} from "@mui/material";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { visuallyHidden } from "@mui/utils";
import { Mail } from "@mui/icons-material";

const HFTable = ({
  headers,
  data,
  action,
  handleStar,
  filteredData,
  setFilteredData,
  isItemSelected,
  setIsItemSelected,
}) => {
  // console.log("HFTable-props", headers);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");

  const Action = action;

  useEffect(() => {
    // console.log("filer-data", filters);
    if (Object.values(filters).length > 0) {
      let temp = data;
      Object.values(filters).map((filter) => {
        temp = temp.filter((item) =>
          (item[filter.key] ? item[filter.key] : "")
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        );
      });
      setFilteredData(temp);
    }
  }, [filters, data]);

  const handleFilter = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: { key: e.target.name, value: e.target.value },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    console.log("sort-prop", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const onSelectAllClick = (e) => {
    // console.log("select all", e.target.checked);
    let isChecked = e.target.checked;
    console.log(filteredData.length, "fsdfdsfdsf filter");
    if (isChecked) {
      setIsItemSelected(
        filteredData.map((el) => ({ id: el.id, email: el.email }))
      );
    } else {
      setIsItemSelected([]);
    }
  };

  const OnChangeSigleCheckbox = (ischecked, id, email) => {
    console.log("select one", ischecked);
    if (ischecked) {
      setIsItemSelected((prev) => [...prev, { id, email }]);
    } else {
      setIsItemSelected((prev) => [...prev.filter((el) => el.id != id)]);
    }
  };
  console.log(isItemSelected, "dfsdfsdf datas");

  return (
    <>
      {Object.keys(data).length > 0 ? (
        <>
          <TableContainer>
            <Table size="small" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: "0px 10px", pt: "20px" }}>
                    <Checkbox
                      color="primary"
                      // indeterminate={numSelected > 0 && numSelected < rowCount}
                      // checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {headers.map((header, index) =>
                    header.title !== "Count" ? (
                      <TableCell
                        sx={{
                          minWidth: "130px",
                          ...header.Prostyle,
                          paddingTop: "10px",
                        }}
                        key={`key-${index}`}
                        className="px-0"
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            size="small"
                            label={header.title}
                            variant="outlined"
                            name={header.key}
                            autoComplete="off"
                            onChange={handleFilter}
                          />
                          <TableSortLabel
                            active={orderBy === header.key}
                            direction={orderBy === header.key ? order : "asc"}
                            onClick={() => {
                              handleSort(header.key);
                            }}
                          >
                            {orderBy === header.key ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          minWidth: "130px",
                          ...header.Prostyle,
                          paddingTop: "10px",
                        }}
                        key={`key-${index}`}
                        className="px-0"
                      >
                        {header.title}
                      </TableCell>
                    )
                  )}

                  {action && <TableCell align="center">Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData &&
                  stableSort(filteredData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index1) => (
                      <TableRow key={`${row.id}`}>
                        <TableCell
                          // padding="checkbox"
                          sx={{ p: "0px 10px", pt: "20px" }}
                        >
                          <Checkbox
                            color="primary"
                            checked={
                              isItemSelected?.find((el) => el.id == row?.id)
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              OnChangeSigleCheckbox(
                                e.target.checked,
                                row.id,
                                row.email
                              )
                            }
                            inputProps={{
                              "aria-labelledby": `enhanced-table-checkbox-${index1}`,
                            }}
                          />
                        </TableCell>
                        {headers.map((header, index) => {
                          let Component = header.component;
                          return header.key === "start_date" ? (
                            <TableCell
                              sx={{
                                ...header.Prostyle,
                                paddingTop: "20px",
                              }}
                            >
                              {row.start_date
                                ? moment(row.start_date).format("DD-MM-YYYY")
                                : "-"}
                            </TableCell>
                          ) : header.key === "remind_on" ? (
                            <TableCell
                              sx={{
                                ...header.Prostyle,
                                paddingTop: "20px",
                              }}
                            >
                              {row.remind_on
                                ? moment(row.remind_on).format("DD-MM-YYYY")
                                : "-"}
                            </TableCell>
                          ) : header.key === "mail_count" ? (
                            <TableCell
                              sx={{
                                ...header.Prostyle,
                                paddingTop: "10px",
                              }}
                              key={`header-${index}`}
                            >
                              <Badge
                                badgeContent={row[header.key]}
                                color="secondary"
                              >
                                {/* {row[header.key]} */}
                                <Mail
                                  color="action"
                                  onClick={() => {
                                    window.location.href = `mailto:${row["email"]}?subject='HousingFactory'&body='hello form Housing factory'`;
                                  }}
                                />
                              </Badge>
                            </TableCell>
                          ) : (
                            <TableCell
                              sx={{
                                ...header.Prostyle,
                                paddingTop: "20px",
                              }}
                              key={`header-${index}`}
                            >
                              {row[header.key] && row[header.key] !== " " ? (
                                header.component ? (
                                  <Component row={row} />
                                ) : (
                                  row[header.key]
                                )
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          );
                        })}
                        {action && (
                          <TableCell padding="none">
                            <Action row={row} />
                            {/* <Tooltip title="Mark as important">
                        <IconButton color="primary"
                          onClick={() => {
                            handleStar(row);
                          }}
                        >
                          {row.star ? <StarIcon /> : <StarOutlineIcon />}
                        </IconButton>
                      </Tooltip> */}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 300, 500]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Typography variant="h3" component="h4" style={{ color: "gray" }}>
          No records found!!
        </Typography>
      )}
    </>
  );
};

export default HFTable;
