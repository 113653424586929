import React, { useEffect, useState, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import LedgerForm from "../Ledger/LedgerForm";
import { AppContext } from "../../Context/appContext";
import { PaymentType, PaymentMode } from "@/shared/helpers/constants";
import FormField from "../Ledger/FormField";
import Select from "react-select";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Table,
  Col,
  Button,
} from "reactstrap";

import {
  fetchPaymentRequests,
  updateWoLedgerEntries,
  updateWoPaymentStatus,
  getLedgerDataApi,
} from "../../redux/actions/workOrderAction";
import {
  submitLedger,
  fetchProjects,
  getCategories,
  fetchPersons,
} from "../../redux/actions/ledgerAction";
import { woPaymentStatus } from "../../shared/helpers/constants";
import DownloadBoxIcon from "mdi-react/DownloadBoxIcon";
import csvDownload from "json-to-csv-export";
import Pagination from "../Pagination/Pagination";
import { Box, CircularProgress, Fade } from "@mui/material";
import WopBlock from "./WopBlock";
import moment from "moment";

const Requests = () => {
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [assignToList, setAssignToList] = useState([]);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(data.length);

  const [returnid, setreturnid] = useState("")

  const [motheren, setmotheren] = useState(false)

  const [selectedProject, setSelectedProject] = useState({
    value: 0,
    label: "Select All",
  });
  const [selectedCategory, setSelectedCategory] = useState({
    value: 0,
    label: "Select All",
  });
  const [selectedAssignToList, setSelectedAssignTo] = useState({
    value: 0,
    label: "Select All",
  });

  const [filterData, setFilterData] = useState({
    projectTitle: "",
  });
  // const selectedProject = {};
  const AppProvider = useContext(AppContext);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const onChangeStatus = (evt, mid, wid, id, dat, oriamount) => {
    let obj = {
      status: evt.target.value,
      wid: wid,
      milestoneId: mid,
      id: id,
    };
    // selectedProject={lable:}

    if (evt.target.value == 4) {
      navigateToLedger(dat, oriamount);
    } else {
      updateWoPaymentStatus(JSON.stringify(obj)).then((res) => {
        getPaymentRequests();
      });
    }
  };

  const navigateToLedger = (item, oriamount) => {
    //console.log("navigateToLedger", item);
    setInitialValues({
      category: { value: item.category_id, label: item.category_name },
      id: item.id,
      milestoneId: item.wo_milestones_id,
      person: {
        value: item.assigned_to,
        label: `${item.first_name} ${item.last_name}`,
      },
      date: new Date(),
      amount: oriamount,
      paymentType: PaymentType[2],
      paymentMode: 0,
      txnRef: "",
      gstBills: false,
      comments: "",
      project_title: { value: item.project_id, label: item.project_title },
    });
    toggleLedgerModal(true);
  };

  const toggleLedgerModal = (flag) => {
    setShowForm(flag);
    if (flag) setSubmitBtnStatus(false);
  };

  const onSubmit = (data) => {
    //console.log(data, "data")
    setSubmitBtnStatus(true);
    if (!data?.project_title?.value) {
      return;
    }

    data.date = moment(data.date).format("yyyy-MM-DD");
    data.project_id = data.project_title;
    data.gstBills = data.gstBills ? 1 : 0;
    data.mother = motheren ? 1 : 0
    let dat = new FormData();
    let selectedFiles = data.files;

    /*******************Update Ledger Entry****************************** */
    dat.append("data", JSON.stringify(data));

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        dat.append("files", selectedFiles[i]);
      }
    }
    submitLedger(dat).then((res) => {
      toggleLedgerModal(false);

      console.log(res, 151515151)

      setreturnid(res?.result[0]?.txn_id)

      if (res.status) {
        AppProvider.setAlertFunc({
          txt: res.msg,
          clr: "success",
        });
        ////////////////////////////////////////////////////////
        if (res.result) {
          if (!motheren) {
            updateWoLedgerEntries(
              data.id,
              res.result[0].txn_id,
              data.milestoneId
            ).then((res2) => {
              if (res2.status) {
                getPaymentRequests();
              }
            });
          }
        }

      } else {
        AppProvider.setAlertFunc({
          txt: res.msg,
          clr: "success",
        });
      }
    });
  };

  console.log(returnid, 183183)
  const getPaymentRequests = () => {


    fetchPaymentRequests(
      selectedProject.value,
      selectedCategory.value,
      selectedAssignToList.value
    ).then((res) => {
      if (res.data) {
        //console.log(res.data, "184184184")
        setData(res.data);
        setCount(res.data.length);
      }
      setLoading(false);
    });
  };

  const getLedgerData = (index) => {
    // console.log(index, data, "192.192.192")
    let list = [...data];

    if (
      Object.prototype.hasOwnProperty.call(list[index], "ledgerInfo") &&
      list[index].ledgerInfo.length
    ) {
      list[index].viewLedgerEntries = false;

      return;
    }

    getLedgerDataApi(
      JSON.stringify({ ledger_ids: list[index].ledger_ids })
    ).then((res) => {
      if (res.status) {
        //  console.log(res, "207.207.207.20702757947459")
        let list = [...data];
        list[index].ledgerInfo = res.ledger_info;
        list[index].viewLedgerEntries = true;
        setData(list);
      }
    });
  };

  const OnChangeProject = (val) => {
    if (!val) {
      return;
    }
    //
    setValue("project_title", val);
    setSelectedProject(val);
  };
  const OnChangeCategory = (val) => {
    if (!val) {
      return;
    }
    setValue("Category", val);
    setSelectedCategory(val);
  };
  const OnChangeAssignTo = (val) => {
    if (!val) {
      return;
    }
    //console.log(val, "setSelectedAssignTo");
    setValue("AssignTo", val);
    setSelectedAssignTo(val);
  };

  const onChangeFilters = (evt) => {
    let val = evt.target.value.toLocaleLowerCase();
    let key = evt.target.name;

    setFilterData({ ...filterData, [key]: val });
  };

  const loadOptions = async () => {
    const response = fetchProjects().then((res) => {
      // console.log(res, "rthrytjdryjsrjytrj");
      let data = [{ value: 0, label: "Select All" }];
      let arr = res.data;
      for (let idx in arr) {
        data.push({
          value: arr[idx].id,
          label: arr[idx].project_title,
        });
      }
      setProjectsList(data);
    });
    const resp = await getCategories();
    //console.log(resp.data, "rthftdyjhythj");
    setCategoriesList(modify(resp.data));

    const resp2 = await fetchPersons();
    setAssignToList(modify2(resp2.data));
    // console.log(resp2, "setAssignToList");
  };

  function modify2(arr) {
    let ansArr = [];
    arr.forEach((obj) => {
      ansArr.push({
        label: `${obj.first_name} ${obj.last_name}`,
        value: obj.id,
      });
    });
    return ansArr;
  }
  function modify(arr) {
    let ansArr = [];
    arr.forEach((obj) => {
      ansArr.push({ label: obj.name, value: obj.id });
    });
    return ansArr;
  }

  useEffect(() => {
    setLoading(true);
    getPaymentRequests();
  }, [selectedProject, selectedCategory, selectedAssignToList]);

  if (count !== 0) {
    // console.log("hello sdfdsds", count);
    localStorage.setItem("requestCount", count);
  }
  if (count !== 0 && !localStorage.getItem("requestCountNew")) {
    localStorage.setItem("requestCountNew", count);
  }
  localStorage.setItem("requestCountgetNotification", 1);

  useEffect(() => {
    loadOptions();
    if (count !== 0 && !localStorage.getItem("requestCountNew")) {
      localStorage.setItem("requestCountNew", count);
    }
  }, []);

  const dataToConvert = {
    filename: "ledger.csv",
    delimiter: ",",
  };

  const download = () => {
    dataToConvert.data = data;
    // console.log(data, "clggggg")
    csvDownload(dataToConvert);
  };
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 20;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage]);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }


  const loginState = useSelector((state) => state.login);

  return (
    <>
      <Container className="dashboard ledger">
        <Row>
          <Col md={12} style={{ width: "70vw" }}>
            <h3
              className="page-title"
              style={{
                textAlign: "center",
                textTransform: "uppercase",
                letterSpacing: "5px",
                wordSpacing: "10px",
              }}
            >
              Work order Payments
            </h3>
          </Col>

          <Col md={12} style={{ margin: "0px 15px 0px auto", width: "10vw" }}>
            {binaryconverter(loginState.userDetails.access_control).charAt(
              16
            ) == 1 && (
                <button
                  type="button"
                  color="primary"
                  className="clearFilter"
                  onClick={download}
                >
                  <DownloadBoxIcon size="2em" />
                </button>
              )}
          </Col>
        </Row>
        <Row>
          <form className="form form--horizontal">
            <Row className="form__form-group">
              <Col>
                {/* <Button type="button" color="primary" onClick={clearFilters}>
                  Clear Filters
                </Button> */}
              </Col>
            </Row>

            <div className="table react-table">
              <div className="table react-table table--not-pagination">
                <table className="react-table">
                  <thead>
                    <tr style={{ zIndex: 7 }}>
                      <th>Sl.No</th>
                      <th>
                        {/* <div>Project Title</div> */}

                        <FormField
                          name="project_title"
                          control={control}
                          component="creatableSelect"
                          errors={errors}
                          selectRender={({ field }) => (
                            <Select
                              // value={value}
                              {...field}
                              onChange={OnChangeProject}
                              options={projectsList}
                              menuPortalTarget={document.body}
                              clearable={false}
                              className="react-select"
                              classNamePrefix="react-select"
                              placeholder={"Select Project"}
                            />
                          )}
                        />
                      </th>
                      <th>MileStone</th>
                      <th>
                        {/* <div>Category</div> */}
                        <FormField
                          name="Category"
                          control={control}
                          component="creatableSelect"
                          errors={errors}
                          selectRender={({ field }) => (
                            <Select
                              value={selectedCategory}
                              {...field}
                              onChange={OnChangeCategory}
                              options={categoriesList}
                              menuPortalTarget={document.body}
                              clearable={false}
                              className="react-select"
                              classNamePrefix="react-select"
                              placeholder={"Select Category"}
                            />
                          )}
                        />
                      </th>
                      <th>
                        {/* <div>Assigned To</div> */}
                        <FormField
                          name="AssignTo"
                          control={control}
                          component="creatableSelect"
                          errors={errors}
                          selectRender={({ field }) => (
                            <Select
                              // value={value}
                              {...field}
                              onChange={OnChangeAssignTo}
                              options={assignToList}
                              menuPortalTarget={document.body}
                              clearable={false}
                              className="react-select"
                              classNamePrefix="react-select"
                              placeholder={"Assigned To"}
                            />
                          )}
                        />
                      </th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>TDS (1%)</th>
                      <th>Amount Payable</th>
                      <th>Payment Date</th>
                      <th>Comments</th>
                      <th>Txns</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData.map((dat, idx) => {
                      // console.log(dat, "rhrfdhyfgh")
                      return (
                        <WopBlock onChangeStatus={onChangeStatus} dat={dat} idx={idx} currentPage={currentPage} woPaymentStatus={woPaymentStatus} PaymentType={PaymentType} getLedgerData={getLedgerData} PaymentMode={PaymentMode} />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={data.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </form>
          <Box
            sx={{
              height: 40,
              textAlign: "center",
              paddingTop: "10px",
              display: `${loading ? "block" : "none"}`,
            }}
          >
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </Row>

        <Modal
          isOpen={showForm}
          toggle={() => toggleLedgerModal(false)}
          className="customModalDialog"
        >
          <ModalHeader toggle={() => toggleLedgerModal(false)}>
            <strong>Create Entry</strong>
          </ModalHeader>
          <ModalBody>
            <LedgerForm
              // selectedProject={selectedProject}
              getPaymentRequests={getPaymentRequests}
              closePopup={toggleLedgerModal}
              onSubmit={onSubmit}
              submitBtnStatus={submitBtnStatus}
              disableAmoutField={true}
              initialValues={initialValues}
              disablePaymentTypeField={true}
              setmotheren={setmotheren}
              returnid={returnid}
            // projectsList={projectsList}
            />
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default Requests;
