import React, { useState, useEffect, useContext, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import _ from "lodash";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  ButtonToolbar,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import { FormField } from "./FormField";
import CreateVendor from "./CreateVendor";
import {
  fetchPersons,
  fetchPersonsVS,
  getCategories,
} from "../../redux/actions/ledgerAction";
import {
  saveVendorInfo,
  getVendorsList,
  savePurchaseOrder,
  getPOById,
  updatePurchaseOrder,
  getUnits,
  getPOHistoryBYPId,
} from "../../redux/actions/poAction";
import { fetchProjects } from "../../redux/actions/workOrderAction";
import { AppContext } from "../../Context/appContext";
import renderDropZoneMultipleField from "../../shared/components/form/DropZone";
import { getValue } from "@mui/system";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Table,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import Pagination from "../Pagination/Pagination";
import { useMemo } from "react";
import moment from "moment";
import { Add, AddCard, Delete } from "@mui/icons-material";
import styles from "./PO.module.css";
const schema = yup.object().shape({
  startDate: yup.string().required("This field is required"),
  endDate: yup.string().required("This field is required"),
  payDate: yup.string().required("This field is required"),
});

const CreatePO = () => {
  const location = useLocation();
  console.log("po-props", location);
  const [pleaseWait, setPleasewait] = useState(false);
  const navigate = useNavigate();
  const { poId } = useParams();
  const [showCreateVendorForm, setShowCreateVendorForm] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({ label: "", value: 0 });
  const [previousHistory, setPreviousHistory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({
    label: "",
    value: 0,
  });
  const [catagoryForHistory, setCatagoryForHistory] = useState({
    label: "",
    value: 0,
  });
  const [errors, setErrors] = useState("");
  const [vendors, setVendors] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [catgegoryWithUnits, setCatgegoryWithUnits] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const AppProvider = useContext(AppContext);
  const poFiles = useRef();
  // const [poFiles, setPoFiles] = useState([]);
  const [milestoneList, setMilestoneList] = useState([
    { label: "Footing", value: "footing" },
    { label: "Sump", value: "sump" },
    { label: "Plinth", value: "plinth" },
    { label: "GF Masonry", value: "gf masonry" },
    { label: "GF Lintel", value: "gf lintel" },
    { label: "GF Slab", value: "gf slab" },
    { label: "1F Masonry", value: "1f masonry" },
    { label: "1F Lintel", value: "1f lintel" },
    { label: "1F Slab", value: "1f slab" },
    { label: "2F Lintel", value: "2f lintel" },
    { label: "2F Masonry", value: "2f masonry" },
    { label: "2F Lintel", value: "2f lintel" },
    { label: "2F Slab", value: "2f slab" },
    { label: "3F Masonry", value: "3f masonry" },
    { label: "3F Lintel", value: "3f lintel" },
    { label: "3F Slab", value: "3f slab" },
    { label: "4F Masonry", value: "4f masonry" },
    { label: "4F Lintel", value: "4f lintel" },
    { label: "4F Slab", value: "4f slab" },
    { label: "5F Masonry", value: "5f masonry" },
    { label: "5F Lintel", value: "5f lintel" },
    { label: "5F Slab", value: "5f slab" },
    { label: "Parapet Wall", value: "parapet wall" },
    { label: "Compound Wall", value: "compound wall" },
    { label: "Tank Room", value: "tank room" },
    { label: "Head Room", value: "head room" },
    { label: "Plastering", value: "plastering" },
    { label: "Painting", value: "painting" },
    { label: "Electricals", value: "electricals" },
    { label: "Plumbing", value: "plumbing" },
    { label: "Flooring", value: "flooring" },
    { label: "Fabrication", value: "fabrication" },
    { label: "Carpentry", value: "carpentry" },
    { label: "Interiors", value: "interiors" },
    { label: "Others", value: "others" },
  ]);
  const [itemsList, setItemsList] = useState([
    {
      item_description: "",
      milestone: {},
      category: {},
      qty: 1,
      cost: 0,
      total_cost: "",
      isGST: 0,
      unit: {},
      size: "",
    },
  ]);

  const {
    handleSubmit,
    reset,
    control,
    formState: {},
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const toggleModal = (flag) => {
    setShowCreateVendorForm(flag);
  };

  const addItems = () => {
    console.log("sssssssssssss");
    let list = [...itemsList];
    list.push({
      item_description: "",
      milestone: {},
      category: {},
      qty: 1,
      cost: 0,
      total_cost: "",
      isGST: 0,
      unit: {},
      size: "",
    });

    console.log(list);

    setItemsList([...list]);
  };

  const onChange = (evt, index, fieldName) => {
    let list = [...itemsList];

    if (fieldName === "isGST") {
      list[index][fieldName] = Number(evt.target.value) ? 0 : 1;
      setValue("isGST", list[index][fieldName]);
    } else if (fieldName === "cost") {
      list[index][fieldName] = evt.target.value;
    } else list[index][fieldName] = evt.target.value;

    setItemsList([...list]);
  };

  const removeItem = (evt, index) => {
    let list = [...itemsList];

    list.splice(index, 1);

    setItemsList([...list]);
  };

  const onSubmit = () => {
    setErrors("");

    if (!Object.keys(selectedVendor).length) {
      setErrors("Please select Vendor");
      return;
    }
    if (!Object.keys(selectedProject).length) {
      setErrors("Please select Project");
      return;
    }
    console.log(itemsList);
    for (let idx in itemsList) {
      if (!itemsList[idx].item_description) {
        setErrors("Please enter all fields");
        return;
      }
      console.log("description asldfjfsf");

      if (
        isNaN(itemsList[idx].qty) ||
        isNaN(itemsList[idx].cost) ||
        isNaN(itemsList[idx].total_cost)
      ) {
        setErrors(
          "Alphabets and special characters are not allowed for quantity and cost"
        );
        return;
      }
    }

    let data = {
      poId,
      vendor: selectedVendor,
      selectedProject,
      itemsList,
    };

    if (poId) {
      updatePurchaseOrder(JSON.stringify(data)).then((res) => {
        if (res.status) {
          AppProvider.setAlertFunc({ txt: res.msg, clr: "success" });
        } else {
          AppProvider.setAlertFunc({ txt: res.msg, clr: "danger" });
        }
        window.scrollTo(0, 0);
      });
    } else {
      var poData = new FormData();
      var pofiles = poFiles.current.files;
      for (let idx in pofiles) {
        poData.append("files", pofiles[idx]);
      }
      poData.append("data", JSON.stringify(data));
      savePurchaseOrder(poData).then((res) => {
        if (res.status) {
          if (poId) {
          } else {
            setItemsList([]);
            setSelectedProject({});
            setSelectedVendor({});
          }

          AppProvider.setAlertFunc({ txt: res.msg, clr: "success" });
          window.scrollTo(0, 0);
        }
      });
    }
  };

  const onSaveVendorDetails = (res) => {
    let clr = "success";
    if (!res.status) {
      clr = "danger";
    }
    setShowCreateVendorForm(false);
    AppProvider.setAlertFunc({
      txt: res.msg,
      clr: "success",
    });
    getVendorsList().then((res) => {
      if (res.status) {
        let arr = [];
        for (let idx in res.data) {
          arr.push({
            value: res.data[idx].id,
            label: res.data[idx].first_name,
          });
        }
        setVendors(arr);
      }
    });
  };

  const saveVendor = (data) => {
    console.log(data);
    return;
    data.first_name = data.first_name.trim();
    data.vendor_address = data.vendor_address.trim();

    saveVendorInfo(JSON.stringify(data)).then((res) => {
      let clr = "success";
      if (!res.status) {
        clr = "danger";
      }
      setShowCreateVendorForm(false);
      AppProvider.setAlertFunc({
        txt: res.msg,
        clr: "success",
      });
      getVendorsList().then((res) => {
        if (res.status) {
          let arr = [];
          for (let idx in res.data) {
            arr.push({
              value: res.data[idx].id,
              label: res.data[idx].first_name,
            });
          }
          setVendors(arr);
        }
      });
      // fetchPersons().then((res) => {
      //   if (res[1].status) {
      //     let arr = [];
      //     for (let idx in res[1].data) {
      //       arr.push({
      //         value: res[1].data[idx].id,
      //         label: `${res[1].data[idx].first_name} ${res[1].data[idx].last_name}`,
      //       });
      //     }
      //     setPersonList(arr);
      //   }
      // });
    });
  };

  const onChangeCategory = (evt, itemIndex) => {
    let list = itemsList;
    list[itemIndex].category = evt;
    setValue("category", evt);
    setCatagoryForHistory(evt);

    for (let idx in catgegoryWithUnits) {
      if (catgegoryWithUnits[idx].id === evt.value) {
        let selectedUnit = {
          value: catgegoryWithUnits[idx].unit_id,
          label: catgegoryWithUnits[idx].unit_name,
        };
        setValue("unit", selectedUnit);
        list[itemIndex].unit = {
          value: catgegoryWithUnits[idx].unit_id,
          label: catgegoryWithUnits[idx].unit_name,
        };

        break;
      }
    }

    setItemsList([...list]);
  };
  const onChangeUnit = (evt, itemIndex) => {
    let list = itemsList;
    list[itemIndex].unit = evt;
    setValue("unit", evt);
    setItemsList([...list]);
  };

  const onChangeMilestone = (evt, itemIndex) => {
    let list = itemsList;
    list[itemIndex].milestone = evt;
    setValue("milestone", evt);
    setItemsList([...list]);
  };

  const onChangeProject = (evt, itemIndex) => {
    setSelectedProject(evt);
  };

  const onChangeVendor = (evt, itemIndex) => {
    console.log(evt, "sdfsdf");
    setSelectedVendor(evt);
  };

  const getTotalQty = () => {
    let qty = 0;
    for (let idx in itemsList) {
      qty += Number(itemsList[idx].qty);
    }
    qty = Number(qty).toFixed(2);
    return Math.trunc(qty);
  };

  const getTotalCost = () => {
    let cost = 0;
    for (let idx in itemsList) {
      cost += Number(itemsList[idx].cost);
    }
    cost = Number(cost).toFixed(2);
    return Math.trunc(cost);
  };

  const getTotalAmount = () => {
    let amt = 0;
    for (let idx in itemsList) {
      amt += Math.round(Number(itemsList[idx].total_cost));
    }
    return Math.trunc(amt) || 0;
  };

  const cancelCreatePO = () => {
    toggleModal(false);
    if (poId) {
      navigate(-1);
    }
  };

  useEffect(() => {
    Promise.all([
      getCategories(),
      getVendorsList(),
      fetchProjects(),
      getUnits(),
      fetchPersonsVS(),
    ]).then((res) => {
      if (res[0].status) {
        let arr = [];
        for (let idx in res[0].data) {
          arr.push({
            value: res[0].data[idx].id,
            label: res[0].data[idx].name,
          });
        }
        setCatgegoryWithUnits(res[0].data);
        setCategoryList(arr);
      }
      if (res[1].status) {
        let arr = [];
        for (let idx in res[1].data) {
          arr.push({
            value: res[1].data[idx].id,
            label: res[1].data[idx].first_name,
          });
        }
        setVendors(arr);
      }
      if (res[2].status) {
        let list = [];
        for (let idx in res[2].data) {
          list.push({
            value: res[2].data[idx].id,
            label: res[2].data[idx].project_title,
          });
        }
        setProjectsList(list);
      }

      if (res[3].status) {
        let arr = [];
        for (let idx in res[3].data) {
          arr.push({
            value: res[3].data[idx].id,
            label: res[3].data[idx].name,
          });
        }
        setUnitsList(arr);
      }

      if (res[4].status) {
        let arr = [];
        for (let idx in res[4].data) {
          arr.push({
            value: res[4].data[idx].id,
            label: `${res[4].data[idx].first_name} ${res[4].data[idx].last_name}`,
          });
        }
        setPersonList(arr);
      }
    });
    if (poId) {
      getPOById(poId).then((res) => {
        if (res.status) {
          setSelectedVendor({
            value: res.data[0].vendor_id,
            label: res.data[0].first_name,
          });
          setSelectedProject({
            value: res.data[0].project_id,
            label: res.data[0].project_title,
          });

          if (res.poItems?.length) {
            let list = res.poItems;

            for (let idx in list) {
              setValue("isGST", list[idx].isGST);
              list[idx].milestone = _.find(milestoneList, {
                value: list[idx].milestone,
              });
            }

            setItemsList([]);
            setTimeout(() => {
              setItemsList([...list]);
            }, 2000);
          }
        }
      });
    }
    getPOHistoryBYPId(selectedProject.value, 0).then((res) => {
      // console.log(res, "fdsf new one 12");
      let newdata = res?.data;
      newdata.sort(function (var1, var2) {
        var a = new Date(var1.modified_timestamp),
          b = new Date(var2.modified_timestamp);
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      });
      // console.log(newdata, "fdsf new one 12");
      setPreviousHistory(newdata);
    });
  }, []);

  useEffect(() => {
    if (
      location.state &&
      vendors.length > 0 &&
      categoryList.length > 0 &&
      unitsList.length > 0
    ) {
      console.log("venders", vendors);
      setSelectedVendor(vendors.length > 1 ? vendors[1] : {});
      setSelectedProject({
        value: location.state.project.id,
        label: location.state.project.project_title,
      });
      setItemsList([
        {
          item_description: "",
          milestone: {},
          category: _.find(categoryList, { value: location.state.category.id }),
          qty: 1,
          cost: 0,
          total_cost: "",
          isGST: 0,
          unit: _.find(unitsList, { label: location.state.category.unit }),
          size: "",
        },
      ]);
    }
  }, [categoryList, unitsList, vendors]);

  useEffect(() => {
    getPOHistoryBYPId(selectedProject.value, 0, catagoryForHistory.value).then(
      (res) => {
        // console.log(res, "fdsf new one 12");
        let newdata = res?.data;
        newdata.sort(function (var1, var2) {
          var a = new Date(var1.modified_timestamp),
            b = new Date(var2.modified_timestamp);
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });
        console.log(newdata, "fdsf new one 12");
        setPreviousHistory(newdata);
      }
    );
  }, [selectedProject, catagoryForHistory]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return previousHistory.slice(firstPageIndex, lastPageIndex);
  }, [previousHistory, currentPage]);
  // pagination

  return (
    <>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardBody className="wizard">
            <Container className="dashboard create-project">
              <Row>
                <Col md={12}>
                  <h3
                    style={{
                      color: "#646777",
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      textAlign: "center",
                      textTransform: "uppercase",
                      letterSpacing: "6px",
                      wordSpacing: "10px",
                    }}
                  >
                    Create Purchase Order
                  </h3>
                </Col>
              </Row>

              {/* ________________________Project and Vendor selection List_____________________________ */}
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <div className="form__half">
                    <span
                      className="form__form-group-label"
                      style={{
                        textAlign: "left",
                        position: "relative",
                        fontSize: ".71rem",
                        top: "9px",
                        left: "10px",
                        padding: "-1px",
                        fontWeight: "600",
                        color: "#646777",
                        background: "#FFF ",
                        display: "inline",
                        zIndex: 4,
                      }}
                    >
                      Vendor
                    </span>
                    <div className="form__form-group-field ">
                      <FormField
                        name="vendor"
                        control={control}
                        component="creatableSelect"
                        value={selectedVendor}
                        errors={errors}
                        selectRender={({ field }) => (
                          <Select
                            {...field}
                            options={personList}
                            clearable={false}
                            value={selectedVendor}
                            className={`reactSelect`}
                            onChange={(evt) => onChangeVendor(evt)}
                            classNamePrefix="react-select"
                            placeholder={"Select Vendor"}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="form__half">
                    <span
                      className="form__form-group-label"
                      style={{
                        textAlign: "left",
                        position: "relative",
                        fontSize: ".71rem",
                        top: "9px",
                        left: "10px",
                        padding: "-1px",
                        fontWeight: "600",
                        color: "#646777",
                        background: "#FFF ",
                        display: "inline",
                        zIndex: 4,
                      }}
                    >
                      Projects
                    </span>
                    <div className="form__form-group-field">
                      <FormField
                        name="vendor"
                        control={control}
                        component="creatableSelect"
                        value={selectedVendor}
                        errors={errors}
                        selectRender={({ field }) => (
                          <Select
                            {...field}
                            options={projectsList}
                            clearable={false}
                            value={selectedProject}
                            className={`reactSelect`}
                            onChange={(evt) => onChangeProject(evt)}
                            classNamePrefix="react-select"
                            placeholder={"Select Vendor"}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col style={{ alignSelf: "flex-end", margin: "5px" }}>
                  <Button
                    style={{ margin: "0px", textAlign: "center" }}
                    color="primary"
                    type="submit"
                    className="next"
                    onClick={() => toggleModal(true)}
                  >
                    <Add />
                    Add new vendor
                  </Button>
                </Col>
              </Row>

              {/* ________________________TABLE ROW DATA_____________________________ */}
              {/* <Box> */}
              <Table
                style={{
                  fontSize: ".7rem",
                  backgroundColor: "#ddebec8 !important ",
                  minWidth: "900px",
                }}
              >
                <TableHead
                  style={{
                    fontSize: ".7rem",
                    background: "#02505629",
                  }}
                >
                  <TableRow
                    style={{
                      fontSize: ".7rem",
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="1%"
                    >
                      Sl.No
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="20%"
                    >
                      Category
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="20%"
                    >
                      Milestone
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="20%"
                    >
                      Items Description
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="0px"
                    >
                      Size
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="15%"
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="2%"
                    >
                      {" "}
                      Quantity
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="6%"
                    >
                      Cost
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="10%"
                    >
                      Total Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                      width="5%"
                    >
                      GST
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "2px 3px !important",
                        textAlign: "center",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsList.map((item, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell
                          sx={{
                            padding: "7px 3px !important",
                            textAlign: "center",
                          }}
                        >
                          {idx + 1}
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <FormField
                            name="category"
                            value={item.category}
                            control={control}
                            component="creatableSelect"
                            errors={errors}
                            selectRender={({ field }) => (
                              <Select
                                {...field}
                                onChange={(evt) => onChangeCategory(evt, idx)}
                                options={categoryList}
                                clearable={false}
                                value={item.category}
                                className={`reactSelect ${styles.react_select}`}
                                classNamePrefix="react-select"
                                placeholder={"Select Category"}
                                defaultValue={item.category}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <FormField
                            name="milestone"
                            value={item.milestone}
                            control={control}
                            component="creatableSelect"
                            errors={errors}
                            selectRender={({ field }) => (
                              <Select
                                {...field}
                                onChange={(evt) => onChangeMilestone(evt, idx)}
                                options={milestoneList}
                                clearable={false}
                                value={item.milestone}
                                className={`reactSelect ${styles.react_select}`}
                                classNamePrefix="react-select"
                                placeholder={"Select Milestone"}
                                defaultValue={item.milestone}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "7px 3px !important",
                          }}
                        >
                          <Input
                            type="text"
                            value={item.item_description}
                            onChange={(evt) =>
                              onChange(evt, idx, "item_description")
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <Input
                            type="text"
                            value={item.size}
                            onChange={(evt) => onChange(evt, idx, "size")}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <FormField
                            name="unit"
                            value={item.unit}
                            control={control}
                            component="creatableSelect"
                            errors={errors}
                            selectRender={({ field }) => (
                              <Select
                                {...field}
                                onChange={(evt) => onChangeUnit(evt, idx)}
                                options={unitsList}
                                clearable={false}
                                value={item.unit}
                                className={`reactSelect ${styles.react_select}`}
                                classNamePrefix="react-select"
                                placeholder={"Select Unit"}
                                defaultValue={item.unit}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <Input
                            style={{ padding: "0px !important" }}
                            padding="2px"
                            type="text"
                            value={item.qty}
                            onChange={(evt) => onChange(evt, idx, "qty")}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <Input
                            padding="2px"
                            type="text"
                            value={item.cost}
                            onChange={(evt) => onChange(evt, idx, "cost")}
                          />
                        </TableCell>

                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <Input
                            type="text"
                            value={
                              item.total_cost
                                ? Math.trunc(item.total_cost)
                                : item.total_cost
                            }
                            placeholder={
                              Math.round(
                                Number(item.cost) * Number(item.qty) * 100
                              ) / 100
                            }
                            onChange={(evt) => onChange(evt, idx, "total_cost")}

                            // disabled={true}
                          />
                          <div></div>
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <div>
                            <FormField
                              name={`isGST`}
                              type="checkbox"
                              value={item.isGST}
                              control={control}
                              onChange={(evt) => {
                                onChange(evt, idx, "isGST");
                              }}
                              checked={item.isGST}
                              className={"checkbox"}
                              // disabled={item.disabled}
                            />
                          </div>
                        </TableCell>
                        <TableCell sx={{ padding: "7px 3px !important" }}>
                          <a href="#" onClick={(evt) => removeItem(evt, idx)}>
                            <Delete />
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell
                      sx={{ padding: "2px 3px !important" }}
                    ></TableCell>
                    <TableCell
                      sx={{ padding: "2px 3px !important" }}
                    ></TableCell>
                    <TableCell
                      sx={{ padding: "2px 3px !important" }}
                    ></TableCell>
                    <TableCell
                      sx={{ padding: "2px 3px !important" }}
                    ></TableCell>
                    <TableCell
                      sx={{ padding: "2px 3px !important" }}
                    ></TableCell>
                    <TableCell
                      sx={{ padding: "2px 3px !important" }}
                    ></TableCell>
                    <TableCell sx={{ padding: "2px 3px !important" }}>
                      <span>Total Qty: {getTotalQty()}</span>
                    </TableCell>
                    <TableCell sx={{ padding: "2px 3px !important" }}>
                      <span>Total Cost: {getTotalCost()}</span>
                    </TableCell>

                    <TableCell sx={{ padding: "2px 3px !important" }}>
                      <span>Total : {getTotalAmount()}</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* </Box> */}

              {!poId && (
                <div
                  className="form form--horizontal"
                  style={{
                    width: "100%",
                    padding: "0px 10px 10px 0px",
                  }}
                >
                  <input
                    style={{
                      height: "fit-content",
                      borderRadius: "5px",
                      color: "rgb(117 115 115)",
                      background: "#d6e3e4",
                      boxShadow: "2px 3px 3px #c9c7c7",
                    }}
                    type="file"
                    name="poFiles"
                    ref={poFiles}
                    multiple
                    // onChange={(e) => setPoFiles({ ...e.target.files })}
                  />
                </div>
              )}

              {errors && <div style={{ color: "#F00" }}>* {errors}</div>}

              <div className="form__form-group">
                <ButtonToolbar
                  className="form__button-toolbar wizard__toolbar"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <Button
                    color="primary"
                    type="button"
                    className="next"
                    onClick={(e) => addItems()}
                    sx={{ textAlign: "center" }}
                  >
                    <AddCard />
                    Add Items
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className="next"
                    onClick={(evt) => onSubmit()}
                  >
                    Submit
                  </Button>
                  <Button type="button" onClick={() => cancelCreatePO()}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </div>
            </Container>
          </CardBody>
        </Card>
      </form>
      <Typography
        sx={{ fontSize: "2rem", fontWeight: "500", textAlign: "center" }}
      >
        Previous History
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="simple table">
          <TableHead sx={{ background: "#02505629" }}>
            <TableRow sx={{ padding: "2px 3px !important" }}>
              <TableCell sx={{ padding: "2px 3px !important" }}>
                Sl.No
              </TableCell>
              <TableCell
                sx={{ minWidth: "75px", padding: "2px 3px !important" }}
              >
                Mdf Date
              </TableCell>
              <TableCell sx={{ padding: "2px 3px !important" }}>
                Category
              </TableCell>
              <TableCell sx={{ padding: "2px 3px !important" }}>
                Milestone
              </TableCell>
              <TableCell
                sx={{ minWidth: "160px", padding: "2px 3px !important" }}
              >
                Items Desc...
              </TableCell>
              <TableCell
                sx={{ minWidth: "60px", padding: "2px 3px !important" }}
              >
                Unit
              </TableCell>
              <TableCell
                sx={{ minWidth: "60px", padding: "2px 3px !important" }}
              >
                {" "}
                Quantity
              </TableCell>
              <TableCell
                sx={{ minWidth: "60px", padding: "2px 3px !important" }}
              >
                Cost
              </TableCell>
              <TableCell
                sx={{ minWidth: "60px", padding: "2px 3px !important" }}
              >
                Total At
              </TableCell>
              <TableCell
                sx={{ minWidth: "60px", padding: "2px 3px !important" }}
              >
                GST
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(currentTableData) &&
              currentTableData.map((obj, ind) => (
                <TableRow
                  key={ind}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important", textAlign: "center" }}
                  >
                    {ind + 1 + (currentPage - 1) * 10}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {moment(obj?.modified_timestamp).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {
                      categoryList?.find((el) => el.value == obj?.category_id)
                        ?.label
                    }
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    maxWidth={"20px"}
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {obj?.milestone}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {obj?.item_description}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {obj?.unit}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {obj?.qty}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {obj?.cost}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {obj?.total_cost}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "7px 3px !important" }}
                  >
                    {obj?.is_gst ? "True" : "False"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={previousHistory.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </TableContainer>

      <Modal
        isOpen={showCreateVendorForm}
        toggle={() => toggleModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleModal(false)}>
          <strong>Add new Vendor</strong>
        </ModalHeader>
        <ModalBody>
          <CreateVendor
            toggleModal={toggleModal}
            onSaveVendorDetails={onSaveVendorDetails}
            categoryList={categoryList}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreatePO;
