import { IconButton, Tooltip } from "@mui/material";

const HFIconButton = ({ onClick, icon, title }) => {
  const Icon = icon;
  return (
    <Tooltip title={title} onClick={onClick}>
      <IconButton>
        <Icon color="primary" />
      </IconButton>
    </Tooltip>
  );
};
export default HFIconButton;
