import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
// import { ChakraProvider, Input, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Create, Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  deleteBooking,
  deleteBuilding,
  deleteUnit,
  fetchBookings,
  fetchBuildings,
  fetchUnit,
  fetchUnits,
} from "../../../redux/actions/rentalsActions";

import { styled } from "@mui/system";
import UpdateBooking from "./UpdateBooking";
import AddBooking from "./AddBooking";
import moment from "moment";

const Booking = () => {
  const [loading, setLoading] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [enableInput, setEnableInput] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [initialValues, setInitialValues] = useState({
    rent: "",
    deposit: "",
    tenent_name: "",
    tenent_contact: "",
    notes: "",
  });
  const [UpdateValues, setUpdateValues] = useState({
    rent: "",
    deposit: "",
    tenent_name: "",
    tenent_contact: "",
    notes: "",
  });

  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState([]);
  const [dataBuilding, setDataBuilding] = useState({});
  const [deleteId, setdeleteId] = useState({});

  //__________________ Model______________
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  //__________________ Model__________________

  const { id, uid } = useParams();
  function UpdatePropValue(data) {
    setUpdateValues({ ...data });
  }
  const StatusArr = ["", "Unpaid", "Paid", "Cancelled", "partially paid"];

  const createUser = () => {
    setInitialValues({
      rent: "",
      deposit: "",
      tenent_name: "",
      tenent_contact: "",
      notes: "",
    });

    // setShowCreateUserForm(true);
    handleOpen();
  };
  function delUnits() {
    console.log(deleteId);
    deleteBooking(deleteId).then((res) => {
      console.log(res, "deleted");
      setAlertMsg("Unit Data Deleted successfully");
    });
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  useEffect(() => {
    fetchBookings().then((res) => {
      console.log(
        res?.res?.rows.find(
          (el) => el.building_id === Number(id) && el.unit_id === Number(uid)
        ),
        "res of rental Booking",
        id,
        res,
        uid
      );
      setData([
        ...res?.res?.rows.filter(
          (el) => el.building_id === Number(id) && el.unit_id === Number(uid)
        ),
      ]);
    });
  }, [alertMsg]);

  return (
    <Container className="dashboard">
      <h3
        className="page-title"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "8px",
          wordSpacing: "15px",
        }}
      >
        Booking Units
      </h3>
      <Card sx={{ overflow: "scroll", height: "83vh" }}>
        <CardHeader
          title={
            <Button
              color="primary"
              type="button"
              className="previous"
              onClick={() => createUser()}
              variant="contained"
            >
              <Create />
              Create Booking
            </Button>
          }
        />
        <CardContent>
          {/* <EditableReactTable reactTableData={reactTableData} /> */}

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            // columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data.map((obj) => (
              <Grid xs>
                <Card key={obj.booking_id} sx={{ minWidth: "250px", m: 2 }}>
                  <CardHeader
                    title={
                      <Link
                        to={`/app/${role}/Building/unit/${obj.building_id}/Booking/${obj.unit_id}/Invoice/${obj.booking_id}`}
                      >
                        {obj.booking_id}
                      </Link>
                    }
                    subheader={moment(obj.booking_date).format("DD-MM-YYYY")}
                    action={
                      <>
                        <IconButton
                          size="small"
                          onClick={() => {
                            UpdatePropValue(obj);
                            handleOpenUpdate();
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setdeleteId(obj);
                            handleOpenDialog();
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    }
                  />
                  {/* <Link

                    to={`/app/${role}/Building/unit/${obj.building_id}/Booking/${obj.unit_id}/Invoice/${obj.booking_id}`}
                  > */}
                  <CardContent>
                    <p>{`Tenant Contact: ${obj.tenent_contact}`}</p>
                    <p>{`Tenant Name: ${obj.tenent_name}`}</p>
                    <p>
                      {`Tenant ID: `}
                      {Array.isArray(obj.tenent_id) && obj.tenent_id.length ? (
                        <Link
                          to="/app/admin/Building/ledger/vimgArr/"
                          state={{ myState: obj.tenent_id }}
                        >
                          <span style={{ fontWeight: 500 }}>tenent_ids</span>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </p>
                    <p>{`Move In: ${moment(obj.move_in).format(
                      "DD-MM-YYYY"
                    )}`}</p>
                    <p>{`Move Out: ${moment(obj.move_out).format(
                      "DD-MM-YYYY"
                    )}`}</p>
                    <p>{`Rent: ${obj.rent}`}</p>
                    <p>{`Deposit: ${obj.deposit}`}</p>
                    <p>{`Notes: ${obj.notes}`}</p>
                  </CardContent>
                  {/* </Link> */}
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ height: 40, textAlign: "center " }}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </CardContent>
      </Card>
      {/* //_____________________________ ADD Unit _______________________ */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <AddBooking
            closePopup={handleClose}
            initialValues={initialValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}

            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      {/* //_____________________________ Delete Unit _________________________ */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are U Sure You want to delete The building"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            if you click on Agree the building will be permanently delete from
            DataBase
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button
            onClick={() => {
              delUnits();
              handleCloseDialog();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* //_____________________________ Update Unit _____________________________ */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <UpdateBooking
            closePopup={handleCloseUpdate}
            initialValues={UpdateValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      <Snackbar
        open={!!alertMsg}
        autoHideDuration={3000}
        onClose={() => setAlertMsg("")}
        sx={{
          "&.MuiSnackbar-root": { left: "50%", transform: "translateX(-50%)" },
        }}
      >
        <Alert
          onClose={() => setAlertMsg("")}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Booking;
