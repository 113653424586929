import React, { useState } from "react";
import {
  MenuItem,
  FormControl,
  Box,
  Container,
  Card,
  CardContent,
  Button,
  Stack,
  TextField,
  Autocomplete,
  Select,
  Input,
  InputLabel,
} from "@mui/material";
import { Formik, Form, Field, useField } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const config = {
  size: "small",
  variant: "outlined",
};

export const HFPicker = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  console.log("hf-picker", field, meta, helpers);
  return (
    <FormControl className="w-100 mb-2">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          {...field}
          {...props}
          inputFormat="dd-MM-yyyy"
          onChange={(value) => {
            helpers.setValue(value);
          }}
          renderInput={(params) => (
            <TextField size={config.size} {...params} label={label} />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export const HFAutoComplete = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <FormControl className="w-100 mb-2">
      <Autocomplete
        {...field}
        {...props}
        size={config.size}
        onChange={(e, value) => {
          helpers.setValue(value);
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </FormControl>
  );
};

export const HFSelect = ({ options, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControl className="w-100 mb-2">
      <InputLabel>{props.label}</InputLabel>
      <Select
        {...field}
        {...props}
        size={config.size}
        onChange={(e) => {
          helpers.setValue(e.target.value);
        }}
      >
        {options.map((item, index) => (
          <MenuItem key={`${item.value}-${index}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const HFTextField = (props) => {
  const [field, meta, helpers] = useField(props);
  console.log('hftext',field)
  return (
    <FormControl className="w-100 mb-2">
      <TextField
        type="text"
        size={config.size}
        {...field}
        {...props}
      />
    </FormControl>
  );
};

export const FileUpload = (props) => {
  const [field, meta, helpers] = useField(props);
  console.log("file-props", field, meta, helpers);

  return (
    <FormControl className="w-100 mb-2">
      <input
        type="file"
        accept="image/*"
        {...props}
        onChange={(event) => {
          helpers.setValue(field.name, event.currentTarget.files[0]);
        }}
      />
    </FormControl>
  );
};
