import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { getLocData } from '../../redux/actions/locdataAction';
import { Box, Button } from '@chakra-ui/react';
import AllLocs from './AllLocs';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
const Map = ({ emailid ,liveshow}) => {
    const [map, setMap] = useState(null);
    const [clicked, setclicked] = useState("")
    const [polyline, setPolyline] = useState(null);
    const [borderPolyline, setBorderPolyline] = useState(null);
    const [gotpaths, setgotpaths] = useState([])

   let totalkm=0

    const [shopaths, setshopaths] = useState([])

    const [allpaths, setallpaths] = useState([])

    const [todayPaths, setTodayPaths] = useState([])

//console.log(todayPaths,"tpp")

    function fetchdata() {

        getLocData().then((res) => {
            // let latlons = []
            // res.res.rows[0].path.map((ele) => {
            //     latlons.push(JSON.parse(ele))
            // })
            // console.log(res)
            // setgotpaths(latlons)
            let data = []
            res?.res?.rows?.map((ele) => {
                console.log(ele, "111")
                let path = []
                ele?.path?.map((ele) => {
                    path?.push(JSON.parse(ele))
                })

                console.log(path, "2222")
                let obj = {
                    path, ele
                }
                console.log(obj, "333")
                data?.push(obj)
            })
            setallpaths(data)
            
        })
    }

    useEffect(() => {  // Filter the allpaths array to get paths with endtrack value for today
        console.log("53535353")
        const todayDate = new Date().toISOString().slice(0, 10);
        const todayPaths = allpaths.filter((path) => {
            console.log(path,"55")
          const endDate = new Date(path.ele.endtrack).toISOString().slice(0, 10);
          return endDate === todayDate;
        });
  
            console.log(todayPaths,"tpp")
        setTodayPaths(todayPaths);
    }, [allpaths]);
    

    useEffect(() => {
        fetchdata()
    }, []);

    console.log(gotpaths, "15151515")

    const onMapLoaded = ({ map }) => {
        setMap(map);
    };

    console.log(polyline, "pppplllllllll")


    function handleclickpath(path) {
        if (map) {
            if (polyline) {
                // Update the existing Polyline object with the new path
                polyline.setPath(path);
                borderPolyline.setPath(path); // update borderPolyline
            } else {
                // Create a new Polyline object and add it to the map
                const newPolyline = new window.google.maps.Polyline({
                    path,
                    strokeColor: 'rgb(0, 0, 158)',
                    strokeOpacity: 0.5,
                    strokeWeight: 10
                });

                const borderPolyline = new window.google.maps.Polyline({
                    path,
                    strokeColor: "red",
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    map: map,
                });

                newPolyline.setMap(map);
                borderPolyline.setMap(map);
                setPolyline(newPolyline);
                setBorderPolyline(borderPolyline); // add borderPolyline to state
            }
        }
    }

    function getDistanceFromLatLonInM(coords) {
        const R = 6371e3; // Radius of the earth in meters
        let distance = 0;
        for (let i = 1; i < coords.length; i++) {
            const lat1 = coords[i - 1].lat;
            const lon1 = coords[i - 1].lng;
            const lat2 = coords[i].lat;
            const lon2 = coords[i].lng;
            const dLat = deg2rad(lat2 - lat1);
            const dLon = deg2rad(lon2 - lon1);
            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            distance += R * c;
        }
        return distance;
    }

    function deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    // const path = [

    //     { lat: 12.9625, lng: 77.6132 },
    //     { lat: 21.291, lng: -157.821 },
    //     { lat: -18.142, lng: 178.431 },
    //     { lat: -27.467, lng: 153.027 }
    // ];

console.log(clicked,"152152")

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            {
                liveshow && <>
              
            <Box>Today's</Box>
            
           <AllLocs allpaths={todayPaths} />   </>
}

            <Button m={"4rem 0 1rem 1rem"} colorScheme="blue" onClick={() => fetchdata()} >Reload Data</Button>
           
          

            <Box p={"1rem"} >
            <TableContainer>
  <Table>
    <Thead>
      <Tr>
        <Th>User ID</Th>
        <Th>Start Time</Th>
        <Th>End Time</Th>
        <Th>Number of Points</Th>
        <Th>Distance Travelled</Th>
      </Tr>
    </Thead>
    <Tbody>
      {
        allpaths && allpaths.length && allpaths.map((el) => {
          let row;
          if (emailid) {
            if (emailid === el?.ele?.userid) {
              totalkm += Number((getDistanceFromLatLonInM(el.path) / 1000).toFixed(4));
              row = (
                  <Tr backgroundColor={el.ele.id==clicked?"blue.200":"white"}  key={el.ele.starttrack} onClick={() => {
                    setclicked(el.ele.id)
                      handleclickpath(el.path)
                  }} style={{ cursor: "pointer" }}>
                  <Td>{el.ele.userid}</Td>
                      <Td>
                          {new Date(el.ele.starttrack).toLocaleString()}
                      </Td>
                  <Td>{new Date(el.ele.endtrack).toLocaleString()}</Td>
                  <Td>{el.path.length}</Td>
                  <Td><strong>{(getDistanceFromLatLonInM(el.path) / 1000).toFixed(4)} Km</strong></Td>
                </Tr>
              );
            }
          } else {
            row = (
              <Tr backgroundColor={el.ele.id==clicked?"blue.200":"white"}  key={el.ele.starttrack}onClick={() => {
                setclicked(el.ele.id)
                  handleclickpath(el.path)
              }} style={{cursor: "pointer"}}>
                <Td>{el.ele.userid}</Td>
                <Td>{new Date(el.ele.starttrack).toLocaleString()}</Td>
                <Td>{new Date(el.ele.endtrack).toLocaleString()}</Td>
                <Td>{el.path.length}</Td>
                <Td><strong>{(getDistanceFromLatLonInM(el.path) / 1000).toFixed(4)} Km</strong></Td>
              </Tr>
            );
          }
          return row;
        })
      }
    </Tbody>
                    </Table>
            </TableContainer>
</Box>



            <Box m={"1rem"} fontSize={"1.5rem"} >{totalkm.toFixed(4)}</Box>

            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyB6xEjzV9o4ukyimy9KLMeTmhXXsWBmAZQ" }}
                defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
                defaultZoom={11}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={onMapLoaded}
            />
        </div>
    );
};

export default Map;