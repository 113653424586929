
import { Box, Button, Checkbox, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { fetchUsersList } from '../../redux/actions/usersAction'


export default function CheckBoxes({ cdata, handleTaskData }) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [userslist, setuserslist] = useState([])
    const [filteredUserList, setfilteredUserList] = useState([...userslist])


    function getUserName() {
        let useremail = JSON.parse(JSON.parse(localStorage?.getItem("persist:root")).login)?.userDetails?.email
        // console.log(useremail)
        return useremail
    }

    function fetchUsers() {
        fetchUsersList({
            first: "",
            last: "",
            role_id: 0,
            email: "",
            contact_num: "",
        }).then((res) => {

            if (!res?.status) {
                return;
            }
            let filteredData = {}
            res?.data?.filter((ele) => {
                if (ele?.role_id != 6 && ele?.role_id != 4 && ele?.role_id != 5 && ele?.role_id != 7) {
                    return filteredData[`${ele?.first_name.toUpperCase()} ${ele?.email}`] = 1
                }
            })
            let finalFilteredData = ["ALL", `SELF ${getUserName()}`]
            //   console.log(filteredData, "edrftygbh")
            for (let key in filteredData) {
                finalFilteredData.push(key)
            }
            setuserslist(finalFilteredData)
            setfilteredUserList(finalFilteredData)
        })
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    function searcher(val) {
        //console.log(val, "SI")
        let nlist = []
        // console.log(userslist, "uytgfyt")
        userslist?.map((ele) => {
            if (ele?.toLowerCase()?.includes(val?.toLowerCase())) {
                // console.log(ele, "inc")
                nlist.push(ele)
            }
        })
        setfilteredUserList([...nlist])
        // console.log(searchInput, nlist)
    }
    // console.log(filteredUserList, "fill", filteredUserList.length)


    return (<>
        <Button mt={4} onClick={onOpen}>Task Assign To</Button>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(50deg)' />
            <ModalContent borderRadius={"1rem"} maxW={"xs"} >
                <ModalHeader>Assign To</ModalHeader>
                <ModalCloseButton />
                <ModalBody  >
                    <Input mb={5} placeholder='Enter Assign To' onChange={(e) => {
                        searcher(e.target.value)

                    }} />
                    <Box display={"flex"} flexDirection={"column"} overflowY={"scroll"} h={"15rem"} >
                        {
                            filteredUserList?.map((ele) => {

                                return <Box key={ele} display={"flex"} height={"fit-content"} mt={2} ><Checkbox
                                    m={2}
                                    size={"lg"}
                                    isChecked={
                                        cdata?.includes(ele)
                                    }
                                    onChange={(e) => {
                                        handleTaskData(e?.target?.checked, "assignedTo", ele)

                                    }}
                                >

                                </Checkbox><Box textOverflow={"ellipsis"} overflowX={"hidden"} >{ele}</Box></Box>
                            })

                        }

                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Save
                    </Button>

                </ModalFooter>
            </ModalContent>
        </Modal>
    </>)
}
















// {/* <Button onClick={onCheckOpen}>Open Modal</Button>

//         <Modal isOpen={isCheckOpen} onClose={onCheckClose}>
//             <ModalOverlay />
//             <ModalContent>
//                 <ModalHeader>Assigned To</ModalHeader>
//                 <ModalCloseButton />
//                 <ModalBody>

//                     {/* {
//                         data.map((ele) => {
//                             <Checkbox
//                                 isChecked={checkedItems[1]}
//                                 onChange={(e) => checker(ele, e.target.checked)}
//                             >
//                                 {ele}
//                             </Checkbox>
//                         })
//                     } */}

//                 </ModalBody >

//     <ModalFooter>
//         <Button colorScheme='blue' mr={3} onClick={onCheckClose}>
//             Close
//         </Button>
//         <Button variant='ghost'>Save</Button>
//     </ModalFooter>
//             </ModalContent >
//         </Modal > * /}