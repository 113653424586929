import {
  MenuItem,
  Select,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { range } from "lodash";
import { fetchProjects } from "../../redux/actions/ledgerAction";
import DatepickerField from "../Datepicker/DatepickerField";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import { uniqueId } from "lodash";
import { postAttData } from "../../redux/actions/attendanceAction";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./attendanceClass.css";
import {
  getAdminProjects,
  getAdminProjectsAtt,
} from "../../redux/actions/projectsAction";
import { RWebShare } from "react-web-share";
// import { WhatsappShareButton } from "react-share";

import { FormField } from "./FormField";
// import Select from "react-select";
const defaultValue = {};
const CreateLabourAttendenceForm = () => {
  //extracting important function from useForm
  //--------use form----------
  const {
    handleSubmit,
    reset,
    setValue,
    getValue,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project_title: null,
      attendance: {},
    },
  });
  //-------------------Defined Stetes--------------------

  const [projectsList, setProjectsList] = useState([]);
  const [image, setImage] = useState("");
  const [data, setData] = useState(null);
  const [noOfRows, setNoOfRows] = useState([0]);
  const [date, setDate] = useState(new Date());
  const [attendenceList, setAttendenceList] = useState([0]);
  // const [statusList, setStatusList] = useState([0]);

  const [comment, setComment] = useState("");
  const [multipleResult, setMultipleResult] = useState({});
  const [buttonDisable, setButtonDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [objectToArray, setObjectToArray] = useState([]);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [shareImage, setShareImage] = useState([]);
  let imagesArr = [];
  //-----------------------Defined constants -----------------------
  const table_data = [
    "Attendence",
    "No. of worker",
    "Task status",
    "Attactmant",
    "Delete",
  ];
  const attendance = [
    "Mason",
    "Helper",
    "Tile",
    "Painters",
    "Carpenters",
    "Electrician",
    "Fabricator",
    "Bar bending",
    "Shuttering",
    "other",
  ];
  const status_ALL = ["Complete", "Panding", "Not Started"];
  //-----------------------Defined function ----------------------
  const handleClickOpenDialogSuccess = () => {
    setOpenDialogSuccess(true);
  };

  const handleCloseDialogSuccess = () => {
    setOpenDialogSuccess(false);
    setData({});
    setMultipleResult({});
    setName();
    getData();
    // window.location.reload(false);
  };
  console.log("error", errors);
  function OnChangeWorker(e, i) {
    setValue(`attendance.["key${i}"].noOfWorker`, e.target.value);
    data
      ? setData({
          ...data,
          [`key${i}`]: {
            ...data[`key${i}`],
            [e.target.name]: e.target.value,
          },
        })
      : setData({
          ...data,
          [`key${i}`]: {
            [e.target.name]: e.target.value,
          },
        });
  }
  const getData = (type) => {
    getAdminProjectsAtt(JSON.stringify({ project_status: "all" })).then(
      (res) => {
        if (res.status) {
          let data1 = [];
          let arr = res.data;
          for (let idx in arr) {
            data1.push({
              value: arr[idx].project_id,
              label: arr[idx].project_title,
            });
          }
          // console.log(res)
          setProjectsList(data1);
        }
      }
    );
    setMultipleResult((prev) => ({ ...prev, date: date }));
    setAttendenceList(modify(attendance));
  };
  function handleSubmit1(e) {
    // e.preventDefault();
    setButtonDisable(true);
    postData();
  }

  async function postData() {
    for (const obj in multipleResult.attendance) {
      const resp = await postAttData(
        multipleResult?.date,
        multipleResult?.name,
        multipleResult?.project?.value,
        multipleResult?.attendance[obj]
      );
      console.log(resp, "Submit Resp");
      imagesArr = [...imagesArr, resp?.result?.image];
    }
    setShareImage(imagesArr);
    console.log("fatching");
    setButtonDisable(false);
    handleClickOpenDialogSuccess();
    reset();
  }
  function setName() {
    let name =
      JSON.parse(JSON.parse(localStorage.getItem("persist:root")).login)
        .userDetails.first_name +
      " " +
      JSON.parse(JSON.parse(localStorage.getItem("persist:root")).login)
        .userDetails.last_name;

    setMultipleResult((prev) => ({ ...prev, name: name }));
    return name;
  }
  function OnChangeDate(val) {
    // console.log(val);
    setDate(val);
    setMultipleResult((prev) => ({ ...prev, date: val }));
  }
  function modify(arr) {
    let ansArr = [];
    arr.forEach((str, i) => {
      ansArr.push({
        label: str,
        value: i,
      });
    });
    return ansArr;
  }

  function OnChangeProject(val) {
    // console.log(val);
    setValue("project_title", val);
    setMultipleResult((prev) => ({ ...prev, project: val }));
  }
  function CreateField() {
    const uniqueid = uniqueId();
    setNoOfRows([...noOfRows, uniqueid]);
  }
  // console.log(getValue())
  function OnChangeAttandance(val, key) {
    // console.log({ val, key, data });
    const key1 = `key${key}`;
    setValue(`attendance.['key${key}'].Attendance`, val);
    data
      ? setData({ ...data, [key1]: { ...data[key1], Attendance: val.label } })
      : setData({ ...data, [key1]: { Attendance: val.label } });
  }
  function OnChangeTask(val, key) {
    // console.log(val, "task change");

    const key1 = `key${key}`;
    data
      ? setData({ ...data, [key1]: { ...data[key1], Task: val.label } })
      : setData({ ...data, [key1]: { Task: val.label } });
  }

  function fileChangedHandler(event, key) {
    setImage(event.target.files[0]);

    const key1 = `key${key}`;
    data
      ? setData({
          ...data,
          [key1]: { ...data[key1], File: event.target.files[0] },
        })
      : setData({ ...data, [key1]: { File: event.target.files[0] } });
  }
  // console.log(projectsList);

  function filterobject(filterKey) {
    const newObj = {};
    for (const key in data) {
      if (key !== filterKey) {
        // console.log(key, "key filter  dfdsf");
        newObj[key] = data[key];
      }
    }
    // console.log(newObj, "new object");
    setData(newObj);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function FobjectToArray() {
    const obj = [];
    for (const key in multipleResult.attendance) {
      // console.log(multipleResult.attendance[key], "hello");

      obj.push(multipleResult.attendance[key]);
    }
    setObjectToArray(obj);
    return obj;
  }

  function onChangeComment(e, key) {
    const key1 = `key${key}`;
    setComment(e.target.value);
    data
      ? setData({
          ...data,
          [key1]: { ...data[key1], comment: e.target.value },
        })
      : setData({ ...data, [key1]: { comment: e.target.value } });
  }
  function ShareData(result, images = []) {
    // console.log({ ...result });
    let ans = `Name = ${result.name}
                \nProject = ${result?.project?.label}
                \nDate = ${new Date(result.date).toLocaleDateString("en-IN")}`;
    let i = 1;
    for (const key in result.attendance) {
      ans += `\n\t${i}.1)-Attendance =  ${
        result.attendance[key]?.Attendance
          ? result.attendance[key]?.Attendance
          : ""
      }
                \n\t${i}.2)-No Of Worker = ${
        result.attendance[key]?.noOfWorker
          ? result.attendance[key]?.noOfWorker
          : ""
      }
                \n\t${i}.3)-Comment = ${
        result.attendance[key]?.comment ? result.attendance[key]?.comment : ""
      }
                \n\t${i}.4)-Image URI = ${images[i - 1] ? images[i - 1] : ""}`;
      i++;
      // \n\t${i}File ${result.attendance[key]?.File?.}`;
    }
    // console.log(ans);
    ans += "\n\n";
    return ans;
  }

  //--------------------------define useEffect-----------------------

  useEffect(() => {
    setMultipleResult((prev) => ({ ...prev, attendance: data }));
  }, [data]);

  useEffect(() => {
    FobjectToArray();
  }, [multipleResult]);

  useEffect(() => {
    // loadOptions();
    setName();
    getData();
  }, []);
  // console.log(data, "data dsf");
  console.log(multipleResult, "multiple result");
  // console.log(objectToArray, "objs");

  return (
    <>
      <Col md={12} lg={12} className="workOrder">
        <Card>
          <CardBody>
            {/* <div className="card__title">
              <h5 className="bold-text">Attendence info</h5>
            </div> */}

            <form
              encType="multipart/form-data"
              className="form"
              onSubmit={handleSubmit(handleSubmit1)}
            >
              {/* ----------------For Date selection --------------- */}
              <div className="form__half">
                <div className="form__form-group">
                  {/* <span className="form__form-group-label">Date</span> */}
                  <div className="form__form-group-field dateSelect">
                    <DatepickerField
                      name="Date"
                      onChange={OnChangeDate}
                      className="AttDate"
                      value={new Date(date).toLocaleDateString("en-IN")}
                    ></DatepickerField>
                  </div>
                </div>
              </div>
              {/* ----------------For project selection--------------- */}
              <div className="form__half">
                <div className="form__form-group">
                  {/* here is the code for project selection */}

                  <section style={{ width: "100%", marginTop: "0px" }}>
                    <label>
                      <small className="attProject">Project Title</small>
                    </label>
                    {/* <TextField
                      id="outlined-select-currency"
                      select
                      name="project"
                      label="Select Project"
                      value={multipleResult?.project}
                      onChange={(e) => OnChangeProject(e.target.value)}
                      style={{ width: "100%", zIndex: 0 }}
                      required={true}
                    >
                      {projectsList.map((option) => (
                        <MenuItem key={option.value} value={option}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <FormField
                      name="project_title"
                      control={control}
                      rules={{
                        required: "This feild is compulsory",
                      }}
                      component="creatableSelect"
                      errors={errors}
                      selectRender={({ field }) => (
                        <ReactSelect
                          {...field}
                          onChange={OnChangeProject}
                          options={projectsList}
                          menuPortalTarget={document.body}
                          clearable={false}
                          // aria-invalid={true}
                          // aria-label={"no label"}
                          // aria-errormessage={"msg"}
                          className={`attProject2 react-select ${
                            errors?.project_title ? "error_project" : ""
                          }`}
                          classNamePrefix="react-select"
                          placeholder={"Select Project"}
                        />
                      )}
                    />
                  </section>
                </div>
              </div>
              {/* ----------------For attendance taking----------- */}
              <Container>
                {/* ----- Add Button-------  */}
                <Button
                  variant="contained"
                  style={{ marginBottom: "20px" }}
                  onClick={CreateField}
                >
                  Add More Work Force
                </Button>
                {/* ----------- */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {noOfRows.map((i, key) => (
                    <Box
                      key={i}
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                      style={{
                        margin: "8px 13px",
                      }}
                    >
                      <Container
                        align="center"
                        style={{
                          border: "1px solid #5f5a5a30",
                          borderRadius: "5px",
                          padding: "8px",
                          boxShadow: "1px 1px 7px gray",
                          maxWidth: "350px",
                        }}
                        required={true}
                      >
                        <div>
                          {/* Deleting the Box */}
                          <div
                            style={{
                              maxWidth: "350px",
                              display: "flex",
                            }}
                          >
                            <DeleteIcon
                              align="right"
                              style={{
                                color: "rgb(2 80 86)",
                              }}
                              onClick={() => {
                                noOfRows.length > 1 &&
                                  setNoOfRows((prev) =>
                                    prev.filter((el) => el !== i)
                                  );
                                filterobject(`key${i}`);
                              }}
                            />
                          </div>
                          {/* -----------------------Attendance name --------------------*/}
                          <Controller
                            name={`attendance.key${i}.Attendance`}
                            control={control}
                            rules={{
                              required: "!Select Work Force Type",
                            }}
                            render={({ field }) => (
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Work Force Type"
                                value={
                                  data?.Attendance
                                    ? data?.Attendance
                                    : undefined
                                }
                                onChange={(e) =>
                                  OnChangeAttandance(e.target.value, i)
                                }
                                style={{ zIndex: 0 }}
                                error={
                                  errors?.attendance?.[`key${i}`]?.Attendance
                                    ?.message
                                }
                                helperText={
                                  errors?.attendance?.[`key${i}`]?.Attendance
                                    ?.message
                                }
                              >
                                {attendenceList.map((option, ind) => (
                                  <MenuItem key={ind} value={option}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                          {/* <FormField
                            name={`Attendance`}
                            control={control}
                            rules={{
                              required: "This feild is also compulsory ",
                            }}
                            component="creatableSelect"
                            errors={errors}
                            selectRender={({ field }) => (
                              <ReactSelect
                                {...field}
                                onChange={(e) => OnChangeAttandance(e, i)}
                                options={attendenceList}
                                menuPortalTarget={document.body}
                                clearable={false}
                                // className="react-select"
                                // classNamePrefix="react-select"
                                placeholder={"Select Project"}
                              />
                            )}
                          /> */}

                          {/* -----------------------No of worker---------------- */}
                          <Controller
                            name={`attendance.key${i}.noOfWorker`}
                            control={control}
                            rules={{
                              required: "!Please Enter Work Force",
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Worker"
                                id="outlined-start-adornment"
                                sx={{ m: 1, width: "25ch" }}
                                value={data?.noOfWorker}
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment noOfWorkerposition="start">
                                      No .
                                    </InputAdornment>
                                  ),
                                }}
                                name="noOfWorker"
                                onChange={(val) => OnChangeWorker(val, i)}
                                error={
                                  errors?.attendance?.[`key${i}`]?.noOfWorker
                                    ?.message
                                }
                                helperText={
                                  errors?.attendance?.[`key${i}`]?.noOfWorker
                                    ?.message
                                }
                              />
                            )}
                          />

                          {}
                          {/* Select Task Status */}
                          {/* <TextField
                            id="outlined-select-currency"
                            select
                            label="Status List"
                            value={data?.Task}
                            onChange={(e) => OnChangeTask(e.target.value, i)}
                            // helperText="Pleace Select Worker Name"
                          >
                            {statusList.map((option) => (
                              <MenuItem key={option.value} value={option}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField> */}

                          <TextField
                            id="outlined-basic"
                            label="Comment"
                            variant="outlined"
                            style={{ padding: "3px" }}
                            onChange={(e) => onChangeComment(e, i)}
                          />

                          {/* ___________________Select Files Attendance________________ */}
                          <div style={{ margin: "auto" }}>
                            <Button
                              variant="outlined"
                              component="label"
                              style={{ color: "#025056" }}
                            >
                              {multipleResult?.attendance
                                ? multipleResult.attendance[`key${i}`]
                                  ? multipleResult.attendance[`key${i}`].File
                                    ? "uploaded"
                                    : "upload Files"
                                  : "upload Files"
                                : "upload Files"}
                              <input
                                type="file"
                                hidden
                                onChange={(val) => fileChangedHandler(val, i)}
                              />
                            </Button>
                          </div>
                        </div>
                      </Container>
                    </Box>
                  ))}
                </div>
                {/* ___________________SUCCESS ALERT BOX______________ */}
                <div>
                  {/* <Button
                      type="submit"
                      variant="contained"
                      style={{ marginBottom: "10px" }}
                      onClick={openDialogSuccess}
                    >
                      dialog
                    </Button> */}
                  <Dialog
                    open={openDialogSuccess}
                    onClose={handleCloseDialogSuccess}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{ color: "#FFF", background: "#2e7d32" }}
                    >
                      {"Successfully Upload Attendance"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-description"
                        style={{ textTransform: "capitalize" }}
                      >
                        Attendance data is success upload please .If you want to
                        share the attendance <em>click share below</em>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <RWebShare
                        data={{
                          text: ShareData(multipleResult, shareImage),
                          url: "https://housingfactory.in",
                          title: "Attendance Details",
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <Button onClick={handleCloseDialogSuccess}>
                          Share
                          <ShareIcon />
                        </Button>
                      </RWebShare>
                      <Button onClick={handleCloseDialogSuccess} autoFocus>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    disabled={buttonDisable}
                    type="submit"
                    variant="contained"
                    style={{ marginBottom: "10px" }}
                  >
                    Submit
                  </Button>

                  {/* ___________________preview Button___________ */}

                  <Button
                    disabled={buttonDisable}
                    // variant="contained"
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    onClick={handleClickOpen}
                  >
                    Preview
                  </Button>
                  {/* ___________________Dialog box code_________________ */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Attendence Preview"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Grid container style={{ width: "200px " }}>
                          <Grid container>
                            <Grid
                              item
                              xs
                              style={{
                                fontWeight: 500,
                                borderBottom: " 1px solid black",
                              }}
                            >
                              {"Date"}
                            </Grid>
                            <Divider
                              orientation="vertical"
                              flexItem
                              color="black"
                            ></Divider>
                            <Grid
                              item
                              xs
                              style={{
                                paddingLeft: "10px",
                                borderBottom: "1px solid black",
                              }}
                            >
                              {`${new Date(
                                multipleResult?.date
                              ).toLocaleDateString("en-IN")}`}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid
                              item
                              xs
                              style={{
                                fontWeight: 500,
                                borderBottom: " 1px solid black",
                              }}
                            >
                              {"Project"}
                            </Grid>
                            <Divider
                              orientation="vertical"
                              flexItem
                              color="black"
                            ></Divider>
                            <Grid
                              item
                              xs
                              style={{
                                paddingLeft: "10px",
                                borderBottom: "1px solid black",
                              }}
                            >
                              {`${
                                multipleResult?.project?.label
                                  ? multipleResult?.project?.label
                                  : ""
                              }`}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid
                              item
                              xs
                              style={{
                                fontWeight: 500,
                                borderBottom: " 1px solid black",
                                padding: "auto 0px",
                              }}
                            >
                              {"Name"}
                            </Grid>
                            <Divider
                              orientation="vertical"
                              flexItem
                              color="black"
                            ></Divider>
                            <Grid
                              item
                              xs
                              style={{
                                paddingLeft: "10px",
                                borderBottom: "1px solid black",
                                textTransform: "capitalize",
                              }}
                            >
                              {`${
                                multipleResult?.name ? multipleResult?.name : ""
                              }`}
                            </Grid>
                          </Grid>
                        </Grid>
                        {objectToArray.map((obj, i) => (
                          <div key={i} className="previewAttBox">
                            <div>
                              <span className="previewAtt">Attendance</span>
                              <div className="previewContent">
                                {`:${
                                  obj?.Attendance
                                    ? obj?.Attendance
                                    : "Not Define"
                                }`}
                              </div>
                            </div>
                            <div>
                              <span className="previewAtt">noOfWorker</span>
                              <div className="previewContent">
                                {`:${
                                  obj?.noOfWorker
                                    ? obj?.noOfWorker
                                    : "Not Define"
                                }`}
                              </div>
                            </div>
                            <div>
                              <span className="previewAtt">Comments</span>
                              <div className="previewContent">
                                {`:${
                                  obj?.comment ? obj?.comment : "Not Define"
                                }`}
                              </div>
                            </div>
                            <div>
                              <span className="previewAtt">File</span>
                              <div className="previewContent">
                                {`:${
                                  obj?.File?.name
                                    ? obj?.File?.name
                                    : "Not Define"
                                }`}
                              </div>
                            </div>
                          </div>
                        ))}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                      <Button onClick={handleClose} autoFocus>
                        Update
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* ___________________Dialog box code_________________ */}
                  <RWebShare
                    data={{
                      text: ShareData(multipleResult),
                      url: "https://housingfactory.in",
                      title: "Attendance Details",
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <Button style={{ paddingBottom: "15px" }}>
                      <ShareIcon />
                    </Button>
                  </RWebShare>
                  {/* <WhatsappShareButton
                    title="hello world"
                    separator=" hello"
                    url=""
                  >
                    <Button >
                      <ShareIcon />
                    </Button>
                  </WhatsappShareButton> */}
                  {/* <a href="whatsapp://send?text=' + this is new message">
                      whatsapp
                    </a>   */}
                </div>
              </Container>
            </form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default CreateLabourAttendenceForm;
