import React, { useState, useEffect, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import { Box, Button } from "@chakra-ui/react";
import Map from "./Map";
import { submitLocData, updateLocData } from "../../redux/actions/locdataAction";
import { useSelector } from "react-redux";

const Marker = ({ lat, lng }) => <Box fontSize={"1.2rem"} style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>📍</Box>;

const GeoLocationTracker = () => {
    const [path, setPath] = useState([]);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [locwatch, setlocwatch] = useState(false)

    const [geoid, setgeoid] = useState(null)

    const loginState = useSelector((state) => state.login);
    const userId = loginState.userDetails.email;

    const [postData, setPostData] = useState({
        starttrack: new Date(Date.now()),
        endtrack: new Date(),
        path,
        userId
    });

    const [watchId, setWatchId] = useState(null);

    const watchLocation = useCallback(() => {
        setlocwatch(true)
        const id = navigator.geolocation.watchPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                const newPosition = { lat: latitude, lng: longitude };
                setPath((prevPath) => [...prevPath, newPosition]);
                setCenter(newPosition);
            },
            (error) => console.log(error),
            { enableHighAccuracy: true, maximumAge: 0 }
        );
        setWatchId(id);
    }, []);



    const stopWatchingLocation = useCallback(() => {
        navigator.geolocation.clearWatch(watchId);
        setWatchId(null);
    }, [watchId]);



    const postGeoData = useCallback(() => {
        setlocwatch(false)
        stopWatchingLocation()
        console.log(path, "464646")
       //path.shift()
        console.log(path, "484848")
        const newPostData = { ...postData, path, userid: userId };
        updateLocData(newPostData)
        .then((res) => {
            console.log(res);
            //   alert("Data posted successfully");
            // setPath([]);
            alert("Data posted successfully");
            setPath([]);
            setgeoid(null)
            clearInterval(intervalId);
            intervalId=null
        })
        .catch((re) => {
            alert("Data not posted");
        });
    }, [path, postData]);


    useEffect(() => {
        if (path.length === 3 && !geoid) {
            console.log("firs77")
            initial();
        }
    }, [path]);

    //console.log(geoid);

    function initial() {
        console.log(path, "464646");
        path.shift();
        console.log(path, "484848");
        const newPostData = { ...postData, path, userid: userId };
        submitLocData(newPostData)
            .then((res) => {
                setgeoid(res.id);
                //alert("Data posted initial");
            })
            .catch((re) => {
                alert("Data not posted");
            });
    }

    function updatelocdata() {
        if (geoid) {
            const newPostData = { ...postData, path, userid: userId, id: geoid };
            console.log(newPostData)
            updateLocData(newPostData)
                .then((res) => {
                    console.log(res);
                    //   alert("Data posted successfully");
                    // setPath([]);
                })
                .catch((re) => {
                    alert("Data not posted");
                });
        }
    }

    let intervalId=setInterval(() => {
            if (geoid && path.length > 2) {
              //alert("updated")
            updatelocdata();
          }
        }, 60000);
   
      

    return (
        <div style={{ height: "100vh", width: "100%", padding: "0.5rem", margin: "0.5rem" }}>
            {
                path.length
            }
           

            <Button m={"0.2rem"} size={"lg"} disabled={locwatch} colorScheme="blue" onClick={watchLocation}>
                Login
            </Button>
            {geoid}
            {/* <Button m={"0.2rem"} colorScheme="red" onClick={stopWatchingLocation} disabled={!watchId}>
                Stop Tracking
            </Button> */}
            <Button m={"0.2rem"} size={"lg"} disabled={path.length < 3} colorScheme="green" onClick={postGeoData}>
                Logout
            </Button>
          

            {locwatch&&
                <Box>
                    Tracking Your Location....
                </Box>
            }

            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
                defaultZoom={11}
            >
                <Marker lat={center.lat} lng={center.lng} />
            </GoogleMapReact>

        </div>
    );
};

export default GeoLocationTracker;
