import { handleActions } from 'redux-actions';
import {
  basicInfoFieldsState
} from '../actions/projectsAction';

const defaultState = {
  isFetching: false,
  basicInfoflds: {}
};

export default handleActions(
  {
    [basicInfoFieldsState](state, { payload }) {
      return {
        ...state,
        basicInfoflds: payload,
        isFetching: false,
        error: null
      }
    }
  },
  defaultState,
);
