import React, { useEffect, useState } from "react";
import myPostReq from "../../utils/post";
import _ from "lodash";
import HFBox from "../../Components/HFBox";
import HFMap from "../../Components/HFMap";
import {
  HFAutoComplete,
  HFPicker,
  HFTextField,
} from "../../Components/FormikFields";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import { Select } from "formik-mui";
import * as Yup from "yup";
import { Col, Container, Row } from "reactstrap";
import {
  MenuItem,
  FormControl,
  Button,
  Box,
  LinearProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";

const Header = (props) => {
  return (
    <Row className="mb-2">
      <Col md={12}>
        <Box
          sx={{ display: "flex", flex: "row", justifyContent: "space-between" }}
        >
          <h3 className="page-title" style={{ marginBottom: "4px" }}>
            {props.title}
          </h3>
          <Box
            className="mb-1"
            sx={{ display: "flex", flex: "row", justifyContent: "flex-end" }}
          >
            <Box className="px-2">
              <RoomIcon color="warning" />- Leads ({props.leads})
            </Box>
            <Box className="px-2">
              <RoomIcon color="primary" />- Projects ({props.projects})
            </Box>
          </Box>
        </Box>
      </Col>
    </Row>
  );
};

const Filters = (props) => {
  const validation = Yup.object().shape({
    status: Yup.array().min(1, "Required").required("Required"),
  });
  const propTypeList = [
    { value: "g", title: "G" },
    { value: "g+1", title: "G+1" },
    { value: "g+2", title: "G+2" },
    { value: "g+3", title: "G+3" },
    { value: "g+4", title: "G+4" },
    { value: "commercial", title: "Commercial" },
  ];
  const statusList = [
    { value: "active", title: "Active" },
    { value: "waiting", title: "Waiting" },
    { value: "ignored", title: "Ignored" },
    { value: "booked", title: "Booked" },
  ];
  const feedbackList = [
    { value: "hot", title: "Hot" },
    { value: "warm", title: "Warm" },
    { value: "cold", title: "Cold" },
  ];

  const handleSubmit = (values, e) => {
    console.log("submit-form", e);
    props.getData(values, () => {
      e.setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
      onReset={handleSubmit}
    >
      <Form>
        <div className="row">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-4">
                <HFAutoComplete
                  type=""
                  name="status"
                  label="Status"
                  variant="outlined"
                  size="small"
                  options={statusList}
                  getOptionLabel={(option) => option.title}
                  multiple
                />
              </div>
              <div className="col-md-4">
                <HFAutoComplete
                  name="prop_type"
                  label="Prop Type"
                  variant="outlined"
                  size="small"
                  options={propTypeList}
                  getOptionLabel={(option) => option.title}
                  multiple
                />
              </div>
              <div className="col-md-4">
                <HFAutoComplete
                  name="feedback"
                  label="Feedback"
                  variant="outlined"
                  size="small"
                  options={feedbackList}
                  getOptionLabel={(option) => option.title}
                  multiple
                />
              </div>
              <div className="col-md-4">
                <HFTextField
                  name="budget"
                  label="Min. Budget"
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="col-md-4">
                <HFPicker
                  type=""
                  name="start_date"
                  label="Start Month"
                  variant="outlined"
                  inputFormat="MM-yyyy"
                  views={["year", "month"]}
                  size="small"
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              className="mb-2"
            >
              Filter
            </Button>
            <Button type="reset" fullWidth={true} variant="outlined">
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

const Dashboard = (props) => {
  const [mapData, setMapData] = useState([]);
  const [leadsCount, setLeadsCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const initialValues = {
    start_date: null,
    budget: "",
    feedback: [],
    status: [{ value: "active", title: "Active" }],
    prop_type: [],
  };

  const getData = (values, cb) => {
    console.log("submit-params", values);
    setIsLoading(true);
    myPostReq(`/app/usr/dashboard`, JSON.stringify(values))
      .then((res) => {
        let data = [];
        for (let i = 0; i < res.leads.length; i++) {
          data.push({
            lat: res.leads[i].lat,
            lng: res.leads[i].lng,
            location: res.leads[i].location,
            color: "warning",
            type: "lead",
            payload: res.leads[i],
          });
        }
        for (let i = 0; i < res.projects.length; i++) {
          data.push({
            lat: res.projects[i].lat,
            lng: res.projects[i].lng,
            location: res.projects[i].location,
            color: "primary",
            type: "project",
            payload: res.projects[i],
          });
        }
        setMapData(data);
        setLeadsCount(res.leads.length);
        setProjectsCount(res.projects.length);
        setIsLoading(false);
        cb();
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
        cb();
      });
  };

  useEffect(() => {
    getData(initialValues, () => {
      console.log("dashboard data fetched");
    });
  }, []);

  return (
    <Container className="dashboard">
      <Header
        title="Leads Dashboard"
        leads={leadsCount}
        projects={projectsCount}
      />
      <HFBox>
        <Filters getData={getData} initialValues={initialValues} />
      </HFBox>
      {isLoading && <LinearProgress />}
      <HFBox>
        <HFMap data={mapData} pathname={props.pathname} />
      </HFBox>
    </Container>
  );
};
export default Dashboard;
