import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  updateBuilding,
  updateInvoice,
  updateUnit,
} from "../../../redux/actions/rentalsActions";
import moment from "moment";
import DatepickerField from "../../Datepicker/DatepickerField";
import styles from "./Invoice.module.css";
import { useParams } from "react-router-dom";
import { invoice_type_arr, pay_status_arr } from "../Constant";

const UpdateInvoice = ({
  alertMsg,
  setAlertMsg,
  isHorizontal,
  closePopup,
  initialValues,
  currentRent,
}) => {
  const [isAboveError, setIsAboveError] = useState(false);
  // const [alertMsg, setAlertMsg] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [difference, setDifference] = useState(0);
  const [attachments, setAttachments] = useState([]);
  const [bookingDate, setBookingDate] = useState({
    startdate: new Date(),
    enddate: new Date(),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: initialValues });
  const { id, uid, bookid } = useParams();
  const onSubmit = (data) => {
    data.building_id = Number(id);
    data.unit_id = Number(uid);
    data.booking_id = Number(bookid);
    console.log({ ...data, ...bookingDate }, "create building data");
    const newData = {
      ...data,
      ...bookingDate,
      invoice_id: initialValues.invoice_id,
    };

    setDisabled(true);

    newData.then((res) => {
      console.log(res, "");
      setAlertMsg("Booking Data Updated successfully");
      setDisabled(false);
    });
    reset({
      rent: "",
      status: 1,
      description: "",
    });
    setBookingDate({});
    setAttachments([]);
  };

  useEffect(() => {
    setBookingDate({
      startdate: new Date(initialValues.startdate),
      enddate: new Date(initialValues.enddate),
    });
    setValue("rent", initialValues.rent);
    setValue("status", initialValues.status);
    setValue("description", initialValues.description);
    // setUserRole(initialValues.user_role);
  }, [initialValues]);

  useEffect(() => {
    setDifference(
      (prev) =>
        bookingDate.enddate?.getDate() - bookingDate?.startdate?.getDate(),
      "121",
      Number(currentRent)
    );
  }, [bookingDate.enddate, bookingDate.startdate]);

  return (
    <Grid container>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h5">
            Update Invoice
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/*_____________________________units_name_________________________ */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {/*_____________________________ startdate _________________________ */}

                <DatepickerField
                  name="Start Date"
                  className={styles.DateAtt}
                  stl={styles.DateWrapperClass}
                  onChange={(val) => OnChangeDate("startdate", val)}
                  value={new Date(bookingDate.startdate).toLocaleDateString(
                    "en-IN"
                  )}
                ></DatepickerField>
                {/*___________________________ Rent __________________________ */}
                <Controller
                  name="rent"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.rent}
                      margin="dense"
                      mb="20px"
                      id="rent"
                      label="Rent"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.rent?.message}
                      {...rest}
                      placeholder={Math.floor(
                        difference * (Number(currentRent) / 30.0)
                      )}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    max: {
                      value: Math.floor(difference * (currentRent / 30.0)),
                      message: `value should be equal to ${Math.floor(
                        difference * (1221 / 30.0)
                      )}`,
                    },
                    min: {
                      value: Math.floor(difference * (currentRent / 30.0)),
                      message: `value should be equal to ${Math.floor(
                        difference * (1221 / 30.0)
                      )}`,
                    },
                    pattern: {
                      value: /^[0-9]{9}$/,
                      message: "Please enter a number",
                    },
                  }}
                />
                {/*___________________________ select  __________________________ */}
                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    // defaultValue=""
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.status}
                        labelId="demo-simple-select-label"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="status"
                        label="Status"
                        variant="outlined"
                        fullWidth
                        {...rest}
                        disabled
                      >
                        {pay_status_arr
                          .map((el, ind) => (
                            <MenuItem value={ind}>{el}</MenuItem>
                          ))
                          .filter((el, ind) => ind !== 0)}
                      </Select>
                    )}
                    rules={{ required: "This is required field" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {/*_____________________________ enddate _________________________ */}

                <DatepickerField
                  name="End Date"
                  className={styles.DateAtt}
                  stl={styles.DateWrapperClass}
                  onChange={(val) => OnChangeDate("enddate", val)}
                  value={new Date(bookingDate.enddate).toLocaleDateString(
                    "en-IN"
                  )}
                ></DatepickerField>

                {/*___________________________ description __________________________ */}
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.description}
                      margin="dense"
                      mb="20px"
                      id="description"
                      label="Description"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.description?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />
                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Controller
                    name="invoice_type"
                    control={control}
                    // defaultValue=""
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.status}
                        labelId="demo-simple-select-label"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="invoice_type"
                        label="Invoice Type"
                        variant="outlined"
                        fullWidth
                        {...rest}
                        // disabled={true}
                      >
                        {invoice_type_arr
                          .map((el, ind) => (
                            <MenuItem value={ind}>{el}</MenuItem>
                          ))
                          .filter((el, ind) => ind !== 0)}
                      </Select>
                    )}
                    rules={{ required: "This is required field" }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <ButtonGroup className="form__button-toolbar">
              <Button color="primary" type="submit" disabled={disabled}>
                Update
              </Button>
              <Button
                type="button"
                onClick={() => {
                  closePopup();
                  setIsAboveError(false);
                  reset({
                    rent: "",
                    status: 1,
                    description: "",
                  });
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UpdateInvoice;
