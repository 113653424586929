import { Route, Routes } from "react-router-dom";
import LeadList from "./LeadList";
import AddLead from "./AddLead";
import UpdateLead from "./UpdateLead";
import Dashboard from "./LeadDashboard";
import { SmartPrivateRouter } from "../App/PrivateRouter";

const Leads = ({ pathname }) => {
  return (
    <Routes>
      <Route path="dashboard" element={
        <SmartPrivateRouter charat={"20"} >
          < Dashboard pathname={pathname} />
        </SmartPrivateRouter>
      } />
      <Route path="add" element={
        <SmartPrivateRouter charat={"22"} >
          <AddLead pathname={pathname} />
        </SmartPrivateRouter>
      } />
      <Route path="update/:id" element={
        <SmartPrivateRouter charat={"32"} >
          <UpdateLead pathname={pathname} />
        </SmartPrivateRouter>
      } />

      <Route path="my" element={
        <SmartPrivateRouter charat={"23"} >
          <LeadList pathname={pathname} type="my" />
        </SmartPrivateRouter >
      } />

      <Route path="*" element={
        <SmartPrivateRouter charat={"21"} >
          <LeadList pathname={pathname} type="all" />
        </SmartPrivateRouter>
      } />

    </Routes>
  );
};
export default Leads;
