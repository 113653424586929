
const myDeleteReq = async (url, data,
    headers = new Headers({ 'Content-Type': 'application/json' })) => {
    //console.log("rrrrrrrrrrrrrrrrrrrrrrrrdddd")
    console.log("deleting data", data);
    try {
        //console.log('fetching');
        const res = await fetch(url, {
            body: data,
            withCredentials: true,
            headers: headers,
            method: 'DELETE',
            mode: 'cors'
        });
        if (!res.ok) {
            window.location.href = '/app/login';
        }
        return await res.json();
    } catch (err) {
        console.log("imp errrrrrrrrrrrrrr", err)
        return await err;
    }
}

export default myDeleteReq;