import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectFileImage } from "../redux/actions/projectsAction";

const ViewImage = (listView) => {
  const { id } = useParams();
  const [image, setImage] = useState([]);
  console.log(id);

  async function getImage() {
    const location = `https://housingfactory.s3.ap-south-1.amazonaws.com/${id}`;
    let fileName = location.split("/");
    fileName = fileName[fileName.length - 1];
    console.log(location, "location");
    const files = await getProjectFileImage(location, true);
    console.log(files, "start");
    let blob = window.URL.createObjectURL(files);
    let file = new File([blob], `${fileName}`, {
      preview: blob,
      type: files.type,
    });
    file.preview = blob;
    file.path = blob;
    // file.fromsrv = filesList;
    // return file;
    setImage(file);
  }
  useEffect(() => {
    console.log(getImage(), "dsdsa");
  }, []);
  return (
    <>
      {id?.split(".")[1] != "pdf" ? (
        <div>
          <img
            src={`https://housingfactory.s3.ap-south-1.amazonaws.com/${id}`}
            target="_blank"
            style={{ width: "250px" }}
          />
        </div>
      ) : (
        <a href={`${image?.preview}`} target="_blank">
          <Button>View</Button>
        </a>
      )}

      <a href={`https://housingfactory.s3.ap-south-1.amazonaws.com/${id}`}>
        Download
      </a>
    </>
  );
};

export default ViewImage;
