import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const ProImg = ({ data, handleOrder, Order }) => {

    const [clicked, setclicked] = useState(true)
    console.log(Order, "fygchytgfvhg")

    const [orderno, setorderno] = useState("")

    function checkNo() {
        console.log(Order.indexOf(data))
        setorderno(Order.indexOf(data) + 1)
    }

    useEffect(() => {
        checkNo()
    }, [Order])


    return (
        <Box position={"relative"}>

            <Box width={"fit-content"} h={"fit-content"} position={"absolute"} top={"50%"} left={"50%"} fontSize={"3rem"} fontWeight={"600"} color={"blackAlpha.800"} zIndex={10} pointerEvents={"none"} transform={"translate(-50%, -50%)"} >{
                orderno
            }</Box>

            <Image opacity={"0.5"} borderRadius='lg' boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} w={"15rem"} onClick={() => {
                handleOrder(data, clicked)
                setclicked(!clicked)
            }} src={data} />

        </Box >
    )
}

export default ProImg