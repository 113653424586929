import myPostReq from "../../utils/post";
import myGetReq from "../../utils/get";

export const getLocData = async () => {
    try {
        return await myGetReq(
            `/app/usr/locdata/get`
        );
    } catch (err) {
        console.log("get req", err)
        return err;
    }
};

export const submitLocData = async (data) => {
    // console.log(obj, 595959)
    let jdata = JSON.stringify({ data: data })
    console.log(jdata)

    try {
        return await myPostReq("/app/usr/locdata/add", jdata);
    } catch (err) {
        return err;
    }
};
export const updateLocData = async (data) => {
    // console.log(obj, 595959)
    let jdata = JSON.stringify({ data: data })
    console.log(jdata)

    try {
        return await myPostReq("/app/usr/locdata/update", jdata);
    } catch (err) {
        return err;
    }
};


