import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import Moment from "moment";
import AddCircleIcon from "mdi-react/AddCircleIcon";
import AttachmentIcon from "mdi-react/AttachmentIcon";
import CurrencyRupeeIcon from "mdi-react/CurrencyRupeeIcon";
import SearchAddOutlineIcon from "mdi-react/SearchAddOutlineIcon";
import { FormField } from "./FormField";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Link } from "react-router-dom";

import {
  Col,
  Container,
  Row,
  // Table,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import {
  getItemDetails,
  getPurchaseOrders,
  updatePOStatus,
  updatePoLedgerEntries,
  viewPoLedgerEntries,
} from "../../redux/actions/poAction";
import { AppContext } from "../../Context/appContext";
import { poStatus } from "../../shared/helpers/constants";
import LedgerForm from "../Ledger/LedgerForm";
import {
  submitLedger,
  fetchProjects,
  updateLedger,
  deleteLedgerEntry,
} from "../../redux/actions/ledgerAction";
import { PaymentType, PaymentMode } from "@/shared/helpers/constants";
import FileUpload from "../FileUpload/FileUpload";
// import { ChakraProvider, Input } from "@chakra-ui/react";
// import * as chakra from "@chakra-ui/react";
import * as Mui from "@mui/material";
import { TextField } from "@mui/material";
const schema = yup.object().shape({
  startDate: yup.string().required("This field is required"),
  endDate: yup.string().required("This field is required"),
  payDate: yup.string().required("This field is required"),
});
import { useMemo } from "react";
import Pagination from "../Pagination/Pagination";
import { CircularProgress, Fade } from "@mui/material";
import style from "./PO.module.css";
import moment from "moment/moment";
import { CurrencyRupee, Search } from "@mui/icons-material";

const ViewPO = () => {
  const [pleaseWait, setPleasewait] = useState(false);

  const [showCreateVendorForm, setShowCreateVendorForm] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [selectedProject, setSelectedProject] = useState({
    value: 0,
    label: "Select All",
  });
  const [projectsList, setProjectsList] = useState([]);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false);

  const [errors, setErrors] = useState("");
  const AppProvider = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [showFileUploading, setShowFileUploading] = useState(false);
  const [showFileViewModal, setShowFileViewModal] = useState(false);
  const [selectedPO, setSelectedPO] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const poFiles = useRef();
  const loginState = useSelector((state) => state.login);

  const [type, setType] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [filterData, setFilterData] = useState({
    poid: 0,
    status: 0,
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: {},
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadOptions = async () => {
    const response = fetchProjects().then((res) => {
      let data = [{ value: 0, label: "Select All" }];
      let arr = res.data;
      for (let idx in arr) {
        data.push({
          value: arr[idx].id,
          label: arr[idx].project_title,
        });
      }
      setProjectsList(data);
      // setLoading(false);
    });
  };

  const toggleModal = (flag) => {
    setShowForm(flag);
  };

  const toggleFileUploadingModal = (isActive, PO) => {
    setShowFileUploading(isActive);
    if (PO) {
      setSelectedPO(PO);
    }
  };

  const toggleFileViewModal = (isActive) => {
    setShowFileViewModal(isActive);
  };

  const getTotalCost = (sItems, index) => {
    let list = [...itemsList];
    let cost = 0;
    for (let idx in sItems) {
      cost += Number(sItems[idx].total_cost);
    }
    return cost;
  };

  const getPaidInfo = (ledgerInfo) => {
    if (ledgerInfo.length) {
      let amt = 0;
      for (let idx in ledgerInfo) {
        amt += Number(ledgerInfo[idx].amount);
      }
      return amt;
    }
    return 0;
  };

  const OnChangeProject = (val) => {
    if (!val) {
      return;
    }
    setValue("project_title", val);
    setSelectedProject(val);
    clearFilters();
  };

  const onChangeStatus = (index, evt, currentPage, poid) => {
    let list = itemsList;
    list[index + (currentPage - 1) * 20].status = Number(evt.target.value);
    setItemsList(list);

    if (poid == list[index + (currentPage - 1) * 20].po_id) {
      updatePOStatus(
        list[index + (currentPage - 1) * 20].po_id,
        evt.target.value
      );
    } else {
      alert("Please do after reload");
    }
  };

  const clearFilters = () => {
    setFilterData({
      poid: 0,
      status: 0,
    });
  };

  const navigateToLedger = (evt, item) => {
    let cat = { value: 49, label: "Purchase Orders" };
    if (item.subItems.length) {
      cat = {
        value: item.subItems[0].category_id,
        label: item.subItems[0].category_name,
      };
      // for (let idx in item.subItems) {
      //   if (
      //     JSON.stringify({
      //       value: item.subItems[idx].category_id,
      //       label: item.subItems[idx].category_name,
      //     }) === JSON.stringify(cat)
      //   ) {
      //   } else {
      //     cat = { value: 49, label: "Purchase Orders" };
      //     break;
      //   }
      // }
    }
    console.log(item, "Ledger entry for fir es");

    setInitialValues({
      category: cat,
      po_id: item.po_id,
      person: { label: item.first_name, value: item.vendor_id },
      date: new Date(),
      amount: "",
      paymentType: { label: "Expense", value: 2 },
      paymentMode: 0,
      txnRef: "",
      gstBills: false,
      comments: "",
      project_title: { value: item.project_id, label: item.project_title },
    });
    toggleModal(true);
  };

  const onSubmit = (data) => {
    setSubmitBtnStatus(true);
    if (!data?.project_title?.value) {
      return;
    }

    data.date = Moment(data.date).format("yyyy-MM-DD");
    data.project_id = data.project_title;
    data.gstBills = data.gstBills ? 1 : 0;
    let dat = new FormData();
    let selectedFiles = data.files;

    /*******************Update Ledger Entry****************************** */
    if (data.txn_id) {
      let existingFiles = [];
      let tempArr = [];
      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          if (selectedFiles[i].fromsrv) {
            existingFiles.push(selectedFiles[i].fromsrv);
            delete selectedFiles[i];
          } else {
            tempArr.push(selectedFiles[i]);
            dat.append("files", selectedFiles[i]);
          }
        }
      }

      data.existingFiles = existingFiles;
      dat.append("data", JSON.stringify(data));

      updateLedger(dat).then((res) => {
        toggleModal(false);
        if (res.status) {
          AppProvider.setAlertFunc({ txt: res.msg, clr: "success" });

          getpo();
        } else {
          AppProvider.setAlertFunc({
            txt: "Failed to save ledger entry. Please try again",
            clr: "danger",
          });
        }
      });
    } else {
      dat.append("data", JSON.stringify(data));

      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          dat.append("files", selectedFiles[i]);
        }
      }
      submitLedger(dat).then((res) => {
        toggleModal(false);
        if (res.status) {
          AppProvider.setAlertFunc({
            txt: res.msg,
            clr: "success",
          });

          if (res.result) {
            //console.log(" submit Ledger", res.result[0].txn_id);
            updatePoLedgerEntries(data.po_id, res.result[0].txn_id);
          }
        } else {
          AppProvider.setAlertFunc({
            txt: res.msg,
            clr: "success",
          });
        }
      });
    }
  };

  const getpo = () => {
    Promise.all([
      getPurchaseOrders(selectedProject.value, filterData, type),
    ]).then((res) => {
      console.log(res, "po data po data");
      if (res[0].status) {
        if (res[0].data) {
          let list = res[0].data;
          for (let idx in list) {
            list[idx].totalCost = getTotalCost(list[idx].subItems, idx);
            list[idx].totalPaid = getPaidInfo(list[idx].ledgerInfo, idx);
          }
          setItemsList(res[0].data);
        } else {
          setItemsList([]);
        }
        setLoading(false);
      }
    });
  };

  const onChangeFilters = (evt) => {
    console.log(evt);
    let val;
    if (evt.target.name !== "status")
      val = evt.target.value.toLocaleLowerCase();
    else val = evt.target.value;
    let key = evt.target.name;

    if (key === "status" && val == 0) {
      val = 0;
    }
    if (key === "poid" && val == 0) {
      val = 0;
    }

    setFilterData({ ...filterData, [key]: val });
  };

  const [tabValue, setTabValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setItemsList([]);
    if (newValue == "two") {
      setType("completed");
    } else if (newValue == "three") {
      setType("rejected");
    } else {
      setType("");
    }
    setTabValue(newValue);
  };

  const setData = (list) => {
    setItemsList(list);
  };
  const [loading, setLoading] = useState(true);
  const handleClickLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };
  useEffect(() => {
    setLoading(true);
    let timer = setTimeout(() => {
      getpo();
      loadOptions();
    }, 500);

    return () => {
      setLoading(false);
      clearTimeout(timer);
    };
  }, [selectedProject, filterData, type]);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  return (
    <>
      <Card>
        <CardBody className="wizard">
          <Container className="dashboard create-project">
            <Row>
              <Col md={10}>
                <h3
                  style={{
                    color: "#646777",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    textTransform: "uppercase",
                    letterSpacing: "6px",
                    wordSpacing: "10px",
                  }}
                >
                  View Purchase Order
                </h3>
              </Col>
              <Col>
                {binaryconverter(loginState.userDetails.access_control).charAt(
                  12
                ) == 1 && (
                  <NavLink to={`/app/${role}/po-create`}>
                    <button type="button" className="clearFilter">
                      <AddCircleIcon size="2em" />
                    </button>
                  </NavLink>
                )}
              </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="one" label="Item One" />
                <Tab value="two" label="Completed" />
                <Tab value="three" label="Rejected" />
              </Tabs>
            </Box>

            {tabValue == "one" && (
              <>
                <CustomPOTable
                  onChangeFilters={onChangeFilters}
                  filterData={filterData}
                  control={control}
                  errors={errors}
                  itemsList={itemsList}
                  OnChangeProject={OnChangeProject}
                  projectsList={projectsList}
                  navigateToLedger={navigateToLedger}
                  onChangeStatus={onChangeStatus}
                  setData={setData}
                  toggleFileUploadingModal={toggleFileUploadingModal}
                  type={type}
                  loading={loading}
                />
                {errors && <div style={{ color: "#F00" }}>* {errors}</div>}
              </>
            )}
            {tabValue == "two" && (
              <>
                <CustomPOTable
                  onChangeFilters={onChangeFilters}
                  filterData={filterData}
                  control={control}
                  errors={errors}
                  itemsList={itemsList}
                  OnChangeProject={OnChangeProject}
                  projectsList={projectsList}
                  navigateToLedger={navigateToLedger}
                  onChangeStatus={onChangeStatus}
                  setData={setData}
                  toggleFileUploadingModal={toggleFileUploadingModal}
                  type={type}
                  loading={loading}
                />
                {errors && <div style={{ color: "#F00" }}>* {errors}</div>}
              </>
            )}
            {tabValue == "three" && (
              <>
                <CustomPOTable
                  onChangeFilters={onChangeFilters}
                  filterData={filterData}
                  control={control}
                  errors={errors}
                  itemsList={itemsList}
                  OnChangeProject={OnChangeProject}
                  projectsList={projectsList}
                  navigateToLedger={navigateToLedger}
                  onChangeStatus={onChangeStatus}
                  setData={setData}
                  toggleFileUploadingModal={toggleFileUploadingModal}
                  type={type}
                  loading={loading}
                />
                {errors && <div style={{ color: "#F00" }}>* {errors}</div>}
              </>
            )}
          </Container>
        </CardBody>
      </Card>

      <Modal
        isOpen={showForm}
        toggle={() => toggleModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleModal(false)}>
          <strong>Create Entry</strong>
        </ModalHeader>
        <ModalBody>
          <LedgerForm
            selectedProject={selectedProject}
            closePopup={toggleModal}
            onSubmit={onSubmit}
            submitBtnStatus={submitBtnStatus}
            initialValues={initialValues}
            // projectsList={projectsList}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showFileUploading}
        toggle={() => toggleFileUploadingModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleFileUploadingModal(false)}>
          <strong>Upload File</strong>
        </ModalHeader>
        <ModalBody>
          <FileUpload
            PO={selectedPO}
            toggleFileUploadingModal={toggleFileUploadingModal}
            getpo={getpo}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showFileViewModal}
        toggle={() => toggleFileViewModal(false)}
        className="customModalDialog"
      >
        <ModalHeader toggle={() => toggleFileViewModal(false)}>
          <strong>Upload File</strong>
        </ModalHeader>
        <ModalBody></ModalBody>
      </Modal>
    </>
  );
};

export default ViewPO;

export const CustomPOTable = ({
  onChangeFilters,
  filterData,
  control,
  errors,
  itemsList,
  OnChangeProject,
  projectsList,
  navigateToLedger,
  onChangeStatus,
  setData,
  toggleFileUploadingModal,
  type,
  loading,
}) => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const appState = useSelector((state) => state.login);
  const [itemsList1, setItemsList1] = useState([]);
  const [newFilterData, setNewFilterData] = useState({
    item_details: "",
    vendor: "",
    created_By: "",
    txns: "",
  });

  const applyFilter = (value) => {
    // console.log(value, "dsfdsf");
    setItemsList1(
      itemsList.filter((el, ind) => {
        if (
          el.subItems.find(
            (ele) =>
              (ele.category_name
                .toLocaleLowerCase()
                .search(newFilterData.item_details.toLocaleLowerCase()) != -1 ||
                newFilterData.item_details === "") &&
              (el.first_name
                .toLocaleLowerCase()
                .search(newFilterData.vendor.toLocaleLowerCase()) != -1 ||
                newFilterData.vendor === "") &&
              ((
                el.created_by_firstname +
                el.created_by_lastname +
                moment(el.created_timestamp).format("DD-MM-YYYY")
              )
                .toLocaleLowerCase()
                .search(newFilterData.created_By.toLocaleLowerCase()) != -1 ||
                newFilterData.created_By === "") &&
              ((
                "T: " +
                Math.trunc(el?.totalCost) +
                " P: " +
                Math.trunc(el?.totalPaid) +
                " B: " +
                Math.trunc(el?.totalCost - el?.totalPaid)
              )
                .toLocaleLowerCase()
                .search(newFilterData.txns.toLocaleLowerCase()) != -1 ||
                newFilterData.txns === "")
          )
        ) {
          return true;
        } else {
          return false;
        }
      })
    );
  };

  useEffect(() => {
    applyFilter();
  }, [itemsList, newFilterData]);

  console.log(itemsList1, itemsList);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 20;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return itemsList1.slice(firstPageIndex, lastPageIndex);
  }, [itemsList1, currentPage]);
  // pagination

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  function canChangeStatus(idx, evt, total, paid, poids) {
    console.log(idx, evt, total, paid, "sdfdsf");
    // if (evt.target.value === 7) {
    //   // complete
    //   if (total === paid) {
    //     onChangeStatus(idx, evt, currentPage);
    //   } else {
    //     window.alert(
    //       "Can't complete Perchase Order some amount is Panding i.e " +
    //         (total - paid) +
    //         "Rs"
    //     );
    //   }
    //   return;
    // }
    // if (evt.target.value === 8) {
    //   // rejected
    //   if (paid == 0) {
    //     onChangeStatus(idx, evt, currentPage);
    //   } else {
    //     window.alert(
    //       "Can't Reject already did some payment i.e " + paid + "Rs"
    //     );
    //   }
    //   return;
    // }
    let con = confirm(`Updating Perchase Order ID is -> ${poids} `);
    if (con) {
      onChangeStatus(idx, evt, currentPage, poids);
    }
  }

  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  return (
    <div
      // className="table react-table"
      style={{ borderRadius: "12px", marginTop: "10px" }}
    >
      <div
      // className="table react-table table--not-pagination"
      >
        <TableContainer component={Paper}>
          <Table
            aria-label="collapsible table"
            // className="react-table"
            style={{ minWidth: "200px", textAlign: "center" }}
          >
            <TableHead sx={{ padding: "0px" }}>
              <TableRow sx={{ zIndex: 2, padding: "0px" }}>
                <TableCell sx={{ minWidth: "50px", padding: "0px" }}>
                  {/* <ChakraProvider> */}
                  <TextField
                    width="60px"
                    type="text"
                    name="poid"
                    onChange={onChangeFilters}
                    // value={filterData.poid}
                    label="Id"
                  />
                  {/* </ChakraProvider> */}
                </TableCell>
                <TableCell
                  sx={{ minWidth: "105px", padding: "7px 2px 2px 4px" }}
                >
                  {/* Project */}
                  <FormField
                    name="project_title"
                    control={control}
                    component="creatableSelect"
                    errors={errors}
                    selectRender={({ field }) => (
                      <Select
                        // value={value}
                        {...field}
                        onChange={OnChangeProject}
                        options={projectsList}
                        menuPortalTarget={document.body}
                        clearable={false}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={"Select Project"}
                      />
                    )}
                  />
                </TableCell>
                <TableCell
                  style={{
                    minWidth: "200px",
                    textAlign: "center",
                    padding: "7px 2px 2px 4px",
                  }}
                >
                  {/* Item Details */}
                  {/* <ChakraProvider> */}
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                    }}
                    width="150px"
                    type="text"
                    name="item_details"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    // value={newFilterData.item_details}
                    label="Item Details"
                  />
                  {/* </ChakraProvider> */}
                </TableCell>
                <TableCell
                  sx={{ minWidth: "100px", padding: "7px 2px 2px 4px" }}
                >
                  {/* Vendor */}
                  {/* <ChakraProvider> */}
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                    }}
                    width="150px"
                    type="text"
                    name="vendor"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    // value={newFilterData.item_details}
                    label="Vendor"
                  />
                  {/* </ChakraProvider> */}
                </TableCell>
                <TableCell
                  sx={{ minWidth: "100px", padding: "7px 2px 2px 4px" }}
                >
                  {/* Created By */}
                  {/* <ChakraProvider> */}
                  <TextField
                    sx={{
                      width: "-webkit-fill-available !important",
                    }}
                    width="150px"
                    type="text"
                    name="created_By"
                    onChange={(e) =>
                      setNewFilterData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    // value={newFilterData.item_details}
                    label="Created By"
                  />
                  {/* </ChakraProvider> */}
                </TableCell>
                {/* <ChakraProvider> */}
                {/* {!type ? ( */}
                <>
                  <TableCell
                    style={{
                      textAlign: "left",
                      // paddingTop: "1px",
                      // paddingBottom: "20px",
                      minWidth: "100px",
                      padding: "7px 2px 2px 4px",
                    }}
                  >
                    {/* <div
                        style={{
                          textAlign: "left",
                          position: "relative",
                          fontSize: ".71rem",
                          top: "9px",
                          left: "10px",
                          padding: "-1px",
                          background: "#025056",
                          display: "inline",
                          zIndex: 4,
                        }}
                      >
                        Status
                      </div> */}
                    <Mui.FormControl fullWidth>
                      <Mui.InputLabel id="demo-simple-select-label">
                        Status
                      </Mui.InputLabel>
                      <Mui.Select
                        labelId="demo-simple-select-label"
                        name="status"
                        onChange={onChangeFilters}
                        value={Number(filterData.status)}
                        label="Status"
                        style={{
                          // zIndex: 2,
                          minWidth: "100px",
                          color: "#000",
                        }}
                      >
                        <Mui.MenuItem value={0} style={{ color: "#000" }}>
                          All
                        </Mui.MenuItem>
                        {poStatus.map((status, idx) => {
                          return (
                            <Mui.MenuItem
                              key={idx}
                              value={Number(status.value)}
                              // style={{ color: "#000" }}
                            >
                              {status.label}
                            </Mui.MenuItem>
                          );
                        })}
                      </Mui.Select>
                    </Mui.FormControl>
                  </TableCell>
                </>
                {/* ) : ( */}
                {/* <TableCell
                    style={{
                      minWidth: "105px",
                      textAlign: "center",
                      padding: "7px 2px 2px 4px",
                    }}
                  >
                    Status
                  </TableCell>
                )} */}
                <TableCell
                  sx={{ minWidth: "105px", padding: "7px 2px 2px 4px" }}
                >
                  Attachments
                </TableCell>

                {loginState.userDetails.role_id !== 3 && (
                  <TableCell
                    sx={{
                      minWidth: "105px",
                      padding: "7px 2px 2px 4px",
                      textAlign: "center",
                    }}
                  >
                    <TextField
                      sx={{
                        width: "-webkit-fill-available !important",
                      }}
                      width="150px"
                      type="text"
                      name="txns"
                      onChange={(e) =>
                        setNewFilterData((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }))
                      }
                      // value={newFilterData.item_details}
                      label="Txns"
                    />
                  </TableCell>
                )}
                {/* <TableCell></TableCell> */}
                {/* </ChakraProvider> */}
              </TableRow>
            </TableHead>
            <TableBody style={{ textAlign: "left", margin: "auto" }}>
              {Array.isArray(currentTableData) &&
                currentTableData.map((item, idx) => {
                  return (
                    <>
                      <TableRow
                        key={idx}
                        style={{
                          background: `${idx % 2 ? "#FFFFE8" : "#CDE990"}`,
                        }}
                      >
                        <TableCell sx={{ minWidth: "50px", padding: "3px" }}>
                          {binaryconverter(
                            loginState.userDetails.access_control
                          ).charAt(13) == 1 && (
                            <NavLink to={`/app/${role}/po-edit/${item.po_id}`}>
                              {item.po_id}
                            </NavLink>
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: "105px", padding: "3px" }}>
                          <div>{item.project_title}</div>
                        </TableCell>
                        <TableCell sx={{ minWidth: "200px", padding: "3px" }}>
                          {item.subItems.map((sItem, xxidx) => {
                            return (
                              <>
                                <div
                                  style={{
                                    padding: "5px",
                                    margin: "2px",
                                    color: "black",
                                    fontSize: "12px",
                                    background: "#0000001a",
                                    borderRadius: "9px",
                                  }}
                                >
                                  {xxidx + 1}. {sItem.category_name} |{" "}
                                  {sItem.item_description} | <strong>Q:</strong>{" "}
                                  {sItem.qty} | <strong>Cost:</strong>
                                  {Math.trunc(sItem.cost)} |{" "}
                                  <strong>Total Cost:</strong>{" "}
                                  {Math.trunc(sItem.total_cost)}
                                </div>
                              </>
                            );
                          })}
                        </TableCell>
                        <TableCell sx={{ minWidth: "100px", padding: "3px" }}>
                          {item.first_name}
                        </TableCell>
                        <TableCell sx={{ minWidth: "100px", padding: "3px" }}>
                          <div>
                            {`${item.created_by_firstname}
                                  ${item.created_by_lastname}`}
                          </div>
                          <div>
                            {Moment(item.created_timestamp).format(
                              "DD-MM-yyyy"
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: "100px",
                            padding: "3px",
                          }}
                        >
                          {loginState.userDetails.role_id !== 3 ? (
                            <Mui.Select
                              onChange={(evt) =>
                                canChangeStatus(
                                  idx,
                                  evt,
                                  Math.trunc(item?.totalCost),
                                  Math.trunc(item?.totalPaid),
                                  item.po_id
                                )
                              }
                              sx={{
                                minWidth: "100px",
                                width: "-webkit-fill-available",
                                maxWidth: "200px",
                              }}
                              // value={item.status}
                              displayEmpty={false}
                              defaultValue={item.status}
                            >
                              {poStatus.map((status, idx) => {
                                return (
                                  <Mui.MenuItem
                                    key={idx}
                                    value={status.value}
                                    // disabled={
                                    //   (status.value === 7 &&
                                    //     Math.trunc(item?.totalCost) !==
                                    //       Math.trunc(item?.totalPaid)) ||
                                    //   (status.value === 8 &&
                                    //     Math.trunc(item?.totalPaid) !== 0)
                                    //     ? true
                                    //     : false
                                    // }
                                    title={
                                      (status.value === 7 &&
                                      Math.trunc(item?.totalCost) !==
                                        Math.trunc(item?.totalPaid)
                                        ? "Can't Complete, Some Amount Is Panding i.e " +
                                          (Math.trunc(item?.totalCost) -
                                            Math.trunc(item?.totalPaid)) +
                                          "Rs"
                                        : false) ||
                                      (status.value === 8 &&
                                      Math.trunc(item?.totalPaid) !== 0
                                        ? "Can't Reject already did some payment i.e " +
                                          Math.trunc(item?.totalPaid) +
                                          "Rs"
                                        : false)
                                    }
                                    selected={item.status === status.value}
                                  >
                                    {status.label}
                                  </Mui.MenuItem>
                                );
                              })}
                            </Mui.Select>
                          ) : (
                            <>
                              {poStatus.map((status, idx) => {
                                if (item.status === status.value) {
                                  return <span key={idx}>{status.label}</span>;
                                }
                              })}
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: "100px",
                            padding: "3px",
                            textAlign: "center",
                          }}
                        >
                          <a
                            key={idx}
                            href="#"
                            onClick={() => toggleFileUploadingModal(true, item)}
                          >
                            <br />

                            <AttachmentIcon
                              size="2em"
                              style={{
                                width: "max-content",
                                margin: "0px auto",
                              }}
                            />
                          </a>
                          {Array.isArray(item.attachments) &&
                          item.attachments.length ? (
                            item.attachments.map((bill, billIdx) => {
                              let imgLink = bill.split("/");
                              imgLink = imgLink[imgLink.length - 1];
                              return (
                                <div>
                                  <Link
                                    to={`/app/vimg/${imgLink}`}
                                    target="_blank"
                                  >
                                    <span>{`Bill ${billIdx + 1}`}</span>
                                  </Link>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </TableCell>

                        {loginState.userDetails.role_id !== 3 && (
                          <TableCell sx={{ minWidth: "100px", padding: "3px" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "#00f",
                                }}
                                onClick={(evt) => navigateToLedger(evt, item)}
                              >
                                {/* <CurrencyRupeeIcon size="1.2em" /> */}
                                <CurrencyRupee color="primary" />
                              </span>
                              <span
                                style={{
                                  color: "#00f",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let list = [...itemsList1];
                                  list[
                                    idx + (currentPage - 1) * 20
                                  ].viewLedgerEntries =
                                    !list[idx + (currentPage - 1) * 20]
                                      .viewLedgerEntries;
                                  setData(list);
                                }}
                              >
                                {"  "}
                                <SearchAddOutlineIcon color="red" />
                              </span>
                            </div>
                            <div>
                              <span>
                                <strong>
                                  T: {Math.trunc(item?.totalCost)}
                                </strong>
                              </span>{" "}
                              <span>
                                <strong>
                                  P: {Math.trunc(item?.totalPaid)}
                                </strong>
                              </span>{" "}
                              <span>
                                <strong>
                                  B:{" "}
                                  {Math.trunc(
                                    item?.totalCost - item?.totalPaid
                                  )}
                                </strong>
                              </span>
                            </div>
                          </TableCell>
                        )}
                      </TableRow>

                      {item.viewLedgerEntries && (
                        <>
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Table
                                style={{ width: "100%" }}
                                className="subTable"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Txn ID</TableCell>
                                    <TableCell>Person</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Payment Type</TableCell>
                                    <TableCell>Payment Mode</TableCell>
                                    <TableCell>Gst Bills</TableCell>
                                    <TableCell>Txn Reference</TableCell>
                                    <TableCell>Comments</TableCell>
                                    <TableCell>Attachments</TableCell>
                                  </TableRow>
                                </TableHead>
                                <tbody>
                                  {item?.ledgerInfo?.length &&
                                    item?.ledgerInfo.map((lItem, xidx) => {
                                      return (
                                        <>
                                          <TableRow key={xidx + "l"}>
                                            <TableCell>
                                              {lItem.txn_id}
                                            </TableCell>
                                            <TableCell>{`${lItem.person_first_name} ${lItem.person_last_name}`}</TableCell>
                                            <TableCell>
                                              {lItem.amount}
                                            </TableCell>
                                            <TableCell>
                                              {
                                                PaymentType.filter(
                                                  (o) =>
                                                    o.value ===
                                                    lItem.payment_type_id
                                                )[0]?.label
                                              }
                                            </TableCell>
                                            <TableCell>
                                              {
                                                PaymentMode.filter(
                                                  (o) =>
                                                    o.value ===
                                                    lItem.payment_mode_id
                                                )[0]?.label
                                              }
                                            </TableCell>
                                            <TableCell>
                                              {lItem.is_gst_bill ? "Yes" : "No"}
                                            </TableCell>
                                            <TableCell>
                                              {lItem.txn_reference}
                                            </TableCell>
                                            <TableCell>
                                              {lItem.comments}
                                            </TableCell>
                                            <TableCell>
                                              {lItem.attachments.length ? (
                                                JSON.parse(
                                                  lItem.attachments
                                                ).map((attach, xxidx) => {
                                                  let imgLink =
                                                    attach.split("/");
                                                  imgLink =
                                                    imgLink[imgLink.length - 1];

                                                  return (
                                                    <div>
                                                      <Link
                                                        to={`/app/vimg/${imgLink}`}
                                                        target="_blank"
                                                      >
                                                        <span>{`Attachment ${
                                                          xxidx + 1
                                                        }`}</span>
                                                      </Link>
                                                    </div>
                                                  );
                                                })
                                              ) : (
                                                <div>NA</div>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })}
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </tbody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={itemsList1.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <Box
        sx={{
          height: 40,
          display: `${loading ? "block" : "none"}`,
          textAlign: "center ",
        }}
      >
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress className={style.circularProgress} />
        </Fade>
      </Box>
    </div>
  );
};
