import React, { useEffect, useState } from "react";
import EditableReactTable from "./EditableReactTable";
import AddUser from "./AddUser";
import { fetchUsersList } from "../../redux/actions/usersAction";
import { set } from "react-hook-form";
import { addUser } from "../../redux/actions/loginActions";
import { ROLES } from "../../shared/helpers/constants";
import { display } from "@mui/system";
import { Link, NavLink } from "react-router-dom";
// import { ChakraProvider, Input, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Create, Edit, Filter } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Fade,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getAllSEProjects } from "../../redux/actions/projectsAction";
import {
  fetchProjects,
  getWorkOrdersApiUserList,
} from "../../redux/actions/workOrderAction";
const UsersList = () => {
  const [loading, setLoading] = useState(true);
  const loginState = useSelector((state) => state.login);
  const [enableInput, setEnableInput] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first: "",
    last: "",
    user_role: 4,
    email: "",
    contact_num: "",
    id: 0,
  });
  const [modalRow, setModalRow] = useState({});
  const [dataModal, setDataModal] = useState([]);
  const [dataColumn, setDataColumn] = useState([]);
  //__________________ Model______________
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  //__________________ Model__________________

  useEffect(() => {
    getWorkOrdersApiUserList().then((res) => {
      if (res.status) {
        console.log(res.data, "res.data");
        setDataModal([...res.data]);
      }
    });
  }, []);

  const [filterData, setFilterData] = useState({
    first: "",
    last: "",
    email: "",
    contact_num: "",
    role_id: 0,
  });

  const onChangeFilters = (evt) => {
    let val = evt.target.value;
    let key = evt.target.name;
    setFilterData({ ...filterData, [key]: val });
  };

  const clearFilters = () => {
    setFilterData({
      first: "",
      last: "",
      email: "",
      contact_num: "",
      role_id: 0,
    });
  };

  const [reactTableData, setReactTableData] = useState({
    tableHeaderData: [
      {
        Header: "#",
        accessor: "sn",
        // Footer: 'Middle age:',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "First name",
        accessor: "first",
      },
      {
        Header: "Last name",
        accessor: "last",
        disableGlobalFilter: false,
      },
      {
        Header: "Email",
        accessor: "email",
        disableGlobalFilter: false,
        disableSortBy: true,
      },
      {
        Header: "Contact Number",
        accessor: "contact_num",
        disableGlobalFilter: false,
        disableSortBy: false,
      },
      {
        Header: "Attachments",
        accessor: "attachments",
        disableGlobalFilter: false,
        disableSortBy: false,
      },
      {
        Header: "User Role",
        accessor: "role_id",
        disableGlobalFilter: false,
        disableSortBy: false,
      },
      {
        Header: "Edit",
        accessor: "",
      },
    ],
    tableRowsData: [],
  });

  useEffect(() => {
    setLoading(true);
    getUsersList();
  }, [filterData]);

  const createUser = () => {
    setInitialValues({
      first: "",
      last: "",
      user_role: 4,
      email: "",
      contact_num: "",
      id: 0,
    });

    // setShowCreateUserForm(true);
    handleOpen();
  };

  const toggle = (flag) => {
    setShowCreateUserForm(flag);
  };

  const getUsersList = () => {
    let dat = [];
    fetchUsersList(filterData).then((res) => {
      if (!res.status) {
        return;
      }
      console.log(res);
      for (let i = 0; i < res.data.length; i++) {
        let a = i + 1;
        a.toString();
        dat.push({
          sn: a.toString(),
          first: res.data[i].first_name,
          last: res.data[i].last_name,
          email: res.data[i].email,
          id: res.data[i].id,
          user_role: res.data[i].role_id,
          contact_num: res.data[i].contact_num,
          attachments: res.data[i].attachments,
        });
      }
      setLoading(false);
      setReactTableData({ ...reactTableData, tableRowsData: dat });
    });
  };

  const displayMsgAndSubmitForm = (data) => {
    // toggle(false);
    handleClose();
    getUsersList();

    if (data.status) {
      setAlertMsg(data.msg);
      setTimeout(() => {
        setAlertMsg("");
      }, 4000);
    }
  };

  const updateUserDetails = (data) => {
    setInitialValues(data);
    // toggle(true);
    handleOpen();
  };

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  function SetModelForm(row) {
    let viewData = [
      ...dataModal.filter((el) => el.assigned_to_firstname === row.first),
    ];

    setDataColumn([...viewData]);

    setModalRow({ ...row });
    handleOpen1();
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  return (
    <Container className="dashboard">
      {/* <Row>
        <Col md={12}> */}
      <h3
        className="page-title"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "8px",
          wordSpacing: "15px",
        }}
      >
        Users List
      </h3>
      {/* </Col>
      </Row> */}

      {/* <Row>
        <Col md={12} lg={12}> */}
      <Card>
        <CardContent sx={{ overflow: "scroll", height: "83vh" }}>
          {alertMsg && (
            <Alert color="success" isOpen={!!alertMsg}>
              {alertMsg}
            </Alert>
          )}
          {binaryconverter(loginState.userDetails.access_control).charAt(2) ==
            1 && (
            <Button
              color="primary"
              type="button"
              className="previous"
              onClick={() => createUser()}
            >
              <Create />
              Create User
            </Button>
          )}
          <Button
            color="primary"
            type="button"
            className="previous"
            onClick={() => clearFilters()}
          >
            <Filter />
            Clear Filters
          </Button>
          {/* <EditableReactTable reactTableData={reactTableData} /> */}

          <Table>
            <TableHead>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    padding: "7px 2px",
                  },
                }}
              >
                {reactTableData.tableHeaderData.map((tHead, idx) => {
                  return (
                    <TableCell
                      key={idx}
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: "7px 2px",
                          textAlign: "center",
                        },
                      }}
                    >
                      {tHead.accessor === "role_id" ? (
                        <Select
                          name={tHead?.accessor}
                          onChange={onChangeFilters}
                          value={filterData[tHead.accessor]}
                          // style={{ margin: "4px" }}
                        >
                          <MenuItem key={"ss"} value={0} color={"#fff"}>
                            Select All
                          </MenuItem>
                          {Object.keys(ROLES).map((role, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={index + 1}
                                style={{ color: "#000" }}
                              >
                                {ROLES[role]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        <TextField
                          id="outlined-basic"
                          label={`${tHead.Header}`}
                          variant="outlined"
                          type="text"
                          value={filterData[tHead.accessor]}
                          onChange={onChangeFilters}
                          name={tHead.accessor}
                        />
                        // <Input
                        //   type="text"
                        //   value={filterData[tHead.accessor]}
                        //   onChange={onChangeFilters}
                        //   name={tHead.accessor}
                        //   // style={{ margin: "4px" }}
                        // />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {reactTableData.tableRowsData.map((row, xidx) => {
                return (
                  <TableRow
                    key={xidx}
                    sx={{
                      ".MuiTableCell-root": {
                        padding: "7px 2px",
                        textAlign: "center",
                      },
                    }}
                  >
                    <TableCell
                      onClick={() => SetModelForm(row)}
                      sx={{ cursor: "pointer" }}
                    >
                      {row.sn}
                    </TableCell>
                    <TableCell>{row.first}</TableCell>
                    <TableCell>{row.last}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.contact_num}</TableCell>
                    <TableCell>
                      {Array.isArray(row.attachments) &&
                      row.attachments.length ? (
                        row.attachments.map((bill, billIdx) => {
                          let imgLink = JSON.parse(bill);
                          imgLink = imgLink.location;
                          imgLink = imgLink.split("/");
                          imgLink = imgLink[imgLink.length - 1];

                          return (
                            <div>
                              <Link to={`/app/vimg/${imgLink}`} target="_blank">
                                <span>{`Attachment ${billIdx + 1}`}</span>
                              </Link>

                              <div
                                onClick={() =>
                                  window.open(`/app/vimg/${imgLink}`, "_blank")
                                }
                              >
                                test
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell>
                      <span>{ROLES[row.user_role]}</span>
                    </TableCell>
                    <TableCell>
                      {binaryconverter(
                        loginState.userDetails.access_control
                      ).charAt(2) == 1 && (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "black",
                            textDecoration: "underline",
                            textAlign: "center",
                          }}
                          onClick={(e) => updateUserDetails(row)}
                        >
                          <Edit color="black" />
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Box sx={{ height: 40, textAlign: "center " }}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </CardContent>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <AddUser
            closePopup={handleClose}
            initialValues={initialValues}
            displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            minWidth: 320,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
            MaxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <Table sx={{ overflow: "scroll" }}>
            <TableHead>
              <TableCell>ID</TableCell>
              <TableCell>Project Name</TableCell>
              <TableCell>Amount</TableCell>
            </TableHead>
            <TableBody>
              {dataColumn.map((data, ind) => (
                <TableRow>
                  <TableCell>
                    {binaryconverter(
                      loginState.userDetails.access_control
                    ).charAt(10) == 1 && (
                      <NavLink
                        to={`/app/${role}/wo-detailed-view/${JSON.stringify({
                          value: data.p_id,
                          label: data.project_title,
                        })}/${data.id}`}
                      >
                        {" "}
                        {data.id}
                      </NavLink>
                    )}
                  </TableCell>

                  <TableCell>{data.project_title}</TableCell>
                  <TableCell>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            M: T: {data.ms_total_amt}, B:{" "}
                            {data.ms_total_balance_amount}, P:{" "}
                            {data.ms_total_total_paid}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            AW: T: {data.a_total_amt}, B:{" "}
                            {data.a_total_balance_amount}, P:{" "}
                            {data.a_total_total_paid}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Modal>
    </Container>
  );
};

export default UsersList;
