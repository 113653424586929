import myDeleteReq from "../../utils/delete";
import myGetReq from "../../utils/get";
import myPostReq from "../../utils/post";

export const getPackageDetails = async () => {
  try {
    return await myGetReq(`/app/usr/packages-details`);
  } catch (err) {
    console.log("get req", err);
    return err;
  }
};
