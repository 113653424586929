import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, useDisclosure } from '@chakra-ui/react'
import React from 'react'

const ProAleartBox = ({ delfun, msg, btn, cbtn, btncs, btnv, btnm, btndis }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const cancelRef = React.useRef()

    return (
        <Box >
            <Button disabled={btndis} m={btnm} variant={btnv} colorScheme={btncs} onClick={() => {
                onOpen()
            }} >{btn}</Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay >
                    <AlertDialogContent m={"1rem"} >
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {msg}
                        </AlertDialogHeader>

                        <AlertDialogBody  >
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                onClose()
                                delfun()
                            }} ml={3}>
                                {cbtn}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}

export default ProAleartBox