import React, { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { SelectField } from '@/shared/components/form/Select';
import { emailPatter, firstNameLastNamePattern, mobileNumberPattern } from '@/shared/helpers';
import showResults from '../../../utils/showResults';
import PasswordField from '@/shared/components/form/Password';
import { FormField } from './FormField';
import { signupReq } from '../../../redux/actions/loginActions';

const Form = ({ isHorizontal }) => {
  const [isAboveError, setIsAboveError] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {

    if (data.password !== data.confirmPassword) {
      setIsAboveError("Password and confirm password field should match");
      return;
    }

    signupReq(JSON.stringify(data))
      .then(data => {
        setIsAboveError(data.msg)

        if (data.status) {
          reset({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
          })
        }
      });
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>

          {isAboveError &&
            <div className="card__title">
              <h5 className="subhead subhead-err">
                {
                  isAboveError
                    ? (
                      <span>
                        <span className="err-white-text">{isAboveError}</span>
                      </span>
                    )
                    : ''
                }
              </h5>
            </div>
          }

          <form className={`form ${isHorizontal && 'form--horizontal'}`} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">Firstname</span>
              <div className="form__form-group-field">
                <FormField
                  name="firstName"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  maxLength="20"
                  errors={errors}
                  rules={{
                    required: 'This is required field',
                    pattern: {
                      value: firstNameLastNamePattern,
                      message: 'Firstname must contain letters only',
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Firstname"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">Lastname</span>
              <div className="form__form-group-field">
                <FormField
                  name="lastName"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  errors={errors}
                  maxLength="20"
                  rules={{
                    required: 'This is required field',
                    pattern: {
                      value: firstNameLastNamePattern,
                      message: 'Lastname must contain letters only',
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Lastname"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr"> E-mail</span>
              <div className="form__form-group-field">
                <FormField
                  name="email"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{
                    required: 'This is required field',
                    pattern: {
                      value: emailPatter,
                      message: 'Entered value does not match email format',
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="example@gmail.com"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">Mobile Number</span>
              <div className="form__form-group-field">
                <FormField
                  name="phoneNumber"
                  className="form__form_inputWhiteClr"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{
                    required: 'This is required field',
                    pattern: {
                      value: mobileNumberPattern,
                      message: 'Please enter a valid contact number',
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Mobile number"
                />
              </div>
            </div>


            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">Password</span>
              <div className="form__form-group-field">
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PasswordField
                      input={field}
                      className="form__form_inputWhiteClr"
                      meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                      placeholder="Password"
                      isAboveError={isAboveError}
                    />
                  )}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label form__form_inputWhiteClr">Confirm Password</span>
              <div className="form__form-group-field">
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PasswordField
                      input={field}
                      className="form__form_inputWhiteClr"
                      meta={{ touched: !!fieldState.error, error: fieldState.error?.message }}
                      placeholder="Confirm Password"
                      isAboveError={isAboveError}
                    />
                  )}
                  rules={{
                    required: 'This is required field'

                  }}
                  defaultValue=""
                />
              </div>
            </div>

            <div className="form__form-group">
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">Signup</Button>
                <Button
                  type="button"
                  onClick={() => {
                    setIsAboveError(false);
                    reset({
                      firstName: '',
                      lastName: '',
                      email: '',
                      phoneNumber: '',
                      password: '',
                      confirmPassword: '',
                    })
                  }}
                >
                  Cancel
                </Button>
              </ButtonToolbar>
            </div>

          </form>
        </CardBody>
      </Card>
    </Col >
  );
};

Form.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

Form.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default Form;
