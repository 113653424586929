import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import SimpleTable from "../../../Components/SimpleTable";
import HFDialog from "../../../Components/HFDialog";
import HFIconButton from "../../../Components/HFIconButton";
import get from "../../../utils/get";
import moment from "moment";
import { Alert } from "@mui/material";
import { poStatus } from "../../../shared/helpers/constants";
import _ from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

const MaterialDetails = (props) => {
  const [dialog, setDialog] = useState();
  const [data, setData] = useState();
  const appState = useSelector((state) => state.login);

  useEffect(() => {
    if (props.material) {
      get(
        `/app/usr/material-transactions?project_id=${props.project.id}&material_id=${props.material.id}`
      )
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      setData(false);
    }
  }, [props.material]);

  useEffect(() => {
    if (data) {
      setDialog(true);
    } else {
      setDialog(false);
    }
  }, [data]);

  const TxnComponent = ({ row }) => {
    return (
      <Typography variant="subtitle2">
        <span style={{ color: row.type === "ordered" ? "green" : "red" }}>
          {row.type === "ordered" ? "+" : "-"}
          {row.qty}
        </span>
      </Typography>
    );
  };

  const AdminComponent = ({ row }) => {
    return (
      <>
        <Typography variant="subtitle1">{row.modified_by_name}</Typography>
        <Typography variant="caption">
          {`on ${moment(row.modified_timestamp).format("DD MMM YYYY")}`}
        </Typography>
      </>
    );
  };

  const statusComponent = ({ row }) => {
    return <>{_.find(poStatus, { value: row.status }).label}</>;
  };



  const loginState = useSelector((state) => state.login);

  let role = ""
  switch (loginState.userDetails.role_id) {

    case 1:
      role = "admin"
      break;
    case 2:
      role = "pm"
      break;
    case 3:
      role = "se"
      break;
    case 4:
      role = "customer"
      break;
    case 5:
      role = "serviceprovider"
      break;
    case 6:
      role = "vendor"
      break;
    case 7:
      role = "user"
      break;
    case 8:
      role = "admin"
      break;
    case 9:
      role = "sales"
      break;

    default:
      break;
  }



  function binaryconverter(hexadecimal) {

    let decimal = parseInt(hexadecimal, 16)
    let binary = (decimal).toString(2)
    return binary
  }


  const EditComponent = ({ row }) => {
    return (
      <HFIconButton
        title="Edit transactioni"
        icon={EditIcon}
        onClick={() => {
          if (
            binaryconverter(loginState.userDetails.access_control).charAt(13) == 1
          ) {
            window.open(`/app/${role}/po-edit/${row.po_id}`);
          }
        }}
      />
    );
  };

  const handleClose = () => {
    props.setMaterial(false);
  };

  return (
    <HFDialog
      open={dialog}
      handleSubmit={handleClose}
      handleClose={handleClose}
      title={props.material.material}
      width="md"
    >
      {data && (
        <>
          {data.requirements && (
            <div className="row mb-4">
              <div className="col-6">
                <Typography variant="h4">
                  {props.material.required} {props.material.unit}
                </Typography>
                <Typography variant="body1">Total Required</Typography>
              </div>
              <div className="col-6">
                <Card variant="outlined">
                  <CardContent className="p-0">
                    <List dense={true}>
                      {data.requirements.map((item, index) => (
                        <>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={item.added_by_name}
                              secondary={item.notes}
                            />
                            <ListItemText
                              primary={
                                item.milestone
                                  ? `Required for ${item.milestone}`
                                  : "-"
                              }
                            />
                            <ListItemText
                              sx={{ textAlign: "right" }}
                              primary={`${item.quantity} ${item.unit}`}
                            />
                          </ListItem>
                          {/* <Divider /> */}
                        </>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </div>
            </div>
          )}
          {data.transactions.length > 0 ? (
            <SimpleTable
              headers={[
                { value: "Admin Details", component: AdminComponent },
                { key: "unit", value: "Unit" },
                { key: "qty", value: "Quantity", component: TxnComponent },
                { key: "status", value: "Status", component: statusComponent },
                { key: "item_description", value: "Description" },
                { value: "Edit", component: EditComponent },
              ]}
              body={data.transactions}
            />
          ) : (
            <Alert variant="outlined" severity="warning" sx={{ width: "100%" }}>
              No transactons
            </Alert>
          )}
        </>
      )}
    </HFDialog>
  );
};
export default MaterialDetails;
