export const ProjectScope = [
  { label: "Inscope", value: 0 },
  { label: "Additional", value: 1 },
  { label: "Reimbursement", value: 3 },
  { label: "Descope", value: 2 },
];

export const workStatusList = [
  { label: "WIP", value: 1 },
  { label: "Yet to Start", value: 2 },
  { label: "Completed", value: 3 },
];

export const paymentStatusList = [
  { label: "Received", value: 1 },
  { label: "Partially Received", value: 2 },
  { label: "Pending", value: 3 },
];

export const ROLES = {
  1: "Admin",
  2: "Project Manager",
  3: "Site Engineer",
  4: "Customer",
  5: "Service Provider",
  6: "Vendor",
  7: "User",
  8: "Super Admin",
  9: "Sales Executive",
};

export const PaymentType = [
  { label: "Please Select", value: 0 },
  { label: "Income", value: 1 },
  { label: "Expense", value: 2 },
];

export const PaymentMode = [
  { label: "Please Select", value: 0 },
  { label: "Bank", value: 1 },
  { label: "Cash", value: 2 },
  { label: "Others", value: 3 },
  { label: "For Reimbursement", value: 4 },
];

export const workOrderStatusList = [
  { label: "Please Select", value: 0 },
  { label: "Assigned", value: 1 },
  { label: "WIP", value: 2 },
  { label: "Completed", value: 3 },
  { label: "Paid", value: 4 },
];

export const paidStatusList = [
  { label: "Paid", value: 1 },
  { label: "Partially Paid", value: 2 },
  { label: "Yet to Pay", value: 3 },
  { label: "Payment Completed", value: 4 },
];

export const poStatus = [
  { label: "New", value: 1 },
  { label: "Modified", value: 2 },
  { label: "Order placed waiting for delivery", value: 3 },
  { label: "Order placed waiting for payment", value: 4 },
  { label: "Paid waiting for deliver", value: 5 },
  { label: "Delivered waiting for payment", value: 6 },
  { label: "Payment Processed", value: 9 },
  { label: "Completed", value: 7 },
  { label: "Rejected", value: 8 },
];

export const woPaymentStatus = [
  { label: "New", value: 1 },
  { label: "Payment Processed", value: 2 },
  { label: "Rejected", value: 3 },
  { label: "Complete", value: 4 },
];

export const projectStatus = [
  { label: "Design", value: 1 },
  { label: "Execution", value: 2 },
  { label: "Completed", value: 3 },
  { label: "Abrupt Ending", value: 4 },
];
