import React from "react";
import Dropzone from "react-dropzone";

const Droper = ({ name, value, onChange, uploadLabel }) => {
  const files = value;
  const onDrop = (onDropFiles) => {
    onChange(
      onDropFiles.map((fl) =>
        Object.assign(fl, {
          preview: URL.createObjectURL(fl),
        })
      )
    );
  };
  const removeFile = (index, e) => {
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  };
  return (
    <div
      className={`dropzone dropzone--multiple`}
      style={{ border: " 1px solid #ccc" }}
    >
      <Dropzone
        className="dropzone__input"
        style={{ border: " 0px solid #ccc !important" }}
        name={name}
        onDrop={(filesToUpload) => {
          onDrop(value ? value.concat(filesToUpload) : filesToUpload);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="dropzone__input"
            style={{ border: "0px solid #ccc !important" }}
          >
            {(!files || files?.length === 0) && (
              <div className="dropzone__drop-here">
                <span className="lnr lnr-upload" />
                {uploadLabel ? uploadLabel : "Drop file here to upload"}
              </div>
            )}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      {files && Array.isArray(files) && (
        <div
          className="dropzone__imgs-wrapper"
          style={{ border: "0px solid #ccc !important" }}
        >
          {files.map((file, i) => (
            <div
              className="dropzone__img"
              key={file.name}
              style={{ backgroundImage: `url(${file.preview})` }}
            >
              <p className="dropzone__img-name">{file.name}</p>
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={(e) => removeFile(i, e)}
              >
                Remove
              </button>
              <button className="dropzone__img-preview" type="button">
                <a href={`${file.preview}`} target="_blank">
                  <span>Preview</span>
                </a>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Droper;
