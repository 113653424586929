import react, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutReq, fetchLogout } from "../../redux/actions/loginActions";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    console.log("logging out");
    logoutReq();
    dispatch(fetchLogout());
    localStorage.clear();

    return navigate("/user/Hello1");
  };

  useEffect(() => {
    logout();
  }, []);

  return <></>;
};

export default Logout;
