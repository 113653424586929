import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  updateBooking,
  updateBuilding,
  updateUnit,
} from "../../../redux/actions/rentalsActions";
import moment from "moment";
import DatepickerField from "../../Datepicker/DatepickerField";
import styles from "./Booking.module.css";
import Droper from "./Droper";
import { useParams } from "react-router-dom";

const UpdateBooking = ({
  alertMsg,
  setAlertMsg,
  isHorizontal,
  closePopup,
  initialValues,
}) => {
  const { id, uid } = useParams();
  const [isAboveError, setIsAboveError] = useState(false);
  // const [alertMsg, setAlertMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [bookingDate, setBookingDate] = useState({
    move_in: new Date(),
    booking_date: new Date(),
    move_out: new Date(),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: initialValues });

  function OnChangeDate(name, val) {
    // console.log(val);
    setBookingDate((prev) => ({ ...prev, [name]: val }));
  }
  const onSubmit = (data) => {
    data.building_id = Number(id);
    data.unit_id = Number(uid);
    console.log(
      { ...data, ...bookingDate, utenent_id: [...attachments] },
      "create building data"
    );
    const newData = { ...data, ...bookingDate, utenent_id: [...attachments] };

    setDisabled(true);
    updateBooking(newData).then((res) => {
      console.log(res, "");
      setAlertMsg("Booking Data Updated successfully");
      setDisabled(false);
    });
    reset({
      rent: "",
      deposit: "",
      tenent_name: "",
      tenent_contact: "",
      notes: "",
    });
    setBookingDate({});
    setAttachments([]);
  };

  useEffect(() => {
    setBookingDate({
      move_in: new Date(initialValues.move_in),
      booking_date: new Date(initialValues.booking_date),
      move_out: new Date(initialValues.move_out),
    });
    setValue("rent", initialValues.rent);
    setValue("deposit", initialValues.deposit);
    setValue("tenent_name", initialValues.tenent_name);
    setValue("tenent_contact", initialValues.tenent_contact);
    setValue("notes", initialValues.notes);
    // setUserRole(initialValues.user_role);
  }, [initialValues]);

  return (
    <Grid container>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h5">
            Create Rental Building
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/*_____________________________Building_name_________________________ */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {/*_____________________________ move_in _________________________ */}

                <DatepickerField
                  name="Move In"
                  className={styles.DateAtt}
                  stl={styles.DateWrapperClass}
                  onChange={(val) => OnChangeDate("move_in", val)}
                  value={new Date(bookingDate.move_in).toLocaleDateString(
                    "en-IN"
                  )}
                ></DatepickerField>

                {/*_____________________________ booking_date _________________________ */}

                <DatepickerField
                  name="Booking Date"
                  className={styles.DateAtt}
                  stl={styles.DateWrapperClass}
                  onChange={(val) => OnChangeDate("booking_date", val)}
                  value={new Date(bookingDate.booking_date).toLocaleDateString(
                    "en-IN"
                  )}
                ></DatepickerField>
                {/*___________________________ Rent __________________________ */}
                <Controller
                  name="rent"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.rent}
                      margin="dense"
                      mb="20px"
                      id="rent"
                      label="Rent"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.rent?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />
                {/*___________________________ deposit __________________________ */}
                <Controller
                  name="deposit"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.deposit}
                      margin="dense"
                      mb="20px"
                      id="deposit"
                      label="Deposit"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.deposit?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {/*_____________________________ move_out _________________________ */}

                <DatepickerField
                  name="Move Out"
                  className={styles.DateAtt}
                  stl={styles.DateWrapperClass}
                  onChange={(val) => OnChangeDate("move_out", val)}
                  value={new Date(bookingDate.move_out).toLocaleDateString(
                    "en-IN"
                  )}
                ></DatepickerField>

                {/*___________________________ tenent_name __________________________ */}
                <Controller
                  name="tenent_name"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.CareTaker_contact}
                      margin="dense"
                      mb="20px"
                      id="tenent_name"
                      label="Tenent Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.tenent_name?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />

                {/*___________________________ tenent_contact __________________________ */}
                <Controller
                  name="tenent_contact"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.tenent_contact}
                      margin="dense"
                      mb="20px"
                      id="tenent_contact"
                      label="Tenent Contact"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.tenent_contact?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit contact number",
                    },
                  }}
                />
                {/*___________________________ notes __________________________ */}
                <Controller
                  name="notes"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.notes}
                      margin="dense"
                      mb="20px"
                      id="notes"
                      label="Notes"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.notes?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />
              </Grid>
            </Grid>
            {/* __________________________________File Picker_________________________*/}
            <Box mt={4}>
              <Droper
                value={attachments}
                onChange={(e) => setAttachments(e)}
                uploadLabel={"Upload ID"}
              />
            </Box>

            <ButtonGroup className="form__button-toolbar">
              <Button color="primary" type="submit" disabled={disabled}>
                Update
              </Button>
              <Button
                type="button"
                onClick={() => {
                  closePopup();
                  setIsAboveError(false);
                  reset({
                    rent: "",
                    deposit: "",
                    tenent_name: "",
                    tenent_contact: "",
                    notes: "",
                  });
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UpdateBooking;
