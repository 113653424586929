import React from 'react'
import Dropzone from "react-dropzone";

const Droper = ({ singleFile, name, value, onChange, uploadLabel, style }) => {
    const files = value;
    const onDrop = (onDropFiles) => {
        onChange(
            onDropFiles.map((fl) =>
                Object.assign(fl, {
                    preview: URL.createObjectURL(fl),
                })
            )
        );
    };
    const removeFile = (index, e) => {
        e.preventDefault();
        onChange(value.filter((val, i) => i !== index));
    };
    return (
        <div style={{ borderRadius: "1rem" }} className={`dropzone dropzone--multiple `}>
            <Dropzone

                className="dropzone__input"
                name={name}
                onDrop={(filesToUpload) => {
                    {
                        (singleFile ? value.length < 1 : true) &&
                            onDrop(value ? value.concat(filesToUpload) : filesToUpload);
                    }
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <div  {...getRootProps()} className="dropzone__input">
                        {(!files || files?.length === 0) && (
                            <div className="dropzone__drop-here">
                                <span className="lnr lnr-upload" />
                                {uploadLabel ? uploadLabel : "Drop file here to upload"}
                            </div>
                        )}
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
            {files && Array.isArray(files) && (
                <div className="dropzone__imgs-wrapper">
                    {files.map((file, i) => (
                        <div
                            className="dropzone__img"
                            key={file.name}
                            style={{ backgroundImage: `url(${file.preview})`, height: "10rem", width: "10rem", borderRadius: "1rem" }}
                        >
                            <p className="dropzone__img-name">{file.name}</p>
                            <button
                                className="dropzone__img-delete"
                                type="button"
                                onClick={(e) => removeFile(i, e)}
                            >
                                Remove
                            </button>
                            <button className="dropzone__img-preview" type="button">
                                <a href={`${file.preview}`} target="_blank">
                                    <span>Preview</span>
                                </a>
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Droper