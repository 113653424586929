"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    'alignContent',
    'alignItems',
    'alignSelf',
    'flex',
    'flexDirection',
    'flexWrap',
    'flexFlow',
    'flexGrow',
    'flexShrink',
    'flexBasis',
    'justifyContent',
    'order',
    // Layout
    'bottom',
    'display',
    'left',
    'position',
    'right',
    'top',
    'overflow',
    // Dimension
    'height',
    'maxHeight',
    'maxWidth',
    'minHeight',
    'minWidth',
    'width',
    // Color
    'backgroundColor',
    'color',
    'opacity',
    // Text
    'fontSize',
    'fontFamily',
    'fontStyle',
    'fontWeight',
    'letterSpacing',
    'lineHeight',
    'maxLines',
    'textAlign',
    'textDecoration',
    'textDecorationColor',
    'textDecorationStyle',
    'textIndent',
    'textOverflow',
    'textTransform',
    // Sizing/positioning
    'objectFit',
    'objectPosition',
    'objectPositionX',
    'objectPositionY',
    // Margin/padding
    'margin',
    'marginHorizontal',
    'marginVertical',
    'marginTop',
    'marginRight',
    'marginBottom',
    'marginLeft',
    'padding',
    'paddingHorizontal',
    'paddingVertical',
    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',
    // Transformations
    'transform',
    'transformOrigin',
    'transformOriginX',
    'transformOriginY',
    // Borders
    'border',
    'borderWidth',
    'borderColor',
    'borderStyle',
    'borderTop',
    'borderTopColor',
    'borderTopStyle',
    'borderTopWidth',
    'borderRight',
    'borderRightColor',
    'borderRightStyle',
    'borderRightWidth',
    'borderBottom',
    'borderBottomColor',
    'borderBottomStyle',
    'borderBottomWidth',
    'borderLeft',
    'borderLeftColor',
    'borderLeftStyle',
    'borderLeftWidth',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'borderBottomRightRadius',
    'borderBottomLeftRadius',
    'borderRadius',
];
