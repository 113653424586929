import React from "react";
import { Card, CardBody, Col, Progress, Tooltip } from "reactstrap";
import TrendingDownIcon from "mdi-react/TrendingDownIcon";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { ic_content_copy_twotone } from "react-icons-kit/md/ic_content_copy_twotone";
import { uniqueId } from "lodash";
import Icon from "react-icons-kit";
import { useState } from "react";

const Bookings = ({ projectInfo, index }) => {
  console.log(projectInfo);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const onCopy = () => {
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <Link to={`/app/se/view/${projectInfo.project_id}`}>
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
                {projectInfo.project_title}
              </h5>
              {/* <TrendingDownIcon className="dashboard__trend-icon" /> */}
            </div>
            <h5 className="dashboard__booking-total-description">
              <strong>Customer Name:</strong>{" "}
              {`${projectInfo.customer_firstname} ${projectInfo.customer_lastname}`}
            </h5>
            <h5 className="dashboard__booking-total-description">
              <strong>Site Engineer Name:</strong>{" "}
              {`${projectInfo.site_engineer_firstname} ${projectInfo.site_engineer_firstname}`}
            </h5>
            <h5 className="dashboard__booking-total-description">
              <strong>Location:</strong> {projectInfo.location}
            </h5>
            {/* <div className="progress-wrap progress-wrap--small progress-wrap--pink-gradient progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">87%</p>
            <Progress value={87} />
          </div> */}
          </Link>
          <div className="text-right" style={{ textAlign: "right" }}>
            <CopyToClipboard
              id={`copy${index}`}
              onCopy={() => onCopy()}
              options={{ format: "text/plain" }}
              // text={
              //   "Customer Name: 	&amp;" +
              //   projectInfo.customer_firstname +
              //   " " +
              //   projectInfo.customer_lastname +
              //   "\
              //   Customer Name: " +
              //   projectInfo.customer_firstname +
              //   " " +
              //   projectInfo.customer_lastname
              // }
              text={`Customer Name: ${projectInfo.customer_firstname} ${projectInfo.customer_lastname}\nSite Engineer: ${projectInfo.site_engineer_firstname} ${projectInfo.site_engineer_firstname}\nSite Engineer Contact #: ${projectInfo.site_engineer_contact_num}\nExecution Lead: ${projectInfo.service_provider_firstname} ${projectInfo.service_provider_lastname}\nExecution Lead Contact #: ${projectInfo.service_provider_contact_num}\nLocation: ${projectInfo.location}\nGeoLocation: ${projectInfo.lat_lng}`}
            >
              <Icon icon={ic_content_copy_twotone} />
            </CopyToClipboard>
            <Tooltip
              className="px-1"
              placement="top"
              isOpen={tooltipOpen}
              delay={{ show: "0", hide: "450" }}
              autohide={false}
              target={`copy${index}`}
              // toggle={tooltipToggle}
            >
              Copied!!
            </Tooltip>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Bookings;
