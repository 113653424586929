import React from "react";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import style from "./Footer.module.css";
const Footer = () => {
  return (
    <div className={style.main_footer}>
      <div className={style.container}>
        <div className={style.main_1}>
          <div className={style.sitemap_container_main}>
            <div className={style.sitemap_content_main}>
              <p>Site Map</p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/">Home</a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/how-we-work/">
                  How it works
                </a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="http://housingfactory.in/stories/">
                  Blogs and Stories
                </a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/app/hf/projects/">
                  Our Projects
                </a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/our-packages/">
                  Our Packages
                </a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/about-us/">About-us</a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/contact-housing-factory/">
                  Contact-us
                </a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/frequently-asked-questions/">
                  FAQ's
                </a>
              </p>
            </div>
          </div>
          <div className={style.sitemap_container}>
            <div className={style.sitemap_content}>
              <p>
                <a href="https://housingfactory.in/Privacy-policy/">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={style.main_2}>
          <div className={style.address}>
            <div className="et_pb_text_inner">
              <h1>Office Location:</h1>
              <p>&nbsp;</p>
              <p>Epic Housing Factory Pvt Ltd.</p>
              <p>No.679, 1st floor</p>
              <p>9th Main, 7th Sector, HSR Layout</p>
              <p>Bengalore-560102</p>
              <p>&nbsp;</p>
            </div>
          </div>
          <div className={style.divider}>
            <div className="et_pb_divider_internal"></div>
          </div>
          <div className={style.contect_us}>
            <div className="et_pb_text_inner">
              <h1>Connect to us:</h1>
              <p>&nbsp;</p>
              <p>Call : +91 6360759762 Email:contact@housingfactory.in</p>
            </div>
          </div>
          <div className={style.divider}>
            <div className="et_pb_divider_internal"></div>
          </div>
          <div className={style.copyRight}>
            <div className="et_pb_text_inner">
              <p>
                <span>
                  © {new Date().getFullYear()} housingfactory.in All rights
                  reserved
                </span>
              </p>
            </div>
          </div>
          <ul className={style.Social_link}>
            <li className={style.followLink}>
              <a
                href="#"
                className="icon et_pb_with_border"
                title="Follow on Facebook"
                target="_blank"
              >
                <span>
                  <Facebook color="blue" />
                </span>
              </a>
            </li>
            <li className={style.followLink}>
              <a
                href="#"
                className="icon et_pb_with_border"
                title="Follow on Twitter"
                target="_blank"
              >
                <span>
                  <Twitter color="blue" />
                </span>
              </a>
            </li>
            <li className={style.followLink}>
              <a
                href="#"
                className="icon et_pb_with_border"
                title="Follow on Instagram"
                target="_blank"
              >
                <span>
                  <Instagram color="red" />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
