
import { Box, Checkbox, Input, Menu, MenuButton, MenuItem, MenuList, Radio, RadioGroup, Select, Stack, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useRef, useState } from 'react'
import { addTask } from '../../redux/actions/taskManager';
import styles from "./taskmanager.module.css"
import Droper from './Droper';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,

} from '@chakra-ui/react'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { useEffect } from 'react';
import CheckBoxes from './Check';
import { AddIcon, CloseIcon } from "@chakra-ui/icons"



const TaskInput = ({ asstasks, taskFetcher }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    /////////chakra end/////////////





    const [TaskData, setTaskData] = useState(
        {
            assignDate: new Date(Date.now()).toISOString()
            , assignedBy: getUserName()
            , assignedTo: ""
            , task: ""
            , taskStartDate: ""
            , taskEndDate: ""
            , status: "ToDo"
            , comments: "Default Comment"
            , attachments: []
            , remainder: "00:00"
            , remainderdate: "15-DEC-2022"
            , tasktitle: ""

        }
    )

    function handleTaskData(e, referer, assVal) {
        //  console.log(e, referer, "oooooooooo")
        if (referer == "attachments") {
            //  console.log(e, "cc1")
            setTaskData({
                ...TaskData,
                attachments: [...e]
            })


        }
        else {

            if (referer == "remainder") {
                let [date, time] = e.split("T")
                // console.log(date, time, "ppppppppppp")

                setTaskData({
                    ...TaskData,
                    ["remainder"]: time,
                    ["remainderdate"]: date
                })
            } else if (referer == "assignedTo") {
                if (e) {
                    setTaskData({
                        ...TaskData,
                        ["assignedTo"]: TaskData.assignedTo + assVal + "$"
                    })
                } else {
                    setTaskData({
                        ...TaskData,
                        ["assignedTo"]: TaskData.assignedTo.replace(`${assVal}$`, "")
                    })
                }
                //  console.log(assVal, referer, e)
            }
            else {

                //  console.log(e, referer)
                setTaskData({
                    ...TaskData,
                    [referer]: e,
                })
            }



        }
    }

    // function checker() {
    //     let taskArr = TaskData.assignedTo.split("$")
    //     let obj = {}
    //     taskArr.map((ele) => obj[ele] = false)
    //     console.log(obj, "ooobbbjjj")
    // }

    function handleSubmit() {
        event.preventDefault()

        addTask(TaskData).then((res) => {
            taskFetcher()
            if (res.status) {
                toast({
                    title: 'Task created.',
                    description: "Task has been sent.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    size: "lg"
                })
                onClose()

            } else {
                toast({
                    title: 'Task not created.',
                    description: "Internal Error",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    size: "5rem"
                })

            }
            // console.log(res.status, "hjsbvcjks")
        })
    }

    function getUserName() {
        let useremail = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).login).userDetails.email
        //  console.log(useremail)
        return useremail
    }

    // console.log(TaskData, "cc2")
    return (
        <div>
            <Button w={"1rem"} borderRadius={"50%"} colorScheme='blue' m={1} onClick={() => {
                onOpen()
            }}>
                <AddIcon />
            </Button>

            <Modal

                size={"6xl"}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(50deg)' />
                <ModalContent borderRadius={"1rem"} overflowX={"hidden"} overflowY={"scroll"} h={"85vh"} mx={3} >

                    <ModalHeader display={"flex"} flexWrap={"wrap-reverse"} justifyContent={"space-between"} fontSize={"large"} paddingLeft={"4"} paddingRight={"10"} pb={0} paddingTop={"6"} >
                        <Text px={2} fontSize={"1.5rem"} color={"rgb(2,80,86)"} fontWeight={"550"}  >
                            Task Manager
                        </Text>
                        <Text px={2} fontWeight={"550"}  >
                            Add Task
                        </Text>
                    </ModalHeader>

                    <ModalCloseButton />
                    <form onSubmit={handleSubmit} enctype="multipart/form-data" >
                        <ModalBody pb={6} py={2} >

                            <FormControl mt={4} >
                                <Input required type={"text"} placeholder={"Task Title"} value={TaskData.tasktitle} onChange={(e) => handleTaskData(e.target.value, "tasktitle")} />
                            </FormControl>

                            <CheckBoxes cdata={TaskData.assignedTo} handleTaskData={handleTaskData} taskData={TaskData.assignedTo} />

                            <Box>
                                {TaskData.assignedTo.split("$").map((ele) => {
                                    //console.log(ele, "ele")
                                    //console.log(TaskData.assignedTo)
                                    return <Box key={ele} >
                                        {
                                            ele && <Box m={2} borderRadius={"1rem"} pl={3} backgroundColor="blackAlpha.200" w={"fit-content"} >
                                                {ele == "ALL" ? "ALL" : ele.split(" ")[1]}
                                                <Button backgroundColor={"transparent"} w={"0.5rem"} h={"1.3rem"} borderRadius={"50%"}
                                                    onClick={() => handleTaskData(false, "assignedTo", ele)} >
                                                    <CloseIcon
                                                        boxSize={"0.8rem"}
                                                    />
                                                </Button>
                                            </Box>
                                        }
                                    </Box >
                                })}
                            </Box>

                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} >
                                <FormControl maxW={"30rem"} mt={4}  >
                                    <FormLabel> Task Start Date :</FormLabel>
                                    <Input required
                                        size="md"
                                        type="datetime-local"
                                        onChange={(e) => handleTaskData(e.target.value, "taskStartDate")} />
                                </FormControl>

                                <FormControl maxWidth={"30rem"} mt={4} >
                                    <FormLabel> Task End Date :</FormLabel>
                                    <Input required
                                        size="md"
                                        type="datetime-local"
                                        onChange={(e) => handleTaskData(e.target.value, "taskEndDate")} />
                                </FormControl>
                            </Box>
                            <FormControl mt={4} >
                                <Textarea required type={"text"} placeholder={"Enter Task Description"} value={TaskData.task} onChange={(e) => handleTaskData(e.target.value, "task")}
                                />
                            </FormControl>




                            <FormControl textAlign={"center"} isRequired={true} mt={4} >
                                <FormLabel> Task Status :</FormLabel>
                                <RadioGroup onChange={(e) => handleTaskData(e, "status")} value={TaskData.status} >
                                    <Box flexWrap={"wrap"} display={"flex"} justifyContent={"space-between"}  >
                                        <Radio size={"lg"} value='ToDo'>ToDo</Radio>
                                        <Radio size={"lg"} value='WIP'>WIP</Radio>
                                        <Radio size={"lg"} value='Pending'>Pending</Radio>
                                        <Radio size={"lg"} value='Completed'>Completed</Radio>
                                    </Box>
                                </RadioGroup>
                            </FormControl>

                            {/* <FormControl mt={4} >
                                <Input required type={"text"} placeholder={"Comments"} value={TaskData.comments} onChange={(e) => handleTaskData(e.target.value, "comments")} />
                            </FormControl> */}


                            {/* <FormControl mt={4} >
                                <FormLabel>Remainder :</FormLabel>
                                <Input
                                    required
                                    size="md"
                                    type="datetime-local"
                                    onChange={(e) => handleTaskData(e.target.value, "remainder")} />
                            </FormControl> */}

                            <Box mt={4} >
                                <Droper value={TaskData.attachments} onChange={(e) => handleTaskData(e, "attachments")} uploadLabel={"Task Attachments"} />

                            </Box>

                        </ModalBody>

                        <ModalFooter>

                            <Button disabled={TaskData?.assignedTo?.length <= 0 || !TaskData.assignedBy} type="submit" colorScheme='blue' mr={3}>
                                Save
                            </Button>
                            <Button colorScheme='red' variant='outline' onClick={() => {
                                onClose()
                            }}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

        </div >




    )
}

export default TaskInput






