import React, { useEffect, useState } from "react";
import PropTypes, { func } from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { useSelector } from "react-redux";
import { fetchPaymentRequestsCount } from "../../../redux/actions/workOrderAction";

const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,
  workOrders,
}) => {
  const [accesscode, setaccesscode] = useState("");
  const loginState = useSelector((state) => state.login);

  let newOneCount = localStorage.getItem("requestCountNew");
  let Oldcount = localStorage.getItem("requestCount");
  const [newCount, setNewCount] = useState(newOneCount);

  const [notify, setNotify] = useState(false);
  console.log(Oldcount, newCount, notify, "sfdfsdfds");

  const getPaymentRequests = async () => {
    const res = await fetchPaymentRequestsCount(0, 0, 0);
    if (res?.data) {
      setNewCount((prev) => res?.data?.[0]?.count);
      localStorage.setItem("requestCountNew", res?.data?.[0]?.count);
    }
  };
  useEffect(() => {
    if (Oldcount != newCount) {
      setNotify((prev) => true);
    } else {
      setNotify((prev) => false);
    }
  });

  useEffect(() => {
    if (Oldcount != newCount) {
      setNotify((prev) => true);
    } else {
      setNotify((prev) => false);
    }
  }, [newCount]);

  useEffect(() => {
    let interval = setInterval(() => {
      accesscode.charAt(15) == 1 && getPaymentRequests();
      //console.log(Oldcount, newCount, "timer");
      if (localStorage.getItem("requestCountgetNotification")) {
        setNotify((prev) => false);
        localStorage.setItem("requestCountgetNotification", 0);
      }
    }, 60000);
    setNewCount(newOneCount);
    return () => clearInterval(interval);
  }, [accesscode]);

  function getUserName() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    // console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com"
    ) {
      return true;
    }
    return false;
  }
  function getUserName22() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    // console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com" ||
      useremail == "nehag.ehf@gmail.com"
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    getUserName();
    setaccesscode(binaryconverter(loginState.userDetails.access_control));
  }, []);

  // console.log(accesscode.length);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  return (
    <div className="sidebar__content">
      {accesscode.charAt(25) == 1 && (
        <SidebarLink
          title="Dashboard"
          icon="apartment"
          route={`/app/${role}/dashboard`}
          onClick={onClick}
        />
      )}

      {accesscode.charAt(26) == 1 && (
        <SidebarLink
          title="Create Project"
          icon="file-add"
          route={`/app/${role}/create-project`}
          onClick={onClick}
        />
      )}
      {(accesscode.charAt(17) == 1 ||
        accesscode.charAt(18) == 1 ||
        accesscode.charAt(19) == 1) && (
        <SidebarCategory
          title="Rental"
          icon="user"
          newLink={true}
          sidebarCollapse={sidebarCollapse}
        >
          <SidebarLink
            title="Rental Dashboard"
            // icon="users"
            route={`/app/${role}/Building`}
            onClick={onClick}
          />
          <SidebarLink
            title="Unit Dashboard"
            // icon="users"
            route={`/app/${role}/UnitDashboard`}
            onClick={onClick}
          />
          <SidebarLink
            title="Ledger"
            // icon="users"
            route={`/app/${role}/Building/ledger`}
            onClick={onClick}
          />
        </SidebarCategory>
      )}

      {accesscode.charAt(1) == 1 && (
        <SidebarLink
          title="Users List"
          icon="users"
          route={`/app/${role}/users-list`}
          onClick={onClick}
        />
      )}

      {accesscode.charAt(3) == 1 && (
        <SidebarLink
          title="Ledger"
          icon="users"
          route={`/app/${role}/ledger`}
          onClick={onClick}
        />
      )}

      {accesscode.charAt(8) == 1 && (
        <SidebarLink
          title="Work Orders"
          icon="users"
          route={`/app/${role}/wo-view`}
          onClick={onClick}
        />
      )}

      {accesscode.charAt(11) == 1 && (
        <SidebarLink
          title="Purchase Orders"
          icon="users"
          route={`/app/${role}/po-view`}
          onClick={onClick}
        />
      )}

      {accesscode.charAt(14) == 1 && (
        <SidebarLink
          title="Vendors List"
          icon="users"
          route={`/app/${role}/vendors-list`}
          onClick={onClick}
        />
      )}

      {accesscode.charAt(15) == 1 && (
        <div style={{ position: "relative", display: "Flex" }}>
          {notify && (
            <span
              className="notification"
              style={{
                display: "block",
                color: "#fff",
                borderRadius: "50%",
                position: "absolute",
                background: "red",
                padding: "2px 8px",
                top: "0px",
                left: "0px",
                zIndex: 3,
              }}
            >
              {1}
            </span>
          )}
          <SidebarLink
            title="Requests"
            icon="tag"
            route={`/app/${role}/requests`}
            onClick={onClick}
          />
        </div>
      )}

      {(accesscode.charAt(17) == 1 ||
        accesscode.charAt(18) == 1 ||
        accesscode.charAt(19) == 1) && (
        <SidebarCategory
          title="Attendance"
          icon="user"
          newLink={true}
          sidebarCollapse={sidebarCollapse}
        >
          {accesscode.charAt(17) == 1 && (
            <SidebarLink
              title="View"
              // icon="users"
              route={`/app/${role}/labour-attendance/view`}
              onClick={onClick}
            />
          )}

          {accesscode.charAt(18) == 1 && (
            <SidebarLink
              title="Dashboard"
              // icon="users"
              route={`/app/${role}/labour-attendance/dashboard`}
              onClick={onClick}
            />
          )}

          {accesscode.charAt(19) == 1 && (
            <SidebarLink
              title="Attendance"
              // icon="users"
              route={`/app/${role}/labour-attendance`}
              onClick={onClick}
            />
          )}
        </SidebarCategory>
      )}

      {(accesscode.charAt(20) == 1 ||
        accesscode.charAt(21) == 1 ||
        accesscode.charAt(23) == 1) && (
        <SidebarCategory
          title="Leads"
          icon="diamond"
          newLink={true}
          sidebarCollapse={sidebarCollapse}
        >
          {accesscode.charAt(20) == 1 && (
            <SidebarLink
              title="Dashboard"
              route={`/app/${role}/leads/dashboard`}
              onClick={onClick}
            />
          )}
          {accesscode.charAt(21) == 1 && (
            <SidebarLink
              title="All Leads"
              route={`/app/${role}/leads/all`}
              onClick={onClick}
            />
          )}
          {accesscode.charAt(23) == 1 && (
            <SidebarLink
              title="My Leads"
              route={`/app/${role}/leads/`}
              onClick={onClick}
            />
          )}
          {/* <SidebarLink
            title="Add Lead"
            route="/app/admin/leads/add"
            onClick={onClick}
          /> */}
        </SidebarCategory>
      )}

      {accesscode.charAt(31) == 1 && (
        <SidebarLink
          title="Tasks"
          icon="users"
          route={`/app/${role}/tasks`}
          onClick={onClick}
        />
      )}
      {!(
        loginState.userDetails.role_id == 4 ||
        loginState.userDetails.role_id == 5 ||
        loginState.userDetails.role_id == 6 ||
        loginState.userDetails.role_id == 7
      ) && (
        <SidebarLink
          title="Location Tracker"
          icon="users"
          route={`/app/${role}/locationtracker`}
          onClick={onClick}
        />
      )}

      {getUserName() && (
        <>
          <SidebarLink
            title="Access Control"
            icon="users"
            route={`/app/${role}/accesscontrol`}
            onClick={onClick}
          />
        </>
      )}

      {getUserName() && (
        <>
          <SidebarLink
            title="Projects Display"
            icon="diamond"
            route={`/app/${role}/projectsdisplay`}
            onClick={onClick}
          />
        </>
      )}
      {getUserName() && (
        <>
          <SidebarLink
            title="Projects Display Edit"
            icon="diamond"
            route={`/app/${role}/projectsdisplayedit`}
            onClick={onClick}
          />
        </>
      )}

      {getUserName22() && (
        <>
          <SidebarLink
            title="Tracking Data"
            icon="diamond"
            route={`/app/${role}/expenditure`}
            onClick={onClick}
          />
        </>
      )}

      {/* 
      {loginState.userDetails.role_id === 4 && (
        <>
          <SidebarLink
            title="Dashboard"
            icon="users"
            route="/app/customer/dashboard"
            onClick={onClick}
          />
        </>
      )} */}

      <SidebarLink
        title="Log out"
        icon="exit"
        route="/app/logout"
        onClick={onClick}
      />

      <ul className="sidebar__block">
        {/* <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={onClick} /> */}
        {/* <SidebarCategory title="Layout" icon="layers" sidebarCollapse={sidebarCollapse}>
        <button type="button" className="sidebar__link" onClick={changeToLight}>
          <p className="sidebar__link-title">Light Theme</p>
        </button>
        <button type="button" className="sidebar__link" onClick={changeToDark}>
          <p className="sidebar__link-title">Dark Theme</p>
        </button>
      </SidebarCategory> */}
      </ul>
      {/* <ul className="sidebar__block">
      <SidebarCategory title="Example Pages" icon="diamond" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Page one" route="/pages/one" onClick={onClick} />
        <SidebarLink title="Page two" route="/pages/two" onClick={onClick} />
        <SidebarCategory title="Example Subpages" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Subpage one" route="/" onClick={onClick} />
          <SidebarLink title="Subpage two" route="/" onClick={onClick} />
        </SidebarCategory>
      </SidebarCategory>
    </ul> */}
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;

// {
//   loginState.userDetails.role_id === 2 && (
//     <>
//       <SidebarLink
//         title="Dashboard"
//         icon="apartment"
//         route="/app/pm/dashboard"
//         onClick={onClick}
//       />
//       <SidebarLink
//         title="Create Project"
//         icon="file-add"
//         route="/app/pm/create-project"
//         onClick={onClick}
//       />
//       <SidebarCategory
//         title="Work Orders"
//         icon="layers"
//         sidebarCollapse={sidebarCollapse}
//       >
//         <SidebarLink
//           title="View WO"
//           route="/app/pm/wo-view"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="Create WO"
//           route="/app/pm/wo-create"
//           onClick={onClick}
//         />
//       </SidebarCategory>
//       <SidebarCategory
//         title="Attendance"
//         icon="user"
//         newLink={true}
//         sidebarCollapse={sidebarCollapse}
//       >
//         <SidebarLink
//           title="Attendance"
//           // icon="users"
//           route="/app/pm/labour-attendance"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="View"
//           // icon="users"
//           route="/app/pm/labour-attendance/view"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="Dashboard"
//           // icon="users"
//           route="/app/pm/labour-attendance/dashboard"
//           onClick={onClick}
//         />
//       </SidebarCategory>
//     </>
//   )
// }

// {
//   loginState.userDetails.role_id === 3 && (
//     <>
//       <SidebarLink
//         title="Dashboard"
//         icon="apartment"
//         route="/app/se/dashboard"
//         onClick={onClick}
//       />
//       {/* <SidebarCategory
//             title="Purchase Orders"
//             icon="layers"
//             sidebarCollapse={sidebarCollapse}
//           >
//             <SidebarLink
//               title="View PO"
//               route="/app/se/po-view"
//               onClick={onClick}
//             />
//             <SidebarLink
//               title="Create PO"
//               route="/app/se/po-create"
//               onClick={onClick}
//             />
//           </SidebarCategory> */}

//       <SidebarLink
//         title="Purchase Orders"
//         icon="users"
//         route="/app/se/po-view"
//         onClick={onClick}
//       />
//       <SidebarCategory
//         title="Attendance"
//         icon="user"
//         newLink={true}
//         sidebarCollapse={sidebarCollapse}
//       >
//         <SidebarLink
//           title="Attendance"
//           // icon="users"
//           route="/app/se/labour-attendance"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="View"
//           // icon="users"
//           route="/app/se/labour-attendance/view"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="Dashboard"
//           // icon="users"
//           route="/app/se/labour-attendance/dashboard"
//           onClick={onClick}
//         />
//       </SidebarCategory>
//     </>
//   )
// }

// {
//   loginState.userDetails.role_id === 9 && (
//     <>
//       <SidebarCategory
//         title="Leads"
//         icon="diamond"
//         newLink={true}
//         sidebarCollapse={sidebarCollapse}
//       >
//         <SidebarLink
//           title="Dashboard"
//           route="/app/sales/leads/dashboard"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="All Leads"
//           route="/app/sales/leads/all"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="My Leads"
//           route="/app/sales/leads/my"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="Add Lead"
//           route="/app/sales/leads/add"
//           onClick={onClick}
//         />
//       </SidebarCategory>
//       <SidebarCategory
//         title="Attendance"
//         icon="user"
//         newLink={true}
//         sidebarCollapse={sidebarCollapse}
//       >
//         <SidebarLink
//           title="Attendance"
//           // icon="users"
//           route="/app/sales/labour-attendance"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="View"
//           // icon="users"
//           route="/app/sales/labour-attendance/view"
//           onClick={onClick}
//         />
//         <SidebarLink
//           title="Dashboard"
//           // icon="users"
//           route="/app/sales/labour-attendance/dashboard"
//           onClick={onClick}
//         />
//       </SidebarCategory>
//     </>
//   )
// }

// {
//   (loginState.userDetails.role_id === 1 ||
//     loginState.userDetails.role_id === 8) && (
//     <>

//       {/* <SidebarCategory
//             title="Work Orders"
//             icon="layers"
//             sidebarCollapse={sidebarCollapse}
//           >
//             <SidebarLink
//               title="View WO"
//               route="/app/admin/wo-view"
//               onClick={onClick}
//             />
//             <SidebarLink
//               title="Create WO"
//               route="/app/admin/wo-create"
//               onClick={onClick}
//             />
//           </SidebarCategory> */}

//       {/* <SidebarCategory
//             title="Purchase Orders"
//             icon="layers"
//             sidebarCollapse={sidebarCollapse}
//           >
//             <SidebarLink
//               title="View PO"
//               route="/app/admin/po-view"
//               onClick={onClick}
//             />
//             <SidebarLink
//               title="Create PO"
//               route="/app/admin/po-create"
//               onClick={onClick}
//             />
//           </SidebarCategory> */}

//     </>
//   )
// }
