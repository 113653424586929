import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
// import { ChakraProvider, Input, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AutofpsSelect, Create, Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  addUnit,
  deleteBuilding,
  deleteUnit,
  fetchBuildings,
  fetchUnit,
  fetchUnits,
} from "../../../redux/actions/rentalsActions";
import AddUnits from "./AddUnits";
import UpdateUnits from "./UpdateUnits";
import { styled } from "@mui/system";
import { unit_type_arr, amenities_arr } from "../Constant";
const ProjectUnit = () => {
  const [loading, setLoading] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [enableInput, setEnableInput] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [initialValues, setInitialValues] = useState({
    unit_type: 1,
    unit_name: "",
    floor: 0,
    rent: "",
    deposit: "",
    amenities: "1",
    status: 1,
    description: "",
  });
  const [UpdateValues, setUpdateValues] = useState({
    unit_type: 1,
    unit_name: "",
    floor: 0,
    rent: "",
    deposit: "",
    amenities: "1",
    status: 1,
    description: "",
  });

  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState([]);
  const [dataBuilding, setDataBuilding] = useState({});
  const [deleteId, setdeleteId] = useState();

  //__________________ Model______________
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  //__________________ Model__________________

  const { id } = useParams();
  function UpdatePropValue(data) {
    setUpdateValues({ ...data });
  }
  const StatusArr = ["", "Occupied", "Available"];

  const createUser = () => {
    setInitialValues({
      unit_type: 1,
      unit_name: "",
      floor: 0,
      rent: "",
      deposit: "",
      amenities: "1",
      status: 1,
      description: "",
    });

    // setShowCreateUserForm(true);
    handleOpen();
  };
  const GenerateBooking = () => {
    const data = {
      unit_type: 1,
      unit_name: "G",
      floor: 1,
      rent: "1000",
      deposit: "1000",
      amenities: "1",
      status: 2,
      description: "System Generated",
    };
    data.building_id = id;
    console.log(data, "create building data", dataBuilding);
    for (let i = 0; i < dataBuilding.units; i++) {
      data.unit_name = "G" + (i + 1);
      data.unit_name = "G" + (i + 1);
      addUnit(data).then((res) => {
        console.log(res, "");
        setAlertMsg("Unit Data Added successfully");
      });
    }
  };
  function delUnits() {
    console.log(deleteId);
    deleteUnit(deleteId).then((res) => {
      console.log(res, "deleted");
      setAlertMsg("Unit Data Deleted successfully");
    });
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  useEffect(() => {
    fetchUnit(Number(id)).then((res) => {
      console.log(res, "res of units");
      setData([...res?.res?.rows]);
    });
    fetchBuildings().then((res) => {
      console.log(
        res?.res?.rows.find((el) => el.building_id === Number(id)),
        "res of rental",
        id
      );
      setDataBuilding({
        ...res?.res?.rows.find((el) => el.building_id === Number(id)),
      });
    });
  }, [alertMsg]);

  return (
    <Container className="dashboard">
      <h3
        className="page-title"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "8px",
          wordSpacing: "15px",
        }}
      >
        Rental Building Units
      </h3>
      <Card>
        <CardContent sx={{ overflow: "scroll", height: "83vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Building ID</TableCell>
                    <TableCell>{dataBuilding.building_id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Building Name</TableCell>
                    <TableCell>{dataBuilding.bname}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Number of Units</TableCell>
                    <TableCell>{dataBuilding.units}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>{dataBuilding.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Owner</TableCell>
                    <TableCell>{dataBuilding.owner}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Owner Contact</TableCell>
                    <TableCell>{dataBuilding.owner_contact}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Care Taker</TableCell>
                    <TableCell>{dataBuilding.caretaker}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Care Taker Contact</TableCell>
                    <TableCell>{dataBuilding.caretaker_contact}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Box sx={{ minHeight: "50px" }}>
            <ButtonGroup>
              <Button
                color="primary"
                type="button"
                className="previous"
                variant={"outlined"}
                onClick={() => createUser()}
              >
                <Create />
                Create Units
              </Button>
              <Button
                color="primary"
                type="button"
                className="previous"
                variant={"contained"}
                onClick={() => GenerateBooking()}
                disabled={data.length}
              >
                <AutofpsSelect />
                Generate Units
              </Button>
            </ButtonGroup>
          </Box>
          {/* <EditableReactTable reactTableData={reactTableData} /> */}
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            // columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data.map((obj) => (
              <Grid xs>
                <Card
                  key={obj.booking_id}
                  sx={{
                    minWidth: "250px",
                    m: 2,
                    color: ["green", "red"][obj.status - 1],
                    background: ["#def6de", "#f6dede"][obj.status - 1],
                  }}
                >
                  <CardHeader
                    title={
                      <NavLink
                        to={`/app/${role}/Building/unit/${obj.building_id}/Booking/${obj.unit_id}`}
                      >
                        {obj.unit_id + " "} {" " + obj.unit_name}
                      </NavLink>
                    }
                    subheader={StatusArr[obj.status]}
                    action={
                      <>
                        <IconButton
                          size={"small"}
                          onClick={() => {
                            UpdatePropValue(obj);
                            handleOpenUpdate();
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size={"small"}
                          onClick={() => {
                            setdeleteId(obj.unit_id);
                            handleOpenDialog();
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    }
                  />
                  <NavLink
                    to={`/app/${role}/Building/unit/${obj.building_id}/Booking/${obj.unit_id}`}
                  >
                    <CardContent>
                      {" "}
                      <p>Unit Name: {obj.unit_name}</p>
                      <p>Unit Type: {unit_type_arr[Number(obj.unit_type)]}</p>
                      <p>Amenities: {amenities_arr[Number(obj.amenities)]}</p>
                      <p>Deposit: {obj.deposit}</p>
                      <p>Rent: {obj.rent}</p>
                      <p>Floor: {obj.floor}</p>
                      <p>Status: {StatusArr[obj.status]}</p>
                      <p>Description: {obj.description}</p>
                    </CardContent>
                  </NavLink>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ height: 40, textAlign: "center " }}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </CardContent>
      </Card>
      {/* //_____________________________ ADD Unit _______________________ */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <AddUnits
            closePopup={handleClose}
            initialValues={initialValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}

            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      {/* //_____________________________ Delete Unit _________________________ */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are U Sure You want to delete The building"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            if you click on Agree the building will be permanently delete from
            DataBase
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button
            onClick={() => {
              delUnits();
              handleCloseDialog();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* //_____________________________ Update Unit _____________________________ */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <UpdateUnits
            closePopup={handleCloseUpdate}
            initialValues={UpdateValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      <Snackbar
        open={!!alertMsg}
        autoHideDuration={3000}
        onClose={() => setAlertMsg("")}
        sx={{
          "&.MuiSnackbar-root": { left: "50%", transform: "translateX(-50%)" },
        }}
      >
        <Alert
          onClose={() => setAlertMsg("")}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProjectUnit;
