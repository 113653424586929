import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";
import { invoice_type_arr, payment_type_arr, type_arr } from "../Constant";

const LedgerModel = ({ open1, handleClose1, handleOpen1, ledgerModelObj }) => {
  return (
    <Modal
      open={open1}
      onClose={handleClose1}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          minWidth: 320,
          bgcolor: "background.paper",
          // border: "2px solid #000",
          boxShadow: 24,
          // p: 4,
          MaxHeight: "90vh",
          overflow: "scroll",
        }}
      >
        <Table sx={{ overflow: "scroll" }}>
          <TableHead>
            <TableCell>Ledger Id</TableCell>
            <TableCell>Invoice Type</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Attachment</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Payment Type</TableCell>
          </TableHead>
          <TableBody>
            {ledgerModelObj.map((obj, ind) => (
              <TableRow key={ind}>
                <TableCell> {obj.rl_id}</TableCell>
                <TableCell> {invoice_type_arr[obj.invoice_type]}</TableCell>
                <TableCell> {type_arr[obj.type]}</TableCell>
                <TableCell>{obj.amount}</TableCell>

                <TableCell>{obj.b_name + "||" + obj.u_name}</TableCell>
                <TableCell>{moment(obj.date).format("DD-MM-YYYY")}</TableCell>
                <TableCell>{payment_type_arr[obj.payment_type]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Modal>
  );
};

export default LedgerModel;
