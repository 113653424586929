import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { sidebarReducer, themeReducer, rtlReducer, loginReducer, projectsInfoReducer } from '../../redux/reducers/index';
import { persistStore, persistReducer } from 'redux-persist'


const rootReducers = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  login: loginReducer,
  projects: projectsInfoReducer
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducers)

const storeConfiguration = () => {
  let store = createStore(persistedReducer, {}, applyMiddleware(thunk))
  let persistor = persistStore(store)
  return { store, persistor }
};

export default storeConfiguration;

