import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { faHouse, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import styles from "./hflprojects.module.css";
import {
  GetDetailProjectDisplayData,
  getprojectdisplaydata,
} from "../../redux/actions/projectsData";
import { useNavigate, useParams } from "react-router-dom";
// import ploticon from "./plotimg.png"
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Detailspage = () => {
  const [Data, setData] = useState([]);

  const [load, setload] = useState(false);

  const [imgload, setimgload] = useState(false);

  let navigate = useNavigate();

  let { id } = useParams();
  console.log(id, "bgusdhjgbnuhsg");
  function getDetailProjectDisplayData() {
    let projectdisplay_id = id.split("$")[1];
    GetDetailProjectDisplayData(projectdisplay_id).then((res) => {
      setData(res.res.rows[0]);
      console.log(res, "16");
      setload(true);
    });
  }
  console.log(Data);
  useEffect(() => {
    getDetailProjectDisplayData();
  }, []);

  console.log(imgload);

  return (
    <>
      <Header />
      {load ? (
        <Box pt={"10vh"}>
          <Box my={"3rem"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              maxW={"30rem"}
              p={"1rem"}
              margin={"auto"}

            >
              <Image
                src={Data?.cover_image[0]}
                alt="Green double couch with wooden legs"
                borderRadius="lg"
                opacity={imgload ? "1" : "0"}
                onLoad={() => {
                  setimgload(true);
                }}
              />
              {!imgload &&
                <Box
                  boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                  position={"absolute"} alignSelf={"center"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"15rem"}
                  w={"20rem"}
                  borderRadius={"1rem"}
                >
                  <CircularProgress size={"4rem"} isIndeterminate color="green.300" />

                </Box>
              }
            </Box>
            <Box
              textAlign={"center"}
              fontWeight={"700"}
              color={"#04b8ae"}
              fontSize={"1.5rem"}
              m={"1.2rem"}
            >
              {Data.project_title}
            </Box>
            <Box
              width={"80%"}
              m={"auto"}
              color={"blackAlpha.700"}
              p={"1rem"}
              fontSize={"1.2rem"}
              textAlign={"center"}

            >
              {Data.description}
            </Box>
            <Box>
              <Divider
                borderRadius={"1rem"}
                w={"85%"}
                m={"auto"}
                backgroundColor={"blackAlpha.400"}
                border={"2px solid black"}
              />

              <Box
                w={"80%"}
                p={"1rem"}
                m={"auto"}
                fontWeight={"semibold"}
                color={"#04b8ae"}
                fontSize={"1.5rem"}
              >
                Project Details –
              </Box>
              <Box
                px={"2.5rem"}
                display={"flex"}
                justifyContent={"space-evenly"}
                flexWrap={"wrap"}
                color={"#04b8ae"}
                margin={"auto"}
                w={"90%"}
              >
                <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"}>
                  {/* <Image src={ploticon} /> */}

                  <FontAwesomeIcon icon={faHouse} />

                  <Box fontSize={"1.2rem"} color={"blackAlpha.700"}>
                    Plot Size: {Data.plot_size}
                  </Box>
                </Box>

                <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  <Box fontSize={"1.2rem"} color={"blackAlpha.700"}>
                    Package: {Data.package}
                  </Box>
                </Box>
                <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"}>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <Box fontSize={"1.2rem"} color={"blackAlpha.700"}>
                    Location: {Data.location}
                  </Box>
                </Box>
                <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"}>
                  <FontAwesomeIcon icon={faBuilding} />
                  <Box fontSize={"1.2rem"} color={"blackAlpha.700"}>
                    No of Floors: {Data.no_of_floors}
                  </Box>
                </Box>
                <Box textAlign={"center"} p={"0.5rem"} fontSize={"2rem"}>
                  <FontAwesomeIcon icon={faStopwatch} />
                  <Box fontSize={"1.2rem"} color={"blackAlpha.700"}>
                    Duration: {Data.project_duration}
                  </Box>
                </Box>
              </Box>

              <Divider
                borderRadius={"1rem"}
                w={"85%"}
                m={"auto"}
                backgroundColor={"blackAlpha.400"}
                border={"2px solid black"}
              />

              <Box
                color={"#04b8ae"}
                w={"80%"}
                p={"1rem"}
                m={"auto"}
                fontWeight={"semibold"}
                fontSize={"1.5rem"}
              >
                Journey Milestones –
              </Box>
              <Grid
                p={"2rem"}
                columnGap={"1rem"}
                rowGap={"2rem"}
                className={styles.gridbox}
                width={"90%"}
                margin={"auto"}
                gap={1}
              >
                {Data &&
                  Data.detail_images &&
                  Data.detail_images_order.map((ele) => {
                    return (
                      <Box
                        borderRadius="lg"
                        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                        m={"auto"}
                      >
                        <Image
                          maxW={"20rem"}
                          src={ele}
                          alt="Green double couch with wooden legs"
                          borderRadius="lg"
                        />
                      </Box>
                    );
                  })}
              </Grid>
            </Box>

            <Box justifyContent={"center"} display={"flex"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                textAlign={"center"}
                fontWeight={"700"}
                color={"#04b8ae"}
                fontSize={"1.5rem"}
                alignSelf={"center"}
              >
                Hire the best construction service
                <a style={{ textDecoration: "none" }} href="https://housingfactory.in/contact-housing-factory/"><Button colorScheme={"red"} m={"1.5rem"} w={"20rem"}>
                  Let's Construct
                </Button></a>
              </Box>
              <Box width={"30%"}>
                <Image src="https://housingfactory.in/Assets/wp-content/uploads/2020/08/2.png" />
              </Box>
            </Box>
          </Box>

          <Footer />
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100vh"}
        >
          <CircularProgress size={"6rem"} isIndeterminate color="green.300" />
        </Box>
      )}
    </>
  );
};

export default Detailspage;
