import React, { useEffect, useState } from "react";
import PropTypes, { func } from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { useSelector } from "react-redux";
import { fetchPaymentRequestsCount } from "../../../redux/actions/workOrderAction";

const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,
  workOrders,
}) => {
  const [accesscode, setaccesscode] = useState("");
  const loginState = useSelector((state) => state.login);

  function getUserName() {
    let useremail = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).login
    ).userDetails.email;
    // console.log(useremail, "uihwfdwiufh");
    if (
      useremail == "kvksatish.hf@gmail.com" ||
      useremail == "gauravpadaliya.ehf@gmail.com" ||
      useremail == "rakesh.kamble@gmail.com" ||
      useremail == "aparna.n2@gmail.com"
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    getUserName();
    setaccesscode(binaryconverter(loginState.userDetails.access_control));
  }, []);

  // console.log(accesscode.length);

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }

  return (
    <div className="sidebar__content">
      {localStorage.getItem("isLoggedin") === "true" && (
        <SidebarLink
          title="Dashboard"
          // icon="tag"
          route={`/user/dashboard`}
          onClick={onClick}
        />
      )}
      {localStorage.getItem("isLoggedin") === "true" &&
        (accesscode.charAt(17) == 1 ||
          accesscode.charAt(18) == 1 ||
          accesscode.charAt(19) == 1) && (
          <SidebarCategory
            title="Attendance"
            // icon="user"
            newLink={true}
            sidebarCollapse={sidebarCollapse}
          >
            {accesscode.charAt(17) == 1 && (
              <SidebarLink
                title="View"
                // icon="users"
                route={`/user/labour-attendance/view`}
                onClick={onClick}
              />
            )}

            {accesscode.charAt(18) == 1 && (
              <SidebarLink
                title="Dashboard"
                // icon="users"
                route={`/user/labour-attendance/dashboard`}
                onClick={onClick}
              />
            )}

            {accesscode.charAt(19) == 1 && (
              <SidebarLink
                title="Attendance"
                // icon="users"
                route={`/user/labour-attendance`}
                onClick={onClick}
              />
            )}
          </SidebarCategory>
        )}

      <SidebarLink
        title="Packages"
        // icon="users"
        route={`/user/Packages`}
        onClick={onClick}
      />
      <SidebarLink
        title="Hello2"
        // icon="tag"
        route={`/user/Hello2`}
        onClick={onClick}
      />
      <SidebarLink
        title="Hello3"
        // icon="users"
        route={`/user/Hello3`}
        onClick={onClick}
      />
      <SidebarLink
        title="Hello4"
        // icon="tag"
        route={`/user/Hello4`}
        onClick={onClick}
      />
      <SidebarLink
        title="Hello5"
        // icon="users"
        route={`/user/Hello5`}
        onClick={onClick}
      />
      <SidebarLink
        title="Hello6"
        // icon="tag"
        route={`/user/Hello6`}
        onClick={onClick}
      />
      <ul className="sidebar__block">
        {/* <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={onClick} /> */}
        {/* <SidebarCategory title="Layout" icon="layers" sidebarCollapse={sidebarCollapse}>
        <button type="button" className="sidebar__link" onClick={changeToLight}>
          <p className="sidebar__link-title">Light Theme</p>
        </button>
        <button type="button" className="sidebar__link" onClick={changeToDark}>
          <p className="sidebar__link-title">Dark Theme</p>
        </button>
      </SidebarCategory> */}
      </ul>
      {/* <ul className="sidebar__block">
      <SidebarCategory title="Example Pages" icon="diamond" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Page one" route="/pages/one" onClick={onClick} />
        <SidebarLink title="Page two" route="/pages/two" onClick={onClick} />
        <SidebarCategory title="Example Subpages" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Subpage one" route="/" onClick={onClick} />
          <SidebarLink title="Subpage two" route="/" onClick={onClick} />
        </SidebarCategory>
      </SidebarCategory>
    </ul> */}
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
