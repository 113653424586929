import { Box, CircularProgress, Fade } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import CreateWorkOrderForm from "./CreateWorkOrderForm";

const WorkOrder = () => {
  const [pleaseWait, setPleasewait] = useState(false);

  const onChangePleaseWait = (status) => {
    setPleasewait(status);
  };
  const [loading, setLoading] = useState(true);

  return (
    <Container className="dashboard create-project">
      <Row>
        <Col md={12}>
          <h3 style={{ color: "#000" }}>Work Orders</h3>
        </Col>
      </Row>
      <Row>
        <CreateWorkOrderForm
          onChangePleaseWait={onChangePleaseWait}
          setLoading={setLoading}
        />
      </Row>


      <Box sx={{ height: 40, textAlign: "center " }}>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      </Box>
      {pleaseWait && (
        <div class="pleasewait-load">
          <div class="load__icon-wrap">
            <svg class="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      )}
    </Container>
  );
};

export default WorkOrder;
