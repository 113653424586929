import { Box, Button, CircularProgress, Input, Textarea, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import Droper from './Droper'
import { useState } from 'react'
import { addProjectData, getprojectdisplaydata } from '../../redux/actions/projectsData'

const ProjectsDisplay = () => {
    const [proData, setproData] = useState({
        project_no: "",
        cover_title: "",
        project_title: "",
        plot_size: "",
        location: "",
        no_of_floors: "",
        project_duration: "",
        package: "",
        description: "",
        cover_image: [],
        detail_images: [],
        detail_images_order: ""

    })
    const [load, setload] = useState(false)

    const toast = useToast()

    function handleProData(val, name) {
        console.log(val, name)
        if (name == "detail_images") {
            console.log(val, "detail_images")

            setproData({
                ...proData,
                detail_images: [...val],
                detail_images_order: [...proData.detail_images_order]
            })

        }
        else if (name == "cover_image") {
            console.log(val, "cover_image")
            setproData({
                ...proData,
                cover_image: val
            })
        }
        else {
            setproData({
                ...proData,
                [name]: val,
            })
        }
    }

    function handleProDataSubmit() {
        setload(false)
        addProjectData(proData).then((re) => {
            if (re.status) {
                toast({
                    title: 'Uploaded Successfully.',
                    description: "Project Data Uploaded Successfully",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setproData({
                    project_no: "",
                    cover_title: "",
                    project_title: "",
                    plot_size: "",
                    location: "",
                    no_of_floors: "",
                    project_duration: "",
                    package: "",
                    description: "",
                    cover_image: [],
                    detail_images: [],
                    detail_images_order: ""

                })
                setload(true)
            } else {
                setload(true)
                toast({
                    title: 'Deleted unsuccessfull.',
                    description: "Internal Error",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        })
    }



    function getDetailProjectDisplayData() {
        setload(false)
        getprojectdisplaydata().then((res) => {
            handleProData(res?.res?.rows[0]?.project_no + 1, "project_no")
            setload(true)
        })
    }

    useEffect(() => {
        getDetailProjectDisplayData()
    }, [])


    return (<>
        {
            load ?

                <Box p={"1rem"} >
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        handleProDataSubmit()
                    }} action="">
                        <Box fontSize={"1.4rem"} >

                            <Droper singleFile={true} value={proData.cover_image} onChange={(e) => handleProData(e, "cover_image")} uploadLabel={"Cover Image"} />

                            <Input fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='Cover Title' onChange={(e) => handleProData(e.target.value, "cover_title")} />

                            <Input fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='Project Title' onChange={(e) => handleProData(e.target.value, "project_title")} />

                            <Input fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='Plot Size' onChange={(e) => handleProData(e.target.value, "plot_size")} />

                            <Input fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='Location' onChange={(e) => handleProData(e.target.value, "location")} />

                            <Input fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='No of Floors' onChange={(e) => handleProData(e.target.value, "no_of_floors")} />

                            <Input fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='Project Duration' onChange={(e) => handleProData(e.target.value, "project_duration")} />

                            <Input fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='Package' onChange={(e) => handleProData(e.target.value, "package")} />

                            <Textarea fontSize={"1.3rem"} my={"0.5rem"} isRequired type={"text"} placeholder='Description' onChange={(e) => handleProData(e.target.value, "description")} />

                            <Droper style={{ height: "80vh" }} singleFile={false} value={proData.detail_images} onChange={(e) => handleProData(e, "detail_images")} uploadLabel={"Detail Images"} />

                        </Box>

                        <Button my={"1rem"} colorScheme='blue' type='submit' disabled={proData.cover_image.length == 0 || proData.detail_images.length == 0} >Submit</Button>
                    </form>
                </Box>
                :
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"100vh"}
                >
                    <CircularProgress size={"6rem"} isIndeterminate color="green.300" />
                </Box>
        }
    </>)
}

export default ProjectsDisplay