import React, { useState } from "react";
import LeadForm from "./LeadForm";
import { Container, Grid } from "@mui/material";
import { Row, Col } from "reactstrap";

const AddLead = (props) => {
  const initialValues = {
    name: "",
    number: "",
    email: "",
    location: "",
    origin: "",
    assign_to: 0,
    start_date: null,
    budget: "",
    follow_up: "new",
    requirement: "",
    feedback: "",
    rating: "",
    status: "active",
    prop_type: "",
    remind_on: null,
    comment: "",
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Add Lead</h3>
        </Col>
      </Row>
      <Grid container spacing={1}>
        <Grid item xs>
          <LeadForm initialValues={initialValues} pathname={props.pathname} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddLead;
