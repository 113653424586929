import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogInForm from "./components/LogInForm";
import GoogleLogin from "../../lib/react-google-login";
import { loginReq, fetchLogin } from "../../redux/actions/loginActions";
import { useSelector, useDispatch } from "react-redux";
import Cunst_Image from "./undraw_UC.svg";
import "./login.css";
const LogIn = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const navigate = useNavigate();

  const responseGoogle = (resp) => {
    // console.log(resp.profileObj);
    let obj = {
      googleId: resp.profileObj.googleId,
      lastName: resp.profileObj.familyName,
      firstName: resp.profileObj.givenName,
      email: resp.profileObj.email,
      name: resp.profileObj.name,
    };

    if (Object.prototype.hasOwnProperty.call(resp, "profileObj")) {
      loginReq(JSON.stringify(obj)).then((res) => {
        if (res.status) {
          dispatch(fetchLogin(res.data));
          localStorage.setItem("isLoggedin", true);
          localStorage.setItem("role", res.data.role_id);

          if (res.data.role_id === 1 || res.data.role_id === 8) {
            navigate("/app/admin/dashboard");
          } else if (res.data.role_id === 2) {
            navigate("/app/pm/dashboard");
          } else if (res.data.role_id === 3) {
            navigate("/app/se/dashboard");
          } else if (res.data.role_id === 9) {
            navigate("/app/sales/leads/my");
          } else {
            navigate("/app/customer/dashboard");
          }
        } else {
          setErrorMessage(res.msg);
        }
      });
      // console.log(resp);
    } else console.log("false");
  };

  const submitLogin = (data) => {
    setErrorMessage("");

    if (!data.email || !data.password) {
      setErrorMessage("Please enter both Email and Password");
    }

    const resp = loginReq(JSON.stringify(data));
    resp.then((data) => {
      if (data.status) {
        localStorage.setItem("isLoggedin", true);
        localStorage.setItem("role", data.data.role_id);
        dispatch(fetchLogin(data.data));
        if (data.data.role_id === 1 || data.data.role_id === 8) {
          navigate("/app/admin/dashboard");
        } else if (data.data.role_id === 2) {
          navigate("/app/pm/dashboard");
        } else if (data.data.role_id === 3) {
          navigate("/app/se/dashboard");
        } else if (data.data.role_id === 4) {
          navigate("/app/customer/dashboard");
        } else {
          navigate("/app/user/dashboard");
        }
      } else {
        setErrorMessage(data.msg);
      }
    });
  };

  return (
    <div>
      <div class="cloud"></div>
      <div class="cloud2"></div>
      <div className="account">
        <div className="Left_image">
          <img src={Cunst_Image} alt="no image" />
        </div>
        <div className="account__wrapper" style={{ color: "#000" }}>
          <div className="account__card" style={{ color: "#000" }}>
            <div className="account__head">
              <h3 className="account__title" style={{ color: "#000" }}>
                {" "}
                Welcome to
                <span className="account__logo" style={{ color: "#000" }}>
                  {" "}
                  Housing Factory
                  {/* <span className="account__logo-accent">DEV</span> */}
                </span>
              </h3>
              {/* <h4 className="account__subhead subhead">Start your business easily</h4> */}
            </div>
            <div className="account__social">
              <GoogleLogin
                clientId="25027215378-sg7g6jtgu3u5csocu1ibq1u8q323ovpc.apps.googleusercontent.com"
                buttonText="Login With Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            <div className="account__or" style={{ color: "#000" }}>
              <p style={{ color: "#000", fontSize: "1rem", fontWeight: "600" }}>
                SignIn/Create
              </p>
            </div>
            {/* <LogInForm onSubmit={submitLogin} errorMessage={errorMessage} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
