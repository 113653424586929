import React, { useState } from 'react'
import { Card, CardHeader, CardBody, CardFooter, Stack, Heading, Divider, ButtonGroup, Button, Image, Text, Box, CircularProgress, ScaleFade, useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import styles from "./hflprojects.module.css"

const ProjectCard = ({ data }) => {
    let navigate = useNavigate()

    const [imgload, setimgload] = useState(false);
    console.log(data, "uhcusdacd")
    return (<>
        <Card bg={"white"} className={styles.imagecont} borderRadius={"lg"} cursor={"pointer"} onClick={() => navigate(`${data.cover_title}$${data?.projectdisplay_id}`)} boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"} maxW='sm' minH={"15rem"} minW={"22rem"}  >
            <CardBody p={"0.2rem"}  >
                <Box boxShadow={"rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px"} borderRadius="lg" overflow={"hidden"}>
                    <Image
                        className={styles.imagehover}
                        src={data?.cover_image[0]}
                        alt={data?.cover_image[0]}
                        borderRadius="lg"
                        opacity={imgload ? "1" : "0"}
                        onLoad={() => {
                            setimgload(true);
                        }}

                    />
                    {!imgload &&
                        <Box

                            position={"absolute"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            height={"15rem"}
                            w={"100%"}
                            top={2}

                            borderRadius={"lg"}
                        >
                            <CircularProgress size={"4rem"} isIndeterminate color="green.300" />

                        </Box>
                    }

                </Box>


                <Box px={"1rem"} py={"0.5rem"} >
                    <Box textTransform={"uppercase"} fontWeight={"400"} color={"#04b8ae"} fontSize="1.5rem" >{data?.cover_title}</Box>
                    <Box color='blue.600' fontSize="1.1rem"  >
                        See More
                    </Box>
                </Box>
            </CardBody>
        </Card>

    </>)
}

export default ProjectCard