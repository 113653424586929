import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
// import { ChakraProvider, Input, Select } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  AutofpsSelect,
  CalendarMonth,
  CompareOutlined,
  Create,
  Delete,
  DiscFullSharp,
  Edit,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  deleteUnit,
  fetchBookings,
  fetchUnits,
} from "../../../redux/actions/rentalsActions";
import UpdateUnits from "../Units/UpdateUnits";
import styles from "./UnitDashboard.module.css";
import moment from "moment/moment";
import { unit_type_arr, amenities_arr } from "../Constant";
const UnitDashboard = () => {
  const [loading, setLoading] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [enableInput, setEnableInput] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [initialValues, setInitialValues] = useState({
    unit_type: 1,
    unit_name: "",
    floor: 0,
    rent: "",
    deposit: "",
    amenities: "1",
    status: 1,
    description: "",
  });
  const [UpdateValues, setUpdateValues] = useState({
    unit_type: 1,
    unit_name: "",
    floor: 0,
    rent: "",
    deposit: "",
    amenities: "1",
    status: 1,
    description: "",
  });

  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [deleteId, setdeleteId] = useState();
  const [calenderData, setCalenderData] = useState([]);

  //__________________ Model______________
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const [openDialog1, setOpenDialog1] = React.useState(false);
  const handleOpenDialog1 = () => setOpenDialog1(true);
  const handleCloseDialog1 = () => setOpenDialog1(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  //__________________ Model__________________

  function UpdatePropValue(data) {
    setUpdateValues({ ...data });
  }
  const StatusArr = ["", "Occupied", "Available"];

  function delUnits() {
    console.log(deleteId);
    deleteUnit(deleteId).then((res) => {
      console.log(res, "deleted");
      setAlertMsg("Unit Data Deleted successfully");
    });
  }

  function binaryconverter(hexadecimal) {
    let decimal = parseInt(hexadecimal, 16);
    let binary = decimal.toString(2);
    return binary;
  }
  let role = "";
  switch (loginState.userDetails.role_id) {
    case 1:
      role = "admin";
      break;
    case 2:
      role = "pm";
      break;
    case 3:
      role = "se";
      break;
    case 4:
      role = "customer";
      break;
    case 5:
      role = "serviceprovider";
      break;
    case 6:
      role = "vendor";
      break;
    case 7:
      role = "user";
      break;
    case 8:
      role = "admin";
      break;
    case 9:
      role = "sales";
      break;

    default:
      break;
  }

  useEffect(() => {
    fetchUnits().then((res) => {
      console.log(res?.res?.rows, "res all of units");
      setData([...res?.res?.rows]);
    });
    fetchBookings().then((res) => {
      console.log(res?.res?.rows, "res all of booking");
      setBookingData([...res?.res?.rows]);
    });
  }, [alertMsg]);

  useEffect(() => {
    setData((prev) =>
      prev?.map((obj, ind) => {
        let bookings = [];
        let status = false;
        let exit_date = new Date();
        bookingData?.forEach((el) => {
          if (el?.unit_id == obj?.unit_id) {
            bookings = [...bookings, el];
            if (
              moment(el.move_in).isSameOrBefore(moment(), "day") &&
              moment(el.move_out).isSameOrAfter(moment(), "day")
            ) {
              status |= true;
              exit_date = moment(el.move_out).format("DD-MM-YY");
            }
          }
        });
        obj.bookings = bookings;
        obj.status = !status + 1;
        obj.exit_date = exit_date;
        return obj;
      })
    );
  }, [bookingData, data]);

  useEffect(() => {
    setData((prev) =>
      prev.sort(function (a, b) {
        return Number(b.status) - Number(a.status);
      })
    );
  }, [data]);
  function getLength(obj) {
    return obj?.bookings?.filter((el) => {
      if (moment(el.move_out).isSameOrAfter(moment(), "day")) {
        return true;
      } else {
        return false;
      }
    }).length;
  }

  return (
    <Container className="dashboard">
      <h3
        className="page-title"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "8px",
          wordSpacing: "15px",
        }}
      >
        All Units
      </h3>
      <Card>
        <CardContent sx={{ overflow: "scroll", height: "83vh" }}>
          {/* <EditableReactTable reactTableData={reactTableData} /> */}

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            // columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data.map((obj) => (
              <Grid xs>
                <Card
                  key={obj.booking_id}
                  sx={{
                    minWidth: "250px",
                    m: 2,
                    fontSize: "2px",
                    color: [, "green", "red"][obj.status],
                    background: [, "#def6de", "#f6dede"][obj.status],
                  }}
                >
                  <div className={styles.contaniner}>
                    <div className={styles.header}>
                      <div className={styles.headerTitle}>
                        <NavLink
                          to={`/app/${role}/Building/unit/${obj.building_id}/Booking/${obj.unit_id}`}
                        >
                          {obj.bname +
                            " || " +
                            obj.unit_name +
                            " || " +
                            unit_type_arr[Number(obj.unit_type)]}
                        </NavLink>
                      </div>
                      <div className={styles.headerButton}>
                        <>
                          {obj.amenities == 1 && (
                            <Tooltip title={amenities_arr[obj.amenities]}>
                              <IconButton
                                size={"small"}
                                onClick={() => {
                                  setdeleteId(obj.unit_id);
                                  handleOpenDialog();
                                }}
                              >
                                <CompareOutlined />
                              </IconButton>
                            </Tooltip>
                          )}
                          {getLength(obj) ? (
                            <IconButton
                              size={"small"}
                              onClick={() => {
                                setCalenderData(
                                  obj.bookings
                                    .filter((el) => {
                                      if (
                                        moment(el.move_out).isSameOrAfter(
                                          moment(),
                                          "day"
                                        )
                                      ) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    })
                                    .map((el) => ({
                                      ...el,
                                      building_id: obj.building_id,
                                      unit_id: obj.unit_id,
                                    }))
                                );

                                handleOpenDialog1();
                              }}
                            >
                              <CalendarMonth />
                            </IconButton>
                          ) : (
                            ""
                          )}
                          <IconButton
                            size={"small"}
                            onClick={() => {
                              UpdatePropValue(obj);
                              handleOpenUpdate();
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </>
                      </div>
                    </div>
                    <div className={styles.content}>
                      <div className={styles.contentTitle}>
                        <span>
                          <div>R:{obj.rent}</div>
                          <div>D:{obj.deposit}</div>
                        </span>
                        <span>
                          {obj.status == 1 ? ` || ed - ${obj?.exit_date}` : " "}
                        </span>
                      </div>
                      <div className={styles.contentButton}></div>
                    </div>
                  </div>
                  {/* <NavLink
                      to={`/app/${role}/Building/unit/${obj.building_id}/Booking/${obj.unit_id}`}
                    >
                      <CardContent>
                        {" "}
                        <p>Unit Name: {obj.unit_name}</p>
                        <p>
                          Unit Type: {unit_type_arr[Number(obj.unit_type) - 1]}
                        </p>
                        <p>
                          Amenities: {amenities_arr[Number(obj.amenities) - 1]}
                        </p>
                        <p>Deposit: {obj.deposit}</p>
                        <p>Rent: {obj.rent}</p>
                        <p>Floor: {obj.floor}</p>
                        <p>Status: {StatusArr[obj.status]}</p>
                        <p>Description: {obj.description}</p>
                      </CardContent>
                    </NavLink> */}
                  {/* </Tooltip> */}
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ height: 40, textAlign: "center " }}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        </CardContent>
      </Card>

      {/* //_____________________________ Calander Unit _________________________ */}
      <Dialog
        open={openDialog1}
        onClose={handleCloseDialog1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Next Booking Details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Move In</TableCell>
                    <TableCell>Move Out</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calenderData?.map((row, ind) => (
                    <TableRow
                      key={ind}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <NavLink
                          to={`/app/${role}/Building/unit/${row.building_id}/Booking/${row.unit_id}/Invoice/${row.booking_id}`}
                        >
                          {moment(row.move_in).format("DD-MM-YYYY")}
                        </NavLink>
                      </TableCell>
                      <TableCell>
                        <NavLink
                          to={`/app/${role}/Building/unit/${row.building_id}/Booking/${row.unit_id}/Invoice/${row.booking_id}`}
                        >
                          {moment(row.move_out).format("DD-MM-YYYY")}
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* //_____________________________ Update Unit _____________________________ */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,

            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            // p: 4,
          }}
        >
          <UpdateUnits
            closePopup={handleCloseUpdate}
            initialValues={UpdateValues}
            alertMsg={alertMsg}
            setAlertMsg={setAlertMsg}
            // displayMsgAndSubmitForm={displayMsgAndSubmitForm}
          />
        </Box>
      </Modal>
      <Snackbar
        open={!!alertMsg}
        autoHideDuration={3000}
        onClose={() => setAlertMsg("")}
        sx={{
          "&.MuiSnackbar-root": { left: "50%", transform: "translateX(-50%)" },
        }}
      >
        <Alert
          onClose={() => setAlertMsg("")}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UnitDashboard;
