import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

const HFSnackbar = ({ snackbar, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={snackbar.status}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        elevation={6}
        variant="filled"
        severity={snackbar.type}
        sx={{ width: "100%" }}
      >
        {snackbar.msg}
      </Alert>
    </Snackbar>
  );
};
export default HFSnackbar;
