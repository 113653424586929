import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  addBooking,
  addBuilding,
  addRentalLedgers,
  addUnit,
  updateInvoice,
} from "../../../redux/actions/rentalsActions";
import { useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import moment from "moment/moment";
import DatepickerField from "../../Datepicker/DatepickerField";
import styles from "./Invoice.module.css";
import Droper from "../Booking/Droper";
import { invoice_type_arr } from "../Constant";

const CreateLedger = ({
  alertMsg,
  setAlertMsg,
  isHorizontal,
  closePopup,
  initialValues,
  invid,
  InvoiceObj,
}) => {
  const [isAboveError, setIsAboveError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [bookingDate, setBookingDate] = useState({
    date: new Date(),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: initialValues });
  console.log(initialValues, "sdfdsfds");
  const { id, uid, bookid } = useParams();
  const onSubmit = (data) => {
    data.building_id = Number(id);
    data.unit_id = Number(uid);
    data.booking_id = Number(bookid);
    data.invoice_id = Number(invid);
    console.log(
      { ...data, ...bookingDate, attachment: [...attachments] },
      "create ledger data"
    );
    const newData = { ...data, ...bookingDate, attachment: [...attachments] };

    setDisabled(true);
    addRentalLedgers(newData).then((res) => {
      console.log(res, "");
      setAlertMsg("Ledger Created successfully");
      setDisabled(false);
    });
    if (newData.amount == Number(InvoiceObj.rent) - Number(InvoiceObj.paid)) {
      InvoiceObj.status = 2;
    } else {
      InvoiceObj.status = 4;
    }
    updateInvoice(InvoiceObj).then((res) => {});

    reset({
      type: "",
      amount: "",
      invoice_type: "",
      payment_type: "",
      description: "",
    });
    setBookingDate({});
    setAttachments([]);
  };
  function OnChangeDate(name, val) {
    // console.log(val);
    setBookingDate((prev) => ({ ...prev, [name]: val }));
  }

  useEffect(() => {
    setValue("type", initialValues.type);
    setValue("invoice_type", initialValues.invoice_type);
    setValue("amount", initialValues.amount);
    setValue("payment_type", initialValues.payment_type);
    setValue("description", initialValues.description);
  }, [initialValues]);

  return (
    <Grid container>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h5">
            Create Ledger
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/*_____________________________units_name_________________________ */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {/*_____________________________ Date _________________________ */}

                <DatepickerField
                  name="Date"
                  className={styles.DateAtt}
                  stl={styles.DateWrapperClass}
                  onChange={(val) => OnChangeDate("move_in", val)}
                  value={new Date(bookingDate.date).toLocaleDateString("en-IN")}
                ></DatepickerField>

                {/*___________________________ Type __________________________ */}
                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.status}
                        labelId="demo-simple-select-label"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="type"
                        label="Type"
                        variant="outlined"
                        fullWidth
                        {...rest}
                      >
                        <MenuItem value="1">Expence</MenuItem>
                        <MenuItem value="2">Income</MenuItem>
                      </Select>
                    )}
                    rules={{
                      required: "This is required field",
                    }}
                  />
                </FormControl>
                {/*___________________________ amount __________________________ */}
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.amount}
                      margin="dense"
                      mb="20px"
                      id="amount"
                      label="Amount"
                      type="number"
                      fullWidth
                      placeholder={InvoiceObj.rent - InvoiceObj.paid}
                      variant="outlined"
                      helperText={errors?.amount?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    max: {
                      value: Number(InvoiceObj.rent - InvoiceObj.paid),
                      message: `  value can't exceed ${Number(
                        InvoiceObj.rent - InvoiceObj.paid
                      )}`,
                    },
                    min: {
                      value: 0,
                      message: `value should be in between 0 to ${Number(
                        InvoiceObj.rent - InvoiceObj.paid
                      )}`,
                    },
                  }}
                />

                {/*___________________________ invoice_type __________________________ */}
                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Invoice Type
                  </InputLabel>
                  <Controller
                    name="invoice_type"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.status}
                        labelId="demo-simple-select-label"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="invoice_type"
                        label="Invoice Type"
                        variant="outlined"
                        fullWidth
                        {...rest}
                      >
                        {invoice_type_arr
                          .map((el, ind) => (
                            <MenuItem value={ind}>{el}</MenuItem>
                          ))
                          .filter((el, ind) => ind !== 0)}
                      </Select>
                    )}
                    rules={{
                      required: "This is required field",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {/*___________________________ payment_type __________________________ */}
                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Payment Type
                  </InputLabel>
                  <Controller
                    name="payment_type"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.status}
                        labelId="demo-simple-select-label"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="payment_type"
                        label="payment_type"
                        variant="outlined"
                        fullWidth
                        {...rest}
                      >
                        <MenuItem value="1">Cash</MenuItem>
                        <MenuItem value="2">Online</MenuItem>
                      </Select>
                    )}
                    rules={{
                      required: "This is required field",
                    }}
                  />
                </FormControl>
                {/*___________________________ description __________________________ */}
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.description}
                      margin="dense"
                      mb="20px"
                      id="description"
                      label="Description"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.description?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    // pattern: {
                    //   value: /^[0-9]{10}$/,
                    //   message: "Please enter a valid 10-digit contact number",
                    // },
                  }}
                />
                <Box sx={{ padding: "15px" }}>
                  <Box>
                    <span sx={{ fontWeight: 600 }}>Rent </span>:{" "}
                    {InvoiceObj.rent}
                  </Box>
                  <Box>
                    <span sx={{ fontWeight: 600 }}>Paid </span>:{" "}
                    {InvoiceObj.paid + " "}
                  </Box>
                  <Box>
                    <span sx={{ fontWeight: 600 }}>To Pay </span>:{" "}
                    {InvoiceObj.rent - InvoiceObj.paid}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box mt={4}>
              <Droper
                value={attachments}
                onChange={(e) => setAttachments(e)}
                uploadLabel={"Upload ID"}
              />
            </Box>
            {/* __________________________________File Picker_________________________*/}

            <ButtonGroup className="form__button-toolbar">
              <Button color="primary" type="submit" disabled={disabled}>
                Submit
              </Button>
              <Button
                type="button"
                onClick={() => {
                  closePopup();
                  setIsAboveError(false);
                  reset({
                    type: "",
                    amount: "",
                    invoice_type: "",
                    payment_type: "",
                    description: "",
                  });
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CreateLedger;
