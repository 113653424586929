import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  updateBuilding,
  updateUnit,
} from "../../../redux/actions/rentalsActions";
import { unit_type_arr, amenities_arr, unit_sta_arr } from "../Constant";
const UpdateUnits = ({
  alertMsg,
  setAlertMsg,
  isHorizontal,
  closePopup,
  initialValues,
}) => {
  const [isAboveError, setIsAboveError] = useState(false);
  // const [alertMsg, setAlertMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: initialValues });

  const onSubmit = (data) => {
    console.log(data, "create Unit data");
    setDisabled(true);
    updateUnit(data).then((res) => {
      console.log(res, "updated");
      setAlertMsg("Unit Data updated successfully");
      setDisabled(false);
    });
    reset({
      unit_type: 1,
      unit_name: "",
      floor: 0,
      rent: "",
      deposit: "",
      amenities: "1",
      status: 1,
      description: "",
    });
  };

  useEffect(() => {
    setValue("unit_type", initialValues.unit_type);
    setValue("unit_name", initialValues.unit_name);
    setValue("rent", initialValues.rent);
    setValue("deposit", initialValues.deposit);
    setValue("Owner", initialValues.Owner);
    setValue("amenities", initialValues.amenities);
    setValue("status", initialValues.status);
    setValue("description", initialValues.description);
    // setUserRole(initialValues.user_role);
  }, [initialValues]);

  return (
    <Grid container>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h5">
            Update Units
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/*_____________________________Building_name_________________________ */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {/*__________________________unit_type_______________________ */}

                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label1">
                    Unit Type
                  </InputLabel>
                  <Controller
                    name="unit_type"
                    control={control}
                    // defaultValue=""
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.unit_type}
                        labelId="demo-simple-select-label1"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="unit_type"
                        label="Unit Type"
                        variant="outlined"
                        fullWidth
                        {...rest}
                      >
                        {unit_type_arr
                          .map((el, ind) => (
                            <MenuItem value={ind}>{el}</MenuItem>
                          ))
                          .filter((el, ind) => ind !== 0)}
                      </Select>
                    )}
                    rules={{ required: "This is required field" }}
                  />
                </FormControl>
                {/*__________________________unit_name_______________________ */}
                <Controller
                  name="unit_name"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.unit_name}
                      margin="dense"
                      mb="20px"
                      id="unit_name"
                      label="Unit Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.unit_name?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                    min: { value: 0, message: "Value cannot be less than 0" },
                    max: {
                      value: 100,
                      message: "Value cannot be greater than 100",
                    },
                  }}
                />
                {/*___________________________Owner_contect__________________________ */}
                <Controller
                  name="floor"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.floor}
                      margin="dense"
                      mb="20px"
                      id="floor"
                      label="Floor"
                      type="Number"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.floor?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />
                {/*___________________________Rent__________________________ */}
                <Controller
                  name="rent"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.deposit}
                      margin="dense"
                      mb="20px"
                      id="rent"
                      label="Rent"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.rent?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {/*__________________________amenities____________________________ */}
                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label2">
                    Amenities
                  </InputLabel>
                  <Controller
                    name="amenities"
                    control={control}
                    // defaultValue=""
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.unit_type}
                        labelId="demo-simple-select-label2"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="amenities"
                        label="Amenities"
                        variant="outlined"
                        fullWidth
                        {...rest}
                      >
                        {amenities_arr
                          .map((el, ind) => (
                            <MenuItem value={ind}>{el}</MenuItem>
                          ))
                          .filter((el, ind) => ind !== 0)}
                      </Select>
                    )}
                    rules={{ required: "This is required field" }}
                  />
                </FormControl>
                {/*___________________________deposit__________________________ */}
                <Controller
                  name="deposit"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.deposit}
                      margin="dense"
                      mb="20px"
                      id="deposit"
                      label="Deposit"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.deposit?.message}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "This is required field",
                  }}
                />
                {/*__________________________description____________________________ */}
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      autoFocus
                      error={errors.description}
                      margin="dense"
                      mb="20px"
                      id="description"
                      label="Description"
                      type="text"
                      fullWidth
                      variant="outlined"
                      helperText={errors?.description?.message}
                      {...rest}
                    />
                  )}
                  rules={{ required: "This is required field" }}
                />
                <FormControl fullWidth sx={{ margin: "9px 0px", mb: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    // defaultValue=""
                    render={({ field: { ref, ...rest } }) => (
                      <Select
                        error={errors.status}
                        labelId="demo-simple-select-label"
                        // sx={{ margin: "9px 0px", mb: "20px" }}
                        id="status"
                        label="Status"
                        variant="outlined"
                        fullWidth
                        {...rest}
                      >
                        {unit_sta_arr
                          .map((el, ind) => (
                            <MenuItem value={ind}>{el}</MenuItem>
                          ))
                          .filter((el, ind) => ind !== 0)}
                      </Select>
                    )}
                    rules={{ required: "This is required field" }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <ButtonGroup className="form__button-toolbar">
              <Button color="primary" type="submit" disabled={disabled}>
                Update
              </Button>
              <Button
                type="button"
                onClick={() => {
                  closePopup();
                  setIsAboveError(false);
                  reset({
                    Address: "",
                    Building_name: "",
                    location: "",
                    No_of_floor: 0,
                    Owner: "",
                    Unit_no: 0,
                    Care_taker: "",
                    Owner_contect: "",
                    CareTaker_contect: "",
                  });
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UpdateUnits;
